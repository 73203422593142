<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <div class="main-wrapper">
      <layout-employeeheader/>
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item"> Manage Orders</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row" v-if="!isFreelancer">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>Manage Orders </h3>
              </div>
              <orders-upBar :arr="this.arr"/>
              <!-- Proposals list -->
              <div class="proposals-section">
                <!-- Proposals -->
                <div class="proposals-section">
                  <div v-if="orders.length==0" class="m-5">
                    <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
                  </div>
                  <div v-for="item in orders" :key="item.id" class="freelancer-proposals">
                    <router-link :to="{name: 'Purchases Chat', params: {id:item.id,title:item.post.title.en}}">
                      <div class="project-proposals align-items-center freelancer">
                        <div class="d-flex space-between ">
                          <div class="content-divider-1"></div>
                          <div style="width:50px" class="projects-amount  ">
                            <div class="proposal-client px-3 ">
                              <h4 class="title-info mb-5 "> ID</h4>
                              <h2 class="client-price  "> {{ item.id }}</h2>
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="projects-amount">
                            <div class="">
                              <h4 class="title-info mb-5 "> Seller</h4>
                              <h2 class="client-price ">{{ item.seller.name }} </h2>
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="projects-amount ">
                            <div class="proposal-client  " style="margin: auto">
                              <img :src="item.post.photo_url" alt="" class="sales-img ">
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="projects-amount">
                            <div class="proposal-client">
                              <h4 class="title-info mb-5 ">Order Date
                              </h4>
                              <h2 class="client-price "> {{ item.post.created_at_format.date }}</h2>
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="projects-amount">
                            <div class="proposal-client ">
                              <h4 class="title-info mb-5 "> Total </h4>
                              <h2 class="client-price "> {{parseFloat(item.post.price)?.toFixed(2)}}</h2>
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="content-divider-1"></div>
                          <div class="projects-action text-center">
                            <div class="projects-btn mt-3">{{ item.status }}</div>
                          </div>
                          <div class="content-divider-1"></div>
                        </div>
                        <div class="description-proposal mt-2 ">
                          <h5 class="desc-title mb-2 ">Title</h5>
                          <p>{{ item.post.title.en }}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-12">
                      <div class="card-pagination text-center">
                        <button v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                                @click="nextPage(pagination.next_page_url)">
                          Load more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
                <please-switch></please-switch>
              </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
        this.api.getCustom(url).then(res => {
          if (res.status === 'success') {
            this.orders = this.orders.concat(res.data.data.orders.data)
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get('user/order?status=success').then(res => {
        if (res.status === 'success') {
          this.orders = res.data.data.orders.data
          this.arr = res.data.data.arr
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      orders: [],
      loading: true,
      activeClass: 'active',
      sales: [],
      page: 1,
      pagination: '',
    }
  },
  async created() {
    await this.getData()
  },
}
</script>