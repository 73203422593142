<template>
<!--<div>-->
<!--       &lt;!&ndash; Main Wrapper &ndash;&gt;-->
<!--        <div class="main-wrapper">-->
<!--			&lt;!&ndash; Header &ndash;&gt;-->
<!--            <layout-header></layout-header>-->
<!--			&lt;!&ndash; /Header &ndash;&gt;-->

<!--            &lt;!&ndash; Breadcrumb &ndash;&gt;-->
<!--			<div class="bread-crumb-bar">-->
<!--				<div class="container">-->
<!--					<div class="row align-items-center inner-banner">-->
<!--						<div class="col-md-12 col-12 text-center">-->
<!--							<div class="breadcrumb-list">-->
<!--								<nav aria-label="breadcrumb" class="page-breadcrumb">-->
<!--									<ol class="breadcrumb">-->
<!--										<li class="breadcrumb-item"><router-link to="/"><img src="../../../assets/img/home-icon.svg" alt=""> Home</router-link></li>-->
<!--										<li class="breadcrumb-item active" aria-current="page">PROFILE</li>-->
<!--									</ol>-->
<!--								</nav>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--			&lt;!&ndash; /Breadcrumb &ndash;&gt;-->
<!--			-->
<!--			&lt;!&ndash; Profile Banner &ndash;&gt;-->
<!--			<profilebanner :user="user" :userData="userData"></profilebanner>-->
<!--			&lt;!&ndash; Profile Banner &ndash;&gt;-->
<!--			-->
<!--			&lt;!&ndash; Page Content &ndash;&gt;-->
<!--			<div class="content">-->
<!--				<div class="container">	-->
<!--					<div class="row">-->
<!--						<div class="col-lg-8 col-md-12">-->
<!--							<div class="pro-view">-->
<!--								-->
<!--								<usertab :user="user"></usertab>-->
<!--								-->
<!--								&lt;!&ndash; About Tab Content &ndash;&gt;-->
<!--								<div class="pro-post widget-box company-post" >-->
<!--									<h3 class="pro-title">Gallery</h3>-->
<!--									<div class="pro-content company-gallery">-->
<!--										<div class="row">-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-10.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-7.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-3.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-4.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-5.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-6.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-7.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-8.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-9.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-10.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-11.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">-->
<!--												<div class="project-widget">-->
<!--													<div class="pro-image">													-->
<!--														<a class="fancystyle" data-fancybox="gallery2">-->
<!--															<img class="img-fluid" alt="User Image" src="../../../assets/img/gallery-12.jpg">-->
<!--															<div class="view-gallery">-->
<!--																<i class="far fa-eye"></i>-->
<!--															</div>-->
<!--														</a>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--											<div class="col-md-12 text-center">-->
<!--												<a href="javascript:void(0);" class="btn more-btn"><img src="../../../assets/img/loader-icon.svg" height="24" alt="User Image">Load More </a>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--								&lt;!&ndash; /About Tab Content &ndash;&gt;-->
<!--								-->
<!--							</div>-->
<!--						</div>-->
<!--					-->
<!--						&lt;!&ndash; profile Sidebar &ndash;&gt;-->
<!--&lt;!&ndash;						<companydetail1></companydetail1>&ndash;&gt;-->
<!--						&lt;!&ndash; /Profile Sidebar &ndash;&gt;-->
<!--						-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>		-->
<!--			&lt;!&ndash; /Page Content &ndash;&gt;-->
<!--           -->
<!--            &lt;!&ndash; Footer &ndash;&gt;-->
<!--            <layout-footer></layout-footer>-->
<!--            &lt;!&ndash; /Footer &ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash; /Main Wrapper &ndash;&gt;-->
<!--        <employeemodal></employeemodal>-->
<!--        </div>-->
</template>
<script>
export default{
	inject: ['api'],
	methods:{
		async getUserInfo(){
			this.api.get(`profile/${this.$route.path.split('/')[2]}`).then(res => {
				if(res.status == 'success'){
					this.userData = res.data.data
            		this.user = res.data.data.user
				}else{
					this.toast(res.data, 'danger')
				}
			})
		}
	},
	created(){
        this.getUserInfo()
	},
	data(){
		return {
			user: {},
            userData: {},
		}
	}
}
</script>