<template>
  <teleport to="head">
    <meta charset="UTF-8" />
    <meta :content="content" property="title" />
    <meta :content="lis" property="keywords" />
    <meta :content="sections.seo_description" name="description" />
    <template v-if="sections.photo_url">
      <meta :content="sections.photo_url" property="og:image:url" />
      <meta :content="sections.photo_url" property="og:image" />
    </template>
    <meta content="website" property="og:type" />
    <meta :content="content" property="og:title" />
    <meta :content="sections.seo_description" property="og:description" />
    <meta content="Gooreo" property="og:site_name " />
    <meta :content="`https://gooreo.com${$route.path}`" property="og:url" />
  </teleport>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header />
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                        ><img alt="" src="../../../assets/img/home-icon.svg" />
                        Home</router-link
                      >
                    </li>
                    <li aria-current="page" class="breadcrumb-item">
                      <router-link to="/projects">Projects</router-link>
                    </li>
                    <li
                      v-if="!loading"
                      aria-current="page"
                      class="breadcrumb-item"
                    >
                      {{ sections.content.en }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="row blog-grid-row">
                <categorycard-skeleton
                  v-for="i in 8"
                  v-if="loading"
                ></categorycard-skeleton>
                <div
                  v-for="item in subs"
                  v-if="!loading"
                  :key="item.id"
                  class="col-md-4 col-lg-3 col-sm-12"
                >
                  <!-- Blog Post -->
                  <div class="s-grid-card grid-card">
                    <div  class="card-image">
                      <router-link
                        :to="{
                          name: 'Project cat',
                          params: { id: item.slug, title: sections.title.en },
                        }"
                        ><img
                          :src="item.media_array[0].lg"
                          alt="Post Image"
                          class="img-fluid"
                      /></router-link>
                    </div>
                    <div style="text-align: center" class="card-content ">
                      <h3 class="m-card-title ">
                        <router-link
                          :to="{
                            name: 'Project cat',
                            params: { id: item.slug },
                          }"
                          >{{ item.title.en }}
                        </router-link>
                      </h3>
                    </div>
                  </div>
                  <!-- /Blog Post -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer />
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
import CategorycardSkeleton from "../components/skeleton-loaders/categorycard-skeleton";

export default {
  components: { CategorycardSkeleton },
  async created() {
    await this.getSubCategories();
  },
  inject: ["api"],
  methods: {
    getSubCategories() {
      this.api
        .get(`categories/${this.$route.path.split("/")[2]}`)
        .then((res) => {
          if (res.status == "success") {
            this.subs = res.data.data.section.childs;
            this.sections = res.data.data.section;
            let list = [];
            Object.keys(this.subs).forEach((e) => {
              list.push(this.subs[e].content.en);
            });
            this.lis = list;
            this.content = res.data.data.section.content.en;
            this.loading = false;
          } else {
            location.replace('404')
          }
        });
    },
  },
  data() {
    return {
      context: "",
      lis: [],
      content: "",
      loading: true,
      subs: [],
      sections: {},
    };
  },
};
</script>
<style scoped>
.m-card-title a:hover {
  color: #fff;
}
.page-breadcrumb .breadcrumb a:hover {
  color: rgb(241, 92, 61);
}
.col-sm-12 .s-grid-card {
  width: 100%;
  height: 300px;
  background-color:rgb(241, 92, 61);
}
.col-sm-12 .s-grid-card .card-image img {
  height: 250px;
}
</style>