<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header></layout-header>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img src="../../../assets/img/home-icon.svg" alt="">
                        Home
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">PROFILE</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <profilebanner :user="user" :userData="userData" :loading="loading"></profilebanner>
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <usertab></usertab>
                  <div class="pro-post widget-box company-post">
                    <div class="row ">
                      <h3 class="pro-title">Services</h3>
                      <div v-if="userData.services =='' " class="d-flex ">
                        <p>No Services added yet</p>
                      </div>
                      <div v-if="loading" class="row">
                        <services-card-skeleton v-for="i in 10 " :key="i"  />
                      </div>
                      <div v-if="!loading" v-for="item in userData.services" :key="item.id" class="col-md-4 col-sm-12">
                        <!-- Blog Post -->
                        <div class="s-grid-card grid-card">
                          <div class="card-image">
                            <router-link :to="`/marketplace/${item.slug}`"><img :src="item.photo_url"
                                                                                alt="Post Image" class="img-fluid">
                            </router-link>
                          </div>
                          <div class="card-content">
                            <ul class="entry-meta meta-item">
                              <li>
                                <div class="p-2"
                                     style="height: 60px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">
                                  <router-link :to="`/profile/${item.author.slug}`">
                                    <span> {{ item.title.en }}</span>
                                  </router-link>
                                </div>
                                <div class="post-author">
                                  <router-link :to="`/profile/${item.author.slug}`"><img
                                      :src="item.author.avatar" alt="Post Author">
                                    <span> {{ item.author.name }}</span>
                                  </router-link>
                                </div>
                              </li>
                              <li><i class="far fa-star"></i> {{
                                  item.reviews_avg == null ? 0 :
                                      item.reviews_avg
                                }}
                              </li>
                            </ul>
                            <h3 class="m-card-title">
                              <router-link :to="`/marketplace/${item.slug}`">See more</router-link>
                            </h3>
                            <p class="mb-0">${{ parseFloat(item.price)?.toFixed(2) }}</p>
                          </div>
                        </div>
                        <!-- /Blog Post -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- profile Sidebar -->
                <!--						<companydetail1></companydetail1>-->
                <!-- /Profile Sidebar -->
              </div>
            </div>
          </div></div>
      </div>
          <!-- /Page Content -->
          <!-- Footer -->
          <layout-footer></layout-footer>
          <!-- /Footer -->

    </div>
  </div>
</template>
<script>
export default {
  inject: ['api'],
  methods: {
    async getUserInfo() {
      this.api.get(`profile/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status == 'success') {
          this.userData = res.data.data
          this.user = res.data.data.user
          this.loading = false
        } else {
          location.replace('404')
        }
      })
    }
  },
  created() {
    this.getUserInfo()
  },
  data() {
    return {
      user: {},
      userData: {},
      loading: true,
    }
  }
}
</script>
<style scoped>
.content {
  padding: 7px 0 30px;
}
</style>