<template>
  <loader v-if="loading"></loader>
  <div class="dashboard-page">
    <div class="main-wrapper">
      <layout-header/>
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Security</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 mt-5 col-md-8 pt-5">
              <div class="account-content setting-content ">
                <div class="card">
                  <div class="pro-head">
                    <h3 class="pro-title without-border mb-0">Security</h3>
                  </div>
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-md-8">
                        <form>
                          <div class="form-group">
                            <label>Old Password</label>
                            <input class="form-control" type="password" v-model="oldPassword">
                          </div>
                          <div class="form-group">
                            <label>New Password</label>
                            <input class="form-control" type="password" v-model="newPassword">
                          </div>
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <input class="form-control" type="password" v-model="confirmPassword">
                          </div>
                          <div class="row">
                            <div class="col-md-12" @click.prevent="changePassword()">
                              <button class="btn btn-primary  btn-plan" type="submit">Update</button>
                            </div>
                          </div>
                        </form>
                        <div class="pro-body card  mt-3">
                          <form action="#" v-if="!user.two_factor_enabled">
                            <div class="form-group">
                              <h3>Two Factor Authentication</h3>
                              <h4>You haven't enabled two factor authentication.</h4>
                              <p>When two factor authentication is enabled, you will be prompted for a secure, random
                                token during authentication. You may retrieve this token from your phone's google
                                authenticator application.</p>
                            </div>
                            <div class="row">
                              <div class="col-md-12" @click.prevent="get2FAEnableCode()">
                                <a class="btn mb-5 btn-primary  btn-plan" data-toggle="modal"
                                   data-target="#qr">Enable</a>
                              </div>
                            </div>
                          </form>
                          <form action="#" v-if="user.two_factor_enabled">
                            <div class="form-group">
                              <h3>Two Factor Authentication</h3>
                              <h4>You have enabled two factor authentication.</h4>
                              <p>When two factor authentication is enabled, you will be prompted for a secure, random
                                token during authentication. You may retrieve this token from your phone's google
                                authenticator application.</p>
                            </div>
                            <div v-if="codes.length > 0" class="my-3">
                              <div class="m-5">
                                <span class="mt-3" v-html="`${this.svg}`"></span>
                              </div>
                            </div>
                            <div v-if="codes.length > 0">
                              <div class="codes"
                                   style="background-color: whitesmoke; padding: 0 15px;">
                                <ul style="list-style: none;">
                                  <li v-for="code in codes" :key="code">{{ code }}</li>
                                </ul>
                              </div>
                              <div>
                                <p>Store These Recovery Codes In A Secure Password Manager. They Can Be Used To Recover
                                  Access To Your Account If Your Two Factor Authentication Device Is Lost. </p>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div v-if="codes.length == 0" class="col-md-4" @click.prevent="show2FACodes()">
                                <a class="btn mb-5 btn-primary  btn-plan">Show recovery codes</a>
                              </div>
                              <div v-else class="" @click="generate2FACodes()">
                                <a class="btn mb-5 btn-primary -btn btn-plan">Regenerate recovery codes</a>
                              </div>
                              <div class="" @click.prevent="disableTwoFactorAuth()">
                                <a class="btn mb-5 btn-primary  btn-plan">Disable</a>
                              </div>
                            </div>
                          </form>
                          <form action="#">
                            <div class="form-group">
                              <h3>Browser Sessions</h3>
                              <div class="session d-flex col-lg-12 p-2" v-for="session in sessions">
                                <div class="me-3">
                                  <i v-if="session.agent.is_desktop" class="fas fa-desktop fa-2x"></i>
                                  <i v-else class="fas fa-mobile-alt fa-2x"></i>
                                </div>
                                <div>
                                  <h6 class="m-0">{{ session.agent.platform }} - {{ session.agent.browser }}</h6>
                                  <p class="m-0">{{ session.ip_address }} - <span
                                      :style="session.is_current_device ? 'color:green; font-weight:normal;' :
                                     'color:black; font-weight:normal;'">{{
                                      session.is_current_device ? 'This device'
                                          : session.last_active
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <a class="btn btn-primary  btn-plan" data-bs-toggle="modal" href="#file">Logout
                                  Other Browser Sessions</a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
    <div class="modal fade wallet-rupee" id="file">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
                class="far fa-times-circle orange-text"></i></a></span>
          </div>
          <div class="modal-body">
            <div class="payment-list wallet card-body wallet-list">
              <div class="wallet-head text-center">
                <h2 class="m-3">Confirm password</h2>
                <p class="m-3">For your security, please confirm your password to continue.</p>
              </div>
              <div class="form-group profile-group">
                <div class="input-group">
                  <form>
                    <input type="password" class="form-control" v-model="password">
                  </form>
                </div>
              </div>
              <div class="col-lg-12 btn btn-primary btn-pad" @click="confirm2FAPassword()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade wallet-rupee" id="qr">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
                class="far fa-times-circle orange-text"></i></a></span>
          </div>
          <div class="modal-body">
            <div class="payment-list wallet card-body wallet-list">
              <span class="m-auto d-block" style="width: 200px; height: 200px;" v-html="`${this.svg}`"></span>
              <div class="wallet-head text-center">
                <h2 class="m-3">Enter the authentication code</h2>
                <p class="m-3">For your security, please enter the authentication code you see in google authenticator
                  after scanning the qr code above to continue.</p>
              </div>
              <div class="form-group profile-group">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="authcode">
                </div>
              </div>
              <div class="col-lg-12 btn btn-primary btn-pad" @click="confirm2FACode()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    async getSessions() {
      this.api.get('auth/getSession').then(res => {
        if (res.status == 'success') {
          this.sessions = res.data.data
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getUser() {
      this.api.get('user').then(res => {
        if (res.status == 'success') {
          this.user = res.data
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async fixSecret() {
      this.api.getRaw(process.env.VUE_APP_BASE_URL + 'auth/2fa/fixsecret')
    },
    async enableTwoFactorAuth() {
      this.loading = true
      this.api.post('user/two-factor-authentication', {}).then(res => {
        this.fixSecret()
        location.reload()
      })
    },
    passwordStatus() {
      this.api.getRaw(process.env.VUE_APP_BASE_URL + 'user/two-factor-qr-code', {
        "Authorization": this.decrypted($cookies.get("token")),
        "Accept": "application/json",
        "Content-Type": "application/json",
      })
    },
    async confirm2FAPassword() {
      this.loading = true
      this.api.post('user/confirm-password', {password: this.password}).then(res => {
        this.loading = false
      })
    },
    async get2FAEnableCode() {
      this.loading = true
      this.api.getRaw(process.env.VUE_APP_BASE_URL + 'auth/2fa/enableqr').then(res => {
        this.svg = res.data.svg
        this.loading = false
      });
    },
    async confirm2FACode() {
      this.loading = true
      this.api.purePost('auth/2fa/validateqr', {
        "secret": this.authcode
      }).then(res => {
        if (res.data.valid) {
          this.enableTwoFactorAuth();
        } else {
          this.toast("The entered auth code is wrong, double check your google authenticator", "danger")
        }
        this.loading = false
      });
    },
    async show2FACodes() {
      try {
        this.api.getAvatar('user/two-factor-qr-code').then(res => {
          this.svg = res.data.data.svg
        })
        this.api.getAvatar('user/two-factor-recovery-codes').then(res => {
          this.codes = res.data.data
        })
      } catch (e) {
      }
    },
    async generate2FACodes() {
      try {
        this.api.post('user/two-factor-recovery-codes', {}).then(res => {
          location.reload()
        })
      } catch (e) {
      }
    },
    async disableTwoFactorAuth() {
      this.api.delete('user/two-factor-authentication').then(res => {
        location.reload()
      })
    },
    async changePassword() {
      this.api.post('auth/change_password', {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_password: this.confirmPassword,
      }).then(res => {
        if (res.status == 'success') {
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      authcode: '',
      password: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      sessions: [],
      user: {},
      codes: [],
      skills: [],
      about: '',
      svg: '',
      safety: '',
      locSafety: false,
      loading: false,
    }
  },
  created() {
    this.getUser()
    this.getSessions()
    // this.show2FACodes()
  }
}
</script>