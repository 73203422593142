<template>
  <loader v-if="loading" />
  <div style="height: 600px" v-if="loading">

  </div>
  <div v-if="!loading" class="chat-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-accountheader></layout-accountheader>
      <!-- /Header -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/user/dashboard">Dashboard
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Sales Chats</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="chat-window">
                <div class="chat-cont-right row">
                  <div class="col-md-12">
                    <div class="chat-body">
                      <div class="chat-scroll">
                        <ul class="list-unstyled">
                          <li v-for="msg in data.msgs?.data"
                            :class="msg.is_sender == 1 ? 'media sent d-flex' : 'media received d-flex'">
                            <div class="avatar flex-shrink-0">
                              <img :src="msg.is_sender == 1 ? this.avatar : msg.sender.avatar" alt="User Image"
                                class="avatar-img rounded-circle">
                              <p>{{
                                msg.created_at.split('T')[1].split(':')[0]
                                + ':' +
                                msg.created_at.split('T')[1].split(':')[1]
                              }}</p>
                            </div>
                            <div class="media-body flex-grow-1">
                              <div class="msg-box">
                                <div>
                                  <p>{{ msg.body }}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="data.order.status != 'cancelled'" class="chat-footer">
                      <div class="input-group">
                        <div class="avatar">
                          <img :src="this.avatar" alt="User Image" class="avatar-img rounded-circle">
                        </div>
                        <input v-model="msg" class="input-msg-send form-control"  @keypress.enter.prevent="sendMsg()" placeholder="Reply..." type="text">
                        <button class="btn btn-primary msg-send-btn rounded-pill" type="button"
                          @click.prevent="sendMsg()"><i class="fab fa-telegram-plane"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pro-post col-md-3 widget-box about-widget about-field">
                  <div class=" bg-warning-light p-2"
                    v-if="data.order.seller.id == this.localuser.id && data.order.status == 'initial'">
                    <p>You have a new order, are you ready to start processing it?</p>
                    <div class="d-flex justify-content-between col-lg-12">
                      <button class="btn border-0 btn-danger bg-danger-light col-lg-4" @click.prevent="rejectOrder()">
                        Reject
                      </button>
                      <button class="btn border-0 btn-success bg-success-light col-lg-4 " @click.prevent="startOrder()">
                        Start
                      </button>
                    </div>
                    <p style="color:black;" class="mb-0 mt-2">Note: The order might be canceled by buyer as long as the
                      order status is "Initial"</p>
                  </div>
                  <div class=" bg-warning-light p-2"
                    v-if="data.order.seller.id == this.localuser.id && data.order.status == 'beforCancel'">
                    <p>The buyer has requested to cancel the order, Would you accept?</p>
                    <div class="d-flex justify-content-between col-lg-12">
                      <button class="btn border-0 btn-danger bg-danger-light col-lg-5"
                        @click.prevent="rejectRequestOrder()">Reject This Request
                      </button>
                      <button class="btn border-0 btn-success bg-success-light col-lg-5 "
                        @click.prevent="acceptRequestOrder()">Accept Cancelation
                      </button>
                    </div>
                  </div>
                  <div class=" bg-info-light p-2"
                    v-if="data.order.seller.id != this.localuserid && data.order.status == 'processing'">
                    <p>You are allowed to cancel the order as long as the seller do not accept it.</p>
                    <div class="d-flex justify-content-between col-lg-12">
                      <button class="btn btn-cancel border-0 col-lg-12" @click.prevent="cancelOrder()">Cancel</button>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex justify-content-between">
                    <h4 class="pro-title ">Service Info</h4>
                  </div>
                  <table class="table">
                    <tbody>
                      <tr v-if="data.order.seller.id == this.localuser.id && data.order.status == 'processing'">
                        <td>Change Order Status :</td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="data.order.seller.id == this.localuser.id && data.order.status == 'processing'"
                    class="settings-btns mt-3 row">
                    <div class="col-lg-9">
                      <select v-model="stat" class="form-select ">
                        <option value="beforSuccess">Service Completed</option>
                        <option value="cancelled">Cancel Service</option>
                      </select>
                    </div>
                    <button class="btn btn-primary mt-3 ms-3 col-lg-3 " @click.prevent="subm()">Save</button>
                  </div>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Customer :</td>
                        <router-link :to="`/profile/${data.order.customer.slug}`">
                          <td>{{ data.order.customer.name }}</td>
                        </router-link>
                      </tr>
                      <tr>
                        <td>Order Status :</td>
                        <td><span
                            :style="`text-transform: capitalize; color:${data.order.status == 'cancelled' ? 'red;' : data.order.status == 'processing' || data.order.status == 'beforCancel' ? '#d869e1;' : data.order.status == 'initial' ? 'blue;' : data.order.status == 'success' ? 'green;' : data.order.status == 'beforSuccess' ? 'orangered;' : 'black;'}`">{{
                              data.order.status == 'beforSuccess' ? 'Awaiting Approval' : data.order.status == 'beforCancel'
                              ? 'Processing' : data.order.status == 'success' ? 'Completed' : data.order.status
                            }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Order Number :</td>
                        <td>{{ data.order.id }}</td>
                      </tr>
                      <tr>
                        <td>Price :</td>
                        <td>${{ data.order.subtotal }}
                        </td>
                      </tr>
                      <tr>
                        <td>Purchase Date :</td>
                        <td>{{ data.order.created_at_format.date }}
                        </td>
                      </tr>
                      <tr>
                        <td>Delivery Time :</td>
                        <td>{{ data.order.days }} Day(s)
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <loader v-if="opining"></loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  inject: ['api'],
  methods: {
    startOrder() {
      if (confirm('Accept the order?')) {
        this.loading = true
        this.api.post(`user/updateOrderStatus`, {
          status: 'processing',
          order_id: Number(this.data.order.id),
        }).then(res => {
          if (res.status == 'success') {
            this.loading = false
            location.reload()
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    acceptRequestOrder() {
      if (confirm('Are you sure you want to accept the request?')) {
        this.loading = true
        this.api.post(`user/updateOrderStatus`, {
          status: 'cancelled',
          order_id: Number(this.data.order.id),
        }).then(res => {
          if (res.status == 'success') {
            this.toast('Request accepted successfully', 'success')
            this.loading = false
            location.reload()
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    rejectRequestOrder() {
      if (confirm('Are you sure you want to reject the request?')) {
        this.loading = true
        this.api.post(`user/updateOrderStatus`, {
          status: 'processing',
          order_id: Number(this.data.order.id),
        }).then(res => {
          if (res.status == 'success') {
            this.loading = false
            this.toast('Request rejected successfully', 'success')
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    rejectOrder() {
      if (confirm('Are you sure you want to reject the order?')) {
        this.loading = true
        this.api.post(`user/updateOrderStatus`, {
          status: 'cancelled',
          order_id: Number(this.data.order.id),
        }).then(res => {
          if (res.status == 'success') {
            this.loading = false
            this.toast('Order rejected successfully', 'success')
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    cancelOrder() {
      if (confirm('Are you sure you want to cancel the order?')) {
        this.loading = true
        this.api.post(`user/updateOrderStatus`, {
          status: 'cancelled',
          order_id: Number(this.data.order.id),
        }).then(res => {
          if (res.status == 'success') {
            this.loading = false
            this.toast('Order canceled successfully', 'success')
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    getData() {
      this.api.get(`user/sales/${this.$route.params.id}`).then(res => {
        if (res.status == 'success') {
          this.data = res.data.data
          this.loading = false
          window.axios = require('axios');

          window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

          window.pusher = require('pusher-js');
          window.Pusher.logToConsole = false;

          var pusher = new Pusher("30fad4c0b0b50a1a41ca", {
            cluster: "eu",
            authEndpoint: "https://api.gooreo.com/api/broadcasting/auth",
            auth: {
              headers: {
                Authorization: this.decrypted($cookies.get("token"))
              }
            },

          });
          var channel = pusher.subscribe(`private-chat.${res.data.data.chat.id}`);
          var vm = this;
          channel.bind("message.new", (data) => {
            var msgnew = data.msg;
            if (this.localuser.id == msgnew.sender.id) {
              msgnew.is_sender = true;
            }
            vm.data.msgs.data.push(msgnew);
          });
          let elmnt = document.getElementsByClassName('chat-scroll')[0];
          elmnt.scrollIntoView(false)
        } else {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const tow = urlParams.get('tow')
          // console.log(tow);
          if (tow == 1 || tow == '1') {
            location.replace('/404');
          }else{
            location.replace(`/user/orders/${this.$route.params.id}?tow=1`)
          }


        
          // 
        }
      })
    },
    subm() {
      if (confirm('Are you sure you want to change the order status?')) {
        this.loading = false
        this.api.post(`user/updateOrderStatus`, {
          status: this.stat,
          order_id: Number(this.$route.params.id),
        }).then(res => {
          if (res.status == 'success') {
            this.loading = false
            location.reload()
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    async search(input) {
      this.loading = true
      this.api.get('user/messages').then(res => {
        if (res.status == 'success') {
          let c = res.data.data.chats
          c.forEach(e => {
            if (!this.getName(e).name.contains(input)) {
              c.splice(c.indexOf(this.getName(e)), 1)
            }
          })
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    getName(chat) {
      let r = {}
      chat.conversation.participants.forEach(element => {
        if (element.messageable_id != this.localuser.id) {
          r = element.messageable
          // console.log(r)
        }
      });
      return r
    },
    getAvatar(chat) {
      let r = {}
      chat.conversation.participants.forEach(element => {
        if (element.messageable_id != this.localuser.id) {
          r = element.messageable
        }
      });
      return r
    },
    async getChat(id, user) {
      this.opining = true
      this.api.get(`user/messages/${id}`).then(res => {
        if (res.status == 'success') {
          this.chatuser = user
          this.opining = false
          this.chat = res.data.data

          window.axios = require('axios');

          window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

          window.pusher = require('pusher-js');
          window.Pusher.logToConsole = false;

          var pusher = new Pusher("30fad4c0b0b50a1a41ca", {
            cluster: "eu",
            authEndpoint: "https://api.gooreo.com/api/broadcasting/auth",
            auth: {
              headers: {
                Authorization: $cookies.get("token")
              }
            },

          });
          var channel = pusher.subscribe(`private-chat.${id}`);
          var vm = this;
          channel.bind("message.new", (data) => {
            var msgnew = data.msg;
            if (this.localuser.id == msgnew.sender.id) {
              msgnew.is_sender = true;
            }
            vm.data.msgs.push(msgnew);
          });
          let elmnt = document.getElementsByClassName('chat-scroll')[0];
          elmnt.scrollIntoView(false)
        } else {
          location.replace('/404')
        }
      })
    },
    async getChats() {
      this.loading = true
      this.api.get('user/messages').then(res => {
        if (res.status == 'success') {
          this.chats = res.data.data.chats
          //  console.log(this.chats.data[0].data)
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async sendMsg() {
      this.api.post('user/sendMsg', {
        msg: this.msg,
        chat_id: this.data.chat.id,
        order_id: this.data.chat.data.order_id,
        photo: null,
      }).then(res => {
        if (res.status == 'success') {
          this.msg = ''
        } else {
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  created() {
    this.getData()
    this.getChats()
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://js.pusher.com/7.0.3/pusher.min.js')
    document.head.appendChild(recaptchaScript)
  },
  data() {
    return {
      avatar: $cookies.get("avatar") ? JSON.parse(this.decrypted($cookies.get("avatar"))) : null,
      localuser: JSON.parse(this.decrypted($cookies.get("user"))),
      stat: '',
      loading: true,
      data: {},
      opining: false,
      chats: {},
      chat: null,
      msg: '',
      chatuser: {},
      term: '',
    }
  }
}
</script>
<style scoped>
.chat-window .chat-scroll {
  min-height: calc(100vh - 226px);
  max-height: calc(100vh - 226px);
}
</style>