<template>
  <div>
    <!-- <p>
      Total : {{ price }}
      <br>

      total old {{ total }}
      <br>
      stripe_fee {{ stripe_fee }} <br>

      stripe_less_than {{ stripe_less_than }}
      <br>
      stripe_increase_amount {{ stripe_increase_amount }}
    </p> -->
    <StripeElements v-if="stripeLoaded" ref="elms" v-slot="{ elements, instance }" :stripe-key="stripeKey">
      <div class="p-3 mt-3 ms-2 col-9 border shadow">
        <StripeElement ref="card" :elements="elements" />
      </div>
    </StripeElements>
    <vue-recaptcha :sitekey="webkey">
      <button :disabled="loading" class="click-btn mt-3 btn-primary" type="button" @click="pay">Pay</button>

    </vue-recaptcha>
  </div>
</template>
<style scoped>
.spacer {
  margin-bottom: 24px;
}

/**
             * The CSS shown here will not be introduced in the Quickstart guide, but shows
             * how you can use CSS to style your Element's container.
             */
.StripeElement {

  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ccd0d2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
}
</style>
<script>

import { StripeElement, StripeElements } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'

export default defineComponent({
  inject: ['api'],
  props: {
    price: Number,
    publishableKey: String,
    total: Number,
    stripe_fee: Number,
    stripe_less_than: Number,
    stripe_increase_amount: Number
  },
  components: {
    StripeElements,
    StripeElement,
  },
  setup(props) {
    const stripeKey = ref(props.publishableKey) // test key
    const stripeLoaded = ref(false)
    const price = ref(props.price)
    const loading = ref(false)
    const webkey = process.env.CAPTCHA_webKey

    const card = ref()
    const elms = ref()
    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value)
      stripePromise.then(() => {
        stripeLoaded.value = true
      })
    })

    const cloading = computed({
      get: () => loading.value,
      set: val => {
        loading.value = val
      }
    })
    return {
      stripeKey,
      stripeLoaded,
      price,
      loading,
      cloading,
      card,
      elms,
      webkey

    }
  },

  methods: {
    async pay() {
      // Get stripe element
      const cardElement = this.card.stripeElement
      let token = "";
      let error = false;
      // Access instance methods, e.g. createToken()
      await this.elms.instance.createToken(cardElement).then((result) => {
        // Handle result.error or result.token
        // console.log(result);
        token = result.token ?? false;
        error = result.error ? result.error.message : false;

      })

      if (!token && error) {

        this.toast(error, 'danger');
        return;
      }

      let vm = this;
      await this.api.post(`stripe`, {
        token: token,
        total: vm.price,
        oldtotal: vm.price,
        stripeToken: token.id,
        type: 'charge',

      }).then(response => {
        // console.log(response);
        // console.log(response.data);
        // console.log(response.data.data.success);
        if (response.data.data.success) {

          // console.log('success');
          // console.log(response);
          window.location.href = 'user/balance';
          $('.stripe-credit-card-form-wrap').html(response.data.message_html);

        } else {
          $('.stripe-credit-card-form-wrap').html(response.data.message_html);
        }

      }).catch(error => {
        $('.stripe-credit-card-form-wrap').html(error.message);

      }).finally(() => {
        // this.cloading = false;
      });


      //  console.log('token');
      //   console.log(token);
    },
  },
})
</script>