<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <h2 class="breadcrumb-title">PRIVACY POLICY</h2>
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item active"> PRIVACY POLICY</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="terms-content">
                <div class="terms-text">
                  <p>Gooreo uses cookies and similar technologies to help provide, protect, and improve the Gooreo
                    Platform. This policy explains how and why we use these technologies and the choices you have.</p>
                  <p>A cookie is a small data file that is transferred to your device (e.g. your phone or your
                    computer). For example, a cookie could allow us to recognize your browser, while another could store
                    your preferences. There are two types of cookies used on the Gooreo Platform: (1) “session cookies”
                    and (2) “persistent cookies.” Session cookies normally expire when you close your browser, while
                    persistent cookies remain on your device after you close your browser, and can be used again the
                    next time you access the Gooreo Platform.</p>
                  <p>We also use other technologies with similar functionality to cookies, such as web beacons, pixels,
                    mobile identifiers, and tracking URLs, to obtain Log Data (as described in the
                 Privacy
                      Policy ). For example, our email messages may contain web beacons and tracking URLs to
                    determine whether you have opened a certain message or accessed a certain link.</p>
                </div>
                <div class="terms-text">
                  <h4>Why Gooreo Uses These Technologies</h4>
                  <p>We use these technologies for a number of purposes, such as:</p>

                  <ul>
                    <li>To enable you to use and access the Gooreo Platform and the Payment Services.</li>
                    <li>To enable, facilitate and streamline the functioning of and your access to the Gooreo
                      Platform.
                    </li>
                    <li>To better understand how you navigate through and interact with the Gooreo Platform and to
                      improve
                      the Gooreo Platform.
                    </li>
                    <li>To serve you tailored advertising (on the Gooreo Platform and on third party websites).</li>
                    <li>To show you content (e.g., advertisements) that is more relevant to you.</li>
                    <li>To monitor and analyze the performance, operation, and effectiveness of the Gooreo Platform and
                      Gooreo advertisements.
                    </li>
                    <li>To enforce legal agreements that govern use of the Gooreo Platform.</li>
                    <li>For fraud detection and prevention, and investigations.</li>
                    <li>For purposes of our own customer support, analytics, research, product development, and
                      regulatory compliance.
                    </li>

                  </ul>
                </div>

                <div class="terms-text">
                  <h3>Third Parties</h3>
                  <p>We may also allow certain business partners to place these technologies on the Gooreo Platform.
                    These partners use these technologies to (1) help us analyze how you use the Gooreo Platform, such
                    as by noting the third party services from which you arrived, (2) market and advertise Gooreo
                    services to you on the Gooreo Platform and third party websites, (3) help us detect or prevent fraud
                    or conduct risk assessments, or (4) collect information about your activities on the Gooreo
                    Platform, other sites, and/or the ads you have clicked on. For example, to help us better understand
                    how people use the Gooreo Platform, we work with a number of analytics partners, including Google
                    Analytics. To prevent Google Analytics from using your information for analytics, you may install
                    the Google Analytics Opt-Out Browser by clicking
                      here.</p>
                  <p>Third parties may also use such tracking technologies to serve ads that they believe are most
                    likely to be of interest to you and measure the effectiveness of their ads both on the Gooreo
                    Platform and on other websites and online services. Targeting and advertising cookies we use may
                    include Google, and other advertising networks and services we use from time to time. For more
                    information about targeting and advertising cookies and how you can opt out, you can visit the
                    Network Advertising Initiative’s opt-out page, the Digital Advertising Alliance’s opt-out page, or

                      https://youronlinechoices.eu. To opt out of Google Analytics for display advertising or
                    customize
                    Google display network ads, you can visit the
                      Google Ads Settings page. To the extent advertising
                    technology is integrated into the Gooreo Platform and you opt-out of tailored advertising, you may
                    still receive advertising content. In that case, the advertising content will just not be tailored
                    to your interests. Also, we do not control any of these opt-out links and are not responsible for
                    the availability or accuracy of these mechanisms.</p>
                </div>
                <div class="terms-text">
                  <h3>Third Party Social Plugins</h3>
                  <p>The Gooreo Platform may use social plugins provided and operated by third parties, such as
                    Facebook’s login and Google login. As a result of this, you may send to the third party the
                    information that you are viewing on a certain part of the Gooreo Platform. If you are not logged
                    into your account with the third party, then the third party may not know your identity. If you are
                    not logged into your account with the third party, then the third party may be able to link
                    information or actions about your interactions with the Gooreo Platform to your account with them.
                    Please refer to the third party’s privacy policies to learn more about its data practices.</p>
                </div>
                <div class="terms-text">
                  <h3>Your Choices</h3>
                  <p>Most browsers automatically accept cookies, but you can modify your browser setting to decline
                    cookies by visiting the Help portion of your browser’s toolbar.
                    Flash cookies operate differently than browser cookies, and cookie management tools available in a
                    web browser will not remove flash cookies. To learn more about how to manage flash cookies, you can
                    visit the
                      Adobe website  and make changes at the Global Privacy Settings Panel.</p>
                  <p>Your mobile device may allow you to control cookies through its settings function. Refer to your
                    device manufacturer’s instructions for more information.</p>
                  <p>If you choose to decline cookies, some parts of the Gooreo Platform may not work as intended or may
                    not work at all.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<style scoped>
a {
  font-size: 16px;
  color: #009df8
}
</style>
<script>
export default {
  mounted() {

  },
}
</script>