<template>
  <div class="skeleton col-md-3 col-sm-12">
    <div class="s-grid-card grid-card">
      <div class="card-image">
  <div class=" daimg ">

  </div>
      </div>
      <div class="card-content">
        <h3 class="m-card-title">
          <h4></h4>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "categorycard-skeleton"
}
</script>

<style scoped>

</style>