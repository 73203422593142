<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header></layout-header>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img src="../../../assets/img/home-icon.svg" alt=""> Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">PROFILE</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Profile Banner -->
      <profilebanner :user="user" :userData="userData"></profilebanner>
      <!-- Profile Banner -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">

          <div class="row">
            <div class="col-lg-8 col-md-12">


              <usertab :user="user"></usertab>

              <!-- Reviews Tab Content -->
              <div class="pro-post widget-box company-post">
                <h3 class="pro-title">Reviews</h3>
                <div class="reviews company-review">
                  <companyreviewcontent></companyreviewcontent>
                  <companyreviewcontent1></companyreviewcontent1>
                  <companyreviewcontent2></companyreviewcontent2>
                  <div class="col-md-12 text-center">
                    <a href="javascript:void(0);" class="btn more-btn"><img src="../../../assets/img/loader-icon.svg"
                                                                            height="24" alt="User Image">Load More </a>
                  </div>
                </div>
              </div>
              <!-- /Reviews Tab Content -->

              <!-- Post a comment -->
              <reviewcomment></reviewcomment>
              <!-- /Post a comment -->
            </div>

            <!-- profile Sidebar -->
            <companydetail1></companydetail1>
            <!-- /Profile Sidebar -->

          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer></layout-footer>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  inject: ['api'],
  methods: {
    async getUserInfo() {
      await this.api.get(`profile/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status == 'success') {
          this.userData = res.data.data
          this.user = res.data.data.user
        } else {
          location.replace('404')
        }
      })
    }
  },
  created() {
    this.getUserInfo()
  },
  data() {
    return {
      user: {},
      userData: {},
    }
  }
}
</script>