<template>
  <div style="display: flex; justify-content: space-around;" class="tabbar px-1 border my-5 p-2">
    <router-link class="nav-link tabitem text-center w-100 m-auto" to="/user/sales/active">Active ({{ arr.active }})
    </router-link>
    <router-link class="nav-link tabitem text-center w-100 m-auto" to="/user/sales/completed">Completed
      ({{ arr.success }})
    </router-link>
    <router-link class="nav-link tabitem text-center w-100 m-auto" to="/user/sales/missing-details">Messing Details
      ({{ arr.initial }})
    </router-link>
    <router-link class="nav-link tabitem text-center w-100 m-auto" to="/user/sales/suspend"> Suspended
      ({{ arr.suspended }})
    </router-link>
    <router-link class="nav-link tabitem text-center w-100 m-auto" to="/user/sales/cancelled"> Cancelled
      ({{ arr.cancelled }})
    </router-link>
  </div>
</template>

<script>
export default {
  name: "sales-upbar",
  props: {
    arr: Object,
  }
}
</script>

<style scoped>
.active {
  padding: 13px 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  background-color: white;
  border-radius: 50px;
}

.tabbar a {
  align-self: center;
}
</style>