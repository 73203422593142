<template>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">User</li>
                    <li aria-current="page" class="breadcrumb-item">Verify Identity</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <!-- Verification Details -->
            <div class="col-xl-9 col-md-8">
              <div class="card">

                <div class="payment-list wallet card-body">
                  <div class="container my-2 center" v-if="identity == false">
                    <div style="margin: auto;width: 245px;" class="d-flex ">
                      <button @click=" this.step=1" class="steps  me-4 d-flex" :class="this.step == 1? 'active-step' :'' " :style="this.front != null? '    background: #3EC70B;':''">
                        <h4 :style="this.front != null? '    color: #fff;':''">1</h4>
                      </button>
                      <button @click=" this.step=2" class="steps d-flex me-4 ms-4" :style="this.back != null? '    background: #3EC70B;':''"
                              :class="this.step == 2? 'active-step' :''">
                        <h4 :style="this.back != null? '    color: #fff;':''">2</h4>
                      </button>
                      <button @click="step =3" class="steps d-flex ms-4" :class="this.step == 3? 'active-step' :''" :style="this.selfie != null? '    background: #3EC70B;':''">
                        <h4 :style="this.selfie != null? '    color: #fff;':''">3</h4>
                      </button>

                    </div>

                  </div>
                  <h3>Verification Details</h3>
                  <form>
                    <div class="row" v-if="identity == false">
                      <div class="front" v-if="step == 1">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Upload a clear picture of your ID card from the front side</label>
                            <div class="uplod">
                              <label class="file-upload image-upbtn">
                                Select Document <input type="file" @change="loadFile($event, step)">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <img :src="frontimg" alt="" class="w-25">
                        </div>
                      </div>
                      <div class="back" v-if="step == 2">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Upload a clear picture of your ID card from the back side</label>
                            <div class="uplod">
                              <label class="file-upload image-upbtn">
                                Select Document <input type="file" @change="loadFile($event, step)">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <img :src="backimg" alt="" class="w-25">
                        </div>
                      </div>
                      <div class="selfie" v-if="step == 3">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Upload a selfie of you, with your ID card</label>
                            <div class="uplod">
                              <label class="file-upload image-upbtn">
                                Select Document <input type="file" @change="loadFile($event, step)">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <img :src="selfieimg" alt="" class="w-25">
                        </div>
                      </div>
                      <div class="d-flex mt-3">
                        <div v-if="step > 1" class="col-md-1 me-3">
                          <button @click.prevent="prev()" class="btn-primary click-btn" type="submit">Back</button>
                        </div>
                        <div class="col-md-1">
                          <button @click.prevent="step == 3 ? submit() : next()" class="btn-primary click-btn"
                                  type="submit">{{ step == 3 ? 'Submit' : 'Next' }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="identity == 2">
                      <label class="file-upload image-upbtn">
                        Your identity is being reviewed right now..
                      </label>
                    </div>
                    <div class="row" v-if="identity == true">
                      <label class="bg-success-light text-white"
                             style="color: white; padding: 10px; border-radius: 5px;">
                        <i class="fas fa-check"></i> Your account is verified
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- Verification Details -->

          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->

  </div>
</template>
<script>
export default {
  mounted() {
  },
  computed: {
    currentPath() {
      return this.$route.name;
    }
  },
  methods: {
    next() {
      this.step++;
    },
    prev() {
      if (this.step > 1) {
        this.step--;
      }
    },
    loadFile(event, step) {
      if (step == 1) {
        this.front = event.target.files[0]
        this.frontimg = URL.createObjectURL(event.target.files[0])
      } else if (step == 2) {
        this.back = event.target.files[0]
        this.backimg = URL.createObjectURL(event.target.files[0])
      } else if (step == 3) {
        this.selfie = event.target.files[0]
        this.selfieimg = URL.createObjectURL(event.target.files[0])
      } else {
        return;
      }
    },
    removeImage(step) {
      if (step == 1) {
        this.front == null;
        this.frontimg == null;
      } else if (step == 2) {
        this.back == null;
        this.backimg == null;
      } else if (step == 3) {
        this.selfie == null;
        this.selfieimg == null;
      } else {
        return;
      }
    },
    submit() {
      if (this.selfie && this.back && this.front != null) {
        let data = new FormData();
        data.append('front', this.front)
        data.append('back', this.back)
        data.append('photo', this.selfie)
        this.api.post('user/identity', data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': this.decrypted($cookies.get("token"))
          }
        }).then(res => {
          if (res.status == 'success') {
            this.toast('Docs sent successfully and now they are being reviewed', 'success')
            setTimeout(() => {
              location.reload()
            }, 2000)
          } else {
            let e;
            // console.log(res)
            for(e in res.data){
              // console.log(res.data[e][0])
              this.toast(res.data[e][0], 'danger')
            }
          }
        })
      } else {
        this.toast("Please select document to upload", 'danger')
      }
    }
  },
  created() {
    this.api.get('user/identity').then(res => {
      if (res.status == 'success') {
        this.identity = res.data.data.identity
      } else {
        this.toast(res.data, 'danger')
      }
    })
  },
  inject: ['api'],
  data() {
    return {
      activeClass: 'active',
      front: null,
      frontimg: null,
      back: null,
      backimg: null,
      selfie: null,
      selfieimg: null,
      step: 1,
      identity: false,
    };
  },
}
</script>
<style scoped>
.steps {
  border: none;
  height: 50px;
  width: 50px;
  background-color: #efefef;
  border-radius: 50%;
}

.steps:before {
  content: '...';
  position: relative;
  left: -27px;
  top: -7px;
  font-size: 30px;

}

.steps:after {
  content: '...';
  position: relative;
  left: 15px;
  top: -7px;
  font-size: 30px;
}

.steps h4 {
  color: #131135;
  top: 14px;
  position: relative;
  right: 8px;
}

.active-step {
  background-color: #009df8;
}

.active-step h4 {
  color: #ffffff
}

.active-step:before {
  content: '...';
  position: relative;
  left: -27px;
  top: -7px;
  font-size: 30px;
  color: #009df8;

}

.active-step:after {
  content: '...';
  position: relative;
  left: 15px;
  top: -7px;
  font-size: 30px;
  color: #009df8;
}

.col-md-12 img {
  max-width: 150px;
  max-height: 150px;
}
</style>