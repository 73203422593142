<template>
  <loader v-if="loading"></loader>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layout-header/>
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                      ><img
                          src="../../../assets/img/home-icon.svg"
                          alt="Post Author"
                      />
                        Home
                      </router-link>
                    </li>

                    <li class="breadcrumb-item" aria-current="page">
                      <router-link to="/user/dashboard">Favourites</router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar/>
            <div class="col-lg-9 col-md-12">
              <div class="row blog-grid-row">
                <div style="justify-content: space-around" class="d-flex tabbar px-1 border my-5 p-2">
                  <router-link class="nav-link tabitem my-2 text-center w-100 m-auto" to="/favourite-services">Favourite
                    Services
                  </router-link>
                  <router-link class="nav-link tabitem my-2 text-center w-100 m-auto" to="/favourite-projects">Favourite
                    Projects
                  </router-link>
                </div>
                <div
                    v-for="item in favourite"
                    :key="item.id"
                    class="col-md-3 col-sm-12"
                >
                  <div class="s-grid-card grid-card">
                    <div class="card-image">
                      <router-link
                          :params="`${item.seo_title}`"
                          :to="`/marketplace/${item.slug}`"
                      ><img
                          :src="item.photo_url"
                          alt="Post Image"
                          class="img-fluid"
                          height="240px"
                      />
                      </router-link>
                      <button
                          class="favourite "
                          style="top: 7.5px;"
                          @click="removeFavorite(item.id)"
                      >
                        <i
                            style="color:rgb(220, 84, 84);"
                            :class="
                            item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                          "
                        ></i>
                      </button>
                    </div>
                    <div class="card-content">
                      <ul class="entry-meta meta-item">
                        <li>
                          <div class="post-author">
                            <router-link
                                :params="`${item.author.name}`"
                                :to="`/profile/${item.author.slug}`"
                            ><img
                                :src="item.author.avatar"
                                alt="Post Author"
                            />
                              <span> {{ item.author.name }}</span>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                      <h3
                          style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;"
                      >
                        <router-link
                            :params="`${item.seo_title}`"
                            :to="`/marketplace/${item.slug}`"
                        >{{ item.title.en }}
                        </router-link>
                      </h3>
                      <div
                          class="d-flex justify-content-between align-items-center mb-3  mt-3"
                      >
                        <h4 class="m-card-title orange-text">
                          ${{ parseFloat(item.price)?.toFixed(2) }}
                        </h4>
                        <div class="fa-grid-card">
                          <i class="far fa-star"></i>
                          {{
                            item.reviews_avg == null
                                ? 0
                                : item.reviews_avg.substr(0, 3)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Favourite Services",
  created() {
    this.getData();
  },
  methods: {
    clearFilter() {
      this.getData();
    },

    removeFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.favourite).map((value, key) => {
            if (this.favourite[value].id == id) {
              delete this.favourite[value];
              // this.favourite[value].favorited = !this.services[value].favorited;
            }
          });
        } else {
        }
      });
    },

    getData() {
      this.api.get(`getFavoriteItems`).then((res) => {
        if (res.status == "success") {
          this.favourite = res.data.data.services;
          //  console.log(this.favourite)
          this.loading = false;
        } else {
          this.toast(res.data, "danger");
        }
      });
    },
  },
  inject: ["api"],
  data() {
    return {
      loading: true,

      favourite: [],
      sections: [],
      subsections: [],
      category: "",
    };
  },
};
</script>

<style scoped>
.active {
  padding: 13px 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  background-color: white;
  border-radius: 50px;
}

.tabbar a {
  align-self: center;
}
</style>