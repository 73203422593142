<template>
  <div class="col-md-12">
    <div class="chat-header">
      <a id="back_user_list" class="back-user-list" href="javascript:void(0)">
        <i @click="passIndex()" class="material-icons">chevron_left</i>
      </a>
      <div class="media d-flex">
        <div class="media-img-wrap flex-shrink-0">
          <div class="avatar">
            <img :src="chatuser?.avatar" alt="User Image"
                 class="avatar-img rounded-circle">
          </div>
        </div>
        <div class="media-body me-2 flex-grow-1">
          <div class="user-name">{{ chatuser?.name || 'Deleted user' }} </div>
        </div>
        <div v-if="chat.conversation.data"
             class="mt-2 user-name"><h4>({{ chat.conversation.data.title }})</h4>
        </div>
      </div>
    </div>
    <div class="chat-body">
      <div class="chat-scroll" style="display: flex;flex-direction: column-reverse;">
        <ul class="list-unstyled" id="list-unstyled">
          <li v-for="(msg ,index) in chat.msgs.data " :id="`message-${index}`" :key="index"
              :class="msg.sender?.id == localuser.id ? 'media sent ' : 'media received '">

            <div class="media-body ">
              <div class="msg-box">
                <div>
                  <p>{{ msg.body }}</p>
                </div>
              </div>
            </div>
            <div class="avatar  ">
              <img :src="msg.sender?.id == localuser.id  ? this.avatar : msg.sender?.avatar"
                   alt="User Image" class="avatar-img rounded-circle">
              <p>{{
                  msg.created_at.split('T')[1].split(':')[0]
                  + ':' +
                  msg.created_at.split('T')[1].split(':')[1]
                }}</p>
            </div>

          </li>
        </ul>
      </div>

      <div class="chat-footer">
        <div class="input-group">
          <div class="avatar">
            <img :src="this.avatar" alt="User Image"
                 class="avatar-img rounded-circle">
          </div>
          <input v-model="msg" @keydown.enter.prevent="sendMsg()" class="input-msg-send form-control"
                 placeholder="Reply..." type="text">
          <button class="btn btn-primary msg-send-btn rounded-pill" type="button"
                  @click.prevent="sendMsg()"><i
              class="fab fa-telegram-plane"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    localuser: Object,
    chat: Object,
    chatuser: Object
  },
  name: 'chat-body',
  data() {
    return {
      avatar:$cookies.get("avatar") ? JSON.parse(this.decrypted($cookies.get("avatar"))) : null
      ,
      msg: '',
      count: 0,
    }
  },

  inject: ['api'],

  methods: {
    passIndex(){
      this.$emit('changeIndex' , '0')
    },
    scrollDown() {
      document.querySelector('.list-unstyled .media:last-child').scrollIntoView();
    },
    async sendMsg() {
      this.api.post('user/sendMsg', {
        msg: this.msg,
        chat_id: this.chat.conversation.id,
        photo: null,
      }).then(res => {
        if (res.status == 'success') {
          this.msg = ''
          this.scrollDown()
        } else {
          this.errors = res.data.msg
          let error;

          for (error in this.errors) {
            this.toast(this.errors[error], 'danger')
          }
        }
      })
    }
  },
}

</script>













