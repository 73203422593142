import axios from 'axios';
import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueCookies from 'vue-cookies';
import CryptoJS from "crypto-js";

function decrypt(text) {
    try {
        return CryptoJS.AES.decrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error(error)
    }
}

const store = createStore({
    // plugins: [
    //   createPersistedState()
    // ],
    state: {
        cart: 0,
        generalsetting: {},
        cooke:false
    },
    mutations: {
        getSetting(state, c) {
            axios.get(`${process.env.VUE_APP_BASE_URL + 'generalSetting'}`, VueCookies.get('loggedin') == 'true' ? {
                headers: {
                    Authorization: decrypt(VueCookies.get("token")),
                },
            } : {}).then(res => {
                state.generalsetting = res.data.data
            })
        },
        addCartItem(state, c) {
            state.cart = c;
        },
        Cookies(state, c) {
            state.cooke = c;
        },
        updateCartItem(state, c) {
            state.cart = c;
        },
        removeCartItem(state, c) {
            state.cart = c;
        }
    }
})

export default store
