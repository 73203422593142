<template>
    <div v-show="showpaypal === true & loadding === false" class="container">
        <!-- <p>
            Total : {{ price }}
            <br>

            total old {{ total }}
            <br>
            paypal_fee {{ paypal_fee }} <br>

            paypal_less_than {{ paypal_less_than }}
            <br>
            paypal_increase_amount {{ paypal_increase_amount }}
        </p> -->
        <div class="row text-center">


            <div class="w-100">
                <div class="w-75 m-auto" ref="paypal"></div>
            </div>

        </div>
    </div>
    <div v-show="showpaypal === true & loadding === true" class="container">
        <div class="row text-center">
            <div class="w-100 text-center">
                <h5 class="text-center">Checking your payment please wait!</h5>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    inject: ['api'],
    props: ['price', 'paypal_client_id', 'total', 'paypal_fee', 'paypal_less_than', 'paypal_increase_amount'],
    data() {
        return {
            loaded: false,
            showpaypal: true,
            loadding: false,
            paidFor: false,
            product: {
                price: this.price,
                description: "Add Balance in Gooreo"
            },

        }
    },
    mounted: function () {
        const script = document.createElement("script");
        script.src =
            `https://www.paypal.com/sdk/js?client-id=${this.paypal_client_id}`;
        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);
    },
    methods: {
        setLoaded: function (resp) {
            this.loaded = true;
            window.paypal
                .Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    description: this.product.description,
                                    amount: {
                                        currency_code: "USD",
                                        value: this.product.price
                                    }
                                }
                            ]
                        });
                    },
                    onApprove: async (data, actions, resp) => {
                        // console.log('order');
                        let vm = this;
                        const order = await actions.order.capture();
                        // console.log(order);
                        await this.api.post(`paypal`, {
                            order: order,
                            total: vm.total


                        }).then(response => {
                            // console.log(response.data);
                            if (response.data.data.success) {


                                window.location.href = 'user/balance';
                                $('.stripe-credit-card-form-wrap').html(response.data.message_html);

                            } else {

                                $('.stripe-credit-card-form-wrap').html(response.data.message_html);
                            }

                        }).catch(error => {
                            $('.stripe-credit-card-form-wrap').html(error.message);

                        }).finally(() => {
                            // this.cloading = false;
                        });
                        this.loadding = true;

                        this.data;
                        this.paidFor = true;
                        this.loadding = false;
                        // window.location.href = "./paymentsuccess/" + this.resp;
                    },
                    onError: err => {
                        // console.log('onError');
                        //   console.log(err);
                    }
                })
                .render(this.$refs.paypal);
        },

    },

}

</script>