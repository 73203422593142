<template>
  <div>
    <div class="main-wrapper">
      <layout-header/>
      <about-header text="Investing in " page="Investing in Gooreo" gooreo='Gooreo'></about-header>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="terms-content">
                <div class="terms-text">
                  <h4>Gooreo Freelance Platform & Gooreo Staking Investment
                    Kernel
                  </h4>
                  <p>Currently, the freelancer has to have a bank account or PayPal account to claim one’s dues
                    against rendered service. The Bank, PayPal, or any similar payment facilitation entity cuts a
                    significant percentage as a commission or fee before passing the remainder to respective
                    freelancer. In Gooreo, the main objective is to limit the fee or commission to the utmost
                    minimal amount in favor of the freelancer and service seekers. Hence, freeing up the
                    entangled, burdened freelance market to flourish and eventually reach its entrepreneurial
                    oomph. </p>
                  <p>Gooreo coin, obviously, is a cryptocurrency based on blockchain smart contract technology.
                    Blockchain is expected to represent the underpinning of intra-globe economy in the future.
                    Blockchain technology provides an unprecedented opportunity to ease the public’s growing
                    frustration with—and distrust of—dysfunctional centralized financial systems. By
                    distributing data across a network of computers – DLT: Distributed Ledger Technology) –
                    the technology allows any group of individuals to embrace transparency rather than centralentity
                    control. </p>
                  <p>Gooreo is a creative freelance solution with a built-in DeFi exchange nucleus for crypto
                    global investors seeking high ROI on their crypto investment deposits. Gooreo – via
                    Gooreo Staking – offers highly efficient, flexible, and decentralized investment marketplace
                    for freelancers and investors all over the world. The result is an unbiased, transparent, and
                    highly efficient DeFi investment system—one that can improve current global financial and
                    monetary networks and better serve the public good.</p>
                </div>
                <div class="terms-text">
                  <h3 id="gooreo_crypto_vs_fiat">1. Gooreo Crypto vs. Fiat</h3>
                  <p>Gooreo is an optimal platform and timely paradigm-shift in how freelancers could provide
                    their services and claim their dues from the four corners of the world. Gooreo platform is
                    fueled by the Gooreo token with the objective of expanding freelance concept and business
                    model across the world. Gooreo coin will be based on blockchain smart contract. Gooreo is
                    powered by Binance Smart Chain (BSC). Hence, all transactions will be stored transparently
                    on the Binance Smart Chain, solving copyright issues between freelancers and clients in a
                    novel and reliable way. </p>

                  <p>
                    Whether in countries with established banking systems and fiat money exchange networks,
                    or in countries with flimsy, untrustworthy banking systems or limited money transfer
                    options. Gooreo token is inherently valuable and useful to participants of the freelance
                    domain because it is not only a tradeable coin – it enables the purchase of products and
                    services. Therefore, it has a built-in, stable value that transcends the ups and downs of the
                    general cryptocurrency market. It is a valuable assent for everyone who recognized the
                    importance of freelance role in tomorrow’s market.</p>

                  <p>At the moment, in many countries with significant number of freelancers it is cumbersome,
                    costly, or quite challenging to finalize the freelance transaction and secure entitlements. In
                    many countries, it is almost impossible for freelancers to claim their cash to utilize in their
                    daily lives because PayPal – the main freelancers payment exchange platform in the world –
                    offer its money transfer services to banks in certain countries. Huge number of freelancers
                    and freelance seekers are left out of the loop globally because they face problems
                    transferring the money to banks in their countries and sometimes shy away from opening
                    bank accounts because of the high fees on bank money transfers.</p>
                </div>

                <div class="terms-text">
                  <h3 id="gooreo_freelance">2. Gooreo Freelance Procedure </h3>
                  <p>Let’s define freelance task to include freelancer and seeker of freelance service, or client.
                    The client chooses between two alternatives to apply for a freelance task. First, to post the
                    freelance task request and freelancers will respond with offers to fulfill the request; the
                    client will select best freelancer offer accordingly. Second, the client will buy the service
                    from the ready-made services market directly. The client will pay the amount specified via
                    Gooreo platform using Gooreo tokens, which will be available in most cryptocurrency
                    trading and exchange platforms. Gooreo platform will convert the amount to Electronic
                    Dollar and hold the deposited amount for the freelancer until task is executed. Upon
                    completion of the task, freelancer sends the finalized service/product to the client. Upon
                    client’s approval Gooreo platform will deduct 10% of the total agreed-upon amount
                    between both parties and sends the held amount to freelancer’s electronic wallet.
                    Meanwhile, % of profit is added to investors’ Gooreo Staking investment kernel
                    proportional to deposited crypto amounts.</p>
                  <h4>Procedure: </h4>
                  <p>Freelance client A looks for a freelancer to design a company’s logo. Client A agreed with
                    freelancer B to execute task for $100 (Fiat money). Client A logs in Gooreo platform to buy
                    the service, finds Gooreo token at that moment at 10 cents (0.1). Client A buys 1000 Gooreo
                    tokens for $100. Gooreo platform will then convert the amount to Electronic Dollar.
                    Freelancer B finishes the design and client A approves it. Gooreo platform instantly converts
                    and transfers $90 to freelancer B keeping 10%.</p>
                </div>
                <div class="terms-text">
                  <h3 id="gooreo_staking">3. Gooreo + Gooreo Staking</h3>
                  <p>Gooreo platform acts also as a host to decentralized Staking DeFi kernel with a wider scope,
                    for global investors, in-and-out of the freelancers circle. Gooreo platform utilizes vault or
                    safe deposit – called Gooreo Staking – where investors, participants, stake their crypto
                    amounts in the Gooreo platform looking for maximized ROI. Gooreo Staking is creative DeFi
                    exchange kernel for global freelance community. Gooreo Staking acts as an investment
                    wallet, as a Gooreo token staking vault or depo, by global crypto traders or investors not
                    limited to Gooreo freelancer participants. Obviously, global investors are free to invest in
                    any crypto coin (e.g. BTC, ETH, BNB, BUSD, YFI, LTC, ADA, COMP, XMR, DAI, DOGE, DOT,
                    etc.) that can instantly be converted token. Gooreo token will be the exclusive processing
                    cryptocurrency token for payment within Gooreo platform or investment within
                    Gooreo Staking .</p>
                </div>
                <div class="container m-auto">
                  <div class="border border-dark py-3 px-5">
                    Gooreo Staking ‘Gooreo Token Staking Depo’ Reward
                    System
                  </div>
                  <div class="col-5  card container m-auto mt-5">
                  <table class="mt-25 table">
                    <tr>
                      <td><span style="color: #009df8;"><strong>Year</strong></span></td>
                      <td><span style="color: #009df8;"><strong>% ‘ of platform profit’</strong></span></td>
                    </tr>
                    <tr>
                      <td>1<sup>st</sup></td>
                      <td>50%</td>
                    </tr>
                    <tr>
                      <td>2<sup>nd</sup></td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>3<sup>rd</sup></td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>4<sup>th</sup></td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>5<sup>th</sup></td>
                      <td>5%, perpetuity</td>
                    </tr>

                  </table>
                </div>
                  <div style="width: 155px" class="mx-auto my-5">
                    <router-link to="/user/staking/"
                      class="btn btn-primary">
                      Start Staking
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 theiaStickySidebar">
              <div class="card list-widget">
                <div class="card-header">
                  <h4 class="card-title">Content List</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item"><a href="/invest-in-gooreo#gooreo_crypto_vs_fiat">1. Gooreo Crypto vs. Fiat</a></li>
                    <li class="list-group-item"><a href="/invest-in-gooreo#gooreo_freelance">2. Gooreo Freelance Procedure </a></li>
                    <li class="list-group-item"><a href="/invest-in-gooreo#gooreo_staking">3. Gooreo + Gooreo Staking </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
export default {
  name: "Invest In Gooreo"
}
</script>
<style>
</style>