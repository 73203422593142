<template>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">
                      <router-link to="/user/Profile">Profile</router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Skills</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <!-- sidebar -->
            <layout-employeesidebar></layout-employeesidebar>
            <!-- /sidebar -->
            <div class="col-xl-9 col-md-8">
              <settingtab></settingtab>
              <div class="setting-content">
                <div class="card p-3">
                  <h3 class="mt-2 mb-2">Tell us about your self</h3>
                  <div class="form-group">
                    <textarea v-model="about" class="form-control" cols="5" placeholder="Text here" rows="5"></textarea>
                  </div>
                  <h3 class="mt-2 mb-2">Skills</h3>
                  <div class="form-group">
                    <div class="col-lg-12 d-flex flex-wrap">
                      <div v-for="skill in skills" class="chip d-flex"
                           style="background-color: #009df8; min-width: 200px; max-height: 40px; text-align: center; padding: 5px; border-radius: 50px; margin: 0 5px;">
                        <div class="removeImg" style="color: white; cursor: pointer; position: absolute;"
                             @click="removeSkill(skill)">
                          <i class="far fa-times-circle"></i>
                        </div>
                        <div class="d-flex"
                             style=" max-width: 80%; color:white; margin: auto; overflow: hidden; text-overflow: ellipsis;">
                          <h5 class="text-white">{{ skill.title }}</h5>
                          <h5 class="text-white">&nbsp;({{ skill.description }}) </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-body pt-0">
                          <div class="card-header">
                            <h5 class="card-title">Add Skills</h5>
                          </div>
                          <form>
                            <div class="settings-form row ">
                              <div class="form-group col-md-1 mt-3 ">
                                <label>Percentage </label>
                                <input
                                    v-model="description"
                                    class="form-control"
                                    maxlength="100"
                                    placeholder="%" type="number"
                                    @change="() => { if( description < 1) { this.description = 0 }if(description >100  ) { this.description = 100 }}">
                              </div>
                              <div class="form-group form-placeholder col-md-10 mt-3">
                                <label> Title </label>
                                <input v-model="title" class="form-control" placeholder="Title" type="text">
                              </div>
                              <div class="form-group mb-0">
                                <div class="settings-btns">
                                  <button class="btn btn-primary " @click.prevent="addSkill()">Add Skill</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="settings-btn ">
                    <button class="btn btn-primary col-lg-12 " @click.prevent="saveSkills()">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    async saveSkills() {
      this.api.post('user/about', {
        about: this.about,
        skills: this.skills,
        description: this.description.toString(),
      }).then(res => {
        //  console.log(res)
        if (res.status == 'success') {
          this.toast("Updated", 'success')


        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    addSkill() {
      if (this.description >= 1 && this.description <= 100) {
        this.skills.push({
          title: this.title,
          description: this.description.toString()
        })
        this.title = ''
        this.description = ''.toString()

      }
    },
    removeSkill(skill) {
      this.skills.splice(this.skills.indexOf(skill), 1)
    },
    async getUser() {
      this.api.get('user').then(res => {
        if (res.status == 'success') {
          this.about = res.data.about
          this.skills = res.data.skills

        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  created() {
    this.getUser()
  },
  data() {
    return {
      about: '',
      skills: [],
      title: '',
      description: '',
    }
  }
}
</script>
<!--<style scoped>-->
<!--input::-webkit-outer-spin-button,-->
<!--input::-webkit-inner-spin-button {-->
<!--  -webkit-appearance: none;}-->
<!--</style>-->