<template>
  <div class="col-md-3 skeleton col-sm-12">
    <!-- Blog Post -->
    <div class="s-grid-card grid-card">
      <div class="card-image">
             <div class="daimg" >
             </div>
      </div>
      <div class="card-content">
        <ul class="entry-meta meta-item">
          <li>
            <div class="post-author d-flex">
              <div class="profile-img  me-2" >
              </div> <h4 style="width: 46%;" class="mt-2"></h4>
            </div>
          </li>
        </ul>
        <h4></h4>
        <div class="d-flex justify-content-between align-items-center mb-3  mt-3">
          <p></p>
        </div>
      </div>
    </div>
    <!-- /Blog Post -->
  </div>
</template>

<script>
export default {
  name: "services-skeleton"
}
</script>

<style scoped>


.profile-img {
  animation: skeleton-frames 2s infinite;
  border-radius: 50%;
  width: 65px;
  height: 45px;
}
.text-align{
  text-align:left;
}
</style>