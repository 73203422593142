import {createApp, defineAsyncComponent} from "vue";
import {router} from "./router";
import 'mdb-vue-ui-kit/css/mdb.min.css';
import VueCryptojs from 'vue-cryptojs'
import axios from "axios";
import VueAxios from "vue-axios";
import vueCountryRegionSelect from 'vue3-country-region-select'
import {VueRecaptcha} from 'vue-recaptcha';
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.bundle.min.js";
import "./assets/plugins/aos/aos.css";
import "./assets/plugins/aos/aos.js";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import App from "./App.vue";

const accordion = defineAsyncComponent(() =>
    import("./views/frontend/components/accordion")
);
const header = defineAsyncComponent(() =>
    import("./views/frontend/header"
        )
);
const NewHeader = defineAsyncComponent(() =>
    import("./views/frontend/new-header")
);
const footer = defineAsyncComponent(() =>
    import("./views/frontend/footer")
);
const Employeesidebar = defineAsyncComponent(() =>
    import("./views/frontend/employer/sidebar.vue")
);
const Filters = defineAsyncComponent(() =>
    import("./views/frontend/components/filtersidebar")
);
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import API from "./api";
import jquery from "jquery";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Loading from 'vue-loading-overlay';
import "core-js/stable";
import "regenerator-runtime/runtime";
import moshaToast from 'mosha-vue-toastify';
import VueCookies from 'vue-cookies'
import 'mosha-vue-toastify/dist/style.css';
import store from "./store";
import Raphael from "raphael/raphael";
import VueClipboard from 'vue3-clipboard'
import {QuillEditor} from "@vueup/vue-quill"
import PleaseSwitch from "./views/frontend/components/pleaseswitch.vue";

/************All components ************/
const Aboutdetail = defineAsyncComponent(() => import("./views/frontend/page/about/aboutdetail"));
const Counter = defineAsyncComponent(() => import("./views/frontend/page/about/counter"));
const Review = defineAsyncComponent(() => import("./views/frontend/page/about/review"));
const Agency = defineAsyncComponent(() => import("./views/frontend/page/about/agency"));
const Projecttab = defineAsyncComponent(() => import("./views/frontend/employer/project/projecttab"));
const Settingtab = defineAsyncComponent(() => import("./views/frontend/employer/setting/settingtab"));
const Chatleft = defineAsyncComponent(() => import("./views/frontend/employer/chat/chatleft"));
const Companydetailtab = defineAsyncComponent(() => import("./views/frontend/components/tab"));
const Widgetdashboard = defineAsyncComponent(() => import("./views/frontend/employer/dashboard/widget"));
const Paymentwidget = defineAsyncComponent(() => import("./views/frontend/employer/payment/widget"));
const Paymenttable = defineAsyncComponent(() => import("./views/frontend/employer/payment/table"));
const UserTab = defineAsyncComponent(() => import("./views/frontend/profile/usertab"));
const ProfileBanner = defineAsyncComponent(() => import("./views/frontend/profile/profilebanner.vue"));
const Cart = defineAsyncComponent(() => import("./views/frontend/headeritems/cart"));
const Loader = defineAsyncComponent(() => import("./views/Loader"));
const LogForm = defineAsyncComponent(() => import("./views/frontend/components/login"))
const AboutHeader = defineAsyncComponent(() => import('../src/views/frontend/components/aboutheader'))
const IsMobile = defineAsyncComponent(() => import("./views/frontend/components/is-mobile"))
const DealsUpBar = defineAsyncComponent(() => import("./views/frontend/components/deals-upbar"))
const OrdersUpBar = defineAsyncComponent(() => import("./views/frontend/components/orders-upbar"))
const SalesUpBar = defineAsyncComponent(() => import("./views/frontend/components/sales-upbar"))
const SearchComponent = defineAsyncComponent(() => import("./views/frontend/components/searchcomponent"))
const CarouselComponent = defineAsyncComponent(() => import("./views/frontend/components/carousel"))
const WorkContentComponent = defineAsyncComponent(() => import("./views/frontend/components/work-content"))
const WrapAround = defineAsyncComponent(() => import("./views/frontend/components/carousel-wrap-around"))
const BeginComponent = defineAsyncComponent(() => import("./views/frontend/components/how-to-start"))
const VueSelect = defineAsyncComponent(() => import("vue3-select2-component"));
const Tab = defineAsyncComponent(() => import("./views/frontend/components/tabbar"))
const TabItem = defineAsyncComponent(() => import("./views/frontend/components/tabitem"))
const SendMessage = defineAsyncComponent(() => import("./views/frontend/components/sendmessage"))
const MyProjectsHeader = defineAsyncComponent(() => import("./views/frontend/components/my-projects-header"))
const ChatBody = defineAsyncComponent(() => import("./views/frontend/components/chat-body"))
const LoadingBtn = defineAsyncComponent(() => import("./views/frontend/components/loading-btn"))
const Notifications = defineAsyncComponent(() => import("./views/frontend/components/notifications"))
const ServicesHeader = defineAsyncComponent(() => import("./views/frontend/components/my-service-header"))

/************ Skeletons ************/
const FilterSkeleton = defineAsyncComponent(() => import("./views/frontend/components/skeleton-loaders/filter-skeleton"))
const ServicesCardSkeleton = defineAsyncComponent(() => import("./views/frontend/components/skeleton-loaders/services-skeleton"))
const ProductsCardSkeleton = defineAsyncComponent(() => import("./views/frontend/components/skeleton-loaders/projectscard-skeleton"))
const CategoryCardSkeleton = defineAsyncComponent(() => import("./views/frontend/components/skeleton-loaders/categorycard-skeleton"))
const ProfileSkeleton = defineAsyncComponent(() => import("./views/frontend/components/skeleton-loaders/profile-skeleton"))
/************ Skeletons ************/

window.$ = jquery;
if (window.location.href.includes("admin")) {
    require("./assets/css/feather.css");
    require("./assets/css/feather.min.js");
    require("./assets/plugins/select2/css/select2.min.css");
    require("./assets/plugins/select2/js/select2.min.js");
    require("./assets/css/bootstrap-datetimepicker.min.css");
    require("./assets/css/bootstrap-datetimepicker.min.js");
    require("./assets/css/slick.js");
} else {
    require("./assets/css/style.css");
    require("./assets/plugins/fancybox/jquery.fancybox.min.css");
    require("./assets/plugins/fancybox/jquery.fancybox.min.js");
    require("./assets/css/slick.js");
    require("./assets/plugins/bootstrap-tagsinput/css/bootstrap-tagsinput.css");
}
require('bootstrap');
global.Raphael = Raphael;
const app = createApp(App);
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});

app.component("app-accordion", accordion);
app.component("layout-header", header);
app.component("new-header", NewHeader);
app.component("layout-footer", footer);
app.component("loader", Loader);
app.component("layout-employeeheader", header);
app.component("layout-employeesidebar", Employeesidebar);
app.component("layout-Filters", Filters);
app.component("layout-accountheader", header);
app.component("layout-adminheader", header);
app.component('QuillEditor', QuillEditor)
/*********All components *********/
app.component("tab", Tab);
app.component("tabitem", TabItem);
app.component("aboutdetail", Aboutdetail);
app.component("counter", Counter);
app.component("review", Review);
app.component("agency", Agency);
app.use(VueCookies, {
    secure: true,
});
app.component("projecttab", Projecttab);
app.component("settingtab", Settingtab);
app.component("chatleft", Chatleft);
app.component("companydetailtab", Companydetailtab);
app.component("searchcomponent", SearchComponent);
app.component("carousel", CarouselComponent);
app.component("work-content", WorkContentComponent);
app.component("wrap-around", WrapAround);
app.component("begin-component", BeginComponent);
app.component("dashboardwidget", Widgetdashboard);
app.component("paymentwidget", Paymentwidget);
app.component("paymenttable", Paymenttable);
app.component("usertab", UserTab);
app.component("profilebanner", ProfileBanner);
app.component("cart", Cart);
app.component("vue-select", VueSelect);
app.component("log-form", LogForm);
app.component("deals-upBar", DealsUpBar);
app.component("orders-upBar", OrdersUpBar);
app.component("sales-upBar", SalesUpBar);
app.component("about-header", AboutHeader);
app.component("send-message", SendMessage)
app.component("my-projects-header", MyProjectsHeader)
app.component("chat-body", ChatBody)
app.component("loading-btn", LoadingBtn)
app.component("notifications", Notifications)
app.component("services-header", ServicesHeader)
app.component("is-mobile", IsMobile);
app.component("please-switch", PleaseSwitch);
/************ Skeletons ************/
app.component("filter-skeleton", FilterSkeleton)
app.component("services-card-skeleton", ServicesCardSkeleton)
app.component("projects-card-skeleton", ProductsCardSkeleton)
app.component("category-card-skeleton", CategoryCardSkeleton)
app.component("profile-card-skeleton", ProfileSkeleton)
/************ Skeletons ************/
const api = new API();

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

app.use(moshaToast);
app.use(Loading);
app.mixin({
    methods: {
        encrypted(text) {
            return this.$CryptoJS.AES.encrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString()
        },
        decrypted(text) {
            return this.$CryptoJS.AES.decrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString(this.$CryptoJS.enc.Utf8)
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }, isiPhone() {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }, isAndroid() {
            if (/Android/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        toast(text, type) {
            this.$moshaToast(text, {
                transition: 'bounce',
                position: 'bottom-right',
                showIcon: 'true',
                type: `${type ?? 'default'}`,
            })
        },
        setMode(){
            let mode = localStorage.getItem("mode");
            if (mode == null) {
              localStorage.setItem("mode", "freelancer");
            }else{
              if (mode == "freelancer") {
                localStorage.setItem("mode", "buyer");
              } else {
                localStorage.setItem("mode", "freelancer");
              }
            }
            location.reload()
          },
    },
    computed: {
        isFreelancer() {
            let mode = localStorage.getItem('mode');
            return mode == 'freelancer' || mode == null;
        },
    },  
})
app.use(store)
app.directive("outclick", clickOutside)
app.provide('api', api);
app.component('vue-recaptcha', VueRecaptcha)
app.use(VueCookies);
app.use(VueAxios, axios);
app.use(router).mount("#app");
app.use(vueCountryRegionSelect);
app.use(VueTelInput);
app.use(VueCryptojs);


