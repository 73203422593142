<template>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader />
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">
                      <router-link to="/user/dashboard"> Dashboard</router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">My work</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <!-- sidebar -->
            <layout-employeesidebar></layout-employeesidebar>
            <!-- /sidebar -->
            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>My work</h3>
                <div class="card p-5">
                  <h5 class="mt-3"> Work Title </h5>
                  <input class="form-control mt-2 " placeholder="Work title" type="text" v-model="title">
                  <h5 class="mt-3"> Photo Gallery </h5>
                  <div class="settings-bt">
                    <input id="file" accept="image/*" class="hide-input" name="image" @change="loadFile($event)"
                      type="file">
                    <label class="upload" for="file">
                      <i class="fa fa-upload"></i>
                    </label>
                  </div>
                  <div class="upload-images upload-size w-100 d-flex flex-wrap">
                    <div class="" v-for="(img, idx) in showimages">
                      <img alt="Image" :src="img"
                        style="height: 125px; width: 150px; object-fit: contain; margin: 0 5px;">
                      <div @click="removeImage(idx)" class="removeImg"
                        style="color: tomato; cursor: pointer; position: relative; top: -100px; left: 10px;">
                        <i class="far fa-times-circle"></i>
                      </div>
                    </div>
                  </div>
                  <div @click="upload()" class="btn btn-primary pt-2 text-white mt-3 center-text">
                    <h4 class="center-text text-white">Add work</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <layout-footer />
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>

export default {
  inject: ['api'],
  mounted() {
    this.api.get('user/work').then(res => {
      if(res.status == 'success'){
        this.works = res.data.data
      }else{
        this.toast(res.data, 'danger')
      }
    })
  },
  methods: {
    loadFile(event) {
      this.images.push(event.target.files[0])
      this.showimages.push(URL.createObjectURL(event.target.files[0]))
    },
    removeImage(img) {
      this.showimages.splice(img, 1)
      this.images.splice(img, 1)
    },
    upload(){
      let form = new FormData();
      for( var i = 0; i < this.images.length; i++ ){
          let file = this.images[i];
          form.append('photo[' + i + ']', file);
      }
      form.append('title', this.title)
      this.api.post('user/work', form, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': this.decrypted($cookies.get("token"))
        }
      }).then(res => {
        if(res.status == 'success'){
          this.toast('Work added successfully', 'success')
        }else{
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  data() {
    return {
      title: '',
      images: [],
      showimages: [],
      works: [],
    }
  }
}
</script>

<style scoped>
.settings-bt {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-bt .upload {
  position: absolute;
  z-index: 11;
  background: #009df8;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
}

.settings-bt .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.setting-size {
  font-size: 14px;
  color: #131523;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.setting-size span {
  font-weight: 700;
}
</style>