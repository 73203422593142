<template>
  <loader v-if="loading"></loader>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header/>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <div class="account-content">
                <div class="align-items-center justify-content-center">
                  <div class="login-right">
                    <div class="login-header text-center">
                      <router-link to="/"><img src="../../assets/img/logo-01.png" alt="logo" class="img-fluid">
                      </router-link>
                      <h3>Join Gooreo</h3>
                    </div>
                    <div class="tab-content pt-0">
                      <div role="tabpanel" id="developer" class="tab-pane fade active show">
                        <form method="post" @submit.prevent="userData">
                          <div class="form-group form-focus">
                            <input v-model="name" type="email" class="form-control floating" required>
                            <label class="focus-label">Name<span
                                style="color: orange;">*</span></label>
                          </div>
                          <div class="form-group form-focus">
                            <input v-model="email" type="email" class="form-control floating" required>
                            <label class="focus-label">Email <span
                                style="color: orange;">*</span></label>
                          </div>
                          <div class="form-group form-focus">
                            <input v-model="phone" type="number" class="form-control floating">
                            <label class="focus-label">Phone <span
                                style="color: orange;"></span></label>
                          </div>
                          <div class="form-group form-focus">
                            <input v-model="password" type="password" class="form-control floating" required>
                            <label class="focus-label">Password<span
                                style="color: orange;">*</span></label>
                          </div>
                          <div class="form-group form-focus mb-0">
                            <input type="password" v-model="confirmpassword" class="form-control floating" required>
                            <label class="focus-label">Confirm Password<span
                                style="color: orange;">*</span></label>
                          </div>
                          <div class="dont-have">
                            <span class="checkmark"></span>By registering You agree to Gooreo's
                            <router-link to="/privacy-policy">
                              User Agreement, Privacy Policy,
                            </router-link>
                            and
                            <router-link to="/cookies-policy">
                              Cookies Policy
                            </router-link>
                            .
                          </div>
                          <vue-recaptcha :sitekey="webkey">
                            <button class="btn btn-primary btn-block mt-3 btn-lg login-btn"
                                    @click.prevent="Register()">JOIN
                            </button>
                          </vue-recaptcha>
                          <div class="login-or">
                            <p>Register with</p>
                          </div>
                          <div class="row space-between social-login">
                            <div class="col-4" v-if="enableLogin.twitter">
                              <a class="btn btn-twitter btn-block" href="javascript:void(0);"
                                 @click="loginSocial('twitter')"> Twitter</a>
                            </div>
                            <div class="col-4" v-if="enableLogin.facebook">
                              <a class="btn btn-facebook btn-block" href="javascript:void(0);"
                                 @click="loginSocial('facebook')"> Facebook</a>
                            </div>
                            <div class="col-4" v-if="enableLogin.google">
                              <a class="btn btn-google btn-block" href="javascript:void(0);"
                                 @click="loginSocial('google')">
                                Google</a>
                            </div>
                            <div class="col-4" v-if="enableLogin.github">
                              <a style="background-color:#24292D" class="btn btn-google btn-block"
                                 href="javascript:void(0);"
                                 @click="loginSocial('github')">
                                Github</a>
                            </div>
                          </div>
                          <div class="col-12 text-center dont-have">Have an account?
                            <router-link to="/login">Click here
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {

    return {
      enableLogin: {
        facebook: false,
        google: false,
        github: false,
        twitter: false,
      },
      loading: false,
      name: '',
      email: '',
      phone: '',
      password: '',
      confirmpassword: '',
      orgin: process.env.VUE_APP_BACK_SITE_URL,
      webkey: process.env.CAPTCHA_webKey,
      inv: '',
    }
  },
  inject: ['api'],
  mounted() {
    window.addEventListener('message', this.onMessage, false);
    this.getSettings();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    async getSettings() {
      this.api.get('generalSetting').then(res => {
        if (res.status == 'success') {
          this.enableLogin.google = res.data.data.setting.googleLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.facebook = res.data.data.setting.facebookLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.twitter = res.data.data.setting.twitterLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.github = res.data.data.setting.githubLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;

        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async login() {
      this.api.login('auth/login', {
        email: this.email,
        password: this.password,
        device_name: 'test',
      }).then(res => {
        if (res.status == 'success') {
          $cookies.set('v', process.env.VUE_APP_KEY)
          $cookies.set("token", this.encrypted(`Bearer ${res.data.access_token}`))
          $cookies.set("user", this.encrypted(JSON.stringify(res.data.user)))
          $cookies.set("avatar", this.encrypted(JSON.stringify(res.data.user.avatar)))
          $cookies.set('loggedin', true)
          location.replace('/')
        } else {
          this.toast("An error happened", 'danger')
        }
      })
    },
    async Register() {
      this.loading = true
      if (this.name.toLowerCase().includes("gooreo", -1)) {
        this.toast("This name is not available, please choose a different one ", 'danger');
        this.loading = false
      } else if (this.email.toLowerCase().includes("gooreo", -1)) {
        this.toast("This email is not available, please choose a different one ", 'danger');
        this.loading = false
      } else {
        if (this.name.length <= 0 || this.email.length <= 0 || this.password.length <= 0 || this.confirmpassword.length <= 0) {
          this.toast('All fields are required', 'danger');
          this.loading = false
        } else {
          this.api.post('auth/register', {
            name: this.name,
            email: this.email,
            phone: '+'.concat(this.phone),
            password: this.password,
            password_confirmation: this.confirmpassword,
            affiliate_id: this.inv
          }).then(res => {
            if (res.status == 'success') {
              if ($cookies.get('inv')) {
                $cookies.remove('inv')
              }
              this.login()
            } else {
              this.toast(res.data, 'danger')
              this.loading = false
            }
          })
        }
      }
    },
    loginSocial($provider) {
      const newWindow = openWindow('', 'message')
      axios.get(`${this.orgin}/api/auth/login/${$provider}`)
          .then(response => {
            newWindow.location.href = response.data.url;
          })
          .catch(function (error) {
            console.error(error);
          });
    },
    // This method save the new token and username
    onMessage(e) {
      if (e.origin !== this.orgin || !e.data.token || e.data.token == "" || e.data.token == undefined) {
        return;
      }
      $cookies.set('loggedin', true)
      $cookies.set('token', this.encrypted(`Bearer ${e.data.token}`))
      $cookies.set('user', this.encrypted(JSON.stringify(e.data.user)))
      $cookies.set('avatar', this.encrypted(JSON.stringify(e.data.user.avatar)))
      location.replace('/')
    }
  },
}

function openWindow(url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }
  options = {url, title, width: 600, height: 720, ...options}
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height
  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop
  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')
  const newWindow = window.open(url, title, optionsStr)
  if (window.focus) {
    newWindow.focus()
  }
  return newWindow
}
</script>
<style scoped>
.focus-label {
  color: #000000
}

.form-control:focus .focus-label {
  color: #ff4817 !important;
}

.form-control:focus {
  border: 1px solid #ff4717 !important;
  box-shadow: none;
  color: #ff4817;
  outline: 0 none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>