<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layout-header/>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <div class="account-content">
                <div class="align-items-center justify-content-center">
                  <div class="login-right">
                    <div class="login-header text-center">
                      <router-link to="/"><img alt="logo" class="img-fluid" src="../../../assets/img/logo-01.png">
                      </router-link>
                      <h3>Welcome Back</h3>
                      <p v-if="codeKey==='recovery'">Please confirm access to your account by entering one of your
                        emergency
                        recovery codes</p>
                      <p v-if="codeKey==='auth'">Please confirm access to your account by entering the authentication
                        code provided by your authenticator application. </p>
                    </div>
                    <form v-show="twoFactor==='hidden'" method="post" @submit.prevent="userData">
                      <div class="form-group form-focus">
                        <input v-model="email" class="form-control floating" type="text">
                        <label class="focus-label">Email</label>
                      </div>
                      <div class="form-group form-focus">
                        <input v-model="password" class="form-control floating"
                               :type="showPassword===false? 'password':'text'"><i @click="showPassword=!showPassword"
                                                                                  class="fas password"
                                                                                  :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        <label class="focus-label">Password</label>
                      </div>
                      <div class="form-group">
                        <label class="custom_check">
                          <input name="rem_password" type="checkbox">
                          <span class="checkmark"></span> Remember password
                        </label>
                      </div>
                      <vue-recaptcha :sitekey="webkey">
                        <button class="btn btn-primary btn-block btn-lg login-btn"
                                @click.prevent="login()">Login
                        </button>
                      </vue-recaptcha>

                      <div class="login-or">
                        <p>Or login with</p>
                      </div>
                      <div class="row social-login">
                        <div v-if="enableLogin.twitter" class="col-4">
                          <a class="btn btn-twitter btn-block" href="javascript:void(0);"
                             @click="loginSocial('twitter')"> Twitter</a>
                        </div>
                        <div v-if="enableLogin.facebook" class="col-4">
                          <a class="btn btn-facebook btn-block" href="javascript:void(0);"
                             @click="loginSocial('facebook')"> Facebook</a>
                        </div>
                        <div v-if="enableLogin.google" class="col-4">
                          <a class="btn btn-google btn-block" href="javascript:void(0);" @click="loginSocial('google')">
                            Google</a>
                        </div>
                        <div v-if="enableLogin.github" class="col-4">
                          <a class="btn btn-google btn-block" href="javascript:void(0);"
                             style="background-color:#24292D" @click="loginSocial('github')">
                            Github</a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 text-start">
                          <router-link class="forgot-link" to="/forgot-password">Forgot Password ?</router-link>
                        </div>
                        <div class="col-6 text-end dont-have">New to Gooreo?
                          <router-link to="/register">Click here</router-link>
                        </div>
                      </div>
                    </form>
                    <form v-if="twoFactor==='show'" method="post" @submit.prevent="userData">
                      <div v-if="codeKey==='auth'" class="form-group form-focus">
                        <input v-model="code" class="form-control floating" type="text">
                        <label class="focus-label">Code</label>
                      </div>
                      <div v-if="codeKey==='recovery'" class="form-group form-focus">
                        <input v-model="recovery" class="form-control floating" type="text">
                        <label class="focus-label">Recovery Code</label>
                      </div>


                      <div class="row ">
                        <div class="col-3 ">
                          <vue-recaptcha :sitekey="webkey">
                            <button class="btn btn-primary btn-block btn-lg code-btn"
                                    @click.prevent="twoFactorLogin()">Login
                            </button>
                          </vue-recaptcha>
                        </div>
                        <div class="col-9 text-start">
                          <button v-if="codeKey==='auth'" @click="codeKey='recovery' ;this.code=''" class="btn btn-primary">Use
                            recovery
                            code
                          </button>
                          <button v-if="codeKey==='recovery'" @click="codeKey='auth' ; this.recovery='' " class="btn btn-primary">
                            Use an authentication code
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      enableLogin: {
        facebook: false,
        google: false,
        github: false,
        twitter: false,
      },
      email: '',
      password: '',
      webkey: process.env.CAPTCHA_webKey,
      orgin: process.env.VUE_APP_BACK_SITE_URL,
      twoFactor: 'hidden',
      codeKey: 'auth',
      code: '',
      recovery: '',
      showPassword: false,
      user_id: '',

    }
  },

  inject: ['api'],
  mounted() {
    window.addEventListener('message', this.onMessage, false)
    this.getSettings();
  },

  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {

    async getSettings() {
      this.api.get('generalSetting').then(res => {
        if (res.status == 'success') {
          this.enableLogin.google = res.data.data.setting.googleLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.facebook = res.data.data.setting.facebookLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.twitter = res.data.data.setting.twitterLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
          this.enableLogin.github = res.data.data.setting.githubLogin.enable == 'true' || res.data.data.setting.googleLogin.enable == true ? true : false;
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async login() {
      this.loading=true
      this.api.login('auth/login', {
        email: this.email,
        password: this.password,
        device_name: 'test',
      }).then(res => {
        if (res.status == 'success') {
          $cookies.set('v', process.env.VUE_APP_KEY)
          $cookies.set("token", this.encrypted(`Bearer ${res.data.access_token}`))
          $cookies.set("user", this.encrypted(JSON.stringify(res.data.user)))
          $cookies.set("avatar", this.encrypted(JSON.stringify(res.data.user.avatar)))
          $cookies.set('loggedin', true)
          location.replace('/')
        } else if (res.data.message == 'Two factor authentication is required') {
          this.loading=false
          this.twoFactor = 'show';
          this.user_id = res.data.user.id
        } else {
          this.loading=false
          this.toast("The email or password is incorrect", 'danger')
        }
      })
    },
    async twoFactorLogin() {
      this.loading=true
      this.api.purePost('two-factor-challenge', {
        code: this.code,
        user_id: this.user_id,
        device_name: 'test',
        recovery_code: this.recovery,
      }).then(res => {
        if (res.status == 'success') {
          $cookies.set('v', process.env.VUE_APP_KEY)
          $cookies.set("token", this.encrypted(`Bearer ${res.data.access_token}`))
          $cookies.set("user", this.encrypted(JSON.stringify(res.data.user)))
          $cookies.set('loggedin', true)
          location.replace('/')
        } else {
          this.loading=true
          this.toast("The given data was invalid.", 'danger')
        }
      })
    },
    loginSocial($provider) {
      const newWindow = openWindow('', 'message')
      axios.get(`${this.orgin}/api/auth/login/${$provider}`)
          .then(response => {
            // console.log(response.data);
            newWindow.location.href = response.data.url;
          })
          .catch(function (error) {
          });
    },
    // This method save the new token and username
    onMessage(e) {
      if (e.origin !== this.orgin || !e.data.token || e.data.token == "" || e.data.token == undefined) {
        return;
      }
      $cookies.set('loggedin', true)
      $cookies.set('token', this.encrypted(`Bearer ${e.data.token}`))
      $cookies.set('user', this.encrypted(JSON.stringify(e.data.user)))
      location.replace('/')
    }
  },
}

function openWindow(url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }
  options = {url, title, width: 600, height: 720, ...options}
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height
  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop
  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')
  const newWindow = window.open(url, title, optionsStr)
  if (window.focus) {
    newWindow.focus()
  }
  return newWindow
}
</script>
<style>
.password {
  display: revert;
  top: 22px;
  right: 15px;
  position: absolute;
  cursor: pointer;
}
</style>