<template>
  <div>
    <div id="teleport" key="teleport">
      <teleport key="teleportwww" to="head">
        <div id="teleportssssss" key="teleportssssss">
          <meta content="Gooreo's goal is to link the business owners with the
               best freelancers and service providers around the world while
               also making payments easier and smoother."
                property="description"/>
          <meta content="Gooreo's goal is to link the business owners with the best
               freelancers and service providers around the world while also
                making payments easier and smoother."
                property="og:description"/>
          <meta content="Gooreo | Freelancer's Platform" property="title"/>
          <meta :content="keywords" property="keywords"/>
          <meta content="Gooreo | Freelancer's Platform" property="og:title"/>
          <meta content="Gooreo" property="og:site_name"/>
        </div>
      </teleport>
    </div>
    <div class="home-page">
      <div class="main-wrapper">
        <new-header/>
        <section data-aos="fade-up" class="section container home-banner homesection background row-middle">
          <div data-aos="fade-up" class=" container row">
            <div class=" col-sm-12 col-md-12 col-lg-7 home-banner-padding align-items-center">
              <div class="banner-content mt-25  aos">
                <div class="p-b-at500">
                  <h1>
                    The <span style="color:#009df8">one-stop </span> place for
                    all your business <span style="color:#ff4514">needs</span>
                  </h1>
                  <p class="subtitle">
                    Business owners can buy services or hire freelancers on the
                    Gooreo platform, where freelancers can work independently
                    and get paid in cryptocurrencies.
                  </p>
                </div>
                <router-link v-if="loggedin == 'false'" to="/login" class="start-btn text-center px-2 py-2"> Start your
                  journey!
                  <div class="icon">
                    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                            fill="currentColor"></path>
                    </svg>
                  </div>
                </router-link>
                <div v-if="loggedin == 'true'">
                  <div class="d-flex text-center m-auto" style="width: 310px">
                    <!--                    <router-link to="/user/add-service" class="add-new-service "> Add service-->
                    <!--                      <div class="icon">-->
                    <!--                        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                          <path d="M0 0h24v24H0z" fill="none"></path>-->
                    <!--                          <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"-->
                    <!--                                fill="currentColor"></path>-->
                    <!--                        </svg>-->
                    <!--                      </div>-->
                    <!--                    </router-link>-->
                    <!--                    <router-link to="/user/add-project" class="add-new-project "> Add project-->
                    <!--                      <div class="icon">-->
                    <!--                        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                          <path d="M0 0h24v24H0z" fill="none"></path>-->
                    <!--                          <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"-->
                    <!--                                fill="currentColor"></path>-->
                    <!--                        </svg>-->
                    <!--                      </div>-->
                    <!--                    </router-link>  -->
                    <router-link style="width: 222px;" to="/add-new" class="add-new-service ">Add Service / Project
                      <div class="icon">
                        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                fill="currentColor"></path>
                        </svg>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-sm-12 col-md-12 col-lg-5 home-banner-padding home-background align-items-center">
              <svg width="733" height="550" viewBox="0 0 733 550" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#svg_edb1b5a3-9d0c-4540-997f-1d2ebaa4187f_filter0_d_1104:1999)">
                  <path
                      d="M83.6794 418.777C96.8221 399.999 123.281 396.624 140.717 411.501L441.443 668.089C456.669 681.081 459.531 703.515 448.054 719.913C434.911 738.691 408.452 742.067 391.016 727.19L90.2906 470.601C75.0641 457.61 72.2022 435.175 83.6794 418.777Z"
                      stroke="url(#svg_0cdbaf4b-b0c8-4103-96de-5561866e3419_paint0_linear_1104:1999)"
                      stroke-width="20"></path>
                </g>
                <g filter="url(#svg_b83e5c16-ed3d-4974-84a7-dd261a7489fe_filter1_d_1104:1999)">
                  <rect x="671.146" y="406.168" width="513.819" height="94.57" rx="47.285"
                        transform="rotate(-139.403 671.146 406.168)"
                        fill="url(#svg_757bec60-100c-4bb2-aec2-2d5e6332c262_paint1_linear_1104:1999)"></rect>
                </g>
                <g filter="url(#svg_567a0f4a-2928-4cf4-8329-a3b820cbdd3b_filter2_d_1104:1999)">
                  <rect x="62.0781" y="21" width="591.881" height="94.57" rx="47.285"
                        transform="rotate(40.6864 62.0781 21)"
                        fill="url(#svg_4d1fc72e-55bc-4daf-821a-f4c66fae9536_paint2_linear_1104:1999)"></rect>
                </g>
                <g filter="url(#svg_44cfdfe4-2346-4508-94c2-45919c42dc35_filter3_d_1104:1999)">
                  <circle cx="575.166" cy="488.165" r="47.285" transform="rotate(-143.323 575.166 488.165)"
                          fill="#FF70C6"></circle>
                </g>
                <defs>
                  <filter id="svg_edb1b5a3-9d0c-4540-997f-1d2ebaa4187f_filter0_d_1104:1999" x="62.6572" y="390.203"
                          width="406.419" height="362.285" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1104:1999"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1104:1999"
                             result="shape"></feBlend>
                  </filter>
                  <filter id="svg_b83e5c16-ed3d-4974-84a7-dd261a7489fe_filter1_d_1104:1999" x="296.388" y="17.3906"
                          width="420.91" height="375.391" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1104:1999"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1104:1999"
                             result="shape"></feBlend>
                  </filter>
                  <filter id="svg_567a0f4a-2928-4cf4-8329-a3b820cbdd3b_filter2_d_1104:1999" x="15.8218" y="38.3945"
                          width="479.677" height="426.781" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1104:1999"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1104:1999"
                             result="shape"></feBlend>
                  </filter>
                  <filter id="svg_44cfdfe4-2346-4508-94c2-45919c42dc35_filter3_d_1104:1999" x="517.878" y="434.879"
                          width="114.576" height="114.574" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"></feColorMatrix>
                    <feMorphology radius="2" operator="dilate" in="SourceAlpha"
                                  result="effect1_dropShadow_1104:1999"></feMorphology>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1104:1999"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1104:1999"
                             result="shape"></feBlend>
                  </filter>
                  <linearGradient id="svg_0cdbaf4b-b0c8-4103-96de-5561866e3419_paint0_linear_1104:1999" x1="461.284"
                                  y1="703.285" x2="66.3111" y2="432.355" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#F6C043"></stop>
                    <stop offset="1" stop-color="#FF9634"></stop>
                  </linearGradient>
                  <linearGradient id="svg_757bec60-100c-4bb2-aec2-2d5e6332c262_paint1_linear_1104:1999" x1="676.994"
                                  y1="434.959" x2="1163.75" y2="542.248" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#3866F5"></stop>
                    <stop offset="1" stop-color="#BD00FF"></stop>
                  </linearGradient>
                  <linearGradient id="svg_4d1fc72e-55bc-4daf-821a-f4c66fae9536_paint2_linear_1104:1999" x1="643.481"
                                  y1="66.7736" x2="66.4887" y2="76.8505" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#6BDAB6"></stop>
                    <stop offset="1" stop-color="#66E0F6"></stop>
                  </linearGradient>
                </defs>

              </svg>
              <img class="header-right" style="drop-shadow: (16px 16px 10px black)" src="../../assets/img/right.png"
                   alt="">
              <!-- <div class="shots language-icon "><i style="font-size: 30px; color: #14C38E "
                                                   class="fas m-3  fa-language"></i></div>
              <div class="shots code-icon "><i style="color: #0096FF ;font-size: 50px;"
                                               class="fas m-3 fa-code"></i></div>
              <div class="shots handshake-icon"><i style="font-size: 30px;color:#143F6B"
                                                   class="fas m-3 fa-handshake"></i></div>
              <div class="shots video-icon"><i style="font-size: 45px;color:#F55353"
                                               class="fas m-3 fa-video"></i></div>
              <div class="shots microphone-alt-icon "><i style="font-size: 40px;color: #3330E4"
                                                         class="fas m-4 fa-microphone-alt"></i></div>
              <div class="shots palette-icon"><i style="font-size: 50px ;color: #F637EC"
                                                 class="fas m-3 fa-palette"></i></div>
              <div class="shots dollar-sign-icon "><i style="font-size: 30px ;color: #32FF6A"
                                                      class="fas mx-4 my-3 fa-dollar-sign"></i></div>
              <div class="shots gooreo-icon"><img class="m-4" src="../../public/favicon.png"/>
              </div>
              <div class="shots bitcoin-icon "><img class="m-3"
                                                    src="../../assets/img/bitcoin.png"/> -->
              <!-- </div> -->
            </div>
          </div>
        </section>
        <section data-aos="fade-up" class="section work">
          <work-content></work-content>
        </section>
        <section data-aos="fade-up" v-if="!isMobile()" class="section projects">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-12 mx-auto">
                <div class="section-header text-center aos" data-aos="fade-up">
                  <div class="section-line"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <!--- Project Item  -->
              <template v-if="loading">
                <categorycard-skeleton
                    v-for="i in 4"
                    :key="i"
                ></categorycard-skeleton>
              </template>
              <template v-if="!loading && !isMobile()">
                <wrap-around :sections="sections"/>
              </template>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="see-all aos" data-aos="fade-up">
                  <router-link class="btn all-btn" to="/marketplace">SERVICES MARKETPLACE
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" class="section feature">
          <div class="container">
            <div class="row">
              <!-- Feature Item -->
              <template v-if="loading">
                <services-skeleton v-for="i in 6" :key="i"></services-skeleton>
              </template>
              <template v-if="!loading">
                <div v-for="item in services.slice(0, 8)" :key="item.id" class="col-md-3 col-sm-12">
                  <!-- Blog Post -->
                  <div class="s-grid-card grid-card">
                    <div class="card-image">
                      <router-link
                          :params="`${item.seo_title}`"
                          :to="`/marketplace/${item.slug}`"><img :src="item.photo_url"
                                                                 alt="Post Image"
                                                                 class="img-fluid"
                                                                 height="240px"/>
                      </router-link>
                      <button class="favourite " style="top: 7.5px;
                         " @click="addFavorite(item.id)">
                        <i :class="  item.favorited ? 'fas fa-heart' : 'fas fa-heart' "
                           :style="item.favorited ? 'color:#dc5454' : ''"></i>
                      </button>
                    </div>
                    <div class="card-content">
                      <ul class="entry-meta meta-item">
                        <li>
                          <div class="post-author">
                            <router-link :params="`${item.author.name}`" :to="`/profile/${item.author.slug}`"><img
                                :src="item.author.avatar" alt="Post Author"/>
                              <span> {{ item.author.name }}</span>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                      <h3 style="overflow: hidden; font-weight: normal; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;">
                        <router-link :to="`/marketplace/${item.slug}`">{{ item.title.en }}
                        </router-link>
                      </h3>
                      <div class="d-flex justify-content-between align-items-center mb-3  mt-3">
                        <h4 class="m-card-title mt-1 orange-text">
                          ${{ parseFloat(item.price)?.toFixed(2)}}
                        </h4>
                        <div class="fa-grid-card">
                          <i class="far mb-2 fa-star"></i>
                          {{ item.reviews_avg == null ? 0 : item.reviews_avg.substr(0, 3) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Blog Post -->
                </div>
              </template>
              <!-- /Feature Item -->
            </div>
          </div>
        </section>
        <!--        <begin-component></begin-component>-->
        <section data-aos="fade-up" class="section developer">
          <div class="container">
            <div class="row">
              <div class="col-md-7 col-sm-12 col-12 mx-auto">
                <div class="section-header text-center aos" data-aos="fade-up">
                  <div class="section-line"></div>
                  <h2 class="header-title">Latest Projects</h2>
                  <p>Work with talented people at the most affordable price</p>
                </div>
              </div>
            </div>
            <div id="testimonial-slider" class="row" data-aos="fade-up">

              <services-skeleton v-if="loading" class=" col-4" v-for="i in 4" :key="i"></services-skeleton>

              <template v-if="!loading">
                <div class="row m-auto">

                  <div
                      v-for="project in projects.slice(0, 4)"
                      :key="project.id"
                      class="col-auto mb-3 "
                      :class="
                    isMobile()
                      ? 'freelance-widget m-auto col-lg-12'
                      : 'freelance-widget freelancerslide col-lg-12'
                  " style=" max-height: 350px;">
                    <div class="freelance-content ">
                      <button
                          class="favourite "
                          style="    right: 4.5px;
                          top: 5.5px;"
                          @click="addPFavorite(project.id)">
                        <i :class="
                          project.favorited ? 'fas fa-heart' : 'fas fa-heart' "
                           :style="project.favorited ? 'color:#dc5454' : ''"></i>
                      </button>
                      <div
                          class="freelance-img mt-2"
                          style="width: 75px;height: 68px;">
                        <router-link
                            :params="`${project.title.en}`"
                            :to="`/profile/${project.author.slug}`"
                            class=""
                            tabindex="-1">
                          <img :src="project.author.avatar" alt="User Image" style="width: 60px;height: 60px;"/>
                          <span class="verified" v-if="project.author.verified_id==true"><i
                              class="fas fa-check-circle"></i
                          ></span>
                        </router-link>
                      </div>
                      <div class="freelance-info mt-5">
                        <router-link
                            :params="`${project.title.en}`"
                            :to="`/project/${project.slug}`"
                            style="text-align: start;" tabindex="-1">
                          <div style=" width: 265px;" class="freelance-specific">
                            <p>
                              {{ project.title.en }}
                            </p>
                          </div>
                        </router-link>
                        <br/>
                        <div class="freelance-specific">
                          {{ project.author.name }}
                        </div>
                        <div class="freelancers-price">
                          {{ project.project_length_from }} -
                          {{ project.project_length_to }}
                          {{ project.project_length_type }}
                        </div>
                        <div class="freelancers-price">
                          ${{ parseFloat(project.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(project.project_budget_to)?.toFixed(2) }}
                        </div>
                      </div>
                    </div>
                    <div class="cart-hover">
                      <router-link :params="`${project.title.en}`" :to="`/project/${project.slug}`" class="btn-cart"
                                   tabindex="-1">View Project
                      </router-link>
                    </div>
                  </div>
                </div>

              </template>
            </div>
          </div>
        </section>
        <layout-footer/>
      </div>
    </div>
  </div>
</template>
<script>
import keywords from "raw-loader!../../../src/assets/keywords.txt";
import ServicesSkeleton from "./components/skeleton-loaders/services-skeleton";
import CategorycardSkeleton from "./components/skeleton-loaders/categorycard-skeleton";

export default {
  components: {CategorycardSkeleton, ServicesSkeleton},
  inject: ["api"],
  data() {
    return {
      keywords: keywords,
      loading: true,
      blogposts: [],
      projects: [],
      services: [],
      home: [],
      loggedin: $cookies.get("loggedin")
    };
  },
  computed: {
    sections() {
      return this.$store.state.generalsetting.sections;
    }
  },
  methods: {
    async homeData() {
      await this.api.get("home").then((resp) => {
        if (resp.status === "success") {
          this.home = resp.data;
          this.projects = resp.data.data.projects;
          this.services = resp.data.data.services;
          this.loading = false;
        } else {
          this.toast(resp.data, "danger");
        }
      });
    },
    async getCart() {
      await this.api.get("cart/cart-content").then((resp) => {
        if (resp.status === "success") {
          let list = [];
          Object.keys(resp.data.data).forEach((e) => {
            list.push(resp.data.data[e]);
          });
          this.$store.commit("addCartItem", list.length);
        } else {
          this.toast(resp.data, "danger");
        }
      });
    },
    async addFavorite(id) {
      await this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.services).map((value, key) => {
            if (this.services[value].id == id) {
              this.services[value].favorited = !this.services[value].favorited;
            }
          });
        } else {
        }
      });
    },
    async addPFavorite(id) {
      await this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.projects).map((value, key) => {
            if (this.projects[value].id == id) {
              this.projects[value].favorited = !this.projects[value].favorited;
            }
          });
        } else {
        }
      });
    },
  },

  created() {
    this.homeData();
    if ($cookies.get("loggedin") == "true") {
      this.getCart();
    }
  },
};
</script>
<style scoped>
.card-content {
  text-align: left;
}

.header a {
  color: #161c2d;
}
</style>