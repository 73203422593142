<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">

              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Payment Methods</li>

                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row" v-if="isFreelancer">
            <!-- sidebar -->
            <layout-employeesidebar></layout-employeesidebar>
            <!-- /sidebar -->
            <div class="col-xl-9 col-md-8">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div class="dash-widget">
                    <div class="dash-info">
                      <div class="dash-title-widget-info">Amount</div>
                      <div class="dash-widget-count">$
                        {{ parseFloat(balance.total)?.toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="dash-widget">
                    <div class="dash-info">
                      <div class="dash-title-widget-info">Amount to be withdrawn</div>
                      <div class="dash-widget-count">$
                        {{ parseFloat(balance.balance_available)?.toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="dash-widget">
                    <div class="dash-info">
                      <div class="dash-title-widget-info">Minimum withdrawal limit
                      </div>
                      <div class="dash-widget-count">$ 25.00</div>

                    </div>
                  </div>
                </div>
              </div>
              <tab style="height: 40px;cursor: pointer" v-model="activeTabId" :tabs="tabs"
                   @clicked="getTab"></tab>
              <!-- Tabs navs -->
              <div v-if="activeTabId===0" style="max-width: 520px;" class="card m-auto p-3">
                <div class="   p-4">
                  <h3>Cryptocurrency Payment</h3>
                  <p>We support BUSD(BEP-20) withdrawal payments, please make sure to put your right wallet
                    address.</p>
                  <h5 class="mt-2">Your BEP-20 Address</h5>
                  <input v-model="wallet_address" class="form-control mb-5  " min="42" placeholder="0x....."
                         type="text">
                  <h5>Amount to be withdrawn</h5>
                  <input v-model="amount" class="form-control mb-5  "
                         placeholder="Amount to be withdrawn"
                         type="number">
                  <div class="filter-widget">
                    <h5>SmartChain</h5>
                    <div class="form-group">
                      <select v-model="networkName" class="form-control select">
                        <option v-for="item in networks" :key=" item.id" :value="item">
                          {{ item }}
                        </option>
                      </select>
                      <h5 class="mt-3">Coin</h5>
                      <select v-model="gateway" class="form-control select">
                        <option v-for="item in gateways.filter((e)=> e.network==networkName)" :key=" item.id"
                                :value="item">
                          {{ item.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div style="width:50%; margin:auto;">
                    <button class=" btn  btn-primary  mt-3 click-btn" @click="Cryptocurrency()">withdrawal
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="activeTabId===1" style="max-width:520px; margin:auto;" class="card  p-5">
                <h3>Cash withdrawal</h3>
                <p>Withdrawals may take between 7 and 14 days</p>
                <div class="row">
                </div>

                <input v-model="amount" class=" mt-2  form-control"
                       placeholder=" Amount to be withdrawn" style="min-height: 40px;" type="number">
                <div style="width:auto; margin:auto;">
                  <button class=" btn  btn-primary mt-4 click-btn" @click=" Cash()">withdrawal</button>

                </div>
              </div>
              <!-- Tabs content -->
            </div>
          </div>
          <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
            <please-switch></please-switch>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
import {MDBTabContent, MDBTabItem, MDBTabNav, MDBTabPane, MDBTabs,} from 'mdb-vue-ui-kit';
import {ref} from 'vue';

export default {
  components: {
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
  },
  inject: ['api'],
  created() {
    this.getData()
    this.GetCurrency()
  },
  methods: {
    getTab(val) {
      this.activeTabId = val

    },
    async getData() {
      await this.api.get('user/dashboard').then(res => {
        if (res.status == 'success') {
          this.balance = res.data.data.balance
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    Cash() {
      if (this.amount !== 0) {
        this.api.post('user/withdrawal/request', {
          payout_method: 'paypal',
          amount: this.amount
        }).then(res => {
          if (res.status === 'success') {
            this.toast("requested", 'success')
          } else {
            let err
            for (err in res.data) {
              console.log(res.data[err][0])
              this.toast(res.data[err][0], 'danger')
            }
          }
        })
      } else {
        this.toast("Withdrawal field is empty", 'danger')
      }
    },
    Cryptocurrency() {
      this.api.post('user/withdrawal/request', {
        payout_method: 'coin',
        currency_gateway_id: this.gateway.id,
        address: this.wallet_address,
        amount: this.amount
      }).then(res => {
        if (res.status == 'success') {
        }
      })
    },
    GetCurrency() {
      this.api.get('generalSetting').then(res => {
        if (res.status === 'success') {
          this.smartchain = res.data.data.currencyGateway
          for (const i in this.smartchain) {
            this.networks.push(i)
            for (const y in this.smartchain[i]) {
              this.gateways.push(this.smartchain[i][y])
            }
          }
        }
      })
    }
  },
  data() {
    return {
      activeTabId: 0,
      loading: true,
      method: 'paypal',
      amount: 0,
      balance: '',
      paypal: '',
      name: '',
      iban: '',
      bank: '',
      sweft: '',
      coin_id: '',
      wallet_address: '',
      currencyName: '',
      SmartChain: '',
      SmartChainName: '',
      smartchain: '',
      gateway: {},
      networkName: '',
      gateways: [],
      networks: [],
      tabs: ['Cryptocurrency Payment', 'Cash withdrawal']
    }
  }
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>