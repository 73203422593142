<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader />
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">
                      <router-link to="/user/dashboard"> Dashboard</router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Balance</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <!-- sidebar -->
            <layout-employeesidebar></layout-employeesidebar>
            <!-- /sidebar -->
            <div class="col-xl-9 col-md-8">
              <div class="d-flex space-between">

                <h3 class="mb-5">Sales Overview</h3>

              </div>
              <div class="dashboard-sec mt3  ">

                <div class="row center-text ">

                  <div class="col-md-6 col-lg-3 ">
                    <div class="dash-widget">
                      <div class="dash-info ">
                        <div class="dash-widget-info ">Total Balance</div>
                        <div class="dash-widget-count  "> $ {{ user.balance }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-widget-info">Suspended Balance</div>
                        <div class="dash-widget-count"> $ {{ user.balance_suspended }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-widget-info">Withdrawable</div>
                        <div class="dash-widget-count"> $ {{ user.balance_available }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-widget-info">Number Of Sales</div>
                        <div class="dash-widget-count"> {{ user.my_sales_count }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-projects-view">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="">
                      <div class="card ">
                        <div class="card-header">
                          <div class="row justify-content-between align-items-center">
                            <div class="col">
                              <h5 class="card-title">Transactions</h5>
                            </div>
                            <div class="col-auto">
                              <router-link class="btn btn-primary btn-rounded" to="/user/add-balance"><i
                                  class="me-1 fas fa-plus"></i>Add fund</router-link>

                            </div>
                          </div>
                        </div>
                        <div v-if="transactions.length==0" class="m-5">
                          <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
                        </div>

                        <div v-else class="card-body">
                          <div class="table-responsive table-box">
                            <table id="milestones" class="table table-center table-hover datatable">
                              <thead class="thead-pink">
                                <tr>
                                  <th>Date</th>
                                  <th>payment Method</th>
                                  <th>type</th>
                                  <th> Amount</th>
                                  <th> Payment Status</th>
                                  <th>Invoice</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in transactions" :key="item.id">
                                  <td>{{ item.created_at_format.date }} ( {{ item.created_at_format.humen }} )</td>
                                  <td>{{ item.payment_method }}</td>
                                  <td>
                                    {{ item.type }}
                                  </td>
                                  <td class="d-flex">
                                    <p v-if="item.type === 'pay'">- </p>
                                    <p v-else>+</p>
                                    $ {{ item.amount }}
                                  </td>
                                  <td> {{ item.status }}</td>
                                  <td>
                                    <router-link style="color:#4B9F18" :to="`/view-invoice/${item.transaction_id}`">
                                      <fa-icon class="fas fa-file-invoice fa-2x " size="2em"></fa-icon>
                                    </router-link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer />
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->

    <div class="modal fade wallet-rupee" id="file">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
                  class="far fa-times-circle orange-text"></i></a></span>
          </div>
          <div class="modal-body">
            <div class="payment-list wallet card-body wallet-list">
              <div class="wallet-head text-center">
                <img class="img-fluid" src="../../../assets/img/logo-01.png" alt="app-store">
                <h2>Add Funds</h2>
              </div>
              <div class="bootstrap-tags text-center p-0">
                <span class="badge badge-pill badge-skills" style="cursor: pointer;" @click="addAmount(50)">$50</span>
                <span class="badge badge-pill badge-skills" style="cursor: pointer;" @click="addAmount(100)">$100</span>
                <span class="badge badge-pill badge-skills" style="cursor: pointer;" @click="addAmount(200)">$200</span>
                <span class="badge badge-pill badge-skills" style="cursor: pointer;" @click="addAmount(500)">$500</span>
              </div>
              <div class="form-group profile-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="dol-btn" type="submit">$</button>
                  </div>
                  <input type="text" class="form-control" v-model="amount">
                </div>
              </div>
              <label class="radio-inline custom_radio me-4">
                <input type="radio" name="optradio" value="paypal" v-model="paymentmethod" checked="true">
                <span class="checkmark"></span> Paypal
              </label>
              <label class="radio-inline custom_radio me-4">
                <input type="radio" name="optradio" value="card" v-model="paymentmethod">
                <span class="checkmark"></span> Card
              </label>
              <label class="radio-inline custom_radio me-4">
                <input type="radio" name="optradio" value="cryptocurrency" v-model="paymentmethod">
                <span class="checkmark"></span> Cryptocurrency
              </label>
              <label class="radio-inline custom_radio">
                <input type="radio" name="optradio" value="gooreo" v-model="paymentmethod">
                <span class="checkmark"></span> Gooreo coin
              </label>
              <div class="row" v-if="paymentmethod === 'card'">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="card_number">Card Number</label>
                    <input class="form-control" id="card_number" type="text" placeholder="XXXX XXXX XXXX XXXX">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="holder_name">Holder Name</label>
                    <input class="form-control" id="holder_name" type="text" placeholder="John Doe">
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="expiry_on">Expires on</label>
                    <input class="form-control" id="expiry_on" type="text" placeholder="MM/YY">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cvv">CVV</label>
                    <input class="form-control" id="cvv" type="text" placeholder="XXX">
                  </div>
                </div>
              </div>
              <div class="col-lg-12 btn-pad" @click="pay()">
                <a href="javascript:void(0);" class="click-btn btn-primary">Confirm</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style scoped>
.dash-info {
  min-height: 144px !important;
}
</style>
<script>
export default {
  methods: {
    submit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.loading = true;
      let vm = this;
      axios.post(this.route('stripe_charge'), {
        token: token,
        stripeToken: token.id,
        type: this.typeCharge,
        _token: this.$page.props.csrf_token,
      })
        .then(response => {
          if (response.data.success) {
            if (vm.redirect) {
              vm.$inertia.get(vm.route(vm.redirect));
            } else {
              $('.stripe-credit-card-form-wrap').html(response.data.message_html);
            }
          } else {
            $('.stripe-credit-card-form-wrap').html(response.data.message_html);
          }
        })
        .catch(error => {
          $('.stripe-credit-card-form-wrap').html(error.message);

        }).finally(() => {
          this.loading = false;
        });
    },
    async pay() {
      if (this.paymentmethod == 'paypal') {
        const res = await this.api.post('paypalRedirect', {
          'total': this.amount,
          'return_url': 'https://api.gooreo.com/success',
          'cancel_url': 'https://api.gooreo.com/cancel',
        }).then(res => {
          if (res.status == 'success') {
            location.replace(res.data.data.url)
          } else {
            this.toast(res.data, 'danger')
          }
        })
      } else if (this.paymentmethod == 'cryptocurrency') {
        this.api.post('coinpayments', {
          'total': this.amount,
          'return_url': 'https://gooreo.com/success',
          'cancel_url': 'https://gooreo.com/cancel',
        }).then(res => {
          if (res.status == 'success') {

          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    addAmount(amount) {
      this.amount = amount;
    },
    changePage(page) {
      this.page = page
      this.getPageData()
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
        this.api.getCustom(
          url
        ).then(
          res => {
            if (res.status == 'success') {
              this.pagination = res.data.data.transctions.current_page
              this.loading = false
            } else {
              this.toast(res.data, 'danger')
            }
          }
        )
      }
    },
    prevPage(url) {
      if (this.page >= 2) {
        this.loading = true
        this.page--
        this.api.getCustom(
          url
        ).then(
          res => {
            if (res.status == 'success') {
              this.user = res.data.data.transctions.data
              this.loading = false
            } else {
              this.toast(res.data, 'danger')
            }
          }
        )
      }
    },
    async getSettings() {
      this.api.get('generalSetting').then(res => {
        if (res.status == 'success') {
          this.publishableKey = res.data.data.setting.stripe_publishable_key
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getPageData() {
      if (this.page > 1 && this.page <= this.pagination.transctions) {
        this.loading = true
      }
      this.api.get(`user/balance?page=${this.page}`).then(res => {
        if (res.status == 'success') {
          this.transactions = res.data.data.transctions.data
          this.user = res.data.data.user
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.transctions) {
        this.loading = true
      }
      this.api.get('user/balance').then(res => {
        if (res.status == 'success') {
          this.transactions = res.data.data.transctions.data
          this.user = res.data.data.user
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      load: true,
      activeClass: 'active',
      user: [],
      page: 1,
      transactions: [],
      paymentmethod: 'paypal',
      amount: 0,
      publishableKey: '',
      token: null
    }
  },
  async mounted() {
    this.load = true
    await this.getData()
    this.getSettings()
    this.load = false
  },
}
</script>

