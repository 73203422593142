<template>
  <div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import axios from 'axios';
import {onMounted, onUnmounted} from 'vue';
import {useRouter} from 'vue-router'
import {createToast} from 'mosha-vue-toastify';
import {useStore} from 'vuex'
import CryptoJS from "crypto-js";


function decrypt(text) {
  try {
    return CryptoJS.AES.decrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString(CryptoJS.enc.Utf8);
  } catch (error) {

  }
}

export default {
  name: "App",
  inject: ['api'],
  setup() {
    const store = useStore()
    store.commit('getSetting');
    const router = useRouter();
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let email_verify_url = params.email_verify_url ? params.email_verify_url : null;
    if (email_verify_url) {
      let vm = this;
      axios.get(
          email_verify_url
          , {
            headers: {
              Authorization: this.decrypted($cookies.get("token")),
            }
          }).then(
          res => {
            createToast(res.data.msg, {

              type: `success`,
            });
            router.replace('/')

          }
      )
    }
    onMounted(async () => {
      if ($cookies.get('loggedin', true)) {
        if ($cookies.get('v') == null || $cookies.get('v') !== process.env.VUE_APP_KEY) {
          $cookies.remove('cart')
          $cookies.remove('cartcount')
          $cookies.set('loggedin', false)
          $cookies.remove('token')
          $cookies.remove('user')
          $cookies.remove('avatar')
          $cookies.set('v', process.env.VUE_APP_KEY)
          location.replace('/')
        }
      }
      if ($cookies.get('loggedin') == null) {
        $cookies.set('loggedin', 'false')
      }
      $(document).on('click', '#toggle_btn', function () {
        if ($('body').hasClass('mini-sidebar')) {
          $('body').removeClass('mini-sidebar');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').addClass('mini-sidebar');
          $('.subdrop + ul').slideUp();
        }
        return false;
      });

    })
  }
};
</script>
