<template>

</template>

<script>
export default {
  name: "why Gooreo"
}
</script>

<style scoped>

</style>