<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta :content="lis +' '+ category.seo_title " name="keywords">
    <meta :content=" 'Gooreo' +' '  +'|' +' '  +  category.seo_title" property="title"/>
    <meta :content="'Best'+ ' ' + category.seo_description +' '+ 'Services form Gooreo'" property="description"/>
    <template v-if="category.photo_url">
      <meta :content="category.photo_url" property="og:image:url"/>
      <meta :content="category.photo_url" property="og:image"/>
    </template>
    <meta :content=" category.seo_title" property="og:title"/>
    <meta :content=" 'Gooreo' " property="og:site_name"/>
    <meta :content="'Best'+ ' ' + category.seo_title +' '+ 'Services form Gooreo'" property="og:description"/>
    <meta :content="`https://gooreo.com${$route.path}`" property="og:url"/>
  </teleport>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div v-if="!isMobile()" class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="" src="../../../assets/img/home-icon.svg"> Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/marketplace">Services Marketplace</router-link>
                    </li>
                    <li v-if="!loading" class="breadcrumb-item">
                      <router-link :params="`${category?.seo_title}`" :to="`/category/${title.slug}`">{{
                          title.title.en
                        }}
                      </router-link>
                    </li>
                    <li v-if="!loading " class="breadcrumb-item">
                      {{ category == null ? '' : category.content?.en }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content pt-5">
        <div class="container">
          <h3 class="mx-2">Filters</h3>
          <div class="col-lg-12 d-flex filters justify-content-between mb-4">
            <div class="d-flex">
              <select v-model="fcategory" class="form-control select mx-2" @change="addFilter(fcategory, 'category')">
                <option value="">Category</option>
                <option v-for="item in sections" :key="item.id" :value="item.id">{{ item.title.en }}</option>
              </select>
              <select v-model="fsection" class="form-control select mx-2" @change="addFilter(fsection, 'section')">
                <option value="">Sub category</option>
                <option v-for="item in subsections" :key="item.id" :value="item.id">{{ item.title.en }}</option>
              </select>
              <select v-model="frate" class="form-control select mx-2" @change="addFilter(frate, 'rating')">
                <option value="">Rating</option>
                <option v-for="item in 5" :key="item" :value="item">
                  <div>{{ item }}</div>
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <div style="margin: auto; cursor: pointer;" class="d-flex align-items-center mb-2">
                  <p class="m-2">Price: </p>
                  <img src="../../../assets/img/signalg.svg" width="20" v-if="pricedir == ''"
                       @click="addFilter('asc', 'price')">
                  <img src="../../../assets/img/signalb2.svg" width="20" v-if="pricedir == 'desc'"
                       @click="addFilter('asc', 'price')">
                  <img src="../../../assets/img/signalb.svg" width="20" v-if="pricedir == 'asc'"
                       @click="addFilter('desc', 'price')">
                </div>
                <div style="margin: auto;" class="d-flex projects-action  text-center mb-0">
                  <input hidden @change="addFilter(fonline, 'online')" v-model="fonline" type="checkbox" id="checkbox"
                         class="check">
                  <label for="checkbox" class="checktoggle"></label>
                  <p class="ms-3"> Online</p>
                </div>
              </div>
              <a class="my-2 ms-5 orange-text" style="cursor: pointer;" @click.prevent="clearFilter()">Clear All</a>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="row blog-grid-row">
                <services-skeleton v-if="loading" v-for="i in 10"></services-skeleton>
                <div v-for="item in services" :key="item.id" class="col-md-3 col-sm-12">
                  <!-- Blog Post -->
                  <div class="s-grid-card grid-card">
                    <div class="card-image">
                      <router-link :params="`${item.seo_title}`" :to="`/marketplace/${item.slug}`"><img
                          :src="item.photo_url" height="240px" alt="Post Image" class="img-fluid">
                      </router-link>
                      <button class="favourite " style="top: 7.5px;
                      " @click="addFavorite(item.id)"><i :style="item.favorited ? 'color:#dc5454' : ''"
                                                         :class="item.favorited ? 'fas fa-heart' : 'fas fa-heart'"></i>
                      </button>
                    </div>
                    <div class="card-content">
                      <ul class="entry-meta meta-item">
                        <li>
                          <div class="post-author">
                            <router-link :params="`${item.author.name}`" :to="`/profile/${categoryslug}`"><img
                                :src="item.author.avatar" alt="Post Author">
                              <span> {{ item.author.name }}</span>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                      <h3
                          style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;">
                        <router-link :params="`${item.seo_title}`" :to="`/marketplace/${item.slug}`">{{ item.title.en }}
                        </router-link>
                      </h3>
                      <div class="d-flex justify-content-between align-items-center mb-3  mt-3">
                        <h4 class="m-card-title orange-text">${{ parseFloat(item.price)?.toFixed(2) }}</h4>
                        <div class="fa-grid-card">
                          <i class="far fa-star"></i> {{ item.reviews_avg == null ? 0 : item.reviews_avg.substr(0, 3) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Blog Post -->
                </div>
              </div>
              <!-- Blog Pagination -->
              <div style="width: 121px" class="pb-4 m-auto">
                <button v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                        @click="nextPage(pagination.next_page_url)">
                  Load more
                </button>
              </div>
              <!-- /Blog Pagination -->
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
import FilterSkeleton from "../components/skeleton-loaders/filter-skeleton";
import ServicesSkeleton from "../components/skeleton-loaders/services-skeleton";

export default {
  components: {ServicesSkeleton, FilterSkeleton},
  async created() {
    await this.getData()
  },
  methods: {
    clearFilter() {
      this.frate = '';
      this.fcategory = '';
      this.fsection = '';
      this.pricedir = '';
      this.fonline = '';
      this.getData()
    },
    addFilter(f, type) {
      if (type == 'category') {
        this.fcategory2 = `cat[parent][id]=${f}`
        this.fsection2 = ''
        this.subsections = this.sections.filter(s => s.id == f)[0].childs
      }
      if (type == 'price') {

        this.pricedir = f
        this.pricedir2 = `f_params[orderBy][field]=price&f_params[orderBy][type]=${f}`
      }
      if (type == 'sales') {
        this.salesdir2 = `f_params[orderBy][field]=sales&f_params[orderBy][type]=${f}`
      }
      if (type == 'sortby') {
        this.fby2 = `sortBy=${f}`
      }
      if (type == 'direction') {
        this.fdir2 = `direction=${f}`
      }
      if (type == 'tags') {
        this.ftags2 = `tags['title'][]=${f}`
      }
      if (type == "online") {
        this.fonline2 = f == true ? `is_online=${f == true ? 1 : 0}` : "";
      }
      if (type == "offline") {
        this.foffline2 = f == true ? `is_online=${f == true ? 0 : 1}` : "";
      }
      if (type == 'section') {
        this.fsection2 = `section_id=${f}`
      }
      if (type == 'rating') {
        this.frate2 = `avgRating=${f}`
      }
      if (type == 'days') {
        this.fdays2 = `days_count=${f}`
      }
      if (type == 'daysfrom') {
        this.fdaysfrom2 = `days_count[start]=${f}`
      }
      if (type == 'daysto') {
        this.fdaysto2 = `days_count[end]=${f}`
      }
      let basefil = `${this.pricedir2}&${this.salesdir2}&${this.ftags2}&${this.fonline2}&${this.foffline2}&${this.fcategory2}&${this.fsection2}&${this.frate2}&${this.fdays2}&${this.fdaysfrom2}&${this.fdaysto2}`
      let fil = []
      basefil.split('&').forEach(bf => {
        if (bf.trim() != '' && bf.trim() != 0) {
          fil.push(bf)
        }
      })
      let fl = ''
      fil.forEach(function (f, idx, array) {
        if (idx !== array.length) {
          fl += `${f}&`
        } else {
          fl += `${f}`
        }
      })
      this.filter = fl
      this.loading = true
      this.api.get(`marketplace?${this.filter}`).then(res => {
        if (res.status == 'success') {
          this.category = res.data.data.sections
          this.services = res.data.data.services.data
          this.pagination = res.data.data.services.pagination
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
          this.loading = false
        }
        this.loading = false
      })
    },
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then(res => {
        if (res.status == 'success') {
          Object.keys(this.services).map((value, key) => {
            if (this.services[value].id == id) {
              this.services[value].favorited = !this.services[value].favorited;
            }
          })
        } else {
          this.toast(resp.data, 'danger')
        }
      })
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
        this.api.getCustom(
            url
        ).then(
            res => {
              this.services = this.services.concat(res.data.data.posts.data)
              this.category = res.data.data.sections
               this.loading = false
            }
        )
      }
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get(
          `section/${this.$route.path.split('/')[2]}/service?page=${this.page}`
      ).then(
          res => {
            if (res.status == "success") {
              this.title = res.data.data.sections.parent
              this.category = res.data.data.sections.childs
              this.pagination = res.data.data.sections.childs
              this.api.get('generalSetting').then(resp => {
                if (resp.status == 'success') {
                  this.sections = resp.data.data.sections
                } else {
                  this.toast(resp.data, 'danger')
                }
              })
              this.services = res.data.data.posts.data
              this.category = res.data.data.sections
              this.pagination = res.data.data.posts.pagination
              this.loading = false
              let list = []
              Object.keys(this.services).forEach(e => {
                list.push(this.services[e].seo_title)
              })
              this.lis = list
            } else {
              location.replace('404')
            }
          }
      )
    },
  },
  computed: {
    sects() {
      return this.$store.state.generalsetting?.sections;
    },
  },
  inject: ['api'],
  data() {
    return {
      context: '',
      lis: [],
      loading: true,
      page: 1,
      services: [],
      sections: [],
      subsections: [],
      category: '',
      pagination: {},
      filter: '?',
      fcategory: '',
      fcategory2: '',
      fby: '',
      fby2: '',
      frate: '',
      frate2: 0,
      fdir: '',
      fdir2: '',
      ftags: '',
      ftags2: '',
      fonline: '',
      fonline2: '',
      foffline: '',
      foffline2: '',
      fsection: '',
      fsection2: '',
      fdays: '',
      fdays2: '',
      fdaysfrom: '',
      fdaysfrom2: '',
      fdaysto: '',
      fdaysto2: '',
      pricedir: '',
      pricedir2: '',
      salesdir: '',
      salesdir2: '',
    }
  }
}
</script>