<template>
  <loader v-if="loading"></loader>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                      ><img
                          src="../../../assets/img/home-icon.svg"
                          alt="Post Author"
                      />
                        Home
                      </router-link>
                    </li>

                    <li class="breadcrumb-item" aria-current="page">
                      <router-link to="/user/dashboard">Favourites</router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar/>
            <div class="col-lg-9 col-md-12">
              <div class="row blog-grid-row">
                <div style="justify-content: space-around" class="d-flex tabbar px-1 border my-5 p-2">
                  <router-link class="nav-link text-center w-100 m-auto tabitem my-2 " to="/favourite-services">Favourite Services</router-link>
                  <router-link class="nav-link text-center w-100  m-auto tabitem my-2 " to="/favourite-projects">Favourite Projects</router-link>
                </div>
                <div class="col-md-12">
                  <div
                      v-for="item in favourite"
                      :key="item.id"
                      class="col-md-12 col-lg-12 col-xl-12">
                    <div class="freelance-widget widget-author">
                      <div class="freelance-content ">
                        <button
                            class="favourite "
                            @click="removeFavorite(item.id)"
                        >
                          <i
                              style="color:rgb(220, 84, 84);"
                              :class="
                              item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                            "
                          ></i>
                        </button>
                        <div class="author-heading">
                          <div class="freelance-location ">
                            <i class="fas fa-clock   " style="width: 20px;"> </i
                            >{{ item.created_at_format.humen }}
                          </div>
                          <div class="d-flex ">
                            <div style="  text-align:center; width: 100px;">
                              <router-link
                                  :to="`/profile/${item.author.slug}`"
                                  class="profile-img"
                              >
                                <img :src="item.author.avatar" alt="author"/>
                              </router-link>

                              <router-link :to="`/profile/${item.author.slug}`">
                                <div class="profile-name">
                                  <div class="author-location mt-1">
                                    {{ item.author.name }}
                                    <i
                                        fas
                                        fa-check-circle
                                        v-if="item.author.verified_id == true"
                                        class="fas fa-check-circle text-success clearfix verified"
                                    ></i>
                                  </div>
                                </div>
                              </router-link>
                            </div>
                            <router-link
                                tabindex="-1"
                                :to="`/project/${item.slug}`"
                            >
                              <h3 class="mt-3">{{ item.seo_title }}</h3>
                            </router-link>
                          </div>
                          <!--                          <div class="freelance-tags">-->
                          <!--                            <a href="javascript:void(0);"><span class="badge badge-pill badge-design me-1">After-->
                          <!--                                Effects</span></a>-->
                          <!--                            <a href="javascript:void(0);"><span-->
                          <!--                                class="badge badge-pill badge-design">Illustrator</span></a>-->
                          <!--                            <a href="javascript:void(0);"><span-->
                          <!--                                class="badge badge-pill badge-design ms-1">HTML</span></a>-->
                          <!--                          </div>-->
                          <div class="freelancers-price">
                            ${{ parseFloat(item.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(item.project_budget_to)?.toFixed(2) }}
                          </div>
                        </div>
                        <div class="counter-stats">
                          <ul>
                            <li>
                              <h5>Project Length</h5>
                              <h3 class="counter-value">
                                {{ item.project_length_from }} -
                                {{ item.project_length_to }}
                                {{ item.project_length_type }}
                              </h3>
                            </li>
                            <li>
                              <h5>Proposals</h5>
                              <h3 class="counter-value">
                                {{ item.orders_count }}
                              </h3>
                            </li>
                            <li>
                              <h5>Payment Type</h5>
                              <h3 class="counter-value">
                                <span class="jobtype">{{
                                    item.project_type
                                  }}</span>
                              </h3>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="cart-hover">
                        <router-link
                            class="btn-cart"
                            tabindex="-1"
                            :to="`/project/${item.slug}`"
                        >Apply now
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Blog Pagination -->
              <!-- /Blog Pagination -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>

    <!-- /Main Wrapper -->
  </div>
</template>

<script>
export default {
  name: "Favourite Projects",
  created() {
    this.getData();
  },
  methods: {
    clearFilter() {
      this.getData();
    },

    removeFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.favourite).map((value, key) => {
            if (this.favourite[value].id == id) {
              delete this.favourite[value];
              // this.favourite[value].favorited = !this.services[value].favorited;
            }
          });
        } else {
        }
      });
    },

    getData() {
      this.api.get(`getFavoriteItems`).then((res) => {
        if (res.status == "success") {
          this.favourite = res.data.data.projects;
          // console.log(this.favourite)
          this.loading = false;
        } else {
          this.toast(res.data, "danger");
        }
      });
    },
  },
  inject: ["api"],
  data() {
    return {
      loading: true,
      favourite: [],
      sections: [],
      subsections: [],
      category: "",
    };
  },
};
</script>

<style scoped>
.active {
  padding: 13px 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  background-color: white;
  border-radius: 50px;
}
.tabbar a{
  align-self: center;
}
</style>