<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="" src="../../../assets/img/home-icon.svg">
                        Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">{{ $route.path.split('/')[1] }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div v-if="active == 'checkout'" class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-12">
              <h4 class="pro-title ">Cart items</h4>
              <div v-for="item in items" class="col-md-12 ">
                <div class="row ">
                  <div class="row col-md-11">
                    <div class="service-image col-md-2 ">
                      <img :src="item.model.photo_url" class="rounded-3" style="width: 100px;">
                    </div>
                    <div class="info col-md-10 p-3">
                      <h4>{{ item.model.title.en }}</h4>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex">
                          <h3 class="m-2 orange-text">${{ parseFloat(item.price)?.toFixed(2) }}</h3>
                          <p class="mt-1">x{{ item.qty }}</p>
                        </div>
                        <h3>Total: <span class="mt-2 orange-text">${{ getItemTotal(item) }}</span></h3>
                      </div>
                      <textarea class="col-lg-12 form-control" v-model="msgs[item.rowId]"
                                placeholder="Type your order information to the seller..." rows="2"></textarea>
                      <div class="exts  h-50">
                        <u v-show="item.options.ext.length > 0">Extentions</u>
                        <div v-for="ext in item.options.ext" class="ext">
                          <div class="row">
                            <p>{{ ext.title }}: </p>
                            <p style="margin-left: 10px;">+${{ parseFloat(item.price)?.toFixed(2) }}</p>
                            <p style="margin-left: 10px;">+{{ ext.days_count }} days</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-primary text-center" @click.prevent="deleteFromCart(item)">
                      x
                    </button>
                  </div>

                </div>
                <hr>
              </div>
            </div>

            <!-- Blog Sidebar -->
            <div class="col-lg-4 h-100 pro-post widget-box about-widget about-field">
              <div class="info pb-3 d-flex justify-content-between">
                <h4>Your Wallet: </h4>
                <h3 class="orange-text">${{ user.balance }}</h3>
              </div>
              <h4 class="pro-title ">Summary</h4>
              <table class="table">
                <tbody>
                <tr>
                  <td>Items</td>
                  <td>{{ items.length }}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>${{ tax }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>${{ total }}</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td>${{ subtotal }}</td>
                </tr>
                </tbody>
              </table>
              <div class="col-lg-12 btn btn-primary text-center p-2 mt-3" @click="confirmOrder()">
                Checkout
              </div>
            </div>
            <!-- /Blog Sidebar -->
          </div>
        </div>
      </div>

      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <loader v-show="loading"></loader>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  created() {
    this.getData()
    // setTimeout(() => {
    //   if ($cookies.get('deletedI')) {
    //     this.api.post(`cart/delete/${$cookies.get('deletedI')}`, {}).then(res => {
    //       if (res.status == 'success') {
    //         let list = []
    //         let indexes = []
    //         list.filter(e => e.id == item.id).forEach(el => {
    //           indexes.push(list.indexOf(el))
    //         })
    //         indexes.forEach(index => {
    //           list.splice(index, indexes.length)
    //         })
    //         this.items.splice(this.items.indexOf(item), 1)
    //         $cookies.remove('deletedI')
    //       } else {
    //         this.toast(res.data, 'danger')
    //       }
    //     })
    //   }
    // }, 2000)

  },
  inject: ['api'],
  methods: {
    async deleteFromCart(item) {
      this.api.post(`cart/delete/${item.rowId}`, {}).then(res => {
        if (res.status == 'success') {
          let list = []
          // if ($cookies.get('cart') != null)
          //   $cookies.get('cart').forEach(elem => {
          //     list.push(elem)
          //   })
          let indexes = []
          list.filter(e => e.id == item.id).forEach(el => {
            indexes.push(list.indexOf(el))
          })
          indexes.forEach(index => {
            list.splice(index, indexes.length)
          })
          // $cookies.set('cart', JSON.stringify(list))
          // $cookies.set('cartcount', list.length)
          this.items.splice(this.items.indexOf(item), 1)
          // setTimeout(() => location.reload(), 1000)
        } else {
          // $cookies.set('deletedI', item.rowId)
          location.reload()
        }
      })
    },
    getItemTotal(item) {
      let prices = 0
      item.options.ext.forEach(e => {
        prices += parseFloat(e.price)
      })
      return parseFloat(item.subtotal) + parseFloat(prices)
    },
    async getData() {
      this.api.get('cart/cart-content').then(res => {
        if (res.status == 'success') {
          let objects = res.data.data
          Object.keys(objects).forEach(key => {
            this.items.push(objects[key])
            this.msgs[key] = ''
          })
          this.api.get('user').then(u => {
            if (u.status == 'success') {
              this.user = u.data
            } else {
              this.toast(res.data, 'danger')
            }
          })
        } else {
          this.toast(res.data, 'danger')
        }
      })
      this.loading = false
    },
    async confirmOrder() {
      let k;
      this.loading = true
      for (k in this.msgs) {
        if (this.msgs[k] == '') {
          this.hasEmpty = true
        }
      }
      if (!this.hasEmpty) {
        for (k in this.msgs) {
          this.api.post(`cart/updateContent/${k}`, {content: this.msgs[k]})
        }
        this.api.post('confirmOrder', {payment: 'wallet'}).then(res => {
          if (res.status == 'success') {
            this.items = []
            this.toast('Order confirmed successfully', 'success')
          } else {
            this.cerror = res.data
            for (const key in this.cerror) {
              for (const item in this.cerror[key]) {
                // console.log(this.cerror[key][key1])
                this.toast(this.cerror[key][item], 'danger')
              }
            }
          }
        })
      } else {
        this.toast('Please fill all the orders information', 'danger')
        this.hasEmpty = false
      }
      this.loading = false
    }
  },
  data() {
    return {
      loading: true,
      hasEmpty: false,
      items: [],
      active: 'checkout',
      user: {},
      msgs: {},
    }
  },
  computed: {
    total() {
      let t = 0
      this.items.forEach(elem => {
        t += parseFloat(elem.subtotal)
        if (elem.options.ext.length > 0) {
          elem.options.ext.forEach(e => {
            t += parseFloat(e.price)
          })
        }
      })
      return parseFloat(t)?.toFixed(2)
    },
    tax() {
      let t = 0
      this.items.forEach(elem => {
        t += parseFloat(elem.tax)
      })
      return parseFloat(t)?.toFixed(2)
    },
    subtotal() {
      return parseFloat(this.tax) + parseFloat(this.total)
    }
  }
}
</script>