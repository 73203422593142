<template>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Create Service</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="card" v-if="isFreelancer">
                <div class="card-body">
                  <div class="warning-card mb-5">
                    <p><i class="fas fa-exclamation-triangle"></i>
                      This page is for freelancers to add their services
                    </p>
                  </div>
                  <div class="card-header pt-0 mb-4">
                    <h5 class="card-title">Add New Service</h5>
                    <p class="card-text mt-2">What the service are you selling?</p>
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <form class="needs-validation" novalidate>
                        <div class=" row">
                          <div class="col-md-12  mb-3">
                            <label for="validationCustom01">Service title</label>
                            <div class="d-flex">
                              <span style=" margin: auto;
                  width: 85px;
                  color: #55545ba3;
                  padding: 10px;
                  font-size: 25px;">
                                I will

                              </span>
                              <input id="validationCustom01" v-model="title" class="form-control"
                                     required type="text">
                            </div>
                            <div class="valid-feedback">
                              Looks good!
                            </div>
                          </div>
                          <p>Include a short title that accurately describes your service.</p>
                          <h3 class="mt-2 ">Category</h3>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Main Category:</label>
                              <select v-model="maincategorey" class="form-select" @change="selectCat()">
                                <option v-for="cat in parents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Sub Category:</label>
                              <select v-model="subcategoryId" class="form-select">
                                <option v-for="cat in subparents" :value="cat">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <h3 class="mt-2 mb-2">Describe your service</h3>
                          <p class="mt mb-2">Include a detailed and accurate description of your service.
                            (It must be at least 40 character)
                          </p>
                          <div class="form-group mb-5 m-2">
                            <QuillEditor ref="editor" v-model:content="description"
                                         :toolbar="editorConfig.modules.toolbar"
                                         theme="snow"/>
                          </div>
                          <div class="m-2">
                            <br>
                            <h3 class="mt-2 mb-2">Attach images</h3>
                            <div>
                              <file-pond ref="pond" accepted-file-types="image/*" allow-multiple="true"
                                         allowReorder="true"
                                         class-name="my-pond" credits="false" label-idle="Drop files here..."
                                         maxFiles="10"
                                         name="test" v-on:init="handleFilePondInit"
                                         v-on:updatefiles="handleFilePondInit"/>
                            </div>
                          </div>

                          <h6 class="settings-size mt-4"></h6>
                          <div class="upload-images upload-size w-100 d-flex flex-wrap">
                            <div v-for="img in showimages" class="">
                              <img :src="img" alt="Image"
                                   style="height: 125px; width: 150px; object-fit: cover; margin: 0 5px;">
                              <div class="removeImg"
                                   style="color: tomato; cursor: pointer; position: relative; top: -125px; left: 5px;"
                                   @click="removeImage(img)">
                                <i class="far fa-times-circle"></i>
                              </div>
                            </div>
                          </div>
                          <h3 class="mt-2 "></h3>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Delivery Time:</label>
                              <select v-model="time" class="form-select">
                                <option v-for="time in deliveryTime" :value="time.split(' ')[0]">{{ time }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group form-placeholder col-md-6 ">
                            <label> Price </label>
                            <input v-model="pprice" class="input-tags form-control" data-role="tagsinput"
                                   required placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div class="form-group form-placeholder col-md-12 ">
                            <label> Keywords </label>
                            <input v-model="keyword" class="input-tags form-control" data-role="tags input"
                                   placeholder="Keywords" style="min-height: 40px;" type="text"
                                   v-on:keydown.space.prevent="addKeyword(keyword)"
                                   @keypress.enter.prevent="addKeyword(keyword)">
                          </div>
                          <div class="keywords col-lg-12 d-flex flex-wrap">
                            <div v-for="chip in keywords" class="chip d-flex"
                                 style="background-color: #009df8; min-width: 100px; text-align: center; padding: 5px; border-radius: 50px; margin: 0 5px;">
                              <div class="removeImg"
                                   style="color: white; margin: 3px; cursor: pointer; position: absolute;"
                                   @click="removeKeyword(chip)">
                                <i class="far fa-times-circle"></i>
                              </div>
                              <h4 style="color:white; margin:auto;">{{ chip }}</h4>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <h3 class="mt-2 ">Buyer Instructions</h3>
                            <div class="form-group">
                              <textarea v-model="instructions" class="form-control" cols="5"
                                        placeholder="Buyer Instructions" rows="5"></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="card">
                                <div class="card-body pt-0">
                                  <div class="card-header">
                                    <h5 class="card-title">Add Service Extensions</h5>
                                  </div>
                                  <div class="exts m-3">
                                    <div v-for="ex in ext" class="ext">
                                      <div class="extbody d-flex justify-content-between"
                                           style="background-color: #ffeeea; padding: 10px 20px;">
                                        <div class="infor">
                                          <h3>{{ ex.title }}</h3>
                                          <p>{{ ex.content }}</p>
                                          <p class="orange-text">+{{ ex.days_count }} Days
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            +${{ ex.price }}</p>
                                        </div>
                                        <div class="btn btn-danger h-100" @click="removeExt(ex)">
                                          <h6 class="m-0 text-white">Delete</h6>
                                        </div>
                                      </div>
                                      <hr>
                                    </div>
                                  </div>
                                  <form>
                                    <div class="settings-form row ">
                                      <div class="form-group form-placeholder col-md-6 mt-3">
                                        <label> Title </label>
                                        <input v-model="ext_title" class="form-control" type="text">
                                      </div>
                                      <div class="form-group form-placeholder col-md-6 mt-3">
                                        <label> Price </label>
                                        <input v-model="ext_price" class="input-tags form-control" data-role="tagsinput"
                                               type="text" @keypress="isNumber($event)">
                                      </div>
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label>Delivery Time:</label>
                                          <select v-model="ext_time" class="form-select">
                                            <option v-for="time in deliveryTime" :value="time.split(' ')[0]">{{
                                                time
                                              }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label>Description </label>
                                        <textarea v-model="ext_desc" class="form-control" cols="5"
                                                  placeholder="Buyer Instructions" rows="5"></textarea>
                                      </div>
                                      <div class="form-group mb-0">
                                        <div class="settings-btns">
                                          <button class="btn btn-primary mt-5 " @click.prevent="addExt()">Add
                                            Extension
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <vue-recaptcha :sitekey="webkey">
                          <button class="btn btn-primary mt-5 " type="submit" @click.prevent="publish()"> Publish
                          </button>
                        </vue-recaptcha>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
                <please-switch></please-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
  <loader v-if="loading"></loader>
</template>
<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType
  from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default {
  components: {
    FilePond
  },
  methods: {
    handleFilePondInit: function () {
      this.images = this.$refs.pond.getFiles().map((file) => file.file);
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addExt() {
      if (this.ext_title == null || this.ext_title == '') {
        this.toast("Service Extensions title is required", 'danger')
      } else if (this.ext_price == null || this.ext_price == '') {
        this.toast("Service Extensions price is required", 'danger')
      } else if (this.ext_time == null || this.ext_time == '') {
        this.toast("Service Extensions delivery time is required", 'danger')
      } else if (this.ext_desc == null || this.ext_desc == '') {
        this.toast("Service Extensions description is required", 'danger')
      } else {
        this.ext.push(
            {
              title: this.ext_title,
              price: this.ext_price,
              days_count: this.ext_time,
              content: this.ext_desc,
            }
        )
        this.ext_title = ''
        this.ext_time = this.deliveryTime[0]
        this.ext_desc = ''
        this.ext_price = ''
      }
    },
    selectCat() {
      this.subparents = this.parents.filter(p => p.text == this.maincategorey)[0].childs
      this.subcategory = this.subparents[0]
    },
    addKeyword(word) {
      if (this.keyword === '' || this.keyword === null) {
      } else {
        this.keywords.push(
            word)
        this.keyword = ''
      }
    },
    loadFile(event) {
      this.images.push(event.target.files[0])
      this.showimages.push(URL.createObjectURL(event.target.files[0]))
    },
    removeImage(img) {
      this.showimages.splice(this.images.indexOf(img), 1)
    },
    removeKeyword(word) {
      this.keywords.splice(this.keywords.indexOf(word), 1)
    },
    removeExt(ext) {
      this.ext.splice(this.keywords.indexOf(ext), 1)
    },
    async getData() {
      this.api.get('user/create/service').then(res => {
        if (res.status == 'success') {
          this.parents = res.data.data.parents
          this.subparents = res.data.data.parents[0].childs
          this.maincategorey = res.data.data.parents[0].text
          this.subcategory = res.data.data.parents[0].childs[0].text
          this.loading = false
        } else {
          this.toast(res, 'danger')
        }
      })
    },
    async publish() {
      if (this.pprice === null) {
        this.toast("Service must have one image at less", 'danger')
      } else {
        this.loading = true
        let form = new FormData();
        form.append('_method', 'POST')
        form.append('title', 'I will '+this.title,)
        form.append('instructions', this.instructions,)
        form.append('content', this.$refs.editor.getHTML())
        form.append('section_id', this.subcategoryId.id)
        form.append('user_id', JSON.parse(this.decrypted($cookies.get("user"))).id,)
        form.append('tags', this.keywords,)
        form.append('price', this.pprice,)
        form.append('days_count', this.time,)
        for (var i = 0; i < this.ext.length; i++) {
          form.append(`ext[${i}][title]`, this.ext[i].title)
          form.append(`ext[${i}][price]`, this.ext[i].price)
          form.append(`ext[${i}][days_count]`, this.ext[i].days_count)
          form.append(`ext[${i}][content]`, this.ext[i].content)
        }
        // form.append('ext', this.ext,)
        for (var i = 0; i < this.images.length; i++) {
          let file = this.images[i];
          form.append('photo[' + i + ']', file);
        }
        this.api.post('user/store/service', form, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': this.decrypted($cookies.get("token"))
          }
        }).then(res => {
          if (res.status == 'success') {
            this.toast("Service  Published", 'success')
            this.$router.push('/user/services-manager')
          } else {
            this.loading = false
            if (res.data.title) {
              this.toast(res.data.title[0], 'danger')
            } else if (res.data.content) {
              this.toast(res.data.content[0], 'danger')
            } else if (res.data.price) {
              this.toast(res.data.price[0], 'danger')
            } else if (res.data.days_count) {
              this.toast("Delivery Time is required ", 'danger')
            } else if (res.data.instructions) {
              this.toast(res.data.instructions[0], 'danger')
            } else if (res.data.tags) {
              this.toast(res.data.tags[0], 'danger')
            } else if (res.data.section_id) {
              this.toast(res.data.section_id[0], 'danger')
            }
          }
        })
      }
    }
  },
  inject: ['api'],
  async created() {
    await this.getData()
    this.time = this.deliveryTime[0]
    this.ext_time = this.deliveryTime[0]
  },
  data() {
    return {
      editorConfig: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            [{indent: "-1"}, {indent: "+1"}], // outdent/indent
            [{direction: "rtl"}], // text direction
            [{size: ["small", false, "large", "huge"]}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}],
            [{align: []}],
            ["clean"],
          ],
        },
        theme: "snow",
      },
      webkey: process.env.CAPTCHA_webKey,
      loading: true,
      title: '',
      maincategorey: '',
      subcategory: '',
      maincategoreyId: 0,
      subcategoryId: 0,
      description: '',
      pprice: '',
      images: [],
      showimages: [],
      parents: [],
      subparents: [],
      time: '',
      deliveryTime: [
        '1 Day',
        '2 Days',
        '3 Days',
        '4 Days',
        '5 Days',
        '6 Days',
        '7 Days',
        '10 Days',
        '15 Days',
        '20 Days',
        '25 Days',
        '30 Days',
        '45 Days',
      ],
      keyword: '',
      price: 0,
      slug: '',
      keywords: [],
      instructions: '',
      ext: [],
      ext_title: '',
      ext_desc: '',
      ext_price: '',
      ext_time: '',
    }
  }
}
</script>
<style scoped>
.warning-card p {
  color: #3f4c53;
  font-size: 15px;
  margin-bottom: 0;
}
.d-flex {
  flex-wrap: nowrap!important;

}
.warning-card i {
  color: #cc2626cc;
}
@media only screen and (max-width:1080px ) {
  span {
    font-size: 20px !important;
  }
}
input ,textarea{
  font-size: 18px !important;
}
.settings-bt {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-bt .upload {
  position: absolute;
  z-index: 11;
  background: #009df8;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
}

.settings-bt .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.setting-size {
  font-size: 14px;
  color: #131523;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.setting-size span {
  font-weight: 700;
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
    height: 250px;
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
    height: 250px;
  }
}
.form-control{
  font-size: 18px;
  padding: 10px 15px;
}
</style>