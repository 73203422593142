<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>

                    <li aria-current="page" class="breadcrumb-item">Projects</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row" v-if="!isFreelancer">

            <!-- sidebar -->
            <layout-employeesidebar></layout-employeesidebar>
            <!-- /sidebar -->

            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>My Projects</h3>
              </div>
              <my-projects-header/>

              <div class="row">
                <div class="col-12">
                  <div class="warning-card">
                    <p><i class="fas fa-exclamation-triangle"></i> Pending for admin Approval.</p>
                  </div>
                </div>
              </div>
              <!-- /Warning -->
              <div v-if="projects.length==0" class="m-5">
                <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
              </div>
              <!-- project list -->
              <div v-for="item in projects" :key="item.id" class="my-projects-list">
                <div class="row">
                  <div class="col-lg-12 flex-wrap">
                    <div class="projects-card flex-fill">
                      <div class="card-body">
                        <div class="projects-details align-items-center">
                          <div class="project-info">
                            <span>{{ item.author.name }}</span>
                            <h2>{{ item.seo_title }}</h2>
                            <div class="customer-info">
                              <ul class="list-details">
                                <li>
                                  <div class="slot">
                                    <p>Section</p>
                                    <h5>{{ item.cat_title.en }}</h5>
                                  </div>
                                </li>
                                <li>
                                  <div class="slot">
                                    <p>Views</p>
                                    <h5>{{ item.views_count }}</h5>
                                  </div>
                                </li>
                                <li>
                                  <div class="slot">
                                    <p>Payment type</p>
                                    <h5>{{ item.project_type }}</h5>
                                  </div>
                                </li>
                                <li>
                                  <div class="slot">
                                    <p>Time</p>
                                    <h5><h5> {{ item.project_length_from }} - {{ item.project_length_to }}
                                      {{ item.project_length_type }}</h5></h5>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="project-hire-info">
                            <div class="content-divider"></div>
                            <div class="projects-amount">
                              <h3>${{ parseFloat(item.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(item.project_budget_to)?.toFixed(2) }}</h3>
                            </div>
                            <div class="content-divider "></div>
                            <div class="projects-action text-center">
                              <div style="margin: auto" class="d-flex projects-action  text-center">
                                <p class="me-3">Activated</p>
                                <input hidden @change="activated( item.status , item.id)" v-model ="item.status" type="checkbox" id="checkbox" class="check">
                                <label for="checkbox" class="checktoggle">{{ item.status  }}</label>
                              </div>
                              <router-link :params="`${item.seo_title}`" :to="`/project/${item.slug}`"
                                           class="projects-btn">View Details
                              </router-link>
                              <button class="projects-btn mt-1" data-bs-dismiss="modal"
                                      style=" background-color:#DA1212; border:none " type="button"
                                      @click="deleteProject(item.id)"><span>Delete</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
            <please-switch></please-switch>
          </div>
          <div class="row pb-4">
            <div class="col-md-12">
              <div class="card-pagination text-center">
                <button v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                        @click="nextPage(pagination.next_page_url)">
                  Load more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  methods: {
    async deleteProject(id) {
      this.api.post(`user/delete/project/${id}`, {'_method' : 'delete'}).then(res => {
        if (res.status == 'success') {
          this.toast(' deleted', 'success')
          setTimeout(() => {
            location.reload()
          }, 2000)
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    activated(status1 ,id1) {
      this.api.post(`user/updateStatus/project`, {'_method' : 'post', status: status1,id:id1 },).then(res => {
        if(res.status == 'success'){
          this.toast('Saved', 'success')
        } else{
          this.toast(res.data, 'danger')
        }
      })
    },

    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
      this.api.getCustom(
        url
      ).then( res => {
          if(res.status == 'success'){
            this.projects = this.projects.concat(res.data.data.projects.data)
            // console.log(this.projects)
            this.loading = false
          }else{
            this.toast(res.data, 'danger')
          }
        }
      )
      }
    },

    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get('user/projects?status=pending').then(res => {
        if(res.status == 'success'){
          this.projects = res.data.data.projects.data
          this.loading = false
          this.pagination = res.data.data.projects.pagination
          this.loading = false
        }else{
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      page: 1,
      projects: [],
    }
  },
  Updated() {
    this.loading = false
  },
  async created() {
    await this.getData()
  },

}
</script>