<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Offers And Deals</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>Manage Deals</h3>
              </div>
              <deals-upBar :arr="this.arr"/>
              <!-- project list -->
              <div v-if="deals.length==0" class="m-5">
                <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
              </div>
              <div v-else class="proposals-section">
                <!-- Proposals -->
                <div v-for="item in deals" :key="item.id" class="freelancer-proposals">
                  <router-link :to="{name: 'Deals Chat', params: {id:item.id,title:item.post.title.en}}">
                    <div class="project-proposals align-items-center freelancer">
                      <div class="proposals-info">
                        <div class="proposals-detail">
                          <h3 class="proposals-title">{{ item.post.title.en }}</h3>
                          <div class="proposals-content">
                            <div class="proposal-img">
                              <div class="text-md-center">
                                <img :src="item.customer.avatar" alt="" class="img-fluid">
                                <h4>{{ item.seller.name }}</h4>
                                <span class="info-btn"> Project Owner</span>
                              </div>
                            </div>
                            <div class="proposal-client">
                              <h4 class="title-info">Services Type</h4>
                              <h3 class="client-price">{{ item.project_type }}</h3>
                            </div>
                            <div class="proposal-client">
                              <h4 class="title-info">Order Date </h4>
                              <h3 class="client-price">{{ item.post.created_at_format.date }}</h3>
                            </div>
                            <img :src="item.post.photo_url" alt="" class="sales-img">
                          </div>
                        </div>
                        <div class="project-hire-info">
                          <div class="content-divider-1"></div>
                          <div class="projects-amount">
                            <div class="proposal-client">
                              <h4 class="title-info"> Price</h4>
                              <h2 class="client-price">$ {{ parseFloat(item.order_data.price)?.toFixed(2) }}</h2>
                            </div>
                          </div>
                          <div class="projects-amount">
                            <div class="proposal-client">
                              <h4 class="title-info">Status</h4>
                              <h3 class="client-price">{{ item.status }}</h3>
                            </div>
                          </div>
                          <div class="content-divider-1"></div>
                          <div class="projects-action text-center">
                            <div class="projects-btn mb-5">Active</div>
                            <router-link class="projects-btn" to="/freelancer-view-project-detail">View Project
                            </router-link>
                          </div>
                          <div class="content-divider-1"></div>
                        </div>
                      </div>
                      <div class="description-proposal">
                        <h5 class="desc-title">Description</h5>
                        <p v-html="`${item.post.content.en }`"></p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <!-- pagination -->
              <!-- /pagination -->
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  methods: {
    changePage(page) {
      this.page = page
      this.getPageData()
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
        this.api.getCustom(url).then(res => {
          if (res.status == 'success') {
            this.sales = res.data.data.orders.data
            this.arr = res.data.data.arr
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    prevPage(url) {
      if (this.page >= 2) {
        this.loading = true
        this.page--
        this.api.getCustom(url).then(res => {
          if (res.status == 'success') {
            this.sales = res.data.data.orders.data
            this.arr = res.data.data.arr
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    async getPageData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get(`user/deals?page={this.page}&status=suspended`).then(res => {
        if (res.status == 'success') {
          this.orders = res.data.data.orders.data
          this.arr = res.data.data.arr
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get('user/deals?status=suspended').then(res => {
        if (res.status == 'success') {
          this.orders = res.data.data.orders.data
          this.arr = res.data.data.arr
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      activeClass: 'active',
      deals: [],
      page: 1,
    }
  },
  Updated() {
    this.loading = false
  },
  async created() {
    await this.getData()
  },
}
</script>