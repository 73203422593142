<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>

                    <li aria-current="page" class="breadcrumb-item">Create project</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="card" v-if="!isFreelancer">
                <div class="card-body">
                  <h3 class="mb-4">Create Project</h3>
                  <div class="card-header pt-0 mb-4">
                    <p class="card-text mt-2">Create your own project.</p>
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <form class="needs-validation" novalidate>
                        <div class="form-row row">
                          <div class="col-md-12 mb-3">
                            <label for="validationCustom01">Project title</label>
                            <input id="validationCustom01" v-model="title" class="form-control" placeholder="Title"
                                   required type="text">
                            <div class="valid-feedback">
                              Looks good!
                            </div>
                          </div>
                          <p>Include a short title that accurately describes your project.</p>
                          <h3 class="mt-2 ">Category</h3>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Main Category:</label>
                              <select class="form-select" v-model="maincategorey" @change="selectCat()">
                                <option v-for="cat in parents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Sub Category:</label>
                              <select class="form-select" v-model="subcategory">
                                <option v-for="cat in subparents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <h3 class="mt-2 mb-2">Describe your project</h3>
                          <div class="form-group m-2">
                            <QuillEditor theme="snow" ref="editor" v-model:content="description"
                                         :toolbar="editorConfig.modules.toolbar"/>
                            <!-- <textarea class="form-control" v-model="description" cols="5" placeholder=" Description"
                              rows="5"></textarea> -->
                          </div>
                          <br>
                          <div class="m-2">
                            <p class="mt mb-2">Include a detailed and accurate description of your project.</p>
                            <h3 class="mt-2 mb-2">Attach images</h3>
                            <div>
                              <file-pond name="test" ref="pond" class-name="my-pond" label-idle="Drop files here..."
                                         allow-multiple="true" allowReorder="true" maxFiles="10" credits="false"
                                         accepted-file-types="image/*" v-on:updatefiles="handleFilePondInit"
                                         v-on:init="handleFilePondInit"/>
                              <!-- <input id="file" accept="image/*" class="hide-input" name="image" @change="loadFile"
                                type="file">
                              <label class="upload" for="file">
                                <i class="fa fa-upload"></i>
                              </label> -->
                            </div>
                          </div>
                          <h6 class="settings-size mt-4"></h6>
                          <div class="upload-images upload-size w-100 d-flex flex-wrap">
                            <div v-for="media in media_array" :key="media.id" class="">
                              <img :src="media.lg" alt="Image"
                                   style="height: 125px; width: 150px; object-fit: contain; margin: 0 5px;">
                              <div class="removeImg"
                                   style="color: tomato; cursor: pointer; position: relative; top: -125px; left: 25px;"
                                   @click="deleteImage(media.id)">
                                <i class="far fa-times-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Project payment type: </label>
                              <select class="form-select" v-model="paymenttype">
                                <option value="once">Once (Projects that pay in full at once)</option>
                                <option value="full">Periodically (Projects larger than 2 months)</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="paymenttype == 'once'">
                            <h3 class="mt-2 ">Project length</h3>
                          </div>
                          <div class="form-group form-placeholder col-md-4 " v-if="paymenttype == 'once'">
                            <label>Project lenght from: </label>
                            <input type="text" data-role="tagsinput" v-model="length_from" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                          <div class="form-group form-placeholder col-md-4 " v-if="paymenttype == 'once'">
                            <label>Project lenght to: </label>
                            <input type="text" data-role="tagsinput" v-model="length_to" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                          <div class="col-md-4" v-if="paymenttype == 'once'">
                            <div class="form-group">
                              <label>Project lenght type: </label>
                              <select class="form-select" v-model="lengthtype">
                                <option value="day">Day</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="paymenttype == 'once'">
                            <h3 class="mt-2 ">Project budget</h3>
                          </div>
                          <div class="form-group form-placeholder col-md-6 " v-if="paymenttype == 'once'">
                            <label>Project budget from: </label>
                            <input type="text" data-role="tagsinput" v-model="budget_from" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                          <div class="form-group form-placeholder col-md-6 " v-if="paymenttype == 'once'">
                            <label>Project budget to: </label>
                            <input type="text" data-role="tagsinput" v-model="budget_to" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                          <div class="col-md-12" v-if="paymenttype == 'full'">
                            <h3 class="mt-2 ">Time Type</h3>
                          </div>
                          <div class="col-md-4" v-if="paymenttype == 'full'">
                            <div class="form-group">
                              <label>Project time type: </label>
                              <select class="form-select" v-model="timetype">
                                <option value="part">Part time</option>
                                <option value="full">Full time</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="paymenttype == 'full'">
                            <h3 class="mt-2 ">Project budget</h3>
                          </div>
                          <div class="form-group form-placeholder col-md-6 " v-if="paymenttype == 'full'">
                            <label>Number of months: </label>
                            <input type="text" data-role="tagsinput" v-model="months" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                          <div class="form-group form-placeholder col-md-6 " v-if="paymenttype == 'full'">
                            <label>Budget: </label>
                            <input type="text" data-role="tagsinput" v-model="budget" @keypress="isNumber($event)"
                                   class="input-tags form-control" placeholder="Price" style="min-height: 40px;">
                          </div>
                        </div>
                        <button @click.prevent="publish()" class="btn btn-primary mt-5 " type="submit"> Publish</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
                <please-switch></please-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType
  from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
export default {
  components: {
    FilePond
  },
  methods: {
    handleFilePondInit: function () {
      this.images = this.$refs.pond.getFiles().map((file) => file.file);

    },
    deleteImage(i) {
      if (confirm('Are you sure you want to delete this post?')) {
        this.api.post(`deleteImage/${i}`);
        this.api.get(`user/edit/project/${this.$route.path.split('/')[2]}`).then(res => {
          if (res.status == 'success') {
            let d = res.data.data
            let x = res.data.data.item

            this.media_array = x.media_array


          } else {
            this.toast(res.data, 'danger')
          }
        })

      }
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectCat() {
      this.subparents = this.parents.filter(p => p.text == this.maincategorey)[0].childs
      this.subcategory = this.subparents[0]
    },
    addKeyword(word) {
      this.keywords.push({
        value: word,
        text: word,
      })
      this.keyword = ''
    },
    loadFile(event) {
      this.images.push(event.target.files[0])
      this.showimages.push(URL.createObjectURL(event.target.files[0]))
    },
    removeImage(i) {
      this.showimages.splice(i, 1)
      this.images.splice(i, 1)
    },
    removeKeyword(word) {
      this.keywords.splice(this.keywords.indexOf(word), 1)
    },
    async getData() {
      this.api.get(`user/edit/project/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status == 'success') {
          let d = res.data.data
          let x = res.data.data.item
          this.title = x.title
          this.slug = x.slug
          this.description = x.content
          this.media_array = x.media_array
          this.$refs.editor.setHTML(this.description)
          this.paymenttype = x.project_type
          this.length_from = x.project_length_from
          this.length_to = x.project_length_to
          this.lengthtype = x.project_length_type
          this.budget_from = x.project_budget_from
          this.budget_to = x.project_budget_to
          this.timetype = x.project_time_type
          this.months = x.project_number_of_month
          this.ext = x.ext
          this.time = x.days_count
          x.tags.forEach(t => {
            this.keywords.push({
              value: t,
              text: t,
            })
          })
          x.media_array.forEach(e => {
            this.showimages.push(e.src)
          })
        } else {
          location.replace('404')
        }
      })
      this.api.get('user/create/projects').then(res => {
        if (res.status == 'success') {
          this.parents = res.data.data.parents
          this.subparents = res.data.data.parents[0].childs
          this.maincategorey = res.data.data.parents[0].text
          this.subcategory = res.data.data.parents[0].childs[0].text
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async publish() {
      let form = new FormData();
      form.append('_method', 'PUT')
      for (var i = 0; i < this.images.length; i++) {
        let file = this.images[i];
        form.append('photo[' + i + ']', file);
      }
      form.append('title', this.title)
      form.append('content', this.$refs.editor.getHTML())
      form.append('section_id', this.parents.filter(e => e.text == this.maincategorey)[0].value)
      form.append('user_id', JSON.parse(this.decrypted($cookies.get("user"))).id)
      form.append('slug', this.slug)
      form.append('project_type', this.paymenttype)
      form.append('project_length_from', this.length_from)
      form.append('project_length_to', this.length_to)
      form.append('project_length_type', this.lengthtype)
      form.append('project_budget_from', this.budget_from)
      form.append('project_budget_to', this.budget_to)
      form.append('project_time_type', this.timetype ?? 'part')
      form.append('project_number_of_month', this.months)
      this.api.post(`user/update/project/${this.$route.path.split('/')[2]}`, form, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': this.decrypted($cookies.get("token"))
        }
      }).then(res => {
        if (res.status == 'success') {

        } else {
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  async created() {
    await this.getData()
    this.time = this.deliveryTime[0]
  },
  inject: ['api'],
  data() {
    return {
      editorConfig: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            // superscript/subscript
            [{indent: "-1"}, {indent: "+1"}], // outdent/indent
            [{direction: "rtl"}], // text direction
            [{size: ["small", false, "large", "huge"]}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}],
            [{align: []}],
            ["clean"],
          ],
        },
        theme: "snow",
      },
      paymenttype: 'once',
      timetype: 'part',
      lengthtype: 'day',
      budget: 0,
      months: 0,
      length_from: 0,
      length_to: 0,
      budget_from: 0,
      budget_to: 0,
      title: '',
      maincategorey: '',
      subcategory: '',
      maincategoreyId: 0,
      subcategoryId: 0,
      description: '',
      images: [],
      showimages: [],
      parents: [],
      subparents: [],
      media_array: [],
      time: '',
      deliveryTime: [
        '1 Day',
        '2 Days',
        '3 Days',
        '4 Days',
        '5 Days',
        '6 Days',
        '7 Days',
        '10 Days',
        '15 Days',
        '20 Days',
        '25 Days',
        '30 Days',
        '45 Days',
      ],
      keyword: '',
      price: 0,
      slug: '',
      keywords: [],
      instructions: '',
    }
  }
}
</script>
<style scoped>
.settings-bt {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-bt .upload {
  position: absolute;
  z-index: 11;
  background: #009df8;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
}

.settings-bt .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.setting-size {
  font-size: 14px;
  color: #131523;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.setting-size span {
  font-weight: 700;
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
    height: 250px;
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
    height: 250px;
  }
}
</style>