<template>
  <div class="dashboard-page mb-5">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->

      <!-- Page Content -->
      <div style="height:700px" class="content ">
        <div class="container-fluid mt-5">
          <div class="row mt-5">
            <div class="col-md-8 offset-md-2">

              <!-- Page Not Found -->
              <div class="empty-content text-center">
                <h2>Payment is <span class="orange-text">Canceled</span></h2>
                <div class="btn-item">
                  <router-link class="btn get-btn" to="/">GO TO HOME</router-link>

                </div>
              </div>
              <!-- / Page Not Found -->

            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
    </div>
  </div>
  <layout-footer/>
  <!-- /Footer -->
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  mounted() {

  },
}
</script>