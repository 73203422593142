<template>
  <div v-if="loading == false">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img src="../../../../assets/img/home-icon.svg"
                                               alt="Post Author"> Home
                      </router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/blog"> Blog</router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">{{ blog.post.title.en }}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="card-view">

                <div class="blog-single-post pro-post widget-box">
                  <div class="card-image">
                    <a href="javascript:void(0);"><img style="height: 300px; object-fit: cover;" alt=""
                                                       :src="blog.post.photo_url" class="img-fluid"></a>
                  </div>
                  <h3 class="m-card-title">{{ blog.post.title.en }}</h3>
                  <div class="card-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li><a href="javascript:void(0);"><i class="far fa-calendar"></i>{{ blog.post.created_at.date }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-content" v-html="blog.post.content.en">

                  </div>
                </div>

              </div>
            </div>

            <!-- Blog Sidebar -->
            <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
              <div class="stickyside">
                <!-- Latest Posts -->
                <div class=" pro-post widget-box post-widget">
                  <h4 class="pro-title">Latest Posts</h4>
                  <div class="pro-content pt-0">
                    <ul class="latest-posts">
                      <li v-for="post in blog.related">
                        <div class="post-thumb">
                          <router-link to="/blog-details">
                            <img class="img-fluid" :src="post.photo_url" alt="">
                          </router-link>
                        </div>
                        <div class="post-info">
                          <h4>
                            <router-link to="/blog-details">{{ post.title.en }}</router-link>
                          </h4>
                          <a href="javascript:void(0);" class="posts-date"><i class="far fa-calendar-alt"></i>
                            {{ post.created_at.date }}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /Latest Posts -->

              </div>

            </div>
            <!-- /Blog Sidebar -->

          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  inject: ['api'],
  data() {
    return {
      loading: false,
      blog: {},
    }
  },
  async created() {
    this.loading = true
    await this.api.get(`blog/${this.$route.params.id}`)
        .then(res => {
          if (res.status == 'success') {
            this.blog = res.data.data
          } else {
            location.replace('404')
          }
          this.loading = false

        })
  },
}
</script>