<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-accountheader></layout-accountheader>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Add Balance</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 mt-5 col-md-8">
              <div class="card mt-5  p-5 col-lg-12">
                <div class="row ">
                  <div class="col-md-12 col-lg-8 col-sm-12">
                    <div class="payment-list wallet card-body wallet-list">
                      <div class="d-flex space-between">
                        <h3>Amount</h3>
                        <!--                          <div class="d-flex">-->
                        <!--                            <i class="fas  mt-1 fa-info-circle"></i>-->
                        <!--                            <p class="ms-1">Need help?</p>-->
                        <!--                          </div>-->
                      </div>
                      <div class="row mb-4">
                        <div class="col-3" :class="selectedAmount == 1 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                          @click.prevent="selectAmount(1, 50)">
                          <label class="radio-inline custom_radio me-4 mb-0">
                            <input type="radio" value="1" v-model="selectedAmount">
                            <span class="checkmark" style="border: 1px solid #99999963;"></span>
                            <h5>$50</h5>
                          </label>
                        </div>
                        <div class="col-3" :class="selectedAmount == 2 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                          @click.prevent="selectAmount(2, 100)">
                          <label class="radio-inline custom_radio me-4 mb-0">
                            <input type="radio" value="2" v-model="selectedAmount">
                            <span class="checkmark" style="border: 1px solid #99999963;"></span>
                            <h5>$100</h5>
                          </label>
                        </div>
                        <div class="col-3" :class="selectedAmount == 3 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                          @click.prevent="selectAmount(3, 200)">
                          <label class="radio-inline custom_radio me-4 mb-0">
                            <input type="radio" value="3" v-model="selectedAmount">
                            <span class="checkmark" style="border: 1px solid #99999963;"></span>
                            <h5>$200</h5>
                          </label>
                        </div>
                        <div class="col-3" :class="selectedAmount == 4 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                          @click.prevent="selectAmount(4, 500)">
                          <label class="radio-inline custom_radio me-4 mb-0">
                            <input type="radio" value="4" v-model="selectedAmount">
                            <span class="checkmark" style="border: 1px solid #99999963;"></span>
                            <h5>$500</h5>
                          </label>
                        </div>
                        <div class="col-3"
                          :class="selectedAmount == 5 ? 'selectedCard fixedCard p-3 custom-amount' : 'fixedCard p-3'"
                          @click.prevent="selectAmount(5, 0)">
                          <h5 class="mb-1">Custom</h5>
                          <input style="border: 1px solid#009df8;" v-if="selectedAmount == 5" type="text"
                            class="form-control " v-model="amount">
                        </div>
                      </div>
                      <div>
                        <h3 class="mb-3">Payment Methods</h3>
                        <div class="row ">
                          <div class="row">
                            <div
                              class="checkboxx  border rounded-2 d-flex space-between align-items-center m-auto my-2">
                              <div class="mt-2">
                                <label class="radio-inline custom_radio me-4">
                                  <input type="radio" name="optradio" value="paypal" v-model="paymentmethod">
                                  <span class="checkmark" style="border: 1px solid #99999963;"></span> Paypal
                                </label>
                              </div>
                              <img class="checkimg" src="../../../assets/img/paypal.png">
                            </div>
                            <div
                              class="checkboxx border rounded-2 d-flex space-between align-items-center  m-auto my-2">
                              <div class="mt-2">
                                <label class="radio-inline custom_radio me-4">
                                  <input type="radio" name="optradio" value="card" v-model="paymentmethod">
                                  <span class="checkmark" style="border: 1px solid #99999963;"></span> Card
                                </label>
                              </div>
                              <img class="checkimg" src="../../../assets/img/cards.png">
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="checkboxx border rounded-2 d-flex space-between align-items-center  m-auto my-2">
                              <div class="mt-2">
                                <label class="radio-inline custom_radio me-4">
                                  <input type="radio" name="optradio" value="cryptocurrency" v-model="paymentmethod">
                                  <span class="checkmark" style="border: 1px solid #99999963;"></span> Cryptocurrency
                                </label>
                              </div>
                              <img class="checkimg" src="../../../assets/img/cryptocurrency.png">
                            </div>
                            <div
                              class="checkboxx border rounded-2 d-flex space-between align-items-center  m-auto my-2">
                              <div class="mt-2">
                                <label class="radio-inline custom_radio me-4">
                                  <input type="radio" name="optradio" value="gooreo" v-model="paymentmethod">
                                  <span class="checkmark" style="border: 1px solid #99999963;"></span> Gooreo Coin
                                </label>
                              </div>
                              <img class="checkimg" src="../../../assets/img/gooreocoin.png">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row  mt-5" v-if="paymentmethod == 'paypal'">
                        <div class="col-md-12 m-auto" :key="'paypal'+subtotal">
                          <PaypalBtnVue :price="subtotal" :total="amount" :paypal_client_id="paypal_client_id" />
                        </div>
                      </div>
                      <div class="row mt-5  ms-lg-4 ms-md-2  ms-sm-4" v-if="paymentmethod == 'card'">
                        <div class="col-md-12  ms-lg-1 ms-md-3  ms-sm-4">
                          <Stripe class=" ms-lg-3 ms-md-3  ms-sm-4" :price="subtotal"
                            :publishableKey="publishableKey" />
                        </div>

                      </div>
                      <div class="row" v-if="paymentmethod == 'cryptocurrency'">
                        <div class="col-lg-12  m-auto btn-pad" @click="paycryptocurrency()">
                          <a href="javascript:void(0);" class="click-btn btn-primary">Confirm</a>
                        </div>
                        <div class="col-md-12  m-auto">
                          <coinpayment v-if="payload" _checkouturl="https://gooreo.com/user/addbalance"
                            _host="https://api.gooreo.com" :_payload="payload" :price="subtotal" />
                        </div>
                      </div>
                      <div class="row" v-if="paymentmethod == 'gooreo'">
                        <div class="col-sm-9 ms-2 p-3 border  m-auto shadow">
                          <div v-if="address != ''">
                            <p class="rounded-2 alert-danger p-2">You take full responsiblty on transaction
                              mistakes</p>
                            <p class="rounded-2 alert-warning p-2">Please note that this addres is <span
                                style="color: #009df8; font-weight: bold;">bep-20</span> address</p>
                            <p>* Note that the transaction can take up to 3 minutes to complete</p>
                            <h6>Your Gooreo Address:</h6>
                            <div class="d-flex">
                              <p> {{ address }}</p>
                              &nbsp;
                              &nbsp;
                              <div style="cursor:pointer;" @click="copy()">
                                <i class="fa fa-copy"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4 col-sm-12  ">
                    <div class="summary shadow">
                      <div>
                        <h3 class="card-header p-3 ">Summary</h3>
                        <div class="d-flex justify-content-between mt-4 align-content-center">
                          <p>Amount to be added to your account : </p>
                          <h5>${{ amount }}</h5>
                        </div>
                        <div class="d-flex justify-content-between align-content-center">
                          <p>Fees : </p>
                          <h5>${{ fee }}</h5>
                        </div>
                        <div class="d-flex justify-content-between align-content-center">
                          <p>Subtotal : </p>
                          <h5>${{ subtotal }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="p-3 mt-3 rounded alert-info info">
                      <!-- <ul class="p-3"> -->
                      <span>Gooreo charges buyers a fee per transaction </span>
                      <ul class="ps-4">
                        <li>5.5% for Paypal and credit card.</li>
                        <li>1% for cryptocurrency payment.</li>
                        <li>0% for Gooreo coin payment.</li>
                      </ul>
                      <span>There is an additional $2 charge for transactions under $50 for Paypal and credit card
                        payments.</span>
                      <!-- <li>Gooreo charges buyers a 5.5% service fee per order.</li>
                      <li>This fee is 5.5% of the order price if it's over $50, and there is an additional $2 'small order' charge for orders under $50.</li>
                      <li> Gooreo always charges these fees no matter what you're buying or who you're buying from.</li>
                      <li>If you're a seller, Gooreo takes 20% of the order fee.</li> -->
                      <!-- </ul> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer />
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
import PaypalBtnVue from './PaypalBtn.vue';
import Stripe from './Stripe';
import coinpayment from './CoinPayment';

export default {
  components: {
    PaypalBtnVue,
    Stripe,
    coinpayment
  },
  async mounted() {
    await this.getSettings()
  },
  methods: {
    submit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.loading = true;
      let vm = this;
      axios.post(this.route('stripe_charge'), {
        token: token,
        stripeToken: token.id,
        type: this.typeCharge,
        _token: this.$page.props.csrf_token,
      })
        .then(response => {
          if (response.data.success) {
            if (vm.redirect) {
              vm.$inertia.get(vm.route(vm.redirect));
            } else {
              $('.stripe-credit-card-form-wrap').html(response.data.message_html);
            }
          } else {
            $('.stripe-credit-card-form-wrap').html(response.data.message_html);
          }
        })
        .catch(error => {
          $('.stripe-credit-card-form-wrap').html(error.message);

        }).finally(() => {
          this.loading = false;
        });
    },
    copy() {
      navigator.clipboard.writeText(this.address).then(res => {
        this.toast('copied')
      });
    },
    async paycryptocurrency() {
      if (this.paymentmethod == 'cryptocurrency') {
        await this.api.post('coinpayments', {
          'total': this.amount,
          // 'total': this.amountCoinpayment,
          'oldtotal': this.amount,
          'return_url': 'https://gooreo.com/user/balance',
          'cancel_url': 'https://gooreo.com/payment/canceled',
        }).then(res => {
          if (res.status == 'success') {
            this.payload = res.data.data;
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    getGooreoAddress() {
      this.api.getAddress('wallets/getGooreoAddress').then(res => {
        if (res.status == 'success') {
          this.address = res.data.address
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    selectAmount(amount, price) {
      this.selectedAmount = amount;
      this.amount = price;
    },
    async getSettings() {
      await this.api.get('generalSetting').then(res => {
        if (res.status == 'success') {
          this.publishableKey = res.data.data.setting.stripe_publishable_key
          this.paypal_client_id = res.data.data.setting.paypal_client_id
          this.paypal_fee = res.data.data.setting.paypal_fee
          this.paypal_less_than = res.data.data.setting.paypal_less_than
          this.paypal_increase_amount = res.data.data.setting.paypal_increase_amount
          this.stripe_fee = res.data.data.setting.stripe_fee
          this.stripe_less_than = res.data.data.setting.stripe_less_than
          this.stripe_increase_amount = res.data.data.setting.stripe_increase_amount
          this.coinpayment_fee = res.data.data.setting.coinpayment_fee
          this.coinpayment_less_than = res.data.data.setting.coinpayment_less_than
          this.coinpayment_increase_amount = res.data.data.setting.coinpayment_increase_amount
          this.selectAmount(1, 50)
          this.getGooreoAddress()
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      selectedAmount: 1,
      paymentmethod: 'paypal',
      payload: "",
      address: "",
      amount: 0,
      publishableKey: '',
      paypal_client_id: '',
      token: null,
      paypal_fee: 0,
      paypal_less_than: 0,
      paypal_increase_amount: 0,
      stripe_fee: 0,
      stripe_less_than: 0,
      stripe_increase_amount: 0,
      coinpayment_fee: 0,
      coinpayment_less_than: 0,
      coinpayment_increase_amount: 0,
      fee: 0,
    }
  },
  computed: {
    subtotal() {
      const v = Number(this.amount) + Number(this.fee)
      return v.toFixed(v == 0 ? 0 : 2)
    }
  },
  watch: {
    amount(newVal, oldVal) {
      if (this.paymentmethod == 'paypal') {
        this.fee = ((Number(this.paypal_fee) * newVal) / 100)
        if (parseInt(this.paypal_less_than) > 0 && parseInt(this.paypal_increase_amount) > 0 && parseInt(this.paypal_less_than) > parseInt(newVal)) {
          this.fee = this.fee + parseInt(this.paypal_increase_amount);
        }
      }
      if (this.paymentmethod == 'card') {
        this.fee = (Number(this.stripe_fee) * newVal) / 100
        if (parseInt(this.stripe_less_than) > 0 && parseInt(this.stripe_increase_amount) > 0 && parseInt(this.stripe_less_than) > parseInt(newVal)) {
          this.fee = this.fee + parseInt(this.stripe_increase_amount);
        }
      }
      if (this.paymentmethod == 'cryptocurrency') {
        this.fee = (Number(this.coinpayment_fee) * newVal) / 100
        if (parseInt(this.coinpayment_less_than) > 0 && parseInt(this.coinpayment_increase_amount) > 0 && parseInt(this.coinpayment_less_than) > newVal) {
          this.fee = this.fee + parseInt(this.coinpayment_increase_amount);
        }
      }
      if (this.paymentmethod == 'gooreo') {
        this.fee = 0
      }
      if (newVal == 0) {
        this.fee = 0
      }
      if (newVal == null || newVal == '') {
        this.amount = 0
      }
    },
    paymentmethod(newVal, oldVal) {
      if (newVal == 'paypal') {
        this.fee = ((Number(this.paypal_fee) * this.amount) / 100)
        if (parseInt(this.paypal_less_than) > 0 && parseInt(this.paypal_increase_amount) > 0 && parseInt(this.paypal_less_than) > parseInt(this.amount)) {
          this.fee = this.fee + parseInt(this.paypal_increase_amount);
        }
      }
      if (newVal == 'card') {
        this.fee = (Number(this.stripe_fee) * this.amount) / 100
        if (parseInt(this.stripe_less_than) > 0 && parseInt(this.stripe_increase_amount) > 0 && parseInt(this.stripe_less_than) > parseInt(this.amount)) {
          this.fee = this.fee + parseInt(this.stripe_increase_amount);
        }
      }
      if (newVal == 'cryptocurrency') {
        this.fee = (Number(this.coinpayment_fee) * this.amount) / 100
        if (parseInt(this.coinpayment_less_than) > 0 && parseInt(this.coinpayment_increase_amount) > 0 && parseInt(this.coinpayment_less_than) > this.amount) {
          this.fee = this.fee + parseInt(this.coinpayment_increase_amount);
        }
      }
      if (newVal == 'gooreo') {
        this.fee = 0
      }
      if (this.amount == 0) {
        this.fee = 0
      }
      if (this.amount == null || this.amount == '') {
        this.amount = 0
      }
    },
  },
}
</script>