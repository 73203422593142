<template>
  <loader v-if="loading"/>
  <div class="main-wrapper">
    <layout-header/>
    <div class="bread-crumb-bar">
      <div class="container">
        <div class="row align-items-center inner-banner">
          <div class="col-md-12 col-12 text-center">
            <div class="breadcrumb-list">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                    </router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item">Notifications</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div style="min-height: 400px" class="row justify-content-center ">
        <div class="row">
          <layout-employeesidebar class="mt-5"/>
          <div class="col-7 ms-5">
            <div class="mt-5  mb-5 pt-5">
              <div class="notify-boy d-flex" v-for="item in notifications" :key="item.id">
                <a @click="readAt(item.id )" class="col-12  px-2 p-1" :href="linkExt(item.data.title)">
                  <div class="w-100">
                    <h5 class="mt-3 mb-0">{{ this.translate(item.data.title) }}</h5>
                    <p class="justify-content-end mb-0 d-flex">{{ item.data.time }}</p>
                  </div>
                  <i v-if="!item.read_at">N</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <layout-footer/>
  </div>
</template>

<script>
import translate from '../../../translate.json'

export default {
  name: "notifications",
  inject: ['api'],
  created() {
    this.getNotifications()
  },
  data() {
    return {
      notifications: {},
      loading: true,
      translates: []
    }
  },
  methods: {
    linkExt(link) {
      if (link.split('] :')[1]) {
        const oldLink = link.split('] :')[1]
        const rawLink = oldLink.match(/"(.*?)"/)[1]
        link.replace(oldLink, rawLink)
        return rawLink
      }
    }, readAt(id) {
      this.api.post(`readAllNotify/${id}`)
    },
    translate(word) {
      if (word.search(/@trans/) !== -1) {
        const trsLine = word.match(/\[@trans:(.*?)\]/)[1]
        let j;
        for (j in translate) {
          if (trsLine == j) {
            return translate[j]
          }
          word.replace(trsLine, translate[j])
        }
        return word
      }
      return word
    },
    getNotifications() {
      this.api.getRaw(`https://api.gooreo.com/api/notifications/${JSON.parse(this.decrypted($cookies.get("user"))).uuid}`).then(res => {
        if (res.status === 'success') {
          this.notifications = res.data.data;
          this.loading = false
          // console.log(res.data.data)
          window.axios = require('axios');
          window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
          window.pusher = require('pusher-js');
          window.Pusher.logToConsole = false;
          var pusher = new Pusher("30fad4c0b0b50a1a41ca", {
            cluster: "eu",
            authEndpoint: "https://api.gooreo.com/api/broadcasting/auth",
            auth: {
              headers: {
                Authorization: this.decrypted($cookies.get("token"))
              }
            },
          });
          const channel = pusher.subscribe(`notify-channel`);
          const vm = this;
          channel.bind("notify-channel", (data) => {
            this.notifications = res.data.data;
            const notify = data.data;
            vm.notifications.data.data.push(notify);
            // console.log( vm.notifications.data.data)
          });
        }
      })
      // console.log(this.notifications)
    },
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 0;
  padding: 10px;
}

.bread-crumb-bar {
  box-shadow: none
}
</style>