<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta content="Gooreo | Services Marketplace" property="title"/>
    <meta
        content="Find The best freelance and services with gooreo"
        name="description"
    />
    <meta :content="lis" name="keywords"/>
    <meta content="Gooreo | Services Marketplace" property="og:title"/>
    <meta content="Gooreo | Marketplace" property="og:site_name"/>
    <meta
        content="Find The best freelance and services with gooreo"
        property="og:description"
    />
    <meta property="og:type" content="website"/>
    <meta :content="$route.path" property="og:url"/>
  </teleport>
  <div class="dashboard-page">
    <div class="main-wrapper">
      <layout-header/>
      <div class="bread-crumb-bar bar2 ">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class=" col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol style="overflow-x: scroll;">
                    <li v-for="item in sections?.slice(0, 9)" class="breadcrumb-item2">
                      <router-link :to="{ name: 'Category',    params: { title: item.title.en, id: item.slug },}">
                        <h6 class="text-white m-auto">{{ item.title.en }}</h6>
                      </router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content pt-5">
        <div class="container">
          <div class="col-lg-12 d-flex filters justify-content-between mb-4 py-2 px-2 ">
            <div class="d-flex">
              <select v-model="fcategory" class="form-control select filter-selector mx-2"
                      @change="addFilter(fcategory, 'category')">
                <option value="">Category &#11167;</option>
                <option v-for="item in sections" :key="item.id" :value="item.id">{{ item.title.en }}</option>
              </select>
              <select v-model="fsection" class="form-control filter-selector select mx-2"
                      @change="addFilter(fsection, 'section')">
                <option value="">Subcategory &#11167;</option>
                <option
                    v-for="item in subsections"
                    :key="item.id"
                    :value="item.id"
                >{{ item.title.en }}
                </option
                >
              </select>
              <select
                  v-model="frate"
                  class="form-control filter-selector select mx-2"
                  @change="addFilter(frate, 'rating')"
              >
                <option value="">Rating &#11167;</option>
                <option style="color: gold !important;" v-for="item in 5" :key="item" :value="item"
                >
                  <div>{{ '&#9733;'.repeat(item) }}</div>
                </option
                >
              </select>
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <div
                    style="margin: auto; cursor: pointer;"
                    class="d-flex align-items-center mb-2"
                >
                  <p class="m-2">Price:</p>
                  <img
                      src="../../../assets/img/signalg.svg"
                      width="20"
                      v-if="pricedir == ''"
                      @click="addFilter('asc', 'price')"
                  />
                  <img
                      src="../../../assets/img/signalb2.svg"
                      width="20"
                      v-if="pricedir == 'desc'"
                      @click="addFilter('asc', 'price')"
                  />
                  <img
                      src="../../../assets/img/signalb.svg"
                      width="20"
                      v-if="pricedir == 'asc'"
                      @click="addFilter('desc', 'price')"
                  />
                </div>
                <div
                    style="margin: auto;"
                    class="d-flex projects-action  text-center mb-0"
                >
                  <input
                      hidden
                      @change="addFilter(fonline, 'online')"
                      v-model="fonline"
                      type="checkbox"
                      id="checkbox"
                      class="check"
                  />
                  <label for="checkbox" class="checktoggle"></label>
                  <p :style="fonline != '' ? 'color:#6bba62 ;font-weight:900':''" class="ms-1">Online</p>
                </div>
              </div>
              <a
                  class="my-2 mx-4 orange-text"
                  style="cursor: pointer;"
                  @click.prevent="clearFilter()"
              >Clear All</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div v-if="services.length>0" class="row blog-grid-row">
                <div
                    v-if="!loading"
                    v-for="item in services"
                    :key="item.id"
                    class="col-md-3 col-sm-12"
                >
                  <div class="s-grid-card grid-card">
                    <div class="card-image">
                      <router-link
                          :params="`${item.seo_title}`"
                          :to="`/marketplace/${item.slug}`"
                      ><img
                          :src="item.photo_url"
                          alt="Post Image"
                          class="img-fluid"
                          height="240px"
                      />
                      </router-link>
                      <button
                          class="favourite "
                          style="top: 7.5px;
                         "
                          @click="addFavorite(item.id)"
                      >
                        <i
                            :class="
                            item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                          "
                            :style="item.favorited ? 'color:#dc5454' : ''"
                        ></i>
                      </button>
                    </div>
                    <div class="card-content">
                      <ul class="entry-meta meta-item">
                        <li>
                          <div class="post-author">
                            <router-link
                                :params="`${item.author.name}`"
                                :to="`/profile/${item.author.slug}`"
                            ><img
                                :src="item.author.avatar"
                                alt="Post Author"
                            />
                              <span> {{ item.author.name }}</span>
                            </router-link>
                          </div>
                        </li>
                      </ul>
                      <h3
                          style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;"
                      >
                        <router-link
                            :params="`${item.seo_title}`"
                            :to="`/marketplace/${item.slug}`"
                        >{{ item.title.en }}
                        </router-link>
                      </h3>
                      <div
                          class="d-flex justify-content-between align-items-center mb-3  mt-3"
                      >
                        <h4 class="m-card-title orange-text">
                          ${{ parseFloat(item.price)?.toFixed(2) }}
                        </h4>
                        <div class="fa-grid-card">
                          <i class="far fa-star"></i>
                          {{
                            item.reviews_avg == null
                                ? 0
                                : item.reviews_avg.substr(0, 3)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <services-card-skeleton
                    v-if="loading || loading2"
                    v-for="index in 10"
                    :key="index"
                ></services-card-skeleton>
              </div>
              <div v-else style="height: 550px" class="col-lg-12 row col-md-12">
                <services-card-skeleton
                    v-if="loading || loading2"
                    v-for="index in 10"
                    :key="index"
                ></services-card-skeleton>
                <div v-else class="h-100 w-100 justify-content-center center-text">
                  <h4  class="m-auto justify-content-center">Empty</h4>
                </div>
              </div>
              <div style="width: 121px" class="pb-4 m-auto">
                <button v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                        @click="nextPage(pagination.next_page_url)">
                  Load more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {

    this.getData();
  },
  methods: {
    clearFilter() {
      this.frate = "";
      this.fcategory = "";
      this.fsection = "";
      this.pricedir = "";
      this.fonline = "";
      this.getData();
    },
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.services).map((value, key) => {
            if (this.services[value].id == id) {
              this.services[value].favorited = !this.services[value].favorited;
            }
          });
        } else {
        }
      });
    },
    addFilter(f, type) {
      if (type == "category") {
        this.fcategory2 = `cat[parent][id]=${f}`;
        this.fsection2 = "";
        this.subsections = this.sections.filter((s) => s.id == f)[0].childs;
      }
      if (type == "price") {
        this.pricedir = f;
        this.pricedir2 = `f_params[orderBy][field]=price&f_params[orderBy][type]=${f}`;
      }
      if (type == "sales") {
        this.salesdir2 = `f_params[orderBy][field]=sales&f_params[orderBy][type]=${f}`;
      }
      if (type == "sortby") {
        this.fby2 = `sortBy=${f}`;
      }
      if (type == "direction") {
        this.fdir2 = `direction=${f}`;
      }
      if (type == "tags") {
        this.ftags2 = `tags['title'][]=${f}`;
      }
      if (type == "online") {
        this.fonline2 = f == true ? `is_online=${f == true ? 1 : 0}` : "";
      }
      if (type == "offline") {
        this.foffline2 = f == true ? `is_online=${f == true ? 0 : 1}` : "";
      }
      if (type == "section") {
        this.fsection2 = `section_id=${f}`;
      }
      if (type == "rating") {
        this.frate2 = `avgRating=${f}`;
      }
      if (type == "days") {
        this.fdays2 = `days_count=${f}`;
      }
      if (type == "daysfrom") {
        this.fdaysfrom2 = `days_count[start]=${f}`;
      }
      if (type == "daysto") {
        this.fdaysto2 = `days_count[end]=${f}`;
      }
      let basefil = `${this.pricedir2}&${this.salesdir2}&${this.ftags2}&${this.foffline2}&${this.fcategory2}&${this.fsection2}&${this.frate2}&${this.fdays2}&${this.fdaysfrom2}&${this.fdaysto2}&${this.fonline2}`;
      let fil = [];
      basefil.split("&").forEach((bf) => {
        if (bf.trim() != "" && bf.trim() != 0) {
          fil.push(bf);
        }
      });
      let fl = "";
      fil.forEach(function (f, idx, array) {
        if (idx !== array.length) {
          fl += `${f}&`;
        } else {
          fl += `${f}`;
        }
      });
      this.filter = fl;
      this.loading = true;
      this.api.get(`marketplace?${this.filter}`).then((res) => {
        if (res.status == "success") {
          this.category = res.data.data.sections;
          this.services = res.data.data.services.data;
          this.pagination = res.data.data.services.pagination;
          this.loading = false;
        } else {
          this.toast(res.data, "danger");
          this.loading = false;
        }
        this.loading = false;
      });
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading2 = true;
        this.page++;
        this.api.getCustom(url).then((res) => {
          if (res.status == "success") {
            this.services = this.services.concat(res.data.data.services.data);
            this.pagination = res.data.data.services.pagination;

            this.loading2 = false;
          } else {
            this.toast(res.data, "danger");
          }
        });
      }
    },
    getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true;
      }
      this.api.get(`marketplace`).then((res) => {
        if (res.status == "success") {
          this.services = res.data.data.services.data;
          let list = [];
          Object.keys(this.services).forEach((e) => {
            list.push(this.services[e].seo_title);
          });
          this.lis = list;

          this.pagination = res.data.data.services.pagination;
          this.loading = false;
        } else {
          this.toast(res.data, "danger");
        }
      });
    },
  },
  inject: ["api"],
  computed: {
    sections() {
      return this.$store.state.generalsetting.sections;
    },
  },
  data() {
    return {
      context: "",
      lis: [],
      loading: true,
      loading2: false,
      page: 1,
      services: [],
      subsections: [],
      category: "",
      pagination: {},
      filter: "?",
      fcategory: "",
      fcategory2: "",
      fby: "",
      fby2: "",
      frate: "",
      frate2: 0,
      fdir: "",
      fdir2: "",
      ftags: "",
      ftags2: "",
      fonline: "",
      fonline2: "",
      foffline: "",
      foffline2: "",
      fsection: "",
      fsection2: "",
      fdays: "",
      fdays2: "",
      fdaysfrom: "",
      fdaysfrom2: "",
      fdaysto: "",
      fdaysto2: "",
      pricedir: "",
      pricedir2: "",
      salesdir: "",
      salesdir2: "",
      title: "marketplace",
    };
  },
};
</script>













