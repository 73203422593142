<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">

      <div class="">
        <div class="">
          <div class="">
            <!-- Verification Details -->
            <div class="col-xl-12 col-md-8">
              <div class="card col-lg-12">
                <div class="row">
                  <div class="payment-list wallet card-body justify-content-around">
                    <div class="col-lg-6">
                      <div class="payment-list wallet card-body wallet-list">
                        <div>
                          <h3>Amount</h3>
                          <div :class="selectedAmount == 1 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                            @click.prevent="selectAmount(1, 50)">
                            <label class="radio-inline custom_radio me-4 mb-0">
                              <input type="radio" value="1" v-model="selectedAmount">
                              <span class="checkmark" style="border: 1px solid #99999963;"></span>
                              <h5>$50</h5>
                            </label>
                          </div>
                          <div :class="selectedAmount == 2 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                            @click.prevent="selectAmount(2, 100)">
                            <label class="radio-inline custom_radio me-4 mb-0">
                              <input type="radio" value="2" v-model="selectedAmount">
                              <span class="checkmark" style="border: 1px solid #99999963;"></span>
                              <h5>$100</h5>
                            </label>
                          </div>
                          <div :class="selectedAmount == 3 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                            @click.prevent="selectAmount(3, 200)">
                            <label class="radio-inline custom_radio me-4 mb-0">
                              <input type="radio" value="3" v-model="selectedAmount">
                              <span class="checkmark" style="border: 1px solid #99999963;"></span>
                              <h5>$200</h5>
                            </label>
                          </div>
                          <div :class="selectedAmount == 4 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                            @click.prevent="selectAmount(4, 500)">
                            <label class="radio-inline custom_radio me-4 mb-0">
                              <input type="radio" value="4" v-model="selectedAmount">
                              <span class="checkmark" style="border: 1px solid #99999963;"></span>
                              <h5>$500</h5>
                            </label>
                          </div>
                          <div :class="selectedAmount == 5 ? 'selectedCard fixedCard p-3' : 'fixedCard p-3'"
                            @click.prevent="selectAmount(5, 0)">
                            <h5 class="mb-1">Custom</h5>
                            <input v-if="selectedAmount == 5" type="text" class="form-control m-auto" v-model="amount">
                          </div>
                        </div>
                        <div>
                          <h3 class="mb-3">Payment Methods</h3>
                          <div class="d-flex flex-column">
                            <div class="">
                              <div
                                class="checkboxx border rounded-2 d-flex justify-content-between align-items-center p-2 m-2">
                                <div class="mt-2">
                                  <label class="radio-inline custom_radio me-4">
                                    <input type="radio" name="optradio" value="paypal" v-model="paymentmethod">
                                    <span class="checkmark" style="border: 1px solid #99999963;"></span> Paypal
                                  </label>
                                </div>
                                <img class="checkimg" src="../../../assets/img/paypal.png">
                              </div>
                              <div
                                class="checkboxx border rounded-2 d-flex justify-content-between align-items-center p-2 m-2">
                                <div class="mt-2">
                                  <label class="radio-inline custom_radio me-4">
                                    <input type="radio" name="optradio" value="card" v-model="paymentmethod">
                                    <span class="checkmark" style="border: 1px solid #99999963;"></span> Card
                                  </label>
                                </div>
                                <img class="checkimg" src="../../../assets/img/cards.png">
                              </div>
                            </div>
                            <div class="">
                              <div
                                class="checkboxx border rounded-2 d-flex justify-content-between align-items-center p-2 m-2">
                                <div class="mt-2">
                                  <label class="radio-inline custom_radio me-4">
                                    <input type="radio" name="optradio" value="cryptocurrency" v-model="paymentmethod">
                                    <span class="checkmark" style="border: 1px solid #99999963;"></span> Cryptocurrency
                                  </label>
                                </div>
                                <img class="checkimg" src="../../../assets/img/cryptocurrency.png">
                              </div>
                              <div
                                class="checkboxx border rounded-2 d-flex justify-content-between align-items-center p-2 m-2">
                                <div class="mt-2">
                                  <label class="radio-inline custom_radio me-4">
                                    <input type="radio" name="optradio" value="gooreo" v-model="paymentmethod">
                                    <span class="checkmark" style="border: 1px solid #99999963;"></span> Gooreo Coin
                                  </label>
                                </div>
                                <img class="checkimg" src="../../../assets/img/gooreocoin.png">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5" v-if="paymentmethod == 'paypal'">
                          <div class="col-md-12 m-auto">
                            <PaypalBtnVue :price="subtotal" :paypal_client_id="paypal_client_id" />
                          </div>

                        </div>
                        <div class="row" v-if="paymentmethod == 'card'">
                          <div class="col-md-12">
                            <Stripe :price="subtotal" :publishableKey="publishableKey" />
                          </div>

                        </div>
                        <div class="row" v-if="paymentmethod == 'cryptocurrency'">
                          <div class="col-lg-12 btn-pad" @click="paycryptocurrency()">
                            <a href="javascript:void(0);" class="click-btn btn-primary">Confirm</a>
                          </div>
                          <div class="col-md-12">
                            <coinpayment v-if="payload" _checkouturl="https://gooreo.com/user/addbalance"
                              _host="https://api.gooreo.com" :_payload="payload" :price="subtotal" />
                          </div>

                        </div>
                        <div class="row" v-if="paymentmethod == 'gooreo'">
                          <div class="col-sm-9 ms-2 p-3 border shadow">
                            <div v-if="address != ''">
                              <p class="rounded-2 alert-danger p-2">You take full responsiblty on transaction
                                mistakes</p>
                              <p class="rounded-2 alert-warning p-2">Please note that this addres is <span
                                  style="color: #009df8; font-weight: bold;">bep-20</span> address</p>
                              <p>* Note that the transaction can take up to 3 minutes to complete</p>
                              <h6>Your Gooreo Address:</h6>
                              <div class="d-flex">
                                <p> {{ address }}</p>
                                &nbsp;
                                &nbsp;
                                <div style="cursor:pointer;" @click="copy()">
                                  <i class="fa fa-copy"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 " >
                      <div class="summry shadow">
                      <div>
                        <h3 class="card-header p-3 shadow">Summry</h3>
                        <div class="d-flex justify-content-between align-content-center">
                          <p>Amount to be added to your account : </p>
                          <h3>${{ amount }}</h3>
                        </div>
                        <div class="d-flex justify-content-between align-content-center">
                          <p>Fees : </p>
                          <h3>${{ fee }}</h3>
                        </div>
                        <div class="d-flex justify-content-between align-content-center">
                          <p>Subtotal : </p>
                          <h3>${{ subtotal }}</h3>
                        </div>
                      </div>
                    </div>
                      <div class="p-3 mt-3 rounded alert-info info">
                        <!-- <ul class="p-3"> -->
                          <span>Gooreo charges buyers a fee per transaction </span>
                          <ul class="ps-4">
                            <li>5.5% for Paypal and credit card. </li>
                            <li>1% for cryptocurrency payment.</li>
                            <li>0% for Gooreo coin payment.</li>
                          </ul>
                          <span>There is an additional $2 charge for transactions under $50 for Paypal and credit card payments.</span>
                          <!-- <li>Gooreo charges buyers a 5.5% service fee per order.</li>
                          <li>This fee is 5.5% of the order price if it's over $50, and there is an additional $2 'small order' charge for orders under $50.</li>
                          <li> Gooreo always charges these fees no matter what you're buying or who you're buying from.</li>
                          <li>If you're a seller, Gooreo takes 20% of the order fee.</li> -->
                        <!-- </ul> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Verification Details -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<style scoped>
.summry {
  background-color: rgba(150, 150, 150, 0.08);
  padding: 20px;
}

.checkboxx {
  padding: 10px;
  width: 75%;
  height: 50px;
  margin: 10px 0;
}

.checkboxx img {
  /* width: 50px; */
  height: 35px;
}

.fixedCard {
  border: 1px solid #99999963;
  border-radius: 10px;
  width: 75%;
  margin: 20px 0;
  box-shadow: 3px 3px 10px #98989944;
}

.fixedCard h5 {
  margin: auto;
}

.selectedCard {
  border: 0px solid #009df870;
  border-radius: 10px;
  width: 75%;
  background-color: #009df8;
}

.selectedCard h5 {
  margin: auto;
  color: white;
}
</style>
<script>
import PaypalBtnVue from './PaypalBtn.vue';
import Stripe from './Stripe';
import coinpayment from './CoinPayment';

export default {
  components: {
    PaypalBtnVue,
    Stripe,
    coinpayment

  },
  async mounted() {
    await this.getSettings()

  },
  methods: {
    submit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.loading = true;
      let vm = this;
      axios.post(this.route('stripe_charge'), {
        token: token,
        stripeToken: token.id,
        type: this.typeCharge,
        _token: this.$page.props.csrf_token,
      }, {
          headers:{
          Authorization: this.$route.query.token
        },
        })
        .then(response => {
          if (response.data.success) {
            if (vm.redirect) {
              vm.$inertia.get(vm.route(vm.redirect));
            } else {
              $('.stripe-credit-card-form-wrap').html(response.data.message_html);
            }
          } else {
            $('.stripe-credit-card-form-wrap').html(response.data.message_html);
          }
        })
        .catch(error => {
          $('.stripe-credit-card-form-wrap').html(error.message);

        }).finally(() => {
          this.loading = false;
        });
    },
    copy() {
      navigator.clipboard.writeText(this.address).then(res => {
        this.toast('copied')
      });
    },
    async paycryptocurrency() {
      if (this.paymentmethod == 'cryptocurrency') {
        this.api.post('coinpayments', {
          'total': this.amountCoinpayment,
          'oldtotal': this.amount,
          'return_url': 'https://gooreo.com/user/balance',
          'cancel_url': 'https://gooreo.com/payment/canceled',
        },
        {
          headers:{
          Authorization: this.$route.query.token
        },
        }).then(res => {
          if (res.status == 'success') {
            this.payload = res.data.data;
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    getGooreoAddress() {
      this.api.getAddress('wallets/getGooreoAddress', {
          headers:{
          Authorization: this.$route.query.token
        },
        }).then(res => {
        if (res.status == 'success') {
          this.address = res.data.address
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    selectAmount(amount, price) {
      this.selectedAmount = amount;
      this.amount = price;
    },
    async getSettings() {
      this.api.get('generalSetting', {
          headers:{
          Authorization: this.$route.query.token
        },
        }).then(res => {
        if (res.status == 'success') {
          this.publishableKey = res.data.data.setting.stripe_publishable_key
          this.paypal_client_id = res.data.data.setting.paypal_client_id


          this.paypal_fee = res.data.data.setting.paypal_fee
          this.paypal_less_than = res.data.data.setting.paypal_less_than
          this.paypal_increase_amount = res.data.data.setting.paypal_increase_amount

          this.stripe_fee = res.data.data.setting.stripe_fee
          this.stripe_less_than = res.data.data.setting.stripe_less_than
          this.stripe_increase_amount = res.data.data.setting.stripe_increase_amount

          this.coinpayment_fee = res.data.data.setting.coinpayment_fee
          this.coinpayment_less_than = res.data.data.setting.coinpayment_less_than
          this.coinpayment_increase_amount = res.data.data.setting.coinpayment_increase_amount
          this.selectAmount(1, 50)

          this.getGooreoAddress()

          this.loading = false
        }
        else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      selectedAmount: 1,
      paymentmethod: 'paypal',
      payload: "",
      address: "",
      amount: 0,
      publishableKey: '',
      paypal_client_id: '',
      token: null,
      paypal_fee: 0,
      paypal_less_than: 0,
      paypal_increase_amount: 0,
      stripe_fee: 0,
      stripe_less_than: 0,
      stripe_increase_amount: 0,
      coinpayment_fee: 0,
      coinpayment_less_than: 0,
      coinpayment_increase_amount: 0,
      fee: 0,
    }
  },
  computed: {
    subtotal() {
      const v = Number(this.amount) + Number(this.fee)
      return v.toFixed(v == 0 ? 0 : 2)
    }
  },
  watch: {
    amount(newVal, oldVal){
      if (this.paymentmethod == 'paypal') {
        this.fee = ((Number(this.paypal_fee) * newVal) / 100)
        if (parseInt(this.paypal_less_than) > 0 && parseInt(this.paypal_increase_amount) > 0 && parseInt(this.paypal_less_than) > parseInt(newVal)) {
          this.fee = this.fee + parseInt(this.paypal_increase_amount);
        }
      }
      if (this.paymentmethod == 'card') {
        this.fee = (Number(this.stripe_fee) * newVal) / 100
        if (parseInt(this.stripe_less_than) > 0 && parseInt(this.stripe_increase_amount) > 0 && parseInt(this.stripe_less_than) > parseInt(newVal)) {
          this.fee = this.fee + parseInt(this.stripe_increase_amount);
        }
      }
      if (this.paymentmethod == 'cryptocurrency') {
        this.fee = (Number(this.coinpayment_fee) * newVal) / 100
        if (parseInt(this.coinpayment_less_than) > 0 && parseInt(this.coinpayment_increase_amount) > 0 && parseInt(this.coinpayment_less_than) > newVal) {
          this.fee = this.fee + parseInt(this.coinpayment_increase_amount);
        }
      }
      if (this.paymentmethod == 'gooreo') {
        this.fee = 0
      }
      if(newVal == 0){
        this.fee = 0
      }
      if(newVal == null || newVal == ''){
        this.amount = 0
      }
    },
    paymentmethod(newVal, oldVal){
      if (newVal == 'paypal') {
        this.fee = ((Number(this.paypal_fee) * this.amount) / 100)
        if (parseInt(this.paypal_less_than) > 0 && parseInt(this.paypal_increase_amount) > 0 && parseInt(this.paypal_less_than) > parseInt(this.amount)) {
          this.fee = this.fee + parseInt(this.paypal_increase_amount);
        }
      }
      if (newVal == 'card') {
        this.fee = (Number(this.stripe_fee) * this.amount) / 100
        if (parseInt(this.stripe_less_than) > 0 && parseInt(this.stripe_increase_amount) > 0 && parseInt(this.stripe_less_than) > parseInt(this.amount)) {
          this.fee = this.fee + parseInt(this.stripe_increase_amount);
        }
      }
      if (newVal == 'cryptocurrency') {
        this.fee = (Number(this.coinpayment_fee) * this.amount) / 100
        if (parseInt(this.coinpayment_less_than) > 0 && parseInt(this.coinpayment_increase_amount) > 0 && parseInt(this.coinpayment_less_than) > this.amount) {
          this.fee = this.fee + parseInt(this.coinpayment_increase_amount);
        }
      }
      if (newVal == 'gooreo') {
        this.fee = 0
      }
      if(this.amount == 0){
        this.fee = 0
      }
      if(amount == null || amount == ''){
        this.amount = 0
      }
    },
  },
}
</script>