<template>
  <div>
    <layout-header/>
    <div class="bread-crumb-bar">
      <div class="container">
        <div class="row align-items-center inner-banner">
          <div class="col-md-12 col-12 text-center">
            <div class="breadcrumb-list">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                    </router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item">
                    staking
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content-page">
      <div class="container-fluid">
        <div class="row">
          <!-- sidebar -->
          <layout-employeesidebar></layout-employeesidebar>
          <!-- /sidebar -->
          <div v-if="verification === true" class="col-xl-9 col-md-8 row">
            <div class="col-xl-7 col-md-12">
              <tab style="width: 100%; height: 40px;cursor: pointer" v-model="tab" :tabs="tabs" @clicked="getTab"></tab>
              <div v-if="tab===0" class=" col-lg-12">
                <div class="row pt-5 container">
                  <div class="card m-auto p-3">
                    <div class="   p-4">
                      <h3>Staking Deposed</h3>
                      <div class="pro-content">
                        <div class="form-group profile-group mb-0">
                          <div class="input-group" style="background-color: #e9ecef;">
                            <input
                                id="link"
                                :value="gooreoAddress"
                                class="form-control"
                                readonly
                                type="text"/>
                            <div class=" popup input-group-append">
                              <span class="popuptext" id="Copy">Copied</span>
                              <button class="btn btn-success sub-btn" type="submit">
                                <i class="fa fa-clone" @click.prevent="copyLink"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width:45%; margin:auto;">
                        <button style="width:100%; height: 50px" class=" btn  btn-primary  mt-3 click-btn"
                                @click="getGooreoAddress()">
                          <loading-btn v-show="pending ===true"/>
                          Create address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tab===2" class=" col-lg-12">
                <div class="row pt-5 container">
                  <div class="card m-auto p-3">
                    <div class="   p-4">
                      <h3>withdraw Profits</h3>
                      <p>We support BUSD(BEP-20) withdraw payments, please make sure to put your right wallet
                        address.</p>
                      <div class="filter-widget">
                        <h5 class="mt-2">Blockchain</h5>
                        <div class="form-group">
                          <select v-model="networkName" class="form-control select">
                            <option v-for="item in networks" :key=" item.id" :value="item">
                              {{ item }}
                            </option>
                          </select>
                          <h5 class="mt-3">Coin</h5>
                          <select v-model="gateway" class="form-control select">
                            <option v-for="item in gateways.filter((e)=> e.network==networkName)" :key=" item.id"
                                    :value="item">
                              {{ item.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <h5>Your BEP-20 Address</h5>
                      <input v-model="wallet_address" class="form-control mb-3  " min="42" placeholder="0x....."
                             type="text">
                      <h5>Staking to be withdraw</h5>
                      <input v-model="amount" class="form-control mb-5  "
                             placeholder="Staking to be withdrawn"
                             type="number">
                      <div style="width:50%; margin:auto;">
                        <button class=" btn  btn-primary  mt-3 click-btn" @click="stakingWithdraw()">
                          <loading-btn v-show="pending ==true"/>
                          withdraw
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tab==1" class=" col-lg-12">
                <div class="row pt-5 container">
                  <div class="card m-auto p-3">
                    <div class="   p-4">
                      <h3>Withdraw Gooreo</h3>
                      <div class="p-4">
                        <h5>Your BEP-20 Address</h5>
                        <input v-model="wallet_address" class="form-control mb-3  " min="42" placeholder="0x....."
                               type="text">
                        <h5>Staking to be withdraw</h5>
                        <input v-model="amount" class="form-control mb-5  "
                               placeholder="Staking to be withdrawn"
                               type="number">
                        <div style="width:50%; margin:auto;">
                          <button class=" btn  btn-primary  mt-3 click-btn" @click="gooreoWithdraw()">
                            <loading-btn v-show="pending ==true"/>
                            withdraw
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-5 mt-5 col-md-12">
              <div class="summary shadow">
                <div>
                  <h3 style="border-bottom: 1px solid rgba(0,0,0,.125);" class=" p-3 ">Summary</h3>
                  <div class="d-flex none-margin justify-content-between mt-4 align-content-center">
                    <p>Staked Gooreo Coins: </p>
                    <h5>{{ available }} Gooreo </h5>
                  </div>
                  <!--                  <div class="d-flex none-margin justify-content-between mt-4 align-content-center">-->
                  <!--                    <p>Gooreo Coins to be withdrawn : </p>-->
                  <!--                    <h4>{{ waiting_confirmed }} Gooreo </h4>-->
                  <!--                  </div>-->
                  <!--                  <div class="d-flex none-margin justify-content-between mt-4 align-content-center">-->
                  <!--                    <p>your staking percentage: </p>-->
                  <!--                    <h5>{{ profits_percent * 100 }}% </h5>-->
                  <!--                  </div>-->
                  <div class="d-flex none-margin justify-content-between mt-4 align-content-center">
                    <p>Your Profit: </p>
                    <h5>{{ profits }} Gooreo </h5>
                  </div>
                </div>
              </div>
              <div class=" card  mt-5">
                <table class=" table">
                  <tr>
                    <td><span style="color: #009df8;"><strong>Year</strong></span></td>
                    <td><span style="color: #009df8;"><strong>% ‘ of platform profit’</strong></span></td>
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>2024</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                    <td>2025</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>2026</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>2027+</td>
                    <td>5%, perpetuity</td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
          <div v-else class="col-xl-9 px-5 col-md-8 row">
            <div class="card px-5">
              <div style="text-align:center; background-color:#e7eaec; border-radius: 5px; "
                   class="p-5 mt-5">
                <h4>Your account needs to be
                  <router-link class="cta" to="/user/verify-identity"><span>Verified</span>
                    <svg viewBox="0 0 13 10" height="10px" width="15px">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </router-link>
                </h4>
              </div>
              <div class="mt-5">
                <h4>Instruction</h4>
                <ul>
                  <li>
                    Here you can deposit Gooreo coin and get profit from Gooreo platform revenue
                  </li>
                  <li>
                    Profits are distributed every three months
                  </li>
                  <li>
                    Percentage is calculated on daily basis of the total of staked Gooreo coins
                  </li>
                </ul>
                <div class="col-5 ms-3 card  mt-5">
                  <table class=" table">
                    <tr>
                      <td><span style="color: #009df8;"><strong>Year</strong></span></td>
                      <td><span style="color: #009df8;"><strong>% ‘ of platform profit’</strong></span></td>
                    </tr>
                    <tr>
                      <td>2023</td>
                      <td>50%</td>
                    </tr>
                    <tr>
                      <td>2024</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>2025</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td>2026</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>2027+</td>
                      <td>5%, perpetuity</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <layout-footer/>
  </div>
</template>

<script>


export default {
  inject: ["api"],
  name: "staking",

  created() {
    this.api.get('user/identity').then(res => {
      if (res.status == 'success') {
        this.verification = res.data.data.identity
      } else {
        this.toast(res.data, 'danger')
      }
    })
    this.generalSetting()
    this.getBankStaking()
    this.GetCurrency()
  },
  methods: {
    copyLink: function (e) {
      this.code = e.target.value;
      this.cStype = "text";
      let copyText = document.querySelector("#link");
      copyText.select();
      document.execCommand("copy");
      var popup = document.getElementById("Copy");
      popup.classList.toggle("show");
      setTimeout(() => {
        popup.classList.toggle("show");
      }, 2000)
    },
    async generalSetting() {
      await this.api.get("generalSetting").then((res) => {
        if (res.status === "success") {
          this.staking_type = res.data.data.setting.staking_type;
        } else {
          this.toast(res.data, "danger");
        }
      });
    },
    getGooreoAddress() {
      if (this.gooreoAddress == "") {
        this.pending = true
        this.api.getAddress("wallets/getGooreoAddressStaking").then((res) => {
          this.gooreoAddress = res.data.address;
          this.pending = false
        });

      }

    },
    getBankStaking() {
      this.api.get("user/balanceStaking").then((res) => {
        if (res.status === "success") {
          this.bankStaking = res.data;
          this.available = res.data.data.user.balance_available_staking;
          this.profits = res.data.data.user.balance_earn_staking;
          this.waiting_confirmed = res.data.data.user.balance_payout_staking;
          this.profits_percent = res.data.data.user.balance_accumulated;
        } else {
          this.toast(res.data, "danger");
        }
      });
    }, getTab(val) {
      this.tab = val
    },
    stakingWithdraw() {
      if (this.amount !== 0 || this.wallet_address !== '') {
        this.pending = true
        this.api.post('user/withdrawal/request', {
              payout_method: 'staking',
              amount: this.wallet_address,
              type: 'staking',
              address: this.address,
            }
        ).then(res => {
          if (res.status == 'success') {
            this.pending = false
            this.toast("The request has been sent successfully ", 'success')
          } else {
            for (this.item in res.data) {
              this.toast(res.data[this.item][0], 'danger')
            }
          }
        })
      } else {
        this.toast("Please fill in all fields", 'danger')
      }
    },
    gooreoWithdraw() {
      if (this.amount !== 0 || this.wallet_address !== '') {
        this.pending = true
        this.api.post('user/withdrawal/request', {
          payout_method: 'Gooreo',
          amount: this.amount,
          type: 'stakingGooreo',
          address: this.wallet_address,
        }).then(res => {
          if (res.status == 'success') {
            this.toast("The request has been sent successfully ", 'success')
          } else {
            for (this.item in res.data) {
              this.toast(res.data[this.item][0], 'danger')
            }
          }
        })
        this.pending = false
      } else {
        this.toast("Please fill in all fields", 'danger')
      }
    },
    GetCurrency() {
      this.api.get('generalSetting').then(res => {
        if (res.status === 'success') {
          this.smartchain = res.data.data.currencyGateway
          this.networkName = this.smartchain[0]
          for (const i in this.smartchain) {
            this.networks.push(i)
            for (const y in this.smartchain[i]) {
              this.gateways.push(this.smartchain[i][y])
            }
          }
        }
      })
    }
  },
  data() {
    return {
      verification: JSON.parse(this.decrypted($cookies.get("user"))).verified_id,
      gooreoAddress: "",
      bankStaking: "",
      available: "",
      profits: "",
      waiting_confirmed: "",
      profits_percent: "",
      staking_type: "",
      loading: true,
      pending: false,
      amount: 0,
      wallet_address: '',
      SmartChain: '',
      smartchain: '',
      gateway: {},
      networkName: '',
      gateways: [],
      networks: [],
      tab: 0,
      tabs: [
        'Deposit',
        'Withdraw Gooreo',
        'Withdraw Profits',
      ],
    }
  }
}
</script>
<style scoped>
.none-margin h3, p {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 479px) {
  .tabbar {
    height: 55px !important;
  }

  button {
    font-size: 10px;
    height: 30px !important;
  }
}
</style>