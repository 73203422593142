<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">My Services</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row" v-if="isFreelancer">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>Manage Sales </h3>
              </div>
              <sales-upBar :arr="this.arr"/>
              <!-- Proposals list -->
              <div class="card-body">
                <div v-if="sales.length==0" class="m-5">
                  <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
                </div>
                <div v-else class="table-responsive table-box">
                  <table id="milestones" class="table table-center table-hover datatable">
                    <thead class="thead-pink">
                    <tr>
                      <th>TX ID</th>
                      <th> Customer</th>
                      <th>Order Date</th>
                      <th> End Date</th>
                      <th> Total Price</th>
                      <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in sales" :key="item.id">

                      <td> {{ item.transaction_id }}</td>
                      <td>{{ item.customer?.name? item.customer.name:'(Deleted user)'  }}</td>
                      <td>
                        {{ item.post.created_at_format.date }}
                      </td>
                      <td>
                        {{ item.end_date }}
                      </td>
                      <td> $ {{ item.subtotal }}</td>
                      <td class=" ">
                        <router-link :to="`/user/sales/${item.id}`" class="projects-btn"> Messing Details</router-link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /Proposals list -->
            </div>
          </div>
          <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
            <please-switch></please-switch>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    changePage(page) {
      this.page = page
      this.getPageData()
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true
        this.page++
        this.api.getCustom(url).then(res => {
          if (res.status == 'success') {
            this.sales = res.data.data.orders.data
            this.arr = res.data.data.arr
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    prevPage(url) {
      if (this.page >= 2) {
        this.loading = true
        this.page--
        this.api.getCustom(url).then(res => {
          if (res.status == 'success') {
            this.sales = res.data.data.orders.data
            this.arr = res.data.data.arr
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    async getPageData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get(`user/sales?page=${this.page}&status=initial`).then(res => {
        if (res.status == 'success') {
          this.sales = res.data.data.orders.data
          this.arr = res.data.data.arr
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true
      }
      this.api.get('user/sales?status=initial').then(res => {
        if (res.status == 'success') {
          this.sales = res.data.data.orders.data
          this.arr = res.data.data.arr
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  data() {
    return {

      loading: true,
      activeClass: 'active',
      sales: [],
      page: 1,
    }
  },
  Updated() {
    this.loading = false
  },
  async created() {
    await this.getData()
  },
}
</script>