<script>
import {VAceEditor} from 'vue3-ace-editor';
export default {
  props: {
    sections: [Array, Object]
  },
  components: {
    VAceEditor
  },
  inject: ['api'],
  data() {
    return {
      maincategorey: null,
      subcategoryId: null,
      services: [],
      servicesApi: [],
      Allsections: this.sections,
      code: '',
      id: JSON.parse(this.decrypted($cookies.get("user"))).id,
    }
  },
  computed: {
    subcategory() {
      return this.sections.find(e => {
        return e.id == this.maincategorey
      })?.childs ?? [];
    },
    servicesCat() {
      if (this.servicesApi.length > 0 && this.subcategoryId) {
        return this.servicesApi.map(e => {
          e.text = e.seo_title;
          return e;
        });
      }
      return [];
    },
    servicesSelected() {
      if (this.servicesApi.length > 0) {
        return this.servicesApi.filter(e => {
          return this.services.includes(e.id.toString());
        });
      }
      return [];
    },
  },
  methods: {
    async getData(id) {
      await this.api.get("user/getPostSection/" + id).then((res) => {
        this.servicesApi = res.data.data.services
      })
    },
    copyHtml() {
      this.$copyText((this.$refs.codepreview.innerHTML), this.$refs.codepreview)
    }
  },
  mounted() {

  },
  watch: {
    sections: {
      handler(newValue, oldValue) {
        this.Allsections = this.sections;
      },
      deep: true
    },
    subcategoryId: {
      async handler(newValue, oldValue) {
        await this.getData(newValue);
      },
      deep: true
    },
    servicesSelected: {
      async handler(newValue, oldValue) {
        setTimeout(() => this.code = this.$refs.codepreview.innerHTML + `<style >
@media (min-width: 600px) {
.s-grid-card{
    max-width: 400px;
}
}

a:link {
  text-decoration: none;
}
  .img-fluid {
  width: 100%;
  object-fit: cover;
  height: 140px;

  border-top-right-radius: 12.5px;
border-top-left-radius: 12.5px;
}
.s-grid-card:hover {
    box-shadow: 6px 6px 10px 0 rgb(215 215 215 / 80%);
}
.s-grid-card grid-card .card-content p {
    margin: 0 0 15px;
}
.s-grid-card {
    margin: 30px auto;
    max-width: 250px;
    border-radius: 15px;
    border: 1px solid #f0f0f0;
    background-color: #fdfdfd;
    padding-bottom: 1px;
    text-align: left;
    position: relative;
    box-shadow: 3px 3px 10px 0 rgb(215 215 215 / 80%);
}
.card-content {
    padding-left: 15px;
    text-align: left;
    position: relative;
}
.card-content p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
    font-size: 16px;
    margin: 0 0 30px;
    line-height: 32px;
}
    .cards-widget {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
    }
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin: 0 .12rem;
  text-transform: uppercase;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2),0 2px 10px 0 rgba(0,0,0,.1);
}
  .btn-primary {
     background-color: #009df8;
    border: 1px solid #009df8;
    position: absolute;
    right: 5px;
    bottom: 20px;
    color: #fff;
}
.card-image{
    max-height: 270px;
    overflow: hidden;
}
/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
    .cards-widget {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
    }
}
</style>`, 300)
      }, deep: true
    },
  }
}
</script>
<template>
  <div class="col-md-6">
    <div class="form-group">
      <label>Main Category:</label>
      <select v-model="maincategorey" class="form-select">
        <option v-for="cat in sections" :value="cat.id" :key="cat.id">{{ cat.title }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>Sub Category:</label>
      <select v-model="subcategoryId" class="form-select">
        <option v-for="cat in subcategory" :value="cat.id" :key="cat.id">{{ cat.title }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>Services:</label>
      <vue-select v-model="services" :options="servicesCat" :settings="{multiple : true}" class="form-select"
                  multiple>
      </vue-select>
    </div>
  </div>
  <div>
    preview
    <div ref="codepreview">
      <div class="cards-widget">
        <div style="width: 250px;min-width: 250px; margin: 30px auto;" class="s-grid-card grid-card"
             v-for="serv in servicesSelected"
             :key="serv.id">
          <a target="__blank" :href="`https://gooreo.com/marketplace/${serv.slug}?invasion=${this.id}`">
            <div class="card__image-container card-image">
              <img class="img-fluid" :src="serv.photo_url" alt="alt"/>
            </div>
            <div class="card__content card-content">
              <p style=" min-height: 65px;" class="card__title text--medium">
                {{ serv.seo_title }}
              </p>
              <div style="display: flex;justify-content: space-between;" class="card__info">
                <p class="card__price text--medium"> ${{ parseFloat(serv.price)?.toFixed(2) }}</p>
                <a target="__blank" align="right " class="btn btn-primary"
                   :href="`https://gooreo.com/marketplace/${serv.slug}?invasion=${this.id}`">Buy </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
   <div class="d-flex mb-3">
     <button class="btn btn-success sub-btn" type="button" v-clipboard:copy="code">
       <i class="fa fa-clone"></i>
     </button>
     <h4 style="margin: 8px 5px;">code</h4>
   </div>
    <v-ace-editor v-model:value="code" lang="html" readonly="true" theme="chrome" style="height: 300px"/>
  </div>
</template>
<style scoped>
.select2-container {
  z-index: 0
}
.card-content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  font-size: 16px;
  margin: 0 0 30px;
  line-height: 32px;
}
.btn-primary {
  background-color: #009df8;
  border: 1px solid #009df8;
  position: absolute;
  right: 5px;
  bottom: 20px;
}

.cards-widget {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;

}

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  img {
    max-width: 200px
  }


}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  img {
    max-width: 300px
  }
}
</style>



