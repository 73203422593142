<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <div class="main-wrapper">
      <layout-employeeheader/>
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Profile</li>
                    <li aria-current="page" class="breadcrumb-item">Profile Basics</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="pro-pos">
                <settingtab></settingtab>
                <div class="setting-content">
                  <div class="card">
                    <div class="pro-head">
                      <h3 class="pro-title without-border mb-0">Profile Basics </h3>
                    </div>
                    <form>
                      <div class="form-row pro-pad pt-0">
                        <div class="form-group col-md-6 pro-pic">
                          <label>Profile Picture</label>
                          <div class="d-flex align-items-center">
                            <div class="upload-images">
                              <img :src="showimage == null  ? avatar?? `icon.svg` : showimage" alt="Image"></div>
                            <a @click="getAvatar()" class="btn learn-btn ms-3" data-bs-toggle="modal"
                               href="#change-image">
                              Change Image
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="pro-body p-0">
                        <div class="form-row pro-pad">
                          <div class="form-group col-md-6">
                            <label>Name</label>
                            <input v-model="name" class="form-control" type="text">
                          </div>
                          <div class="form-group col-md-6">
                            <label>Email Address</label>
                            <input v-model="email" class="form-control" type="email">
                          </div>
                          <div class="form-group col-md-6">
                            <label>Phone Number </label>
                            <!--                            <vue-tel-input v-model="phone"></vue-tel-input>-->
                            <input v-model="phone" class="form-control" type="number">
                          </div>
                          <div class="form-group col-md-6 ">
                            <label>Country</label>
                            <country-select class="form-control" v-model="country" :country="country2"/>
                          </div>
                        </div>
                        <div class="mb-4 mx-3">
                          <div class="">
                            <button class="btn btn-primary click-btn btn-plan" type="submit"
                                    @click.prevent="update()">Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="change-image" class="modal fade custom-modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-modal">
            <div class="modal-header">
              <button aria-label="Close" class="close" data-bs-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center pt-0 mb-4">
                <h5 class="modal-title">Update your profile image</h5>
              </div>
              <div class="row">
                <div :style="showimage ==avatar? 'background-color:#009df8;border-radius: 20px;':''"
                     @click="showimage = avatar" v-for="(avatar , index) in avatarUrl" :key="index"
                     style="cursor:pointer" class="upload-images my-2 col-md-3">
                  <img style="width: 100%;" :src="avatar"/>
                </div>
                <div class="my-2 col-md-3 my-2 col-md-3  ps-5 pt-5">

                  <button @click="getAvatar()" class=" btn btn-primary ">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
              <h6 class="text-center my-3">Or</h6>
              <div class="col-md-12">
                <label style="width: 100%;" class="file-upload text-center image-upbtn  ">
                  Upload an image <input type="file" @change="loadFile">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
export default {
  methods: {

    loadFile(event) {
      this.image = event.target.files[0]
      this.showimage = URL.createObjectURL(event.target.files[0])
    },
    async getData() {
      const resp = JSON.parse(this.decrypted($cookies.get("user")))
      // try {
      const ava = $cookies.get("avatar") ? JSON.parse(this.decrypted($cookies.get("avatar"))) : null
      // }finally {
      //   const ava = null
      // }
      if (resp != null) {
        this.avatar = ava
        this.user = resp
        this.name = this.user.name
        this.email = this.user.email
        this.phone = this.user.phone
        this.country2 = this.user.country_id
        this.loading = false
      } else {
        this.api.get('user').then(res => {
          if (res.status == 'success') {
            this.user = res.data
            this.avatar = res.data.avatar
            this.name = this.user.name
            this.email = this.user.email
            this.phone = this.user.phone
            this.country = this.user.country_id
            this.loading = false
          } else {
            this.toast(res.data, 'danger')
          }
        })
      }
    },
    getAvatar() {
      this.api.getAvatar('auth/getAvatars').then(res => {
        this.avatarUrl = Object.values(res.data.data.avatars)
      })
    },
    async update() {
      let form = new FormData();
      form.append('name', this.name)
      form.append('email', this.email)
      if (this.phone != null && this.phone !== '') {
        form.append('phone', "+" + this.phone)
      }
      if (this.country != null && this.country !== '') {
        form.append('country_id', this.country)
      }
      if (this.image != null) {
        form.append('avatar', this.image)
      }
      if (this.showimage != null && this.image == null) {
        form.append('avatar_url', this.showimage)
      }
      this.api.post('auth/update', form).then(res => {
        if (res.status == 'success') {
          this.toast("Updated", 'success')
          this.api.get('user').then(res => {
            if (res.status == 'success') {
              $cookies.remove("avatar")
              $cookies.set("avatar", this.encrypted(JSON.stringify(res.data.avatar)))
              this.loading = false
              location.reload()
            } else {
              this.toast(res.data, 'danger')
            }
          })
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  mounted() {
    this.getData()
    if ($('.select').length > 0) {
      $('.select').select2({
        minimumResultsForSearch: -1,
        width: '100%'
      });
    }
  },
  data() {
    return {
      avatarUrl: '',
      country: '',
      country2: '',
      region: '',
      loading: true,
      user: {},
      name: '',
      email: '',
      phone: '',
      image: null,
      showimage: null,
      avatar: '',
    };
  },
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.btn {
  margin: 0;
}
</style>