<template>
  <layout-header/>
  <div class="bread-crumb-bar">
    <div class="container">
      <div class="row align-items-center inner-banner">
        <div class="col-md-12 col-12 text-center">
          <div class="breadcrumb-list">
            <nav aria-label="breadcrumb" class="page-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/"><img alt="Post Author" src="../../../assets/img/home-icon.svg"> Home
                  </router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item">
                  Affiliate
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content content-page">
    <div class="container-fluid">
      <tab style="margin: auto; width: 50%; height: 40px;cursor: pointer" v-model="tab" :tabs="tabs"
           @clicked="getTab"></tab>
      <div class="row">
        <layout-employeesidebar></layout-employeesidebar>
        <div v-if="tab==0" class="col-xl-9 col-md-8 row">
          <div class="col-xl-7 col-md-12">
            <div class=" col-lg-12">
              <div class="row pt-5 container">
                <div class="card m-auto p-3">
                  <div class="   p-4">
                    <MDBTooltip v-model="tooltip1">
                      <template #reference>
                        <h4> Your Affiliate link</h4>
                      </template>
                      <template #tip>
                        tip
                      </template>
                    </MDBTooltip>
                    <div class="modal-body">
                      <div class="model-content bg-white col-md-12">
                        <div class="input-group">
                          <input id="link" :value="`https://www.gooreo.com?invitation=${this.id}`" class="form-control"
                                 readonly type="text"/>
                          <div class=" popup input-group-append">
                            <span class="popuptext" id="Copy">Copied</span>
                            <button class="btn btn-success sub-btn" type="submit">
                              <i class="fa fa-clone" @click.prevent="copyLink"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-if="loading ===false" class="col-xl-5 mt-5 col-md-12">
            <div class="summary card">
              <div>
                <h3 class="card-header p-3 ">Stats</h3>
                <div class="d-flex none-margin justify-content-between mt-4 align-content-center">
                  <p>Invited Accounts </p>
                  <h4>{{ this.affiliates.affiliates_count }}$</h4>
                </div>
                <div class="d-flex none-margin justify-content-between mt-4 align-content-center">
                  <p>Balance form affiliates </p>
                  <h4>{{ this.affiliates.balance_affiliate }}$</h4>
                </div>
                <div class="d-flex none-margin justify-content-between mt-4 align-content-center">
                  <p>Affiliate Prize </p>
                  <h4>{{ userAffiliateData.affiliate_service }}$ </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tab==1" class="col-xl-9 col-md-8 row">
          <div class="col-12 card mt-5">
            <div class="   p-4">
              <h4> Create widget</h4>
              <div class="modal-body">
                <div class="model-content bg-white col-md-12">
                  <CreateWidget :sections="sections"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer/>
</template>
<script>
import CreateWidget from '../components/CreateWidget.vue'
import {MDBTooltip, MDBBtn} from "mdb-vue-ui-kit";
import {ref} from 'vue';
export default {
  name: "Affiliate",
  inject: ['api'],
  components: {
    CreateWidget,
    MDBTooltip,
    MDBBtn
  },
  setup() {
    const tooltip1 = ref(false);
    return {
      tooltip1,
    }
  },
  data() {
    return {
      id: JSON.parse(this.decrypted($cookies.get("user"))).id,
      loading: true,
      affiliates: '',
      sections: [],
      tab: 0,
      tabs: [
        'Link invitation',
        'Widgets',
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getTab(val) {
      this.tab = val
    },
    copyLink: function (e) {
      this.code = e.target.value;
      this.cStype = "text";
      let copyText = document.querySelector("#link");
      copyText.select();
      document.execCommand("copy");
      var popup = document.getElementById("Copy");
      popup.classList.toggle("show");
      setTimeout(() => {
        popup.classList.toggle("show");
      }, 2000);
    },
    async getData() {
      await this.api.get("user/indexAffiliate").then((res) => {
        this.sections = res.data.data.section
        this.affiliates = res.data.data.user
        this.loading = false;
      })
    }
  },
  computed: {
    userAffiliateData() {
      return this.$store.state.generalsetting.setting;
    },
  },
}
</script>
<style scoped>
.none-margin h3,
p {
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>