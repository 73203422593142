<template>
  <div>
    <div class="main-wrapper">
      <layout-header/>
      <about-header text='Gooreo Coin' page='Gooreo Coin'></about-header>
      <div  class="container trad-btn ">
        <a target="_blank" class="btn py-4 m-auto mb-5 btn-primary" href="https://exbitx.com/trade/GOOREO-USDT"><h4
            style="color: #fff">Trading Gooreo Coin </h4></a>
      </div>
      <div class="row container m-auto px-5">
        <a target="_blank" href="https://coincost.net/en/currency/gooreo" class="col-md-3 col-sm-6 my-3"><img class="platform-img"
                                                                                                   src="../../../assets/img/platforms/3615348c2865cad00a491454feeeb887.png"
                                                                                                   alt=""></a>
        <a target="_blank" href="https://explorer.bitquery.io/bsc/token/0x7156fc3db62cce82eaa7163279d9f99644257539"
           class="col-md-3 col-sm-6  my-3"><img class="platform-img"
                                   src="../../../assets/img/platforms/bitquery_logo_w.png"
                                   alt=""></a>
        <a target="_blank" href="https://bscscan.com/token/0x7156fc3db62cce82eaa7163279d9f99644257539"
           class="col-md-3 col-sm-6  my-3"><img class="platform-img" alt="" src="../../../assets/img/platforms/bscscan.png"></a>
        <a target="_blank" href="" class="col-md-3 col-sm-6  my-3"><img class="platform-img" alt=""
                                                           src="../../../assets/img/platforms/coingecko.png"></a>
        <a target="_blank" href="https://coinmarketcap.com/currencies/gooreo/" class="col-md-3 col-sm-6  my-3"><img
            class="platform-img" alt="" src="../../../assets/img/platforms/CMC-02.png"></a>
        <a target="_blank" href="https://www.coinbase.com/price/gooreo" class="col-md-3 col-sm-6  my-3"><img class="platform-img"
                                                                                                alt=""
                                                                                                src="../../../assets/img/platforms/Coinbase.svg.png"></a>
        <a target="_blank" href="https://nomics.com/assets/gooreo-gooreo-coin" class="col-md-3 col-sm-6  my-3"><img
            class="platform-img" alt="" src="../../../assets/img/platforms/nomics.png"></a>
        <a target="_blank" href="https://coinpaprika.com/coin/gooreo-gooreo/" class="col-md-3 col-sm-6  my-3"><img
            class="platform-img" alt=""
            src="../../../assets/img/platforms/paprika.svg"></a>

      </div>
      <!-- Counter -->
      <counter></counter>
      <!-- /Counter -->
      <div class="row   mx-lg-5 px-lg-5 px-md-0 mx-md-0  mt-5 pt-5">
        <div class="col-6 center-text ms-lg-5 ms-md-0">
          <h3 class="ms-lg-5 ms-md-0">
            Token Information
          </h3>
        </div>
        <div class="col-5">
          <ul>
            <li><span style="font-weight: bold">Coin Name: </span> Gooreo</li>
            <li><span style="font-weight: bold">Symbol: </span> Gooreo</li>
            <li><span style="font-weight: bold">Code: </span> BEP20</li>
            <li><span style="font-weight: bold">Circulating Supply: </span> 200M</li>
            <li><span style="font-weight: bold">Total Supply: </span> 1B</li>
            <li><span style="font-weight: bold">Decimal: </span> 18</li>
            <li><span style="font-weight: bold">Contract Address: </span> <a style="color:#009df6" target="_blank"
                                                                             href="https://bscscan.com/token/0x7156fc3db62cce82eaa7163279d9f99644257539">
            <p> 0x7156fc3db62cce82eaa7163279d9f99644257539</p>  </a></li>
            <li><span style="font-weight: bold ;">Network: </span> Binance Smart Chain (BSC)</li>
          </ul>
        </div>
      </div>
      <div class="container m-auto row mt-3">
        <div class="m-auto py-3 col-6 mt-2 card" id="chart">
          <h5 class="ms-4 mb-3">Funds Allocation</h5>
          <apexchart class="m-auto" height="450" width="400" type="pie" :options="chartOptions"
                     :series="series"></apexchart>
        </div>
        <div class="m-auto py-3 col-6 mt-2 card" id="chart">
          <h5 class="ms-4 mb-3">Supply Distribution:</h5>
          <apexchart class="m-auto" height="450" width="460" type="donut" :options="chartOptions1"
                     :series="series1"></apexchart>
        </div>
      </div>
      <div class="container  ">
        <div class="row container m-auto">
          <div class="col-md-7 col-sm-6 terms-text  mt-5">
            <h3 class="mt-5 ">
              Gooreo staking ‘Gooreo Token Staking Depo’ Reward System
            </h3>
            <p>Gooreo platform acts as a host to Gooreo staking – a DeFi kernel for global investors. Gooreo staking is an investment
              wallet and a Gooreo token staking depo for global investors looking to stake Gooreo coin. Gooreo token
              will
              be the processing cryptocurrency for payment or investment within Gooreo staking. Global investors are free to
              invest in any crypto coin (e.g. BTC, ETH, BNB, BUSD, etc.). Gooreo staking investors will earn an increasing %
              proportional to Gooreo platform profits as clarified in adjacent table :</p>
          </div>
          <div class="col-5 ms-3 card  mt-5">
            <table class="mt-25 table">
              <tr>
                <td><span style="color: #009df8;"><strong>Year</strong></span></td>
                <td><span style="color: #009df8;"><strong>% ‘ of platform profit’</strong></span></td>
              </tr>
              <tr>
                <td>1<sup>st</sup></td>
                <td>50%</td>
              </tr>
              <tr>
                <td>2<sup>nd</sup></td>
                <td>30%</td>
              </tr>
              <tr>
                <td>3<sup>rd</sup></td>
                <td>20%</td>
              </tr>
              <tr>
                <td>4<sup>th</sup></td>
                <td>10%</td>
              </tr>
              <tr>
                <td>5<sup>th</sup></td>
                <td>5%, perpetuity</td>
              </tr>

            </table>
          </div>
        </div>
      </div>
      <!-- Review -->
      <review></review>

      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  name: "Gooreo Coin",
  data() {
    return {
      series: [45, 147, 3, 15, 15],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Development', 'Marketing', 'Legal', 'Team', 'Operations'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series1: [20, 191, 10, 30, 15],
      chartOptions1: {
        chart: {
          type: 'donut',
        },
        labels: ['Circulating supply', 'Locked Funds for future', 'Team members & advisors', 'Stability Reserve', 'Community Support'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 380
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

    }
  }
}
</script>

<style scoped>
.card {
  width: 460px;
  border-radius: 35px;
}

.platform-img {
  width: 60%;
}
.trad-btn{
  width:267px
}

@media only screen and (max-width:500px){
  .platform-img {
    width: 100%;
  }
}
@media only screen and (max-width: 575.98px){
  .trad-btn{
    width: 243px;
  }
}
</style>