<template>
    <div>
     <layout-header/>
  <div class="container pt-5">
    <div class="d-flex">
      <div class="d-flex mt-5">
      <router-link to="/user/add-project" style="width: 200px;height:100px;border-radius: 10px" class="card add-project mx-2 p-2">
        <h5 class="mt-3">I'm hiring for project</h5>
        <div>
        </div> </router-link>
      <router-link to="/user/add-service" style="width: 200px;height:100px; border-radius: 10px" class="card add-service p-2 mx-2">
        <h5 class="mt-3">I'm a freelancer</h5>
        </router-link> 
    </div>
    </div>
  </div>
  </div>
</template>
<style>
.add-service:hover::before,.add-service:hover {
    border: 1px solid #009df8;
}
.add-service::before{
    content: '';
    right: 10px;
    position: absolute;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid #e1d7d7;
}
.add-project:hover::before,.add-project:hover {
    border: 1px solid #ff4514;
}
.add-project::before{
    content: '';
    right: 10px;
    position: absolute;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid #e1d7d7;
}
.d-flex {
    display: flex!important;
    flex-wrap: wrap;
    justify-content: space-around;
}
</style>