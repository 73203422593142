<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta name="description" :content="user.about">
    <meta property="author" :content="user.name"/>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <template v-if="user.avatar">
      <meta property="og:image:url" :content="user.avatar_url"/>
      <meta property="og:image" :content="user.avatar"/>
    </template>
    <meta property="og:description" :content="user.about"/>
    <meta property="og:url" :content="`https://gooreo.com${$route.path}`"/>
  </teleport>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <!-- Header -->
    <layout-header></layout-header>
    <!-- /Header -->

    <!-- Breadcrumb -->
    <div class="bread-crumb-bar">
      <div class="container">
        <div class="row align-items-center inner-banner">
          <div class="col-md-12 col-12 text-center">
            <div class="breadcrumb-list">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"><img src="../../../assets/img/home-icon.svg" alt="">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">PROFILE</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- Profile Banner -->
    <profilebanner :user="user" :userData="userData" :loading="loading"></profilebanner>
    <!-- Profile Banner -->
    <!-- Page Content -->
    <div class="content">
      <div class="container">

        <div class="row">

          <div class="col-12">

            <usertab></usertab>

            <!-- About Tab Content -->
            <div class="pro-post widget-box company-post abouts-detail">
              <h3 class="pro-title">About</h3>
              <div class="pro-content">
                <p style="word-wrap: break-word;">{{
                    about == null ? 'No info added yet' :
                        about
                  }}</p>
              </div>
            </div>
            <div class="pro-post project-widget widget-box technical-skill" id="skill">
              <h3 class="pro-title">SKILLS</h3>
              <div class="pro-content">
                <div class="tags">
                    <span v-if="skills.length > 0" v-for="skill in skills"
                          class="badge badge-pill badge-skills">{{ skill.title }}</span>
                  <span v-else>No skills added yet</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- Footer -->
    <layout-footer></layout-footer>
    <!-- /Footer -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  inject: ['api'],
  methods: {
    async getUserInfo() {
      this.api.get(`profile/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status === 'success') {
          this.userData = res.data.data
          this.user = res.data.data.user
          this.about = res.data.data.user.about
          this.skills = res.data.data.user.skills
          this.loading = false
        } else {
          location.replace('404')
        }
      })

    },
  },
  async created() {
    await this.getUserInfo()
  },
  data() {
    return {
      loading: true,
      user: {},
      userData: {},
      about: '',
      skills: []
    }
  }
}
</script>
<style>
.content {
  padding: 0 0 30px;
}
</style>