<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta :content="project.seo_title" name="keywords"/>
    <meta
        :content="'Gooreo' + ' ' + '|' + ' ' + project.seo_title"
        property="title"
    />
    <meta :content="project.seo_description" property="description"/>
    <template v-if="project.photo_url">
      <meta :content="project.photo_url" property="og:image:url"/>
      <meta :content="project.photo_url" property="og:image"/>
    </template>
    <meta :content="project.seo_title" property="og:title"/>
    <meta :content="'Gooreo'" property="og:site_name"/>
    <meta content="website" property="og:type"/>
    <meta :content="project.seo_description" property="og:description"/>
    <meta :content="`https://gooreo.com${$route.path}`" property="og:url"/>
  </teleport>
  <loader v-if="loading"></loader>
  <div v-if="!loading">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar bar2" style="background-color: rgb(241, 92, 61);">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol style="overflow-x: scroll; display: block ruby;">
                    <li
                        v-for="item in sections.slice(0, 9)"
                        class="breadcrumb-item2"
                    >
                      <router-link
                          :to="{
                          name: 'Category',
                          params: { title: item.title.en, id: item.slug },
                        }"
                      ><h6 class="text-white m-auto">{{ item.title.en }}</h6>
                      </router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content pt-5">
        <div class="container">
          <div class="p-3 row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                      ><img style="margin-bottom: 8px"
                            alt="Post Author"
                            src="../../../assets/img/home-icon.svg"
                      />
                        Home
                      </router-link>
                    </li>
                    <li style="padding-top: 5px;" class="breadcrumb-item">
                      <router-link style="line-height: 1.8;" to="/projects">Projects</router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="developer-box project-box-view">
                <h2>{{ project.title.en }}</h2>
                <ul class="develope-list-rate project-rate align-items-center">
                  <li>
                    <i class="fas fa-clock"></i>Posted
                    {{ project.created_at.humen }}
                  </li>

                  <li
                      class="full-time"
                      style="background-color: rgb(241, 92, 61);"
                  >
                    {{
                      project.project_time_type == ""
                          ? "One-time"
                          : project.project_time_type
                    }}
                  </li>
                </ul>
                <div class="proposal-box">
                  <div class="proposal-value">
                    <h4 style="color: rgb(241, 92, 61);">
                      $ {{ parseFloat(project.project_budget_from)?.toFixed(2) }} -
                      {{ parseFloat(project.project_budget_to)?.toFixed(2) }}
                    </h4>
                  </div>
                  <div v-if="loggedin === 'true'" class="proposal-box">
                    <button
                        :style="
                        project.favorited
                          ? 'background-color: rgb(241, 92, 61);color:white'
                          : ''
                      "
                        class="btn favourites-btn btn-primary favour-border"
                        @click="addFavorite(project.id)"
                    >
                      Favourite <i class="fas fa-heart"></i>
                    </button>
                    <a
                        v-if="
                        isHasApplicant == false &&
                          project.author.id != this.localuser.id
                      "
                        class="btn proposal-btn btn-primary"
                        data-bs-toggle="modal"
                        href="#file"
                        style="background-color: rgb(241, 92, 61);    border: 1px solid rgb(241, 92, 61); "
                    >Send Proposal <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                  <div v-else class="proposal-box">
                    <a class="btn favourites-btn btn-primary favour-border"
                       data-bs-toggle="modal"
                       href="#login"
                    >Favourite <i class="fas fa-heart"></i
                    ></a>
                    <a v-if="isHasApplicant == false &&project.author.id != this.localuser.id"
                       class="btn proposal-btn btn-primary"
                       data-bs-toggle="modal"
                       href="#login"
                       style="background-color: rgb(241, 92, 61);    border: 1px solid rgb(241, 92, 61); ">Send Proposal
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="pro-view project-details-view">
                <!-- Job Detail -->
                <div class="post-widget">
                  <div class="pro-content">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 d-flex">
                        <div
                            class="pro-post job-type d-flex align-items-center"
                        >
                          <div class="pro-post-head">
                            <p>Proposals</p>
                            <h6>{{ applicant.length }}</h6>
                          </div>
                          <div class="post-job-icon pink-color">
                            <img
                                alt=""
                                class="img-fluid"
                                src="../../../assets/img/icon/icon-13.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 d-flex">
                        <div
                            class="pro-post job-type d-flex align-items-center"
                        >
                          <div class="pro-post-head">
                            <p>Payment type</p>
                            <h6>{{ project.project_type }}</h6>
                          </div>
                          <div class="post-job-icon green-color">
                            <img
                                alt=""
                                class="img-fluid"
                                src="../../../assets/img/icon/icon-12.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 d-flex">
                        <div
                            class="pro-post job-type d-flex align-items-center"
                        >
                          <div class="pro-post-head">
                            <p>Average Offers</p>
                            <h6>${{ avgOffer }}</h6>
                          </div>
                          <div class="post-job-icon">
                            <img
                                alt=""
                                class="img-fluid"
                                src="../../../assets/img/icon/exp-icon-01.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="post-widget project-duration">
                  <div class="pro-content">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 d-flex">
                        <div
                            class="pro-post job-type d-flex align-items-center"
                        >
                          <div class="pro-post-head">
                            <p>Project Duration</p>
                            <h6>
                              {{ project.project_length_from }} -
                              {{ project.project_length_to }}
                              {{ project.project_length_type }}
                            </h6>
                          </div>
                          <div class="post-job-icon">
                            <img
                                alt=""
                                class="img-fluid"
                                src="../../../assets/img/icon/exp-icon-02.png"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 d-flex">
                        <div
                            class="pro-post job-type d-flex align-items-center"
                        >
                          <div class="pro-post-head">
                            <p>Views Count</p>
                            <h6>{{ project.views_count }}</h6>
                          </div>
                          <div class="post-job-icon">
                            <img
                                alt=""
                                class="img-fluid"
                                src="../../../assets/img/icon/exp-icon-03.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Job Detail -->
                <!-- Senior Animator  -->
                <div class="pro-post widget-box">
                  <h3 class="pro-title">About the project</h3>
                  <div class="pro-content" style="word-wrap: break-word;">
                    <p v-html="`${project.content.en}`"></p>
                  </div>
                </div>
                <!-- /Senior Animator  -->
                <!-- Skills Required  -->
                <div class="pro-post project-widget widget-box">
                  <h3 class="pro-title">Offers</h3>
                  <div class="pro-content">
                    <div class="col-xl-12 col-md-8">
                      <div  v-for="item in applicant">
                        <div  v-if="Object.keys(item.author).length > 0">
                          <app-accordion
                              v-if="applicant.length > 0 "
                              :key="item.id"
                              :isOwner="this.localuser.id == project.author.id"
                              class="mb-4"
                          >
                            <template v-slot:title>
                              <div
                                  class="review-top space-between tab-reviews  align-items-center"
                                  style="padding-left: 20px; padding-bottom: 20px"
                              >
                                <div class="d-flex align-items-center">
                                  <div class="review-img">
                                    <router-link to="/developer-details">
                                      <img
                                          :src="item.author.avatar"
                                          alt="Post Image"
                                          class="img-fluid"
                                      />
                                    </router-link>
                                  </div>
                                  <div class="review-info">
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex" style="margin-right: 10px;">
                                        <div>
                                          <h3>
                                            <router-link
                                                :to="`/profile/${item.user.slug}`"
                                            >{{ item.author.name }}
                                            </router-link>
                                          </h3>
                                        </div>
                                        <div
                                            class="rating"
                                            style="margin-top: 0px !important; width: 50px;"
                                        >
                                    <span class="average-rating">
                                      <i class="fas fa-star filled"></i>
                                      {{
                                        item.rating == null ? 0.0 : item.rating
                                      }}
                                    </span>
                                        </div>
                                      </div>
                                      <div>
                                        <span>${{ parseFloat(item.price)?.toFixed(2) }}</span>
                                      </div>
                                    </div>
                                    <h5>{{ item.created_at_format.humen }}</h5>
                                  </div>
                                </div>
                                <div
                                    v-if="this.localuser.id == project.author.id"
                                    class="col-md-7 me-2 submit-section text-end"
                                >
                                  <button
                                      class="btn btn-success sub-btn"
                                      data-bs-toggle="modal"
                                      href="#accept"
                                      style="background-color: rgb(241, 92, 61);    border: 1px solid rgb(241, 92, 61);"
                                      type="submit"
                                      @click="
                                  sedData(
                                    (adays_count = item.days_count),
                                    (apost_id = item.id),
                                    (aprice = item.price)
                                  )
                                "
                                  >
                                    Accept the offer
                                  </button>
                                </div>
                              </div>
                            </template>
                            <template v-slot:content>
                              <div class="review-content no-padding me-5 p-3 ">
                                <p class="mb-0 mt-3">{{ item.content }}</p>
                              </div>
                            </template>
                          </app-accordion>
                          <div v-else class="center-text">
                            <h4>No offers yet</h4>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- /Skills Required  -->
              </div>
            </div>
            <!-- Blog Sidebar -->
            <div
                class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar project-client-view"
            >
              <div class="freelance-widget widget-author mt-2 pro-post">
                <div class="freelance-content">
                  <div class="author-heading">
                    <div class="profile-img">
                      <router-link :to="`/profile/${project.author.slug}`">
                        <img
                            :src="project.author.avatar"
                            alt="author"
                            style=" width:84px;height:84px;"
                        />
                        <span class="project-verified"
                        ><i
                            v-if="project.author.verified_id == true"
                            class="fas fa-check-circle"
                        ></i
                        ></span>
                      </router-link>
                    </div>
                    <div class="profile-name">
                      <div class="author-location">
                        <router-link
                            :to="`/profile/${project.author.slug}`"
                            style="color: rgb(241, 92, 61);"
                        >
                          {{ project.author.name }}
                        </router-link>
                      </div>
                    </div>
                    <div class="freelance-info">
                      <div class="rating mapgridrating1">
                        <i class="fas fa-star filled"></i>
                        <span
                            v-show="`{{ project.author.review_avg  }}`"
                            class="average-rating"
                        >
                          0.0
                        </span>
                        <span class="average-rating"
                        >{{ project.author.review_avg }} ({{
                            project.author.review_count
                          }})
                        </span>
                      </div>
                    </div>
                    <div class="pro-member">
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class="text-start mb-0">
                            Member Since
                          </h6>
                        </div>
                        <div class="col-auto">
                          <span>{{ project.author.member_since.date }}</span>
                        </div>
                      </div>
                      <hr class="my-3"/>
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class=" text-start mb-0">
                            Total Jobs Delivered
                          </h6>
                        </div>
                        <div class="col-auto">
                          <span
                              v-show="
                              `{{  project.author.my_sales_delivered_count }}`
                            "
                          >
                            Not yet
                          </span>
                          <span>{{
                              project.author.my_sales_delivered_count
                            }}</span>
                        </div>
                      </div>
                      <hr class="my-3"/>
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class=" text-start mb-0">
                            About client
                          </h6>
                        </div>
                        <div
                            class="col-auto"
                            style="word-wrap:break-word; text-align:left;"
                        >
                          <span>{{ project.author.about }}.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pro-post widget-box post-widget profile-link">
                <h3 class="pro-title">Service Link</h3>
                <div class="pro-content">
                  <div class="form-group profile-group mb-0">
                    <div
                        class="input-group"
                        style="background-color: #e9ecef;;"
                    >
                      <input
                          id="link"
                          :value="`https://www.gooreo.com${$route.fullPath}${this.inv}`"
                          class="form-control"
                          readonly
                          type="text"
                      />
                      <div class=" popup input-group-append">
                        <span class="popuptext" id="Copy">Copied</span>
                        <button class="btn btn-success sub-btn" type="submit">
                          <i class="fa fa-clone" @click.prevent="copyLink"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Blog Sidebar -->
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
    </div>
    <log-form id="login"></log-form>
    <div id="hire" class="modal fade custom-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-modal">
          <div class="modal-header">
            <button
                aria-label="Close"
                class="close"
                data-bs-dismiss="modal"
                type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center pt-0 mb-4">
              <img
                  alt="logo"
                  class="img-fluid mb-1"
                  src="../../../assets/img/logo-01.png"
              />
              <h5 class="modal-title">Discuss your project with David</h5>
            </div>
            <form>
              <div class="modal-info">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <input
                          class="form-control"
                          name="name"
                          placeholder="First name & Lastname"
                          type="text"
                      />
                    </div>
                    <div class="form-group">
                      <input
                          class="form-control"
                          name="name"
                          placeholder="Email Address"
                          type="email"
                      />
                    </div>
                    <div class="form-group">
                      <input
                          class="form-control"
                          name="name"
                          placeholder="Phone Number"
                          type="text"
                      />
                    </div>
                    <div class="form-group">
                      <textarea
                          class="form-control"
                          name="message"
                          placeholder="Message"
                          rows="5"
                      ></textarea>
                    </div>
                    <div class="form-group row">
                      <div class="col-12 col-md-4 pr-0">
                        <label class="file-upload">
                          Add Attachment <input type="file"/>
                        </label>
                      </div>
                      <div class="col-12 col-md-8">
                        <p class="mb-1">
                          Allowed file types: zip, pdf, png, jpg
                        </p>
                        <p>Max file size: 50 MB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <router-link to="/user/dashboard">
                <div class="submit-section text-center">
                  <button
                      class="btn btn-primary btn-block submit-btn"
                      type="submit"
                  >
                    Hire Now
                  </button>
                </div>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="file" class="modal fade">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div v-if="isHasApplicant == true" class="modal-header">
            <h4 class="modal-title">SEND PROPOSALS</h4>
            <span class="modal-close" @click="clearForm()"
            ><a aria-label="Close" data-bs-dismiss="modal"
            ><i class="far fa-times-circle orange-text"></i></a
            ></span>
          </div>
          <div class="modal-body">
            <div class="modal-info">
              <form>
                <div class="feedback-form">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                          v-model="form.days_count"
                          class="form-control"
                          placeholder="Days count"
                          type="number"
                      />
                    </div>
                    <div class="col-md-6 form-group">
                      <input
                          v-model="form.price"
                          class="form-control"
                          placeholder="Your Price"
                          type="number"
                          @change="calcNet()"
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <input
                          v-model="showNet"
                          class="form-control"
                          placeholder="Your net profit"
                          readonly
                          type="text"
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <textarea
                          v-model="form.offer"
                          class="form-control"
                          placeholder="Offer content"
                          rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="proposal-features">
                  <div class="proposal-widget proposal-success">
                    <label class="custom_check">
                      <span class="proposal-text">Tip: </span>
                    </label>
                    <ul>
                      <li style="margin-left: 20px;">
                        <p style="padding: 0;">
                          To get the job consider that you have understood the
                          request very well and then put a fair price.
                        </p>
                      </li>
                      <li style="margin-left: 20px;">
                        <p style="padding: 0;">
                          Doing this job with
                          <span style="color: #009df8;"
                          ><strong>Gooreo</strong></span
                          >
                          will protect you from not getting paid after
                          completing the project.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="proposal-widget proposal-danger">
                    <label class="custom_check">
                      <span class="proposal-text">Warnings: </span>
                    </label>
                    <ul>
                      <li style="margin-left: 20px;">
                        <p style="padding: 0;">
                          Don't share any contact details like email, phone
                          number or social media id.
                        </p>
                      </li>
                      <li style="margin-left: 20px;">
                        <p style="padding: 0;">
                          Don't send links that are not related to the offer.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 submit-section">
                    <label class="custom_check">
                      <input name="select_time" required type="checkbox"/>
                      <span class="checkmark"></span> I agree to the Terms and
                      Conditions
                    </label>
                  </div>
                  <div class="space-between mt-3 d-flex">
                    <a class="  btn btn-primary " data-bs-toggle="modal" href="#file"
                       type="submit">
                      change</a>
                    <button
                        class="btn btn-primary "
                        type="submit"
                        @click.prevent="addOffer()">
                      SUBMIT PROPOSAL
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="accept" class="modal fade">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Project Acceptance</h4>
            <span class="modal-close"
            ><a aria-label="Close" data-bs-dismiss="modal"
            ><i
                class="far fa-times-circle orange-text"
                style="color: rgb(241, 92, 61); "
            ></i></a></span>
          </div>
          <div class="modal-body">
            <div class="modal-info">
              <form>
                <div class="row">
                  <div class="col-md-12 submit-section">
                    <h5>Accepting this offer?</h5>
                  </div>
                  <div class="col-md-12 submit-section text-end">
                    <a aria-label="Close" class="  btn btn-primary submit-btn " data-bs-dismiss="modal"
                       style="border: 1px solid rgb(241, 92, 61); background-color: rgb(241, 92, 61); ">No</a>
                    <button class="btn btn-primary submit-btn"
                            style="    border: 1px solid rgb(241, 92, 61); background-color: rgb(241, 92, 61); "
                            @click.prevent="acceptOffer()">yes
                    </button>
                  </div>
                  <div class="col-md-2 ">
                    <div class="form-group "></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  async created() {
    if ($cookies.get("loggedin") == "true") {
      this.inv = `?invitation=${JSON.parse(this.decrypted($cookies.get("user"))).id}`
      this.localuser = JSON.parse(this.decrypted($cookies.get("user")));
    }

    await this.getData();
  },
  inject: ["api"],
  methods: {
    sedData(adays_count, apost_id, aprice) {
      this.acc_days_count = adays_count;

      this.acc_post_id = apost_id;
      this.acc_price = aprice;
    },
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          if (this.project.id == id) {
            this.project.favorited = !this.project.favorited;
          }
        } else {

        }
      });
    },
    copyLink: function (e) {
      this.code = e.target.value;
      this.cStype = "text";
      let copyText = document.querySelector("#link");
      copyText.select();
      document.execCommand("copy");
      var popup = document.getElementById("Copy");
      popup.classList.toggle("show");
      setTimeout(() => {
        popup.classList.toggle("show");
      }, 2000);
    },
    async addOffer() {
      if (this.form.days_count == "") {
        this.toast("Days count field is required", "danger");
      } else if (this.form.price == "") {
        this.toast("Price field is required", "danger");
      } else if (this.form.offer.length <= 10 || this.form.offer == "") {
        this.toast("Offer content should be more than 10 characters", "danger");
      } else {
        this.loading = true;
        this.api
            .post("projects/addOffer", {
              post_id: this.project.id,
              price: this.form.price,
              total: this.form.net,
              days_count: this.form.days_count,
              content: this.form.offer,
            })
            .then((res) => {
              if (res.status == "success") {
                this.loading = false;
                location.reload();
              } else {
                this.toast(res.data, "danger");
              }
            });
      }
    },
    calcNet() {
      return 1;
    },
    async getData() {
      this.api.get(`projects/${this.$route.path.split("/")[2]}`).then((res) => {
        if (res.status == "success") {
          this.project = res.data.data.project;
          this.applicant = res.data.data.applicant;
          this.avgOffer = res.data.data.avgOffer;
          this.isHasApplicant = res.data.data.isHasApplicant;
          this.loading = false;
        } else {
          location.replace('/404')
        }
      });
    },
    acceptOffer() {
      this.api
          .post("projects/acceptOffer", {
            console: "",
            days_count: this.acc_days_count,
            id: this.acc_post_id,
            post_id: this.project.id,
            price: this.acc_price,
            total: 0,
          })
          .then((res) => {
            if (res.status == "success") {
              this.loading = false;
              location.reload();
            } else {
              // for(let i=0; i > res.data.msg.length;){
              this.toast("You have already accepted this offer", "danger");
              // }
            }
          });
    },
    applied() {
      if (loggedIn == true) {
        let isApplied = false;
        this.applicant.forEach((a) => {
          if (a.user_id == this.localuser.id) {
            isApplied = true;
          }
        });
        return false;
      }
    },
    clearForm() {
      this.form = {
        price: "",
        net: "",
        days_count: "",
        offer: "",
      };
    },
  },
  computed: {
    sections() {
      return this.$store.state.generalsetting.sections;
    },
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      localuser: "",
      loggedin: $cookies.get("loggedin"),
      loading: true,
      isHasApplicant: false,
      activeClass: "active",
      project: [],
      applicant: [],
      avgOffer: 0,
      form: {
        days_count: "",
        offer: "",
        price: "",
        net: "",
      },
      acc_days_count: "",
      acc_id: "",
      acc_post_id: "",
      acc_price: "",
      acc_total: "",
      inv: "",
      showNet: ''
    };
    //  isactive : true
  },
  watch: {
    // whenever question changes, this function will run
    "form.price"(newPrice, oldPrice) {
      this.form.net = newPrice - newPrice * 0.1;
      this.showNet = 'Your net profit: $' + this.form.net

    },
  },
};
</script>

<style scoped>
.pro-title::before {
  background-color: rgb(241, 92, 61);
}

.proposal-box .favour-border:hover {
  background-color: rgb(241, 92, 61);
  border-color: rgb(241, 92, 61);
}

.pro-title::after {
  background-color: rgb(241, 92, 61);
}

.profile-group .input-group .sub-btn {
  background-color: rgb(241, 92, 61);
  border-color: rgb(241, 92, 61);
}

.page-breadcrumb .breadcrumb a:hover {
  color: rgb(241, 92, 61);

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.paginations li:hover a {
  color: #F15C3DFF;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #F15C3DFF;
}

*::-webkit-scrollbar-thumb {
  background-color: #e7eaec;
  border-radius: 20px;
  border: 3px solid #F15C3DFF;
}
</style>