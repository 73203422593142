<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta :content="service.seo_description" name="description"/>
    <meta :content="service_title" name="keywords"/>
    <template v-if="service.photo_url">
      <meta :content="service.photo_url" property="og:image:url"/>
      <meta :content="service.photo_url" property="og:image"/>
    </template>
    <meta :content="service_title" property="og:title"/>
    <meta content="Gooreo" property="og:site_name"/>
    <meta :content="service.seo_description" property="og:description"/>
    <meta content="website" property="og:type"/>
    <meta :content="`https://gooreo.com${$route.path}`" property="og:url"/>
  </teleport>
  <loader v-if="loading"></loader>
  <div>
    <div v-if="!loading" class="main-wrapper">
      <layout-employeeheader/>
      <div class="bread-crumb-bar bar2 ">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class=" col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol style="overflow-x: scroll;">
                    <li v-for="(item,index) in sections.slice(0, 9)" :key="index" class="breadcrumb-item2">
                      <router-link :to="{
                          name: 'Category',
                          params: { title: item.title.en, id: item.slug },}">
                        <h6 class="text-white m-auto">{{ item.title.en }}</h6>
                      </router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="isMobile() ? 'content pt-5' : 'content pt-0'">
        <div class="container">
          <div>
            <div class="container">
              <div :class="!isMobile()? 'p-4 row  inner-banner': 'pt-4 pb-4 row  inner-banner'">
                <div class="col-md-12 col-12 text-center">
                  <div class="breadcrumb-list">
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <router-link style="font-size: 12px;" to="/"><img class="me-2" alt="Post Author"
                                                                            src="../../../assets/img/home-icon.svg"/>
                            Home
                          </router-link>
                        </li>
                        <li class="breadcrumb-item">
                          <router-link
                              style="font-size: 12px;line-height:21px;"
                              to="/marketplace"
                          >Services Marketplace
                          </router-link>
                        </li>
                        <li
                            v-if="!isMobile()"
                            aria-current="page"
                            class="breadcrumb-item"
                            style="font-size: 12px;">
                          <router-link
                              :to="`/category/${service.cat.parent?.slug}`"
                              style="font-size: 12px;line-height:21px;">
                            {{ service.cat.parent?.title.en }}
                          </router-link>
                        </li>
                        <li
                            v-if="!isMobile()"
                            aria-current="page"
                            class="breadcrumb-item"
                            style="font-size: 12px;">
                          <router-link
                              :to="`/services/${service.cat.slug}`"
                              style="font-size: 12px;line-height:21px;"
                          >
                            {{ service.cat.title.en }}
                          </router-link>
                        </li>
                        <li
                            v-if="isMobile()"
                            aria-current="page"
                            class="breadcrumb-item"
                            style="font-size: 12px;"
                        >
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Tab Detail -->
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="developer-box head-develop">
                <h2>{{ service.title.en }}</h2>
                <div class="d-flex justify-content-between">
                  <ul class="develope-list-rate">
                    <li style="font-size: 14px;">
                      Created:
                      <span style="font-weight:normal; font-size: 14px;">{{ service.created_at.humen }}</span>
                    </li>
                    <li class="bl-0" style="font-size: 14px;">
                      Response Speed:
                      <span style="font-weight:normal; font-size: 14px;">{{ data.responseSpeed.split(",")[0] }}
                      </span>
                    </li>
                    <li>
                      <div class="rating">
                        <i :class="service.reviews_avg == null? 'fas fa-star' : 'fas fa-star filled'"></i>
                        <span class="average-rating">{{
                            service.reviews_avg == null ? 0.0 : service.reviews_avg.substr(0, 3)
                          }}</span>
                      </div>
                    </li>
                  </ul>
                  <a v-if="loggedin === 'true'"
                     style="height: 40px"
                     class="btn proposal-btn btn-primary"
                     data-bs-toggle="modal"
                     href="#message">Contact seller</a>
                  <a v-else
                     style="height: 40px"
                     class="btn proposal-btn btn-primary"
                     data-bs-toggle="modal"
                     href="#login"
                  >Contact seller</a>
                </div>
                <carousel :imgs="service.media_array"></carousel>
              </div>
              <div class="pro-view develop-view">
                <div id="description" class="pro-post widget-box">
                  <h3>Description</h3>
                  <div class="pro-description">
                    <span v-html="`${service.content.en}`"></span>
                  </div>
                </div>
                <div v-if="isMobile()" id="extensions" class="pro-post project-widget widget-box develop-experiance">
                  <div class="pro-post widget-box about-widget about-field">
                    <div class="d-flex justify-content-between">
                      <h4 class="pro-title ">SERVICE INFO</h4>
                      <h4>${{ total }}</h4>
                    </div>
                    <table class="table">
                      <tbody>
                      <tr>
                        <td>Price</td>
                        <td>${{ parseFloat(service.price)?.toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td>Delivery time</td>
                        <td>
                          {{ service.days_count }}
                          {{ service.days_count >= 2 ? "days" : "day" }}
                        </td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td>
                          <div class="d-flex justify-content-between w-25">
                            <i
                                class="fas fa-minus"
                                style="cursor:pointer;"
                                @click="qtyRemove()"
                            ></i>
                            <h6>{{ qty }}</h6>
                            <i
                                class="fas fa-plus"
                                style="cursor:pointer;"
                                @click="qtyAdd()"
                            ></i>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="pro-post widget-box about-widget about-field">
                    <h3 class="pro-title ">EXTENSIONS</h3>
                    <table class="table">
                      <tbody>
                      <tr v-for="(ext ,index) in service.ext" :key="index">
                        <div class="col-lg-12 col-md-6 d-flex align-items-start">
                          <input class="mt-4" type="checkbox"/>
                          <div class="experiance-list">
                            <div class="d-flex justify-content-between">
                              <h4>
                                {{ ext.title }} (+{{ ext.days_count }}
                                {{ ext.days_count >= 2 ? "days" : "day" }})
                              </h4>
                              <h4>${{ parseFloat(ext.price)?.toFixed(2) }}</h4>
                            </div>
                            <p>{{ ext.content }}</p>
                          </div>
                        </div>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="author" class="pro-post project-widget widget-box">
                  <h3 class="pro-title">ABOUT AUTHOR</h3>
                  <div class="pro-content">
                    <div :class="isMobile() ? 'd-block' : 'd-flex'">
                      <div>
                        <router-link :to="`/profile/${service.author.slug}`">
                          <div style="margin: 0 20px;">
                            <img :src="service.author.avatar" alt="" class="img-fluid"
                                 style="border-radius: 50%; width: 70px;height: 70px"/>
                          </div>
                        </router-link>
                        <div style="margin: 13px 34px;">
                          <div v-if="loggedin === 'true'" class="btn btn-success sub-btn" data-bs-toggle="modal"
                               href="#message">
                            <i class="fa fa-envelope"></i>
                          </div>
                          <div v-else class="btn btn-success sub-btn" data-bs-toggle="modal" href="#login">
                            <i class="fa fa-envelope"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 d-flex">
                        <div class="experiance-list">
                          <div class="d-flex">
                            <router-link :to="`/profile/${service.author.slug}`">
                              <h4 class="m-1 capitalize">
                                {{ service.author.name }}
                              </h4>
                            </router-link>
                            <div v-if="service.author.review_avg" class="rating m-1">
                              <i class="fas fa-star filled"></i>
                              <span class="average-rating">{{ parseFloat(service.author.review_avg)?.toFixed(1) }}</span>
                            </div>
                          </div>
                          <p>
                            <span style="color: #5C5C5C;">Member since: </span><br/>
                            {{ service.author.member_since.humen }}.
                          </p>
                          <p>
                            <span style="color: #5C5C5C;">Info: </span><br/>
                            {{ service.author.about == null ? "No info available" : service.author.about }}.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="reviews" class="pro-post author-widget clearfix develop-feedback">
                  <div class="widget-title-box clearfix space-between d-flex">
                    <h3 class="pro-title mb-0">REVIEWS</h3>
                  </div>
                  <div class="about-author shadow-sm">
                    <h6 class="mb-0" v-if="data.reviews === {}">
                      Service has no reviews yet
                    </h6>
                  </div>
                  <div v-if="data.reviews" v-for="(review ,index) in data.reviews" :key="index"
                       class="about-author shadow-sm">
                    <div class="about-author-img" style="width: 60px; height: 60px;">
                      <div class="author-img-wrap">
                        <router-link :to="`/profile/${review.user.slug}`"><img :src="review.author.avatar" alt=""
                                                                               class="img-fluid"/>
                        </router-link>
                      </div>
                    </div>
                    <div class="author-details">
                      <div class="d-flex justify-content-between">
                        <router-link :to="`/profile/${review.user.slug}`" class="card-author-name">
                          {{ review.author.name }}
                        </router-link>
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <span class="average-rating">{{ review.rating }}</span>
                        </div>
                      </div>
                      <p class="mb-0">{{ review.review }}</p>
                    </div>
                  </div>
                </div>
                <div id="related" class="pro-post project-widget widget-box project-gallery">
                  <h3 class="pro-title">RELATED</h3>
                  <div class="pro-content">
                    <div class="row">
                      <div v-for="item in data.related" :key="item.id" class="col-md-6 col-lg-4 col-sm-12">
                        <div class="s-grid-card grid-card">
                          <div class="card-image">
                            <router-link :to="`/marketplace/${item.slug}`"><img :src="item.photo_url" alt="Post Image"
                                                                                class="img-fluid"/>
                            </router-link>
                          </div>
                          <div class="card-content">
                            <ul class="entry-meta meta-item">
                              <li>
                                <div class="post-author">
                                  <router-link :to="`/profile/${item.author.slug}`">
                                    <img :src="item.author.avatar" alt="Post Author"/>
                                    <span> {{ item.author.name }}</span>
                                  </router-link>
                                </div>
                              </li>
                              <li>
                                <i class="far fa-star"></i>{{ item.reviews_avg == null ? 0 : item.reviews_avg }}
                              </li>
                            </ul>
                            <h4 style="overflow: hidden; font-weight: normal; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 45px;">
                              <router-link :to="`/marketplace/${item.slug}`">{{ item.title.en }}
                              </router-link>
                            </h4>
                            <div class="d-flex justify-content-between">
                              <h4 class="m-card-title orange-text">${{ parseFloat(item.price)?.toFixed(2) }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <router-link :to="`/services/${service.cat.slug}`" class="btn more-btn">View more
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isMobile()" class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar developer-view">
              <div class="stickyside">
                <div class="pro-post widget-box about-widget about-field">
                  <div class="d-flex justify-content-between">
                    <h4 class="pro-title ">SERVICE INFO</h4>
                    <div class="proposal-box">
                      <div class="proposal-value">
                        <h4>${{ total }}</h4>
                      </div>
                    </div>
                  </div>
                  <table class="table">
                    <tbody>
                    <tr>
                      <td>Price</td>
                      <td>${{ parseFloat(service.price)?.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td>Delivery time</td>
                      <td>
                        {{ service.days_count }}
                        {{ service.days_count >= 2 ? "days" : "day" }}
                      </td>
                    </tr>
                    <tr>
                      <td>Quantity</td>
                      <td>
                        <div class="d-flex justify-content-between w-50">
                          <i class="fas fa-minus" style="cursor:pointer;" @click="qtyRemove()"></i>
                          <h6>{{ qty }}</h6>
                          <i class="fas fa-plus" style="cursor:pointer;" @click="qtyAdd()"></i>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pro-post widget-box about-widget about-field">
                  <h4 class="pro-title ">EXTENSIONS</h4>
                  <table class="table">
                    <tbody>
                    <tr v-for="(ext ,index) in service.ext" :key="index">
                      <div class="col-lg-12 col-md-6 d-flex align-items-start">
                        <input class="mt-4" type="checkbox" @change="updatePrice(ext.price, $event, ext)"/>
                        <div class="experiance-list">
                          <div class="d-flex justify-content-between">
                            <h4>
                              {{ ext.title }} (+{{ ext.days_count }}
                              {{ ext.days_count >= 2 ? "days" : "day" }})
                            </h4>
                            <h4>${{ parseFloat(ext.price)?.toFixed(2) }}</h4>
                          </div>
                          <p>{{ ext.content }}</p>
                        </div>
                      </div>
                    </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <a v-if="loggedin === 'true'" class="btn learn-btn" href="javascript:void(0);" @click="addToCart()">ADD
                      TO CART</a>
                    <a v-else class="btn learn-btn" data-bs-toggle="modal" href="#login">ADD TO CART</a>
                  </div>
                </div>
                <div class="pro-post widget-box post-widget profile-link">
                  <h3 class="pro-title">Service Link</h3>
                  <div class="pro-content">
                    <div class="form-group profile-group mb-0">
                      <div class="input-group" style="background-color: #e9ecef;;">
                        <input id="link" :value="`https://www.gooreo.com${$route.fullPath}${this.inv}`"
                               class="form-control" readonly type="text"/>
                        <div class=" popup input-group-append">
                          <span class="popuptext" id="Copy">Copied</span>
                          <button class="btn btn-success sub-btn" type="submit">
                            <i class="fa fa-clone" @click.prevent="copyLink"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <send-message :ide="service.id" :title="service.title.en" :name="service.author.name"
                        id="message"/>
          <log-form id="login"></log-form>
        </div>
      </div>
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
export default {
  inject: ["api"],
  methods: {
    async getData() {
      await this.api
          .get(`marketplace/${this.$route.path.split("/")[2]}`)
          .then((response) => {
            if (response.status == "success") {
              this.service = response.data.data.service;
              this.service_title = response.data.data.service.cat_title.en;
              this.data = response.data.data;
              this.baseTotal = parseFloat(response.data.data.service.price);
              this.total = parseFloat(response.data.data.service.price);
              this.buildSlider();
              this.loading = false;
            } else {
              location.replace('/404')
            }
            this.loading = false;
          });
    },

    copyLink: function (e) {
      this.code = e.target.value;
      this.cStype = "text";
      let copyText = document.querySelector("#link");
      copyText.select();
      document.execCommand("copy");
      var popup = document.getElementById("Copy");
      popup.classList.toggle("show");
      setTimeout(() => {
        popup.classList.toggle("show");
      }, 2000);
    },
    addToCart() {
      if ($cookies.get("loggedin") == "true") {
        this.loading = true;
        let exts = [];
        this.ext.forEach((elem) => {
          exts.push(elem.id);
        });
        this.api
            .post(`cart/addToCart/${this.service.id}`, {
              qty: this.qty,
              ext: exts,
            })
            .then((res) => {
              if (res.status == "success") {
                this.$store.commit("addCartItem", this.qty);
                this.loading = false;
              } else {
                this.toast(res.data, "danger");
              }
            });
      } else {
        this.toast("Please sign in first.", "warning");
      }
    },
    updatePrice(val, event, item) {
      if (event.target.checked) {
        this.ext.push(item);
        this.total += parseFloat(val) * this.qty;
      } else {
        this.ext.splice(this.ext.indexOf(item), 1);
        this.total -= parseFloat(val) * this.qty;
      }
    },
    qtyAdd() {
      this.qty++;
      let p = 0;
      let x = 0;
      this.ext.forEach((ex) => {
        x = parseInt(x) + parseInt(ex.price);
      });
      let xs = x * this.qty;
      let t = this.baseTotal * this.qty;
      this.total = t + xs;
    },
    qtyRemove() {
      if (this.qty > 1) {
        this.qty--;
        let p = 0;
        let x = 0;
        this.ext.forEach((ex) => {
          x = parseInt(x) + parseInt(ex.price);
        });
        let xs = x * this.qty;
        let t = this.baseTotal * this.qty;
        this.total = t + xs;
      }
    },
    addExt(item) {
      this.ext.push(item);
      this.total = this.baseTotal * this.qty + item.price * this.qty;
    },
    removeExt(item) {
      this.ext.remove(item);
      this.total = this.baseTotal * this.qty + item.price * this.qty;
    },
    buildSlider() {
      if ($(".developer-slider").length > 0) {
        $(".developer-slider").slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          asNavFor: ".developer-slider-thumb",
          arrows: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
        $(".developer-slider-thumb").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".developer-slider",
          arrows: true,
          variableWidth: true,
          centerMode: true,
          infinite: false,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
      }
    },
  },
  created() {
    if ($cookies.get("loggedin") == "true") {
      this.inv = `?invitation=${JSON.parse(this.decrypted($cookies.get("user"))).id}`
      this.localuser = JSON.parse(this.decrypted($cookies.get("user")));
    }
    this.buildSlider();
    this.getData();
  },
  computed: {
    sections() {
      return this.$store.state.generalsetting.sections;
    },
  },
  data() {
    return {
      service_title: "",

      loggedin: $cookies.get("loggedin"),
      loading: true,
      msgModal: false,
      data: {},
      service: {},
      qty: 1,
      baseTotal: 0,
      total: 0,
      ext: [],
      active: false,
      show: false,
      inv: '',
    };
  },
};
</script>













