<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header></layout-header>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img src="../../../assets/img/home-icon.svg" alt="">
                        Home
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">PROFILE</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Profile Banner -->
      <profilebanner :user="user" :userData="userData"></profilebanner>
      <!-- Profile Banner -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">

          <div class="col-lg-8 col-md-12">
            <div class="row">
              <usertab :user="user"></usertab>

              <!-- profile Sidebar -->
              <!--							<companydetail1></companydetail1>-->
              <!-- /Profile Sidebar -->

            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer></layout-footer>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
export default {
  inject: ['api'],
  methods: {
    async getUserInfo() {
      this.api.get(`profile/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status === 'success') {
          this.userData = res.data.data
          this.user = res.data.data.user
        } else {
          location.replace('404')
        }
      })
    }
  },
  async created() {
    await this.getUserInfo()
  },
  data() {
    return {
      user: {},
      userData: {},
    }
  }
}
</script>