<template>
  <div>
    <div class="main-wrapper">
      <layout-header/>
      <about-header text="About" page="About" gooreo="gooreo" brand="Where There Is Internet, There’ll Be Freelance;
        Where There Is Freelance, There’ll Be " gooreo_brand ='Gooreo'></about-header>
      <aboutdetail></aboutdetail>
      <agency></agency>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
  },
}
</script>