<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-accountheader></layout-accountheader>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item active">Invoice</li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Invoice</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content" style="min-height: 220.7px !important;">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div class="card pro-post b-0">
                <div class="card-body">
                  <div class="invoice-item">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="invoice-logo">
                          <img alt="logo" src="../../../assets/img/logo.png">
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- Invoice Item -->
                  <div class="invoice-item">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="invoice-info">
                          <strong v-if=" transaction.type === 'charge '||' sale' " class="customer-text">Invoice
                            To</strong>
                          <strong v-if=" transaction.type  === 'pay'" class="customer-text">Invoice From</strong>
                          <p class="invoice-details invoice-details-two">
                            {{ transaction.name }} <br>
                          </p>
                          <p>
                            {{ transaction.email }} <br>
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6 ">
                        <p class="invoice-details">
                          <strong>Invoice Number:</strong> {{ transaction.id }} <br>
                          <strong>Date:</strong> {{ transaction.created_at_format.date }} <br>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- /Invoice Item -->
                  <!-- Invoice Item -->
                  <div class="invoice-item">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="invoice-info">
                          <p class="invoice-details invoice-details-two">
                            <br>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Invoice Item -->
                  <!-- Invoice Item -->
                  <div class="invoice-item invoice-table-wrap">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="invoice-table table table-bordered">
                            <thead>
                            <tr>
                              <th>Type</th>
                              <th>Payment Method</th>
                              <th class="text-center">Transaction Id</th>
                              <th class="text-end">Payment Status</th>
                              <th class="text-end">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>{{ transaction.type }}</td>
                              <td> {{ transaction.payment_method }}</td>
                              <td class="text-center">{{ transaction.transaction_id }}</td>
                              <td class="text-end">{{ transaction.status }}</td>
                              <td class="text-end">${{ transaction.total }} {{ transaction.currency }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 card">
                  </div>
                  <div class="d-flex invoice-details  ">
                    <h4 class="me-1"> tax: </h4>
                    <h5 class=" mt-1">{{ transaction.tax }} </h5>
                  </div>
                  <div class="d-flex invoice-details  ">
                    <h4 class="me-1"> Invoice Total: </h4>
                    <h5 class=" mt-1"> ${{ transaction.subtotal }} {{ transaction.currency }} </h5>
                  </div>
                  <div class="invoice-details">
                    <button style="width:100px; height: 40px;" @click="print()" class="btn btn-primary btn-block mt-5  "
                            type="submit" value="Login">Print
                    </button>
                  </div>
                </div>
              </div>
              <!-- /Invoice Item -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
import util from '../../../assets/utils/util'

export default {
  inject: ['api'],
  methods: {
    async getData() {
      this.api.get(`user/invoice/${this.$route.path.split('/')[2]}`).then(res => {
        if (res.status == 'success') {
          this.transaction = res.data.data.transaction
          this.loading = false
        } else {
          location.replace('404')
        }
      })
    },
    print() {
      var pdf = new jsPDF();
      var element = document.getElementById('facture');
      var width = element.style.width;
      var height = element.style.height;
      html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('image/png');
        pdf.addImage(image, 'JPEG', 15, 40, width, height);
        pdf.save('facture' + moment(this.facture.date_debut).format('LL') + '_' + moment(this.facture.date_fin).format('LL') + '.pdf')
      });
    }
  },
  created() {
    this.getData();
    util.datatable('#freelancerfile')
  },
  mounted() {
  },
  data() {
    return {
      loading: true,
      transaction: [],
    }
  }
}
</script>