<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <h2 class="breadcrumb-title">TERMS & CONDITIONS</h2>
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item active"> Terms & Conditions</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="terms-content">
                <div class="terms-text">
                  <h3>Welcome to Gooreo</h3>

                  <p>Thanks for using our products and services ("Services"). The Services are provided by Gooreo. By
                    using our Services, you are agreeing to these terms. </p>

                  <p>Please read them carefully. Our Services are
                    very diverse, so sometimes additional terms or product requirements (including age requirements) may
                    apply.</p>

                  <p>Additional terms will be available with the relevant Services, and those additional terms
                    become part of your agreement with us if you use those Services.</p>
                </div>

                <div class="terms-text">
                  <h3>1. Using our services</h3>

                  <p>You must follow any policies made available to you within the Services. </p>

                  <p>Don't misuse our Services. For example, don't interfere with our Services or try to access them
                    using a method other than the interface and the instructions that we provide. You may use our
                    Services only as permitted by law, including applicable export and re-export control laws and
                    regulations. We may suspend or stop providing our Services to you if you do not comply with our
                    terms or policies or if we are investigating suspected misconduct.</p>
                  <p>Using our Services does not give you ownership of any intellectual property rights in our Services
                    or the content you access. You may not use content from our Services unless you obtain permission
                    from its owner or are otherwise permitted by law.</p>
                  <p>These terms do not grant you the right to use any branding or logos used in our Services. Don't
                    remove, obscure, or alter any legal notices displayed in or along with our Services.</p>
                  <h3>A. Personal Data that we collect about you.</h3>
                  <p>Personal Data is any information that relates to an identified or identifiable individual. The
                    Personal Data that you provide directly to us through our Sites will be apparent from the context in
                    which you provide the data. In particular: When you register for a Gooreo account we collect your
                    full name, email address, and account log-in credentials. When you fill-in our online form to
                    contact our sales team, we collect your full name, work email, country, and anything else you tell
                    us about your project, needs and timeline. When you use the "Remember Me" feature of Gooreo
                    Checkout, we collect your email address, payment card number, CVC code and expiration date. When you
                    respond to Gooreo emails or surveys we collect your email address, name and any other information
                    you choose to include in the body of your email or responses. If you contact us by phone, we will
                    collect the phone number you use to call Gooreo. If you contact us by phone as a Gooreo User, we may
                    collect additional information in order to verify your identity..</p>

                  <h3>B. Information that we collect automatically on our Sites.</h3>
                  <p>We also may collect information about your online activities on websites and connected These Terms
                    will be applied fully and affect to your use of this Website. By using this Website, you agreed to
                    accept all terms and conditions written in here. You must not use this Website if you disagree with
                    any of these Website Standard Terms and Conditions.</p>
                  <div class="terms-text">
                    <p>---------------</p>
                    <p>These Terms and Conditions (the “Terms”) govern the legally binding relationship between us when
                      you use our Website and the Services that we provide. By using the Website or Services, you
                      acknowledge that you understand these Terms and agree to be legally bound by them.
                      Bookings made via our Website, or with our employees over the phone, via email, or via any other
                      method of communication, are subject to these Terms. One person making the Booking on behalf of
                      other travellers is taken to have the authority to agree to these Terms on behalf of any other
                      travellers.</p>
                    <h3>1 Definitions</h3>
                    <p>In these Terms:</p>
                    <p>1.1 Any headings are for reference purposes only and shall not aid in the interpretation of the
                      clauses to which they relate.</p>
                    <p>1.2 Unless the context clearly indicates a contrary intention, use of any gender includes the
                      other genders, the singular includes the plural and vice versa, and natural persons include legal
                      entities and vice versa.</p>
                    <p>1.3 The following terms shall have the meanings assigned to them hereunder, namely:
                    </p>
                    <p>Accommodation Provider: means the provider of a hotel, home, accommodation, or other place of
                      lodging:</p>
                    <p>Activity Provider: means the provider of any tours, activities, or experiences:</p>
                    <p>Additional Services: means a service related to your Booking provided by a Service Provider,
                      including (without limitation) upgrades, incidental charges, additional luggage, priority seating,
                      meals, mini-bar, dry cleaning and laundry, passenger information change, rebooking, or
                      cancellation. The fees associated with the provision of Additional Services are set by the Service
                      Provider and/or other third party and are not included in the Total Price, except where the
                      requested Additional Services are added by you to your Booking with us during the Booking process,
                      which may incur an additional handling fee charged by us that will be displayed to you at the time
                      of your Booking. Where any Additional Services are sought by you following your completed purchase
                      of a Booking, you acknowledge that these arrangements are your sole responsibility and that you
                      will be required to directly contact the Service Provider or any other third party as required to
                      fulfil your request for the Additional Services:</p>
                    <p>Booking: means the process of purchasing a selected hotel, flight, or activity (including any
                      multiple of these) and the resulting outcome of this process. A Booking is completed when we
                      confirm that we have received and accepted your payment for the Total Price;</p>
                    <p>Carrier: means the airline providing the services of carriage by air. When placing a Booking, the
                      identity of the Carrier (including the identity of the Carriers of any connecting flights) for
                      your Booking will be made known to you. Please be aware that, due to circumstances beyond our
                      control, the operating Carrier of your flight may be different from the one displayed at the time
                      of your Booking. As such, it is important that you verify the identity of your operating Carrier
                      prior to your departure:</p>
                    <p>Services: means the Booking services provided by us at the Website in accordance with these Terms
                      that enables you to purchase Travel Products. The Services we provide are further detailed in
                      Section 2 of these Terms</p>
                    <p>Total Price: means the final price displayed immediately prior to the purchase of your Booking,
                      including any Additional Services selected by you (e.g. additional luggage, upgrades, meals, etc.)
                      during the Booking process</p>
                    <p>Travel Document: means any physical or digital document that is intended to confirm a contract
                      that we have arranged on your behalf with a Service Provider. These documents may be, but are not
                      limited to, hotel vouchers, airline itineraries/e-tickets, and activity tickets:</p>
                    <p>Travel Product: means the products, provided by Service Providers that we offer on behalf of the
                      Service Providers on our Website, namely accommodation, flights, activities, and any Additional
                      Services purchased during your Booking</p>
                    <p>Gooreo Account: means the registered account used to log in to the Website</p>
                    <p>Gooreo Wallet: means the wallet associated with your Account on the Website used to store your
                      deposited funds or funds otherwise acquired by you</p>
                    <p>Website: means the website located at Gooreo.com and all associated subdomains at this
                      location.</p>
                    <h3>2 Services</h3>
                    <p>2.1 This section sets forth the contractual relationship between you and us on the basis of which
                      we will provide you with the Services related to the Travel Products that we sell to you in
                      exchange for the Total Price. The following are the Services that we provide to you:</p>
                    <p>
                      (i) Displaying information and pricing of Travel Products on the Website;
                    </p>
                    <p>
                      (ii) Provision of Website functions that enable you to make a Booking and purchase Travel Products
                      for the Total Price;
                    </p>
                    <p>
                      (iii) Facilitation of the agreement between the relevant Service Provider and you; and
                    </p>
                    <p>
                      (iv) Delivery of your Travel Documents resulting from your Booking.
                    </p>
                    <p>2.2 You agree to be bound by these Terms when you complete and send us your online order on the
                      Website in relation to your Booking and when we subsequently confirm the acceptance of your
                      payment. Where your Booking is completed via any other method other than through the Website (e.g.
                      where one of our employees places the order on your behalf following email correspondence), these
                      Terms will be entered into on the basis of your explicit and binding offer to purchase a Booking
                      following the provision of all relevant information in relation to your Booking by us to you.</p>
                    <p>2.3 By purchasing the Booking, you express and warrant that you have the legal capacity to enter
                      into and be bound by these Terms and that you have read and understood the terms and conditions
                      and any other relevant materials of the relevant Service Provider(s).</p>
                    <p>2.4 You acknowledge that the information you provide when placing a Booking is accurate and up to
                      date, and you agree that we shall bear no liability for any inaccurate information provided
                      (except where the inaccuracy or error has resulted due to a fault on our part). It is your
                      responsibility to ensure that any information provided by you to us during the Booking process,
                      including, without limitation, passenger details, travel dates, departure point and destination,
                      is correct and that any documents you receive from us in relation to your Booking are in
                      order. </p>
                    <p>2.5 If you wish to derive the benefits associated with a particular frequent flyer program when
                      purchasing a Booking for a flight with a Carrier, you acknowledge that it is your responsibility
                      to provide us with your frequent flyer number, either during the Booking process in the relevant
                      section of the Booking form on the Website or via correspondence with us at any time before your
                      departure. Please note that we will not be liable for any foregone frequent flyer benefits under
                      any circumstances.</p>
                    <p>2.6 Please note that these Terms are separate from any terms, conditions, or agreements that you
                      enter into with the Service Provider(s) or any other third party responsible for the fulfilment of
                      your Travel Product. By proceeding with your Booking, you acknowledge that these are separate,
                      mutually independent legal relationships conducted with different parties.</p>
                    <p>2.7 We reserve the right to change or remove any Travel Products advertised on the Website
                      without notice, including, but not limited to, where the Service Provider’s offer is changed or
                      removed, or where a computer malfunction or human error has occurred. This includes prices quoted
                      to you via email or any other method of correspondence. Our Website will subsequently be updated
                      accordingly to reflect the accurate availability, information, and price of the Travel Product(s).
                      In some cases, the price of a Travel Product may change between the time that you select the
                      Travel Product and the time when you attempt to complete the purchase of your Booking. If a change
                      of this nature occurs, we will take reasonable measures to ensure that you are aware of this
                      change before you purchase the Booking. Please note that we are under no obligation to provide you
                      with reimbursement or refund of any kind if the price of a Travel Product becomes lower following
                      the successful completion of your Booking.</p>
                    <h3>3 Gooreo Account</h3>
                    <P>3.1 In order to use some aspects of the Services, including to successfully purchase a Booking,
                      you will be required to create a Gooreo Account. As part of the registration process, you will
                      need to provide certain information that enables us to create your Gooreo Account. You acknowledge
                      that it is your responsibility to ensure the confidentiality of your Gooreo Account login
                      credentials, including your password, and that you will be solely responsible for any actions
                      conducted on your Gooreo Account (including any Bookings). You agree to notify us immediately of
                      any unauthorized use of your Gooreo Account.</P>
                    <P>3.2 We may terminate or suspend access to your Gooreo Account or your ability to use the
                      Services, in whole or in part, for any reason at our sole discretion and without notice or
                      liability of any kind. Any such termination or suspension could prevent you from accessing your
                      Gooreo Account temporarily or permanently.</P>
                    <P>
                      3.3 You may opt to terminate your Gooreo Account at any time. By terminating your Gooreo Account,
                      you agree to discontinue your use of any and all parts of the Services. To terminate your Gooreo
                      Account, please Contact Us.</P>
                    <h3>4 Travel Requirements</h3>
                    <p>4.1 Our staff may be able to assist you with information pertaining to passports, visas, and
                      other travel documents you may require for your trip. Please be aware that, while we can assist in
                      providing this information to you, the information that we provide is general in nature and it is
                      ultimately your responsibility to ensure that you have the relevant documents required for your
                      trip. Any penalties, fines or additional expenses incurred as a result of the failure to obtain
                      these documents will be incurred solely by you (subject to our liability).</p>
                    <p>4.2 Any customer who successfully purchases a Booking for a flight with a Carrier must have a
                      valid passport. We assume that a valid passport is held by every person included in the Booking.
                      If any of the travellers does not have a valid passport, you must Contact Us immediately. Please
                      be aware that, in addition to holding a valid passport, your passport is required by many
                      countries to be valid for at least six (6) months from the date of return, and some countries may
                      require passports to be machine-readable. In addition to ensuring that all parties in the Booking
                      have a valid passport, all parties must also obtain visas and re-entry permits that satisfy
                      immigration requirements and any other relevant laws, including for countries that you may only be
                      transiting through.</p>
                    <p>4.3 If you have any special requirements, such as dietary requirements, seating arrangements,
                      disabled access, or any other special requests, please make these requests during the Booking
                      process (if available) or Contact Us immediately following the purchase of your Booking to allow
                      as much time as possible for your requirements to be met. Please note that, while we will
                      endeavour to accommodate your request(s), we cannot guarantee that your request(s) will be
                      honoured by the Service Provider.</p>
                    <h3>5 Travel Documents</h3>
                    <p>5.1 Your Travel Document(s) will be delivered to you electronically to the email address provided
                      by you at the time of your Booking. You acknowledge that it is your responsibility to ensure that
                      the email address you provide is entered correctly and that you will immediately notify us where
                      an error has been made on your part. We shall not be liable for any delay or failure to deliver
                      your Travel Document(s) where the delay or failure was made on your part. The Service Provider or
                      other relevant authorities may require you to present any of the following documents in either
                      electronic or printed format: your itinerary/e-ticket(s), boarding pass(es), visa(s), passport, or
                      any other relevant document.</p>
                    <p>5.2 Certain conditions or restrictions may be imposed on Travel Documents, such as being
                      non-refundable or subject to amendment or cancellation fees. Please ensure that you carefully read
                      each Travel Document upon its receipt to ensure that it is in order.</p>
                    <p>5.3 Travel Documents are non-transferable. Where a Booking for a flight with a Carrier is
                      purchased, the Travel Document must be issued in the name of the passport/photo identity holder.
                      Please ensure that the details on your Booking are correct and confirm that the name, date of
                      birth, and document number of all travellers is correct. If there is a mistake on your Travel
                      Document, such as an incorrect name or date of birth, please Contact Us immediately.</p>
                    <p>5.4 Any health requirements or precautions associated with your trip are your responsibility.
                      Please ensure that you have any relevant vaccination documentation if you are travelling to a
                      country requiring proof of vaccination. It is also generally good practice to visit your general
                      practitioner or vaccination clinic before your trip to see if any vaccinations are required for
                      your travel.</p>
                    <h3>6 Travel Insurance and Travel Advice</h3>
                    <p>6.1 Unforeseen circumstances may arise when travelling. For this reason, it is recommended that
                      you consider whether you require travel insurance, especially for international travel. It is
                      recommended that your travel insurance cover medical expenses, personal injury, death, loss of
                      luggage, loss of money, cancellation, and personal liability insurance. Please note that we do not
                      sell travel insurance, though we may be able to provide you with general information regarding
                      travel insurance. We shall bear no liability for the general information that we provide or for
                      your purchase or non-purchase of travel insurance.
                    </p>
                    <p>6.2 General travel advice and country-specific travel advice is provided by most countries. While
                      we may be able to provide general information to you in relation to country-specific travel
                      advice, we make no representations as to the accuracy of this information and shall bear no
                      liability for any resulting damages. By purchasing a Booking, we assume that your country of
                      departure permits you to visit the destination of your Booking or any transits in between. We
                      shall bear no liability for any cancellation fees or other fees that you may incur as a result of
                      your inability to use your Booking.</p>
                    <h3>7 Payments</h3>
                    <p>7.1 Your price will only be guaranteed once the Total Price for your Booking has been paid in
                      full. Please note that taxes are subject to change and the tax is finalised when your Travel
                      Document is issued. You also acknowledge that Gooreo.com is not subject to VAT, and therefore no
                      VAT invoice will be issued to you following the successful completion of your Booking.</p>
                    <p>7.2 We accept payment via selected cryptocurrencies, selected credit and debit cards, bank wire
                      transfers, and other methods (collectively “Payment Method”). Please see our Payment Options for
                      an up-to-date list of current options.
                    </p>
                    <p>7.3 Cryptocurrency Payments. A Booking purchased with cryptocurrency will only be ticketed once
                      the transaction has received one (1) confirmation on the relevant blockchain. Bookings purchased
                      with cryptocurrency are generally subject to miner fees, which are received by miners (or nodes)
                      that confirm your transaction on the blockchain, not Gooreo.com. The miner fee applicable to your
                      Booking will depend on network conditions and the miner fee selected by you at the time of the
                      cryptocurrency transaction. Please further take note of our cryptocurrency refund policy in clause
                      8.6 herein.</p>
                    <p>7.4 Credit and Debit Cards. By agreeing to these Terms, you authorise us to charge your
                      designated credit or debit card for all fees incurred by you in relation to the Services provided
                      by us. Please note that a surcharge may be incurred on credit and debit card payments. The
                      applicable surcharge will be displayed to you prior to the completion of your Booking. By
                      purchasing a Booking using a credit or debit card, you acknowledge that we will not be liable for
                      any additional costs you incur associated with exchange rates, bank fees, credit card fees or
                      debit card fees. For clarity, Gooreo.com will not refund any such charge to your credit card,
                      debit card, or Gooreo Wallet.</p>
                    <p>7.5 We reserve the right to delegate the management of all or part of our electronic payment
                      collection (Visa, Mastercard, American Express, cryptocurrencies) to our subsidiaries or
                      third-party payment providers. Any fees incurred by you as a result of purchasing a Travel Product
                      from us are non-refundable, subject to your rights under applicable law.
                    </p>
                    <p>7.6 You agree that our payment providers can securely store your payment details, which can
                      subsequently be used by us for later payment or refunds of your Booking. Upon submitting your
                      Booking form, you authorise us to facilitate reservations on your behalf, including making payment
                      arrangements with Service Providers and any third parties related to the provision of your
                      Booking. </p>
                    <p>7.7 For payments made online via the Website, you will not pay the Service Provider for the
                      Travel Product mentioned on the Travel Document. Only Bookings made using direct payment with a
                      Service Provider (e.g. in person at a hotel) will allow you to pay for your reservation once you
                      arrive at the establishment. These two distinct payment options are clearly displayed during the
                      Booking process.</p>
                    <p>7.8 For Bookings made via a Gooreo.com employee, a deposit (or deposits) may be required. The
                      amount of the deposit and number of deposits (for multiple Bookings) will be communicated to you
                      via a Gooreo.com employee. Should an amendment or cancellation be made by you, your deposit(s) may
                      be forfeited (subject to your rights under applicable law). Since final payments for Bookings with
                      Service Providers are variable, we will advise you when final payment for your Booking is due.</p>
                    <p>7.9 Exceptionally, Gooreo.com may, at sole discretion, offer you the option to pay a low deposit
                      for certain Bookings with Accommodation Providers (“Early Booking”). This will be indicated next
                      to the specific Travel Product in your search results. An Early Booking requires a partial payment
                      at the time of reservation and confirmation and a secondary payment consisting of the total
                      balance due at a future point in time, as communicated on Gooreo.com and in your confirmation
                      email. The later payment will be automatically collected on the due date. You must make sure that
                      the Payment Method is valid and has enough funds at the time of the balance payment. In case the
                      Payment Method cannot be charged for whatever reason, you can pay the remaining balance using a
                      valid Payment Method. If the payment is not authorised in due time, the Booking will be
                      cancelled.</p>
                    <p>7.10 If your chosen Travel Product is not available and we provide you with an alternative
                      option, your Payment Method will only be charged if you accept the new Travel Product and the new
                      rate offered. If you do not accept the given alternative, no charge will be made to your Payment
                      Method by Gooreo.com.</p>
                    <p>7.11 Any personal information that you submit to us in relation to the purchase of your Booking
                      will be protected by our secure SSL payment gateway server, which uses 256-bit security technology
                      to protect your online order information. This technology encrypts all information and all
                      personal information passed from you through our Website.</p>
                    <h3>8 Changes, Cancellations and Refunds</h3>
                    <p>8.1 The applicable cancellation policy of the Travel Product (except in the instance of flights
                      notwithstanding the definition of a Travel Product defined herein) is displayed prior to your
                      completion of the online Booking form. Please ensure that you read the cancellation policy
                      applicable to your selected Travel Product carefully as the cancellation policy varies between
                      Service Providers and the respective Travel Products that are offered. Any cancellation of a
                      flight Booking shall be subject to the Service Provider/Carrier’s cancellation terms/policy.</p>
                    <p>8.2 Voluntary changes for flight bookings can only be requested up to 72 hours before departure.
                      If you voluntarily cancel or make changes to your Booking, the Service Provider may charge a
                      cancellation or change fee. Voluntary cancellations or changes to Bookings cannot be reversed once
                      requested by you. Where a change or cancellation to a Booking is made, processing fees may be
                      applied by us in addition to the charges imposed by the Service Provider. If your flight booking
                      contains multiple passengers, any changes you make will apply to the itinerary of all passengers
                      relevant to that Booking; if you wish to change the Booking for some (not all) passengers, you
                      acknowledge that it is your sole responsibility to make these arrangements directly with the
                      Carrier. Please be aware that some Bookings may be non-refundable, and in this instance we cannot
                      provide you with a refund. Where you request a voluntary cancellation on a Booking that may be
                      refundable, please be aware that we cannot calculate the refund amount that you will receive at
                      the time of your cancellation request. If you arrive late on your scheduled Booking date (as
                      stated on your Travel Document), decide to leave before the conclusion of the Travel Product (as
                      stated on your Travel Document), or fail to arrive (no-show), Gooreo.com will not issue any
                      refunds. Please note that, in some cases, you may be charged for the cancellation fee in
                      accordance with the Service Provider’s cancellation policy if the amendment is made after a
                      certain date or falls under an exception. When you cancel a flight Booking, the Booking will be
                      cancelled for all passengers booked under the same Booking.
                    </p>
                    <p>8.3 If your Booking is cancelled or changed by the Service Provider or a related third party, we
                      will endeavour to notify you of the corresponding cancellation or change as soon as possible.
                      Please note that we are not liable for the consequences of any cancellations or changes made by
                      these third parties.</p>
                    <p>8.4 If you wish to modify the dates of your Booking with an Accommodation Provider, please log in
                      to your Gooreo Account and use the "Change my dates" form subject to the Booking term allowing for
                      such changes. For other types of modifications to Bookings with Accommodation Providers, or to
                      modify Bookings with Carriers or Activity Providers, please Contact Us subject to a Booking that
                      allows modifications. Reservations not amended through Gooreo.com will become subject to a 100%
                      charge to be applied.</p>
                    <p>8.5 In the event of a confirmed Booking with an Accommodation Provider becoming unavailable
                      before the check-in date, Gooreo.com will offer a full refund. Where we have prior notice, we will
                      contact you by email. However, upon check-in, if the original Travel Product specified in your
                      Travel Document is closed, overbooked, or has maintenance problems and cannot provide the room(s)
                      booked, you accept that the Accommodation Provider is responsible for finding you alternative
                      accommodation of a similar standard and/or for providing you with a refund. You acknowledge that
                      Gooreo.com will not be liable to you and will not provide you with a refund or alternative
                      accommodation.</p>
                    <p>8.6 If you are entitled to a refund, either in part or in full, the refund amount that you are
                      entitled to will be processed upon our receipt of cleared funds from the Service Provider. Where a
                      refund is properly due to you and we have received your cancellation or amendment email, we will
                      refund your credit card, debit card or Gooreo Wallet within five (5) days after we have received
                      the applicable refund sum from the respective Service Provider. In the event that your initial
                      payment for Booking/s were made through cryptocurrency (except for payments made with Binance Pay
                      and Crypto.com pay), due to cryptocurrency price/value volatility and regulatory requirements, we
                      are only able to refund cryptocurrency payments in part or in full with Gooreo.com travel credits.
                      Please refer to the following page for further information on the modes in which refunds would be
                      made where applicable.</p>
                    <h3>9 Hotel Room Types</h3>
                    <p>9.1 The following room and item descriptions on the Gooreo.com website shall have the following
                      meaning:</p>
                    <p>
                      Single room: one single bed. Suitable for one person.
                    </p>
                    <p>
                      Twin room: two single beds in the same room. Suitable for two people.
                    </p>
                    <p>
                      Double room: one double bed. Suitable for two people.
                    </p>
                    <p>
                      Triple room: twin or double bed/s plus extra bed, or two double beds. Most hotels do not have one
                      full size bed for each guest in their triple rooms.
                    </p>
                    <p>
                      Twin/double room for sole/single use: two single beds or one double bed in the same room, to be
                      occupied by one person.
                    </p>
                    <p>
                      Quadruple room: twin or double bed/s plus extra beds, or two double beds. Most hotels do not have
                      one full size bed for each guest in their quadruple rooms.
                    </p>
                    <p>
                      Twin/Double + child: twin or double room with an extra bed suitable for a child aged between two
                      (2) and twelve (12) years.
                    </p>
                    <p>
                      Cot/cradle or crib: suitable for a child under the age of two (2) years.
                    </p>
                    <h3>10 Acceptable Use</h3>
                    <p>
                      10.1 In using the Website, you agree and warrant that you:
                    </p>
                    <p>
                      (i) will use the Website strictly for personal and non-commercial purposes only and you
                      acknowledge that use of the Website for any other purpose is prohibited;
                    </p>
                    <p>
                      (ii) will not gather, extract, reproduce and/or display on any other website or other online
                      service, any material on or from the Website, specifically including information relating to
                      Travel Product pricing and availability, whether using robots, spiders, or other "screen scraping"
                      software or system used to extract data;
                    </p>
                    <p>
                      (iii) will not use the Website to provide Gooreo.com’s Bookings or details of Travel Product
                      pricing and availability or other information to any other persons or organisations, other than
                      for your personal and non-commercial use;
                    </p>
                    <p>
                      (iv) will not use the Website for any purpose that is either unlawful or not allowed under these
                      Terms.
                    </p>
                    <p>
                      (v) will not commit any act that would constitute a breach of either the privacy (including
                      uploading private or personal information without an individual’s consent) or any other of the
                      legal rights of individuals;
                    </p>
                    <p>
                      (vi) will not use the Website while impersonating another person;
                    </p>
                    <p>
                      (vii) will not use the Website to defame or libel us, our employees or other individuals;
                    </p>
                    <p>
                      (viii) will not transmit any viruses, Trojan horses, worms, or other items that may cause damage
                      to our property or the property of other individuals;
                    </p>
                    <p>
                      (ix) will not post or transmit to this Website any non-authorised material including, but not
                      limited to, material that is, in our opinion, likely to cause annoyance, or which is defamatory,
                      racist, obscene, threatening, pornographic or otherwise, or which is detrimental to or in
                      violation of our systems or a third party’s systems or network security;
                    </p>
                    <p>
                      (x) will not tamper with, hinder the operation of, or make unauthorised modifications to the
                      Website (including deleting data from the Website without our permission);
                    </p>
                    <p>
                      (xi) will not breach any third party’s rights (including intellectual property rights and
                      obligations of confidentiality owed to third parties) or infringe any laws in any jurisdiction in
                      using the Website; and
                    </p>
                    <p>
                      (xii) will not attempt to gain unauthorised access to the Website, user accounts, or computer
                      networks or systems connected to the Website through hacking, password mining, or any other means.
                    </p>
                    <p>10.2 To make a Booking on the Website, you must be at least 18 years of age (or the legal minimum
                      age in countries where the legal minimum age is greater than 18). By entering into this agreement,
                      you confirm that you possess the legal authority and necessary minimum age to use the Website in
                      accordance with these Terms. Gooreo.com will not compensate you or anyone else for any expenses
                      incurred as a result of minors using the Services, including any Bookings placed by minors.</p>
                    <p>10.3 Unless otherwise indicated, we own, or license from third parties, all rights, title, and
                      interest (including, without limitation, copyright, designs, patents, trademarks and other
                      intellectual property rights) contained on the Website and in all of the material (including,
                      without limitation, all text, graphics, logos, audio and software) made available on the Website
                      (“Content”). Your use of the Website and use of and access to any Content does not grant or
                      transfer any rights, title, or interest to you in relation to the Website or the Content. However,
                      we do grant you a licence to access the Website and view the Content on the terms and conditions
                      set out in these Terms and, where applicable, as expressly authorised by us and/or our third party
                      licensors. Any reproduction or redistribution of the Website or Content is prohibited and may
                      result in penalties. In addition, you must not copy the Content to any other server, location or
                      support for publication, reproduction, or distribution is expressly prohibited. All other use,
                      copying, or reproduction of the Website, the Content, or any part of the Website or Content is
                      prohibited, except to the extent permitted by law.</p>
                    <p>10.4 We will determine whether there has been a breach of these Terms through your use of the
                      Website. If a breach of this Policy has occurred, we may take such action as we deem appropriate,
                      including denying you access to the Website, bringing legal proceedings against you, or disclosing
                      such information to law enforcement authorities as we deem appropriate.</p>
                    <p>10.5 Our Website may contain links to other websites operated by third parties. Those links are
                      provided for convenience and may not remain current or be maintained. We are not liable to you if
                      interference with or damage to your computer systems occurs in connection with the use of the
                      Website or any linked website. You must take your own precautions to ensure that whatever you
                      select for your use from our Website is free of viruses or any other malware that may interfere
                      with or damage the operations of your computer systems.</p>
                    <p>10.6 If we allow you to post any information to the Website, we have the right to take down this
                      information at our sole discretion and without notice.</p>
                    <p>10.7 By using the Website, or by entering into correspondence with us through email, telephone,
                      or other communication tools, you agree that we may communicate with you through methods
                      including, but not limited to, email, telephone, messaging applications, and in-app notifications.
                      If you wish to unsubscribe from marketing emails, please click ‘Unsubscribe’ at the footer of any
                      of our marketing emails or Contact Us. Please note that, if you unsubscribe from our marketing
                      emails, you will still receive non-marketing emails from us (including emails relating to any
                      Bookings that you place).</p>
                    <p>10.8 If you do not agree with these Terms, you must not use the Website.</p>
                    <h3>11 Agency</h3>
                    <p>11.1 The Services that we provide to you are in the capacity of an agent with permission to sell
                      products on behalf of various Service Providers. We have an obligation to you to finalise the
                      Booking on your behalf and arrange contracts between you and Service Providers. Any Travel
                      Products that you purchase in connection with your Booking under these Terms is provided to you by
                      third parties, not Gooreo.com </p>
                    <p>11.2 Though we endeavour to select reputable third parties to provide your Travel Product(s) to
                      you, the provision of Travel Products is outside of our control and scope of liability. Any
                      arrangement made by us on your behalf is subject to the terms and conditions of the Service
                      Provider in charge of delivering the purchased Travel Product(s) to you. The terms and conditions
                      of your relevant Service Provider can be provided to you on request. If there are issues with the
                      provision of your Travel Product(s), or the Service Provider is unable to provide the contracted
                      services, unless there has been a fault on our part, your legal rights are against the specific
                      Service Provider, not against Gooreo.com.</p>
                    <h3>12 Representation</h3>
                    <p>12.1 We represent and warrant to you that our Services: (a) will be provided with due care and
                      skill; (b) will be reasonably fit for the specified purpose; (c) can be reasonably expected to
                      achieve the desired result; and (d) will be provided within a reasonable time.</p>
                    <p>12.2 EXCEPT AS DESCRIBED IN THE PRECEDING ITEM ABOVE, ALL EXPRESS OR IMPLIED CONDITIONS,
                      REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE OR NON-INFRINGEMENT ARE DISCLAIMED, EXCEPT TO THE EXTENT THAT THESE DISCLAIMERS
                      ARE HELD TO BE LEGALLY INVALID. WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES ABOUT THE
                      SERVICES THAT WE SELL AND THEIR AVAILABILITY, SAFETY, OR RELIABILITY (EXCEPT IN RELATION TO
                      NON-EXCLUDABLE OBLIGATIONS).</p>
                    <h3>13 Limitation of Liability and Indemnification</h3>
                    <p>13.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT DOES Gooreo.COM NOR ANY OF ITS
                      DIRECTORS, EMPLOYEES, AGENTS, OR RELATED BODIES CORPORATE ACCEPT LIABILITY FOR ANY DIRECT,
                      INDIRECT, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING, WITHOUT
                      LIMITATION, DELAY, INCONVENIENCE OR ADDITIONAL EXPENSE WHICH MAY BE SUFFERED DUE TO (WITHOUT
                      LIMITATION) YOUR USE OF, OR THE INABILITY TO USE, OUR SERVICES, THIRD PARTY PROVIDERS, FORCE
                      MAJEURE, OR EVENTS THAT WE CANNOT CONTROL OR WHICH COULD NOT HAVE BEEN PREVENTED BY REASONABLE
                      DILIGENCE ON OUR PART.</p>
                    <p>13.2 IF IT IS DETERMINED THAT Gooreo.COM IS LIABLE FOR ANY LOSS OR DAMAGE THAT ARISES OUT OF, OR
                      IS CONNECTED TO, YOUR USE OF OUR SERVICES, OUR MAXIMUM LIABILITY UNDER THIS AGREEMENT WILL BE
                      LIMITED TO THE REISSUE OF YOUR BOOKING (OR CORRECTING THE DEFECTIVE PART OF YOUR BOOKING), OR THE
                      PROVISION OF CREDIT OR REFUND FOR THE TOTAL PRICE OF YOUR BOOKING AT THE PRO RATA CONTRACT
                      RATE.</p>
                    <p>13.3 IN THE EVENT THAT THIS PROVISION IS UNENFORCEABLE IN YOUR JURISDICTION, THE LIMITATIONS,
                      EXCLUSIONS AND DISCLAIMERS WILL APPLY TO THE MAXIMUM EXTENT POSSIBLE, AS PERMITTED BY APPLICABLE
                      LAW.</p>
                    <p>13.4 You agree to fully indemnify us and our directors, officers, employees, agents, successors,
                      assigns and/or affiliates (including our affiliated, subsidiary and/or parent companies) for any
                      and all (without limitation) damages, costs, expenses, liabilities, fines and or penalties,
                      including attorney fees, which arise as a result of your breach of this Terms or any other terms
                      supplemental or related to Gooreo.com, your violation of any applicable law or the rights of any
                      third parties and/or any other act or omission by you which is in relation to the subject matter
                      of the Terms herein.</p>
                    <h3>14 Miscellaneous</h3>
                    <p>14.1 Changes to the Website. We may make improvements to, and/or change, the Website at any time.
                      Gooreo.com, its suppliers, and any of its related third parties make no representation about the
                      suitability of the information, software, products and services contained on the Website for any
                      purpose, and the inclusion or offering for sale of any products or services on this Website does
                      not constitute any endorsement or recommendation of such products or services by Gooreo.com,
                      suppliers, Service Providers, or any of related third parties. All such information, software,
                      products and services are provided "as is" and without warranty of any kind.</p>
                    <p>
                      14.2 Our Connection to You: You agree that, by using our Services, no joint venture, agency,
                      partnership, or employment relationship exists between you and us.
                    </p>
                    <p>14.3 Supplier Relations. Gooreo.com’s suppliers and Service Providers are independent contractors
                      and not agents or employees of Gooreo.com. Gooreo.com is not liable for a supplier’s acts, errors,
                      omissions, representations, warranties, breaches or negligence or for any losses, personal
                      injuries, death, property damage, or other damages or expenses arising out of any act or omission
                      of a supplier.
                    </p>
                    <p>14.4 Destination Risk. By offering Travel Products in international destinations on the Website,
                      Gooreo.com does not warrant that travel to such destinations is without risk. Accordingly,
                      Gooreo.com will not be liable for any damages or losses that may be incurred by you as a result of
                      you travelling to such destinations. You are strongly advised to check the relevant travel
                      advisories issued by your country for any destination you are visiting and to take the necessary
                      precautions. Gooreo.com cannot be held liable for any loss incurred in the event of your failure
                      to hold the necessary passports, visas and documentation for your journey. You are advised to
                      check with the relevant embassies, consulates and/or visa departments from which you originate. It
                      is your responsibility to obtain the necessary documents.</p>
                    <p>14.5 Taxes. The tax charge on any Travel Product purchase(s) via Gooreo.com is a recovery of all
                      applicable transaction taxes that Gooreo.com pays its suppliers in connection with your travel
                      arrangements. Taxability and the appropriate tax rate can vary greatly by location. Gooreo.com's
                      actual tax cost paid to the supplier may vary from the tax recovery charge, depending upon the
                      rates and taxability in effect at the time. You acknowledge that Gooreo.com is under no obligation
                      to account to you or refund you in this regard.</p>
                    <p>
                      14.6 Currency Rates. The currency rates displayed on the Website are not verified or guaranteed by
                      Gooreo.com as being accurate and should be used as guidelines only. Rates are not guaranteed to be
                      updated every day and actual rates may vary.
                    </p>
                    <p>14.7 Accuracy of Information. The information, software, products, and services published on the
                      Website may include inaccuracies or errors, including pricing errors. Gooreo.com and its
                      affiliates do not guarantee the accuracy of any part of the Website and disclaim all liability for
                      any errors or other inaccuracies relating to the information, pricing and description of the
                      Travel Products on the Website. In addition, Gooreo.com expressly reserves the right to correct
                      any pricing errors on our Website on pending and/or confirmed reservations made under an incorrect
                      price. In such an event, if available, we will offer you the opportunity to keep your pending
                      Booking at the correct price or we will cancel your Booking without penalty.</p>
                    <p>
                      14.8 The information, software, Travel Products, and Services that are published, displayed or
                      used on this website may include inaccuracies or typographical errors and Gooreo.com and its
                      suppliers will not, therefore, be liable for any inaccuracies displayed on the website relating
                      to, without limitation: descriptions of Travel Products; photographs of Travel Products; property
                      of Accommodation Providers, or; lists
                    </p>
                    <p>
                      14.9 Any ratings of Travel Products displayed on the Website are intended only as general
                      guidelines. Gooreo.com does not, therefore, guarantee the accuracy of the ratings, which may
                      change from time to time. Any reliance you place on such ratings is at your own entire risk.
                    </p>
                    <p>14.10 Our Rights. We reserve the right not to accept customers or Bookings (or, in exceptional
                      cases, to cancel confirmed ones) at our discretion and for whatever (legal) reason without the
                      need to justify such refusal. Typical reasons for rejecting a customer or Booking include, but are
                      not limited to: Service Provider request, breach of the Terms of Use, trade or economic sanctions,
                      embargoes, legal restrictions, (suspicions of) fraud or theft, suspected criminal activity,
                      suspicious Bookings, submission by customer of misleading or erroneous information, credit card
                      problems, inappropriate behaviour, threats, insults, refusal to supply information, practical
                      obstacles, communication problems, obvious errors (see below), history, or blacklisting by
                      governments or international organizations. Where a Booking is rejected or cancelled by Gooreo.com
                      and a payment has already been made, you will receive a refund of the Total Price. We also reserve
                      the right to bar (“blacklist”) users from the Website, on a permanent or temporary basis, at our
                      discretion. Any such blacklisted user must not attempt to use the Website under any other name or
                      through any other user. You must also not create more than one Gooreo Account. In rare cases, we
                      may also have to cancel or reject a Booking due to “obvious errors”, independent of the origin of
                      such errors. For clarity, an obvious error is a mistake on the Website (e.g. in terms of price)
                      which a reasonable person would not consider to be normal. The amount charged will be reimbursed
                      without charge in such case. Whether to cancel or reject a Booking for this reason is in
                      Gooreo.com’s sole discretion.
                    </p>
                    <p>
                      14.11 Compliance with Law Enforcement. These Terms are subject to existing laws and legal
                      processes that Gooreo.com is subject to in delivering the Services and all other aspects of these
                      Terms. Nothing contained in these Terms limits our right to comply with law enforcement,
                      governmental or legal requests, or requirements relating to your use of the Services. To the
                      extent permitted by applicable law, you agree to bring any claim or cause of action arising from
                      or relating to your access or use of the Services within two (2) years from the date on which such
                      claim or action arose
                    </p>
                    <p>
                      14.12 Entire Agreement. These Terms, including any other relevant terms and conditions or policies
                      referenced herein, constitute the entire agreement between you and Gooreo.com with respect to the
                      Services. These Terms supersede all prior or contemporaneous communications (whether electronic,
                      oral, or written) between you and Gooreo.com
                    </p>
                    <p>14.13 Severability. If any provision of these Terms is deemed invalid or unenforceable, the
                      provision will be enforceable to the maximum extent permissible and the remaining provisions will
                      remain in full force and effect. Any invalid or ineffective provision shall be replaced with a
                      new, valid and effective one which corresponds to the intent and purpose of the provision that is
                      being replaced.</p>
                    <p>
                      14.14 Amendments to these Terms. These Terms may be amended at any stage without notice at our
                      sole discretion and will be effective immediately upon their publication on the Website. By
                      continuing to use the Services contained in these Terms, you agree to the changes set out in these
                      Terms. If you do not agree to any modification to these Terms, you should cease using our Services
                      immediately. We encourage you to review these Terms often to stay up to date with the current
                      terms and conditions that apply to your use of the Services.
                    </p>
                    <p>14.15 Data Processing. The information and personal data that you provide when purchasing a
                      Booking is processed in accordance with our Privacy Policy, which forms an inseparable part of
                      these Terms. By accepting these Terms, you confirm that you have read and understood our Privacy
                      Policy.</p>
                    <p>14.16 Force majeure. Our contractual obligations to you under these Terms will be hindered,
                      delayed, or prevented due to reasons of force majeure, which include, but are not limited to,
                      factual, technical, political, economic, meteorological circumstances, including but not limited
                      to acts of God, natural disasters, outbreak of disease, epidemics, pandemics, civil conflicts,
                      protests, riots, blackouts, strikes, wars, any governmental or regulatory action, outage or
                      restrictions of supplies of goods or services, restrictive travel orders and recommendations and
                      other circumstances that Gooreo.com cannot foresee or reasonably resolve. In instances of force
                      majeure, we are exempt from any derived legal responsibilities arising from such deficiencies or
                      non-compliances.</p>
                    <p>14.17 Governing Law. These Terms, the legal relations established under these Terms, and all
                      disputes arising out of or in connection with these Terms, are governed by the laws of the Cayman
                      Islands. You submit to the non-exclusive jurisdiction of the courts exercising jurisdiction of the
                      Cayman Islands.</p>
                    <p>14.18 Agreement. By completing a Booking, or by using any other part of our Services listed in
                      these Terms, you acknowledge that you are at least 18 years of age (or the legal minimum age in
                      countries where the legal minimum age is greater than 18), have the legal capacity to enter into a
                      legally binding agreement, and understand and agree to the above Terms and Conditions.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  mounted() {
  },
}
</script>