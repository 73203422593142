import {createRouter, createWebHistory} from "vue-router";
import VueCookies from 'vue-cookies';

import Index from "../views/frontend/Index"
import Verify from "../views/frontend/verify"
import error404 from "../views/frontend/page/404page"
import e404 from "../views/frontend/page/404page"
import PaymentCanceled from "../views/frontend/page/paymentcanceled"
import About from "../views/frontend/page/about/about"
import Cancelledproject from "../views/frontend/employer/project/cancelledproject/cancelled"
import Work from "../views/frontend/employer/project/completedproject/completed"
import RejectedProjects from "../views/frontend/employer/project/rejected"
import Changepassword from "../views/frontend/employer/setting/changepassword"
import PurchasesChat from "../views/frontend/employer/chat/purchaseschat"
import DealsChat from "../views/frontend/employer/chat/purchaseschat"
import SalesChat from "../views/frontend/employer/chat/saleschat"
import Chat from "../views/frontend/employer/chat/chat"
import Category from "../views/frontend/categories/category"
import ProjectsCategory from "../views/frontend/categories/projectscategory.vue"
import Dashboard from "../views/frontend/employer/dashboard/dashboard"
import Skills from "../views/frontend/employer/setting/deleteaccount"
import Withdrawal from "../views/frontend/employer/payment/depositfund"
import Faq from "../views/frontend/employer/faq"
import GooreoCoin from "../views/frontend/page/gooreocoin";
import Create from "../views/frontend/page/create";
import InvestInGooreo from "../views/frontend/page/investingooreo";
import WhyGooreo from "../views/frontend/page/whygooreo";
import Employeefavourite from "../views/frontend/employer/favourites/favourite"
import Forgotpassword from "../views/frontend/page/forgotpassword"
import Resetpassword from "../views/frontend/page/resetpassword"
import Freelancercancelledproject from "../views/frontend/freelancer/project/cancelled"
import Freelancerongoing from "../views/frontend/freelancer/project/ongoing"
import Freelancercompleted from "../views/frontend/freelancer/project/completed";
import Deals from "../views/frontend/freelancer/project/projectproposal"
import Freelancerverifyidentity from "../views/frontend/freelancer/verifyidentity"
import Viewinvoice from "../views/frontend/page/viewinvoice"
import addBalance from "../views/frontend/employer/addBalance"
import Termcondition from "../views/frontend/page/termcondition"
import Frontendregister from "../views/frontend/register"
import BasicSettings from "../views/frontend/employer/profilesetting"
import Cookieprivacypolicy from "../views/frontend/page/cookiepolicy"
import Employeeprivacypolicy from "../views/frontend/page/privacypolicy"
import Employeelogin from "../views/frontend/page/login"
import Employermanageproject from "../views/frontend/employer/manageproject"
import Balance from "../views/frontend/employer/milestones"
import Employerongoingprojects from "../views/frontend/employer/ongoingprojects"
import Employerpendingproject from "../views/frontend/employer/pendingproject"
import Services from "../views/frontend/section/services"
import ProjectCat from "../views/frontend/section/projects"
import Categories from "../views/frontend/projects/categories"
import AllProjects from "../views/frontend/projects/projects"
import Project from "../views/frontend/projects/project"
import Marketplace from "../views/frontend/marketplace/marketplace"
import Service from "../views/frontend/marketplace/service"
import EditService from "../views/frontend/dashboard/myservices/editservice"
import EditProject from "../views/frontend/dashboard/myprojects/projectsmanger/editproject"
import UserProfile from "../views/frontend/profile/profile"
import UserProjects from "../views/frontend/profile/projects"
import UserReviews from "../views/frontend/profile/reviews"
import UserJobs from "../views/frontend/profile/jobs"
import UserServices from "../views/frontend/profile/services"
import UserGallery from "../views/frontend/profile/gallery"
import Checkout from "../views/frontend/cart/checkout"
import ContactUs from "../views/frontend/contactus"
import Blogdetail from '../views/frontend/blog/detail/detail';
import Blog from '../views/frontend/blog/list/list';
import MobilePayment from '../views/frontend/employer/mobilepayment';
import FavouritesServices from '../views/frontend/page/favouriteservices';
import FavouritesProjects from '../views/frontend/page/favouriteprojects';
import Search from '../views/frontend/search';
import Staking from '../views/frontend/page/staking';
import Affiliate from '../views/frontend/page/affiliate';
import Notifications from '../views/frontend/page/notifications';
/************dashboard ***************/

import AwaitingReviewDeals from "../views/frontend/dashboard/myprojects/mydeals/tabs/awaitingreview"
import SuspendedDeals from "../views/frontend/dashboard/myprojects/mydeals/tabs/Suspended"
import AddService from "../views/frontend/dashboard/myservices/addservice"
import AddProject from "../views/frontend/dashboard/myprojects/projectsmanger/addproject"
import CancelledSales from "../views/frontend/dashboard/myservices/mysales/tabs/cancelled"
import CompletedSales from "../views/frontend/dashboard/myservices/mysales/tabs/completed"
import MissingSales from "../views/frontend/dashboard/myservices/mysales/tabs/missingdetail"
import SuspendSales from "../views/frontend/dashboard/myservices/mysales/tabs/suspended"
import ActiveServices from "../views/frontend/dashboard/myservices/servicesmanager/tabs/active"
import RejectedServices from "../views/frontend/dashboard/myservices/servicesmanager/tabs/rejected"
import PendingServices from "../views/frontend/dashboard/myservices/servicesmanager/tabs/pending"
import InactiveServices from "../views/frontend/dashboard/myservices/servicesmanager/tabs/initial"
import ActiveOrders from "../views/frontend/dashboard/manageorders/tabs/active"
import CancelledOrders from "../views/frontend/dashboard/manageorders/tabs/cancelled"
import CompletedOrders from "../views/frontend/dashboard/manageorders/tabs/completed"
import MissingDetailOrders from "../views/frontend/dashboard/manageorders/tabs/missingdetail"
import WaitingReviewOrders from "../views/frontend/dashboard/manageorders/tabs/waitingreview"
/************dashboard ***************/
import CryptoJS from "crypto-js";

function decrypt(text) {
    try {
        return CryptoJS.AES.decrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error(error)
    }
}

const routes = [
    {
        path: "/",
        name: "Freelancer's Platform",
        component: Index,
    },
    {
        path: "/verify",
        name: "Verify",
        component: Verify,
    },
    {
        path: "/404",
        name: "404",
        component: error404,
    },
    {
        path: "/search",
        name: "search",
        component: Search,
    },
    {
        path: "/user/staking",
        name: "Staking",
        component: Staking,
    }, {
        path: "/user/affiliate",
        name: "Affiliate",
        component: Affiliate,
    }, {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
    },
    {
        path: "/payment/canceled",
        name: "Payment canceled",
        component: PaymentCanceled,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    }, {
        path: "/contactus",
        name: "Contactus",
        component: ContactUs,
    },
    {
        path: "/user/projects-manager/cancelled",
        name: "Cancelled Projects",
        component: Cancelledproject,
    }, {
        path: "/user/projects-manager/rejected",
        name: "Rejected Projects",
        component: RejectedProjects,
    },
    {
        path: "/user/work",
        name: "My Work",
        component: Work,
    },
    {
        path: "/user/security",
        name: "Security",
        component: Changepassword,
    },
    {
        path: "/chats",
        name: "Chats",
        component: Chat,
    },
    {
        path: "/user/orders/:id",
        name: "Purchases Chat",
        component: PurchasesChat,
    },
    {
        path: "/add-new",
        name: "Add New",
        component: Create,
    },
    {
        path: "/user/deals/:id",
        name: "Deals Chat",
        component: DealsChat,
    },
    {
        path: "/user/sales/:id",
        name: "Sales Chat",
        component: SalesChat,
    },
    {
        path: "/category/:id",
        name: "Category",
        component: Category,
    },
    {
        path: "/projects-category/:id",
        name: "Projects Category",
        component: ProjectsCategory,
    },
    {
        path: "/user/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/user/skills",
        name: "Skills",
        component: Skills,
    },
    {
        path: "/user/withdrawal",
        name: "Withdrawal",
        component: Withdrawal,
    },
    {
        path: "/faq",
        name: "Faq",
        component: Faq,
    }, {
        path: "/gooreo-coin",
        name: "Gooreo Coin",
        component: GooreoCoin,
    }, {
        path: "/invest-in-gooreo",
        name: "Invest In Gooreo",
        component: InvestInGooreo,
    }, {
        path: "/why-gooreo",
        name: "Why Gooreo",
        component: WhyGooreo,
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/blog/:id',
        name: 'Blog Post',
        component: Blogdetail
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: Forgotpassword,
    },
    {
        path: "/reset-password",
        name: "reset password",
        component: Resetpassword,
    },
    {
        path: "/user/deals/cancelled",
        name: "Cancelled Deals",
        component: Freelancercancelledproject,
    },
    {
        path: "/user/deals/missing-details",
        name: "Missing Details",
        component: Freelancerongoing,
    },
    {
        path: "/user/deals/active",
        name: "Active Deals",
        component: Deals,
    },
    {
        path: "/user/verify-identity",
        name: "Verify Identity",
        component: Freelancerverifyidentity,
    },
    {
        path: "/view-invoice/:id",
        name: "View Invoice",
        component: Viewinvoice,
    },
    {
        path: "/user/add-balance",
        name: "Add Balance",
        component: addBalance,
    },
    {
        path: "/terms-condition",
        name: "Terms Condition",
        component: Termcondition,
    },
    {
        path: "/register",
        name: "Register",
        component: Frontendregister,
    },
    {
        path: "/projects",
        name: "All Projects",
        component: AllProjects,
    },
    {
        path: "/projects/:id",
        name: "Project cat",
        component: ProjectCat,
    },
    {
        path: "/project/:id",
        name: "Project",
        component: Project,
    },
    {
        path: "/categories/:id",
        name: "Categories",
        component: Categories,
    },
    {
        path: "/user/profile",
        name: "Basic Settings",
        component: BasicSettings,
    },
    {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: Employeeprivacypolicy,
    },
    {
        path: "/cookies-policy",
        name: "Cookies Policy",
        component: Cookieprivacypolicy,
    },
    {
        path: "/login",
        name: "Login",
        component: Employeelogin,
    },
    {
        path: "/user/projects-manager",
        name: "Projects Manager",
        component: Employermanageproject,
    },
    {
        path: "/user/balance",
        name: "User Balance",
        component: Balance,
    },
    {
        path: "/user/projects-manager/completed",
        name: "Completed Projects",
        component: Employerongoingprojects,
    },
    {
        path: "/user/projects-manager/pending",
        name: "Pending Projects",
        component: Employerpendingproject,
    },
    {
        path: "/services/:id",
        name: "Services",
        component: Services,
    },
    {
        path: "/marketplace",
        name: "Marketplace",
        component: Marketplace,
    }, {
        path: "/favourite-services",
        name: "Favourites Services",
        component: FavouritesServices,
    }, {
        path: "/favourite-projects",
        name: "Favourites Projects",
        component: FavouritesProjects,
    },
    {
        path: "/marketplace/:id",
        name: "Service",
        component: Service,
    },
    {
        path: "/profile/:id",
        name: "Profile",
        component: UserProfile,
    },
    {
        path: "/profile/:id/jobs",
        name: "Freelancer Jobs",
        component: UserJobs,
    },

    {
        path: "/profile/:id/services",
        name: "Freelancer Services",
        component: UserServices,
    },
    {
        path: "/profile/:id/reviews",
        name: "Freelancer Reviews",
        component: UserReviews,
    },
    {
        path: "/profile/:id/projects",
        name: "Freelancer Projects",
        component: UserProjects,
    },
    // {
    //     path: "/profile/:id/gallery",
    //     name: "Freelancer Gallery",
    //     component: UserGallery,
    // },
    {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
    },
    {
        path: "/user/services/:id/edit",
        name: "Edit Service",
        component: EditService,
    },
    {
        path: "/projects/:id/edit",
        name: "Edit Project",
        component: EditProject,
    },
    {
        path: '/:pathMatch(.*)*',
        name: "404",
        component: e404,
    },
    {
        path: '/mobile/payment',
        name: "mobilepayment",
        component: MobilePayment,
    },
    /***********Dashboard route *************/
    {
        path: "/user/deals/completed",
        name: "Completed Deals",
        component: Freelancercompleted,
    },
    {
        path: "/user/deals/awaiting-review",
        name: "Awaiting Review Deals",
        component: AwaitingReviewDeals,
    },
    {
        path: "/user/deals/suspended",
        name: "Suspended Deals",
        component: SuspendedDeals,
    },
    {
        path: "/user/add-service",
        name: "Add Service",
        component: AddService,
    },
    {
        path: "/user/add-project",
        name: "Add Project",
        component: AddProject,
    },
    {
        path: "/user/sales/active",
        name: "Active Sales",
        component: Employeefavourite,
    },
    {
        path: "/user/sales/cancelled",
        name: "Cancelled Sales",
        component: CancelledSales,
    },
    {
        path: "/user/sales/completed",
        name: "Completed Sales",
        component: CompletedSales,
    },
    {
        path: "/user/sales/missing-details",
        name: "Missing Details Sales",
        component: MissingSales,
    },
    {
        path: "/user/sales/suspend",
        name: "Suspend Sales",
        component: SuspendSales,
    },
    {
        path: "/user/services-manager",
        name: "Services Manager",
        component: ActiveServices,
    }, {
        path: "/user/services-manager/rejected",
        name: "Rejected Manager",
        component: RejectedServices,
    },
    {
        path: "/user/services-manager/pending",
        name: "Pending Services",
        component: PendingServices,
    },
    {
        path: "/user/services-manager/inactive",
        name: "Inactive Services",
        component: InactiveServices,
    },

    {
        path: "/user/orders/active",
        name: "Active Orders",
        component: ActiveOrders,
    },

    {
        path: "/user/orders/cancelled",
        name: "Cancelled Orders",
        component: CancelledOrders,
    },

    {
        path: "/user/orders/completed",
        name: "Completed Orders",
        component: CompletedOrders,
    },

    {
        path: "/user/orders/missing-detail",
        name: "Missing Detail Orders",
        component: MissingDetailOrders,
    },
    {
        path: "/user/orders/waiting-review",
        name: "Waiting Review Orders",
        component: WaitingReviewOrders,
    },
];
export const router = createRouter({
    history: createWebHistory("/"),
    linkActiveClass: "active",
    routes,
});
router.beforeEach((to, from, next) => {
    const logged = [
        'login',
        'reset-password',
        'register',
        'ref',
    ]
    const allowed = [
        '',
        'login',
        'reset-password',
        'services',
        'register',
        'projects',
        'marketplace',
        'profile',
        'blog',
        'forgot-password',
        'privacy-policy',
        'cookies-policy',
        'term-condition',
        'term-condition',
        'mobile',
        'projects-category',
        'category',
        'project',
        'search',
        'gooreo-coin',
        'faq',
        'about',
        'invest-in-gooreo',
        'why-gooreo',
        'profile',
        'ref',


    ]
    const affiliate = ['invitation']
    if (!VueCookies.get("loggedin")) {
        VueCookies.set('loggedin', false)

    } else if (affiliate.includes(to.fullPath.split('?')[1]?.split('=')[0])) {
        $cookies.set("inv", to.fullPath.split('?')[1].split('=')[1])
        next();
    } else if ($cookies.get("loggedin") == 'false' && !allowed.includes(to.path.split('/')[1])) {
        next('/login')
    } else if ($cookies.get("loggedin") == 'false' && allowed.includes(to.path.split('/')[1])) {
        next();
    } else if ($cookies.get("loggedin") == 'true' && logged.includes(to.path.split('/')[1])) {
        next('/')
    } else if ($cookies.get("loggedin") == 'true' && logged.includes(to.path.split('/')[1])) {
        next('/')
    } else if ($cookies.get("loggedin") == 'true' && logged.includes(to.path.split('/')[1])) {
        next('/')
    } else if ($cookies.get("loggedin") == 'true' && $cookies.get("user")) {
        const user = JSON.parse(decrypt($cookies?.get("user")));
        if (user.email_verified_at == null && to.path !== '/verify')
            next('/verify')
        if (user.email_verified_at != null && to.path === '/verify')
            next('/')
        else {
            next()
        }

    } else {
        $cookies.remove('generalSettings')
        $cookies.remove('home')
        $cookies.remove('cart')
        $cookies.remove('cartcount')
        $cookies.set('loggedin', false)
        $cookies.remove('token')
        $cookies.remove('user')
        $cookies.remove('menuHeader')
        location.replace('/')
    }
    let documentTitle = `${process.env.VUE_APP_TITLE}  | ${to.name}`
    if (to.params.id) {
        documentTitle += ` - ${to.params.id}`
    }
    document.title = documentTitle

});
