<template>
  <div class="chat-page">
    <!-- Main Wrapper -->

    <!-- Header -->
    <layout-accountheader></layout-accountheader>
    <!-- /Header -->
    <div class="bread-crumb-bar">
      <div class="container">
        <div class="row align-items-center inner-banner">
          <div class="col-md-12 col-12 text-center">
            <div class="breadcrumb-list">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/user/dashboard">Dashboard
                    </router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item">Chats</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="chat-window pe-3">
      <!-- Chat Left -->
      <div class="chat-cont-left">
        <div class="chat-users-list ">
          <div class="chat-scroll">
            <a v-if="!loading" v-for="(chat ,index) in chats.data" :key="index"
               @click="readMsh(chat.id); this.activeChat =index; zIndex=2"
               :class="activeChat=== index? 'active-chat':''" class="media  d-flex"
               @click.prevent="getChat(chat.conversation_id, getName(chat))">
              <div class="media-img-wrap flex-shrink-0">
                <div class="avatar ">
                  <img
                      :src="getAvatar(chat)?.avatar ?? 'https://icon-library.com/images/default-user-icon/default-user-icon-8.jpg'"
                      alt="User Image" class="avatar-img rounded-circle">
                </div>
              </div>
              <div class="media-body flex-grow-1">
                <div>
                  <div class="user-name">{{
                      getName(chat)?.name ?? 'Deleted user'
                    }}
                  </div>
                  <div class="user-last-chat">{{
                      chat.conversation.last_message ==
                      null ? '' : chat.conversation.last_message.body
                    }}
                  </div>
                </div>
                <div>
                  <div
                      v-if="chat.conversation.last_message != null && chat.conversation.last_message.is_seen !== 1 && !hasBeenRaed(chat.id)"
                      class="badge bgg-yellow badge-pill">N
                  </div>
                  <div class="last-chat-time block">
                    {{
                      chat.conversation.last_message == null ? '' :
                          chat.conversation.last_message.created_at.split('T')[0]
                    }}
                    <p class="orange-text">{{
                        chat.conversation.last_message == null ? '' : chat.conversation.last_message.created_at.split('T')[1].split('.')[0].split(':')[0] + ':' +
                            chat.conversation.last_message.created_at.split('T')[1].split('.')[0].split(':')[1]
                      }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div :style="`z-index:${zIndex}`" v-if="chat != null" class="chat-cont-right row">
        <chat-body @changeIndex="zIndex=0 ;activeChat=-1 " :chatuser="chatuser" :chat="chat" :localuser="localuser"/>
      </div>
      <div :style="`z-index:${zIndex}`" v-else class="chat-cont-right">
        <div class="text-center" style="margin-top: 25%;">
          <h4 v-if="!opining">Select a chat from the left panel to show messages</h4>
        </div>
      </div>
      <loader v-if="opining"></loader>
    </div>
  </div>
</template>
<script>
import ChatBody from "../../components/chat-body";

export default {
  components: {ChatBody},
  inject: ['api'],
  methods: {
    hasBeenRaed(msgid) {
      return this.readMsgs.includes(msgid)
    },
    readMsh(msgid) {
      this.readMsgs.push(msgid)
      this.api.post('readAllmsg', {
        id: msgid
      })
      this.hasBeenRaed(msgid)
    },
    async search(input) {
      this.loading = true
      this.api.get('user/messages').then(res => {
        if (res.status == 'success') {
          let c = res.data.data.chats
          c.forEach(e => {
            if (!this.getName(e).name.contains(input)) {
              c.splice(c.indexOf(this.getName(e)), 1)
            }
          })
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    getName(chat) {
      let r = {}
      chat.conversation.participants.forEach(element => {
        if (element.messageable_id != this.localuser.id) {
          r = element.messageable
          // console.log(r)
        }
      });
      return r
    },
    getAvatar(chat) {
      let r = {}
      chat.conversation.participants.forEach(element => {
        if (element.messageable_id != this.localuser.id) {
          r = element.messageable
        }
      });
      return r
    },
    async getChat(id, user) {
      this.opining = true
      this.api.get(`user/messages/${id}`).then(res => {
        if (res.status == 'success') {
          this.chatuser = user
          this.opining = false
          this.chat = res.data.data
          window.axios = require('axios');
          window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
          window.pusher = require('vue-pusher');
          window.Pusher.logToConsole = false;
          const pusher = new Pusher("30fad4c0b0b50a1a41ca", {
            cluster: "eu",
            authEndpoint: "https://api.gooreo.com/api/broadcasting/auth",
            auth: {
              headers: {
                Authorization: this.decrypted($cookies.get("token"))
              }
            },
          });
          const channel = pusher.subscribe(`private-chat.${id}`);
          const vm = this;
          channel.bind("message.new", (data) => {
            const msgnew = data.msg;
            vm.chat.msgs.data.push(msgnew);
            document.querySelector('.list-unstyled .media:last-child').scrollIntoView();
          });
        } else {
          location.replace('/404')
        }
      })
    },
    async getChats() {
      this.loading = true
      this.api.get('user/messages').then(res => {
        if (res.status == 'success') {
          this.chats = res.data.data.chats
          //   console.log(this.chats.data[0])
          this.loading = false
          // window.axios = require('axios');
          // window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
          // window.pusher = require('pusher-js');
          // window.Pusher.logToConsole = true;
          // var pusher = new Pusher("30fad4c0b0b50a1a41ca", {
          //   cluster: "eu",
          //   authEndpoint: "https://api.gooreo.com/api/broadcasting/auth",
          //   auth: {
          //     headers: {
          //       Authorization: this.decrypted($cookies.get("token"))
          //     }
          //   },
          // });
          // const channel = pusher.subscribe(`chats-channel`);
          // const vm = this;
          // channel.bind("chats-channel", (data) => {
          //   this.chats = res.data.data.chats;
          //   const c = data.data;
          //   vm.chats.data.data.chats.push(c);
          //   console.log( vm.chats.data.data.chats)
          // });
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  created() {
    this.getChats()
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://js.pusher.com/7.0.3/pusher.min.js')
    document.head.appendChild(recaptchaScript)
  },
  data() {
    return {
      localuser: JSON.parse(this.decrypted($cookies.get("user"))),
      stat: '',
      loading: true,
      opining: false,
      chats: {},
      chat: null,
      msg: '',
      chatuser: {},
      term: '',
      activeChat: '',
      readMsgs: [],
      zIndex: 0
    }
  }
}
</script>
