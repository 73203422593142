<template>

  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="" src="../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/search">Search</router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content p-1">
        <div class="container ">
          <div class="  row  inner-banner">
            <div class="col-xl-12">
              <h5>Search</h5>
              <input v-model="term" class="form-control" type="text" v-on:keyup="research()"
                     @keypress.enter.prevent="research()">
              <br>
            </div>
            <div class="col-xl-12 ">
              <div class="card">
                <div class="card-body">
                  <div class="card-header  mb-4">
                    <h4 class="card-title">Search results for "{{ $route.query.q }}" - found
                      {{ services?.length }} {{ services?.length == 1 ? 'result' : 'results' }}
                    </h4>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <!--                    <loader v-if="!loading" class="col-lg-12 col-md-12" style="position: absolute;"></loader>-->
                    <div v-if="loading">
                      <div id="loader-wrapper">
                        <div id="loader">
                          <div class="loader-ellips">
                            <span class="loader-ellips__dot"></span>
                            <span class="loader-ellips__dot"></span>
                            <span class="loader-ellips__dot"></span>
                            <span class="loader-ellips__dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <nav class="user-tabs mb-4">
                      <ul class="nav nav-tabs-bottom nav-justified">
                        <li class="nav-item">
                          <button :class="nav===1? 'services-button':''" class="nav-link "
                                  style="border: 1px solid #ddd;" @click="servicestabs()">Services
                          </button>
                        </li>
                        <li class="nav-item">
                          <button :class="nav===2? 'projects-button':''" class="nav-link "
                                  style="border: 1px solid #ddd;" @click="porjectstab()">Projects
                          </button>
                        </li>
                      </ul>
                    </nav> -->
                    <tab style="cursor: pointer" class="my-5 p-2" :tabs="tabs" v-model="nav" :width="99.35" @clicked="getTab"></tab>

                    <div v-if="services?.length > 0 && nav===0" class="row blog-grid-row">
                      <div v-for="item in services" v-if="!loading" :key="item.id" class="col-md-3 col-sm-12">
                        <!-- Blog Post -->

                        <div class="s-grid-card grid-card">

                          <div class="card-image">

                            <router-link :params="`${item.seo_title}`"
                                         :to="`/marketplace/${item.slug}`"><img :src="item.photo_url"
                                                                                alt="Post Image" class="img-fluid"
                                                                                height="240px">
                              <button class="favourite "
                                      style="top: 7.5px;"
                                      @click="addFavorite(item.id)"><i
                                  :class="item.favorited ? 'fas fa-heart' : 'fas fa-heart'"
                                  :style="item.favorited ? 'color:yellow' : ''"></i></button>
                            </router-link>
                          </div>
                          <div class="card-content">
                            <ul class="entry-meta meta-item">
                              <li>
                                <div class="post-author">
                                  <router-link :params="`${item.author.name}`"
                                               :to="`/profile/${item.author.slug}`"><img
                                      :src="item.author.avatar" alt="Post Author">
                                    <span> {{ item.author.name }}</span>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                            <h3
                                style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;">
                              <router-link :params="`${item.seo_title}`"
                                           :to="`/marketplace/${item.slug}`">{{ item.title.en }}
                              </router-link>
                            </h3>
                            <div class="d-flex justify-content-between align-items-center mb-3  mt-3">
                              <h4 class="m-card-title orange-text">${{ parseFloat(item.price)?.toFixed(2) }}</h4>
                              <div class="fa-grid-card">
                                <i class="far fa-star"></i> {{
                                  item.reviews_avg == null
                                      ? 0 : item.reviews_avg.substr(0, 3)
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /Blog Post -->
                      </div>
                    </div>
                    <div v-if="projects?.length > 0 && nav===1" class="row blog-grid-row">
                      <div v-for="item in projects" v-if="!loading" :key="item.id" class="col-md-3 col-sm-12">
                        <!-- Blog Post -->

                        <div class="s-grid-card grid-card">

                          <div class="card-image">

                            <router-link :params="`${item.seo_title}`"
                                         :to="`/marketplace/${item.slug}`"><img :src="item.photo_url"
                                                                                alt="Post Image" class="img-fluid"
                                                                                height="240px">
                              <button class="favourite "
                                      style="    top: 7.5px;"
                                      @click="addFavorite(item.id)"><i
                                  :class="item.favorited ? 'fas fa-heart' : 'fas fa-heart'"
                                  :style="item.favorited ? 'color:yellow' : ''"></i></button>
                            </router-link>
                          </div>
                          <div class="card-content">
                            <ul class="entry-meta meta-item">
                              <li>
                                <div class="post-author">
                                  <router-link :params="`${item.author.name}`"
                                               :to="`/profile/${item.author.slug}`"><img
                                      :src="item.author.avatar" alt="Post Author">
                                    <span> {{ item.author.name }}</span>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                            <h3
                                style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; font-size: 0.9rem; height: 35px;">
                              <router-link :params="`${item.seo_title}`"
                                           :to="`/marketplace/${item.slug}`">{{ item.title.en }}
                              </router-link>
                            </h3>
                            <div class="d-flex justify-content-between align-items-center mb-3  mt-3">
                              <h4 class="m-card-title orange-text">${{ parseFloat(item.price)?.toFixed(2) }}</h4>
                              <div class="fa-grid-card">
                                <i class="far fa-star"></i> {{
                                  item.reviews_avg == null
                                      ? 0 : item.reviews_avg.substr(0, 3)
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /Blog Post -->
                      </div>
                    </div>
                    <div v-if="!services  && !projects" class="text-center">
                      <h3>No results found</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <layout-footer/>
  </div>


</template>

<script>
export default {
  name: "search",
  methods: {
    porjectstab() {
      this.nav = 2
      // console.log(this.nav)
    },
    servicestabs() {
      this.nav = 1
    },
    search() {
      this.loading = true
      this.api.getSearch(`search?q=${this.$route.query.q}`).then(res => {
        this.services = res.data.data.service
        this.projects = res.data.data.project
        this.loading = false
      })
    },
    research() {
      if (this.$route.query.q != "") {
        setTimeout(() => {
          this.$router.replace({query: {'q': this.term}})
          this.search()
        }, 3000)
      }
    },
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then(res => {
        if (res.status == 'success') {
          Object.keys(this.services).map((value, key) => {
            if (this.services[value].id == id) {
              this.services[value].favorited = !this.services[value].favorited;
            }

          })
        } else {

        }
      })
    },
    getTab(t){
      this.nav = t
    }
  },
  inject: ['api'],
  created() {
    this.term = this.$route.query.q
    this.search()
  },
  data() {
    return {
      loading: true,
      services: [],
      projects: [],
      term: '',
      nav: 0,
      tabs:['Services', 'Projects'],
    }
  }
}
</script>

<style scoped>
#loader-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

#loader {
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 250px;
  width: 200px;
}

.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 64px;
  margin: 100px auto 0;
}

.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}

.loader-ellips__dot:nth-child(3) {
  left: 1.5em;
}

.loader-ellips__dot:nth-child(4) {
  left: 3em;
}

.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide {
  to {
    transform: translateX(1.5em)
  }
}

.loader-ellips__dot:nth-child(1) {
  background: #404040;
}

.loader-ellips__dot:nth-child(2) {
  background: #009DF8;
}

.loader-ellips__dot:nth-child(3) {
  background: #404040;
}

.loader-ellips__dot:nth-child(4) {
  background: #404040;
}

/*.modal-dialog {*/
/*  max-width: 70%;*/
/*}*/

.services-button {
  background-color: #009df8;
  color: white;
}

.projects-button {
  background-color: #f15c3d;
  color: white;
  border: 1px solid rgb(241, 92, 61)
}

.chat-cont-left .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #009DF8;
  background-color: #fdfdfd;
}

.bg-modal:before {
  display: none;
}

.orinig a:hover {
  color: #f15c3d;
}
.nav-link:focus, .nav-link:hover {
  color: #000000;
}
</style>