<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>

                    <li aria-current="page" class="breadcrumb-item">Edit Service</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="card" v-if="isFreelancer">
                <div class="card-body">
                  <div class="card-header pt-0 mb-4">
                    <h5 class="card-title">Edit Service</h5>

                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <form class="needs-validation" novalidate>
                        <div class=" row">
                          <div class="col-md-12 mb-3">
                            <label for="validationCustom01">Service title</label>
                            <input id="validationCustom01" v-model="title" class="form-control" placeholder="Title"
                                   required type="text">
                            <div class="valid-feedback">
                              Looks good!
                            </div>
                          </div>
                          <p>Include a short title that accurately describes your service.</p>
                          <h3 class="mt-2 ">Category</h3>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Main Category:</label>
                              <select v-model="maincategorey" :disabled='false' class="form-select"
                                      @change="selectCat()">
                                <option v-for="cat in parents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Sub Category:</label>
                              <select v-model="subcategory" :disabled='false' class="form-select">
                                <option v-for="cat in subparents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="col-md-2 ">
                            <div class="form-group ">
                              <a class="  btn btn-primary " data-bs-toggle="modal" href="#file" style="margin-top:30px"
                                 type="submit">
                                change</a>
                            </div>
                          </div> -->
                          <h3 class="mt-2 mb-2">Describe your service</h3>
                          <p class="mt mb-2">Include a detailed and accurate description of your service.</p>
                          <div class="form-group m-2">
                            <QuillEditor ref="editor" v-model:content="description"
                                         :toolbar="editorConfig.modules.toolbar"
                                         theme="snow"/>
                            <!-- <textarea v-model="description" class="form-control" cols="5" placeholder=" Description"
                              rows="5"></textarea> -->
                          </div>
                          <div class="m-2">
                            <br>
                            <h3 class="mt-2 mb-2">Attach images</h3>
                            <div>
                              <file-pond ref="pond" accepted-file-types="image/*" allow-multiple="true"
                                         allowReorder="true"
                                         class-name="my-pond" credits="false" label-idle="Drop files here..."
                                         maxFiles="10"
                                         name="test" v-on:init="handleFilePondInit"
                                         v-on:updatefiles="handleFilePondInit"/>
                            </div>
                          </div>

                          <h6 class="settings-size mt-4"></h6>
                          <div class="upload-images upload-size w-100 d-flex flex-wrap">
                            <div v-for="media in media_array" :key="media.id" class="">
                              <img :src="media.lg" alt="Image"
                                   style="height: 125px; width: 150px; object-fit: contain; margin: 0 5px;">
                              <div class="removeImg"
                                   style="color: tomato; cursor: pointer; position: relative; top: -125px; left: 25px;"
                                   @click="deleteImage(media.id)">
                                <i class="far fa-times-circle"></i>
                              </div>
                            </div>
                          </div>
                          <h3 class="mt-2 "></h3>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Delivery Time:</label>
                              <select v-model="time" class="form-select">
                                <option v-for="time in deliveryTime" :value="time.split(' ')[0]">{{ time }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group form-placeholder col-md-6 ">
                            <label> Price </label>
                            <input v-model="price" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div class="form-group form-placeholder col-md-12 ">
                            <label> Keywords </label>
                            <input v-model="keyword" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Keywords" style="min-height: 40px;" type="text"
                                   v-on:keydown.space.prevent="addKeyword(keyword)"
                                   @keypress.enter.prevent="addKeyword(keyword)">
                          </div>
                          <div class="keywords col-lg-12 d-flex flex-wrap">
                            <div v-for="chip in keywords" class="chip d-flex"
                                 style="background-color: #009df8; min-width: 100px; text-align: center; padding: 5px; border-radius: 50px; margin: 0 5px;">
                              <div class="removeImg" style="color: white; cursor: pointer; position: absolute;"
                                   @click="removeKeyword(chip)">
                                <i class="far fa-times-circle"></i>
                              </div>
                              <h4 style="color:white; margin:auto;">{{ chip }}</h4>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <h3 class="mt-2 ">Buyer Instructions</h3>
                            <div class="form-group">
                              <textarea v-model="instructions" class="form-control" cols="5"
                                        placeholder="Buyer Instructions" rows="5"></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="card">
                                <div class="card-body pt-0">
                                  <div class="card-header">
                                    <h5 class="card-title">Add Service Extensions</h5>
                                  </div>
                                  <div class="exts m-3">
                                    <div v-for="ex in ext" class="ext">
                                      <div class="extbody d-flex justify-content-between"
                                           style="background-color: #ffeeea; padding: 10px 20px;">
                                        <div class="infor">
                                          <h3>{{ ex.title }}</h3>
                                          <p>{{ ex.content }}</p>
                                          <p class="orange-text">+{{ ex.days_count }} Days
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            +${{ ex.price }}</p>
                                        </div>
                                        <div class="btn btn-danger h-100" @click="removeExt(ex)">
                                          <h6 class="m-0 text-white">Delete</h6>
                                        </div>
                                      </div>
                                      <hr>
                                    </div>
                                  </div>
                                  <form>
                                    <div class="settings-form row ">
                                      <div class="form-group form-placeholder col-md-6 mt-3   ">
                                        <label> Title </label>
                                        <input v-model="ext_title" class="form-control" type="text">
                                      </div>
                                      <div class="form-group form-placeholder col-md-6 mt-3  ">
                                        <label> Price </label>
                                        <input v-model="ext_price" class="input-tags form-control" data-role="tagsinput"
                                               type="text" @keypress="isNumber($event)">
                                      </div>
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label>Delivery Time:</label>
                                          <select v-model="ext_time" class="form-select">
                                            <option v-for="time in deliveryTime" :value="time.split(' ')[0]">{{time}}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label>Description </label>
                                        <textarea v-model="ext_desc" class="form-control" cols="5"
                                                  placeholder="Buyer Instructions" rows="5"></textarea>
                                      </div>
                                      <div class="form-group mb-0">
                                        <div class="settings-btns">
                                          <button class="btn btn-primary mt-5 " @click.prevent="addExt()">Add
                                            Extension
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-primary mt-5 " type="submit" @click.prevent="publish()">update</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
                <please-switch></please-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
    <!-- <div id="file" class="modal fade">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"> CHANGE CATEGORY</h4>
          </div>
          <div class="modal-body">
            <div class="modal-info">
              <form>
                <div class="feedback-form">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Main Category:</label>
                        <select v-model="xmaincategorey" class="form-select" @change="selectCat()">
                          <option v-for="cat in xparents" :value="cat.text">{{ cat.text }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Sub Category:</label>
                        <select v-model="xsubcategory" class="form-select">
                          <option v-for="cat in xsubparents" :value="cat.text">{{ cat.text }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 submit-section text-end">
                    <button class="btn btn-primary submit-btn" type="submit" @click.prevent="submitData()">SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType
  from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
export default {
  components: {
    FilePond
  },
  methods: {
    handleFilePondInit: function () {
      this.images = this.$refs.pond.getFiles().map((file) => file.file);

    },
    submitData() {
      this.xmaincategorey = this.maincategorey
      this.xsubcategory = this.subcategory
      this.xmaincategoreyId = this.maincategoreyId
      this.xsubcategoryId = this.subcategoryId
    },
    deleteImage(i) {
      if (confirm('Are you sure you want to delete this post?')) {
        this.api.post(`deleteImage/${i}`);
        this.api.get(`user/edit/service/${this.$route.path.split('/')[3]}`).then(res => {
          if (res.status == 'success') {
            let d = res.data.data
            let x = res.data.data.item

            this.media_array = x.media_array


          } else {
            this.toast(res.data, 'danger')
          }
        })

      }
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addExt() {

      if (this.ext_title == null || this.ext_title === '') {
        this.toast("Service Extensions title is required", 'danger')
      } else if (this.ext_price == null || this.ext_price === '') {
        this.toast("Service Extensions price is required", 'danger')
      } else if (this.ext_time == null || this.ext_time === '') {
        this.toast("Service Extensions delivery time is required", 'danger')
      } else if (this.ext_desc == null || this.ext_desc === '') {
        this.toast("Service Extensions description is required", 'danger')
      } else {
        this.ext.push({
          title: this.ext_title,
          days_count: this.ext_time,
          content: this.ext_desc,
          price: this.ext_price,
        })
        this.ext_title = ''
        this.ext_time = this.deliveryTime[0]
        this.ext_desc = ''
        this.ext_price = ''
      }
    },
    selectCat() {
      this.subparents = this.parents.filter(p => p.text == this.maincategorey)[0].childs
      this.subcategory = this.subparents[0]
    },
    addKeyword(word) {
      if (this.keyword === '' || this.keyword === null) {
      } else {
        this.keywords.push(
          word)
        this.keyword = ''
      }
    },
    loadFile(event) {
      this.images.push(event.target.files[0])
      this.showimages.push(URL.createObjectURL(event.target.files[0]))
    },
    removeImage(i) {
      this.showimages.splice(this.images.indexOf(i), 1)
      this.images.splice(this.images.indexOf(i), 1)
    },
    removeKeyword(word) {
      this.keywords.splice(this.keywords.indexOf(word), 1)
    },
    removeExt(ext) {
      this.ext.splice(this.keywords.indexOf(ext), 1)
    },
    async getData() {
  await    this.api.get('user/create/service').then(res => {
        if (res.status == 'success') {
          this.parents = res.data.data.parents
          this.subparents = res.data.d
          this.xparents = res.data.data.parents
          this.xsubparents = res.data.data.parents[0].childs
          this.xmaincategorey = res.data.data.parents[0].text
          this.xsubcategory = res.data.data.parents[0].childs[0].text
          this.maincategorey = res.data.data.parents[0].text
          this.subcategory = res.data.data.parents[0].childs[0].text
        } else {
          this.toast(res.data, 'danger')
        }
      })
  await    this.api.get(`user/edit/service/${this.$route.path.split('/')[3]}`).then(res => {
        if (res.status == 'success') {
          this.subcategoryId = res.data.data.item.section_id
          let x = res.data.data.item
          this.title = x.title
          this.slug = x.slug
          this.description = x.content
          this.media_array = x.media_array
          this.$refs.editor.setHTML(this.description)
          this.price = x.price
          this.instructions = x.instructions
          this.ext = x.ext
          this.time = x.days_count
          let t;
          for(t in x.tags){
            this.keywords.push(x.tags[t])
          }
          x.media_array.forEach(e => {
            this.showimages.push(e.src)
          })
          Object.values(res.data.data.parents).map((y) => {
            Object.values(y.childs).map((sub) => {
              if (sub.id == x.section_id) {
                this.maincategoreyId = y.id
                this.maincategorey = y.text
                this.subparents = y.childs
                this.subcategoryId = sub.id
                this.subcategory = sub.text
              }
            })
          })
        } else {
          location.replace('404')
        }
      })

    }
    ,
    async publish() {
      let form = new FormData();
      form.append('_method', 'PUT')
      form.append('title', this.title)
      form.append('instructions', this.instructions)
      // form.append('content', this.description)
      form.append('content', this.$refs.editor.getHTML())
      form.append('section_id', this.subparents.filter(e => e.text == this.subcategory)[0].value)
      form.append('user_id', JSON.parse(this.decrypted($cookies.get("user"))).id)
      form.append('tags', this.keywords)
      form.append('days_count', this.time)
      form.append('ext', this.ext)
      form.append('slug', this.slug)
      form.append('price', this.price)
      for (var i = 0; i < this.ext.length; i++) {
        form.append(`ext[${i}][title]`, this.ext[i].title)
        form.append(`ext[${i}][price]`, this.ext[i].price)
        form.append(`ext[${i}][days_count]`, this.ext[i].days_count)
        form.append(`ext[${i}][content]`, this.ext[i].content)
      }

      for (var i = 0; i < this.images.length; i++) {
        let file = this.images[i];
        form.append('photo[' + i + ']', file);
      }
      this.api.post(`user/update/service/${this.$route.path.split('/')[3]}`, form, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': this.decrypted($cookies.get("token"))
        }
      }).then(res => {
        if (res.status == 'success') {
          this.toast("Service  Edited", 'success')
          setTimeout(() => {
                location.replace('/user/services-manager')
              }, 1000
          )
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
  },
  inject: ['api'],
  async created() {
    await this.getData()
  },

  data() {

    return {
      editorConfig: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            // superscript/subscript
            [{indent: "-1"}, {indent: "+1"}], // outdent/indent
            [{direction: "rtl"}], // text direction

            [{size: ["small", false, "large", "huge"]}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],

            [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}],
            [{align: []}],

            ["clean"],
          ],
        },
        theme: "snow",
      },
      title: '',
      maincategorey: '',
      subcategory: '',
      maincategoreyId: 0,
      subcategoryId: 0,
      xmaincategorey: '',
      xsubcategory: '',
      xmaincategoreyId: 0,
      xsubcategoryId: 0,
      description: '',
      images: [],
      showimages: [],
      parents: [],
      subparents: [],
      xparents: [],
      xsubparents: [],
      time: '',
      media_array: [],
      deliveryTime: [
        '1 Day',
        '2 Days',
        '3 Days',
        '4 Days',
        '5 Days',
        '6 Days',
        '7 Days',
        '10 Days',
        '15 Days',
        '20 Days',
        '25 Days',
        '30 Days',
        '45 Days',
      ],
      keyword: '',
      price: 0,
      slug: '',
      keywords: [],
      instructions: '',
      ext: [],
      ext_title: '',
      ext_desc: '',
      ext_price: '',
      ext_time: '',
    }
  }
}
</script>
<style scoped>
.settings-bt {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-bt .upload {
  position: absolute;
  z-index: 11;
  background: #009df8;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
}

.settings-bt .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.setting-size {
  font-size: 14px;
  color: #131523;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.setting-size span {
  font-weight: 700;
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
    height: 250px;
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
    height: 250px;
  }
}
</style>