<template>
  <div class=" skeleton col-md-6 col-lg-6 col-xl-6">

    <div class="freelance-widget widget-author">
      <div class="freelance-content ">


        <div class="author-heading">
          <div class="freelance-location "><span style="width:15%;margin:auto;"></span>
          </div>
            <div style="  text-align:center; ">
              <div class="post-author d-flex">
                <div style="width:50px;height:50px" class="profile-img  me-2" ></div>
                <div style="height: 100px"></div>
                <h4 class="mt-3" ></h4>
              </div>
              <h4 class="mt-3"></h4>
              <p></p>
            </div>
        </div>
        <div class="counter-stats">
          <ul>
            <li>
              <h4></h4>
            </li>
            <li>
              <h4></h4>
            </li>
            <li>
              <h4> </h4>
              <h3 ><span></span></h3>
            </li>
          </ul>
        </div>
      </div>
      <div class="cart-hover">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectscard-skeleton"
}
</script>

<style scoped>
.profile-img {
  animation: skeleton-frames 2s infinite;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
</style>