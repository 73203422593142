<template>
	<div>
		<!-- Main Wrapper -->
		<div class="main-wrapper">
			<!-- Header -->
			<layout-header />
			<!-- /Header -->

			<!-- Breadcrumb -->
			<div class="bread-crumb-bar">
				<div class="container">
					<div class="row align-items-center inner-banner">
						<div class="col-md-12 col-12 text-center">
							<div class="breadcrumb-list">
								<nav aria-label="breadcrumb" class="page-breadcrumb">
									<ol class="breadcrumb">
										<li class="breadcrumb-item">
											<router-link to="/"><img src="../../../../assets/img/home-icon.svg"
													alt="Post Author"> Home</router-link>
										</li>
										<li class="breadcrumb-item" aria-current="page">Blog</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /Breadcrumb -->

			<!-- Page Content -->
			<div class="content">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">
							<div class="card-view">

								<!-- Blog Post -->
								<div class="blog-single-post pro-post widget-box" v-for="post in blog.posts.data">
									<div class="card-image">
										<router-link :to="`/blog/${post.slug}`"><img alt="" style="height: 300px;"
												:src="post.photo_url" class="img-fluid"></router-link>
									</div>
									<router-link :to="`/blog/${post.slug}`"><h3 class="m-card-title">{{ post.title.en }}</h3></router-link>
									<div class="card-info clearfix">
										<div class="post-left">
											<ul>
												<li><i class="far fa-calendar"></i>{{ post.created_at.date }}</li>
												<li @click.prevent="filter(post.cat.id)"><i class="fas fa-tags"></i>{{ post.cat.seo_title }}</li>
											</ul>
										</div>
									</div>
									<div class="card-content"
										v-html="post.content.en.split(' ').slice(0, 40).join(' ')">
									</div>
								</div>
								<!-- /Blog Post -->

								<!-- Blog Pagination -->
								<div class="row pb-4">
									<div class="col-md-12">
										<div class="card-pagination text-center">
											<ul class="paginations">
												<li><a @click.prevent="prevPage(pagination.prev_page_url)"> <i
															class="fas fa-angle-left"></i>
														Previous</a>
												</li>
												<li v-for="(item, index) in pagination.total_pages "><a
														:class="index + 1 == pagination.current_page ? 'active' : ''"
														@click.prevent="changePage(index + 1)">{{ index + 1 }}</a></li>

												<li><a @click.prevent="nextPage(pagination.next_page_url)">Next <i
															class="fas fa-angle-right"></i></a></li>
											</ul>
										</div>
									</div>
								</div>
								<!-- /Blog Pagination -->

							</div>
						</div>


					</div>
				</div>
			</div>
			<!-- /Page Content -->

			<!-- Footer -->
			<layout-footer />
			<!-- /Footer -->
		</div>
		<!-- /Main Wrapper -->
	</div>
</template>
<script>
export default {
	inject: ['api'],
	methods: {
		filter(id){
			let newarr = []
			this.blog.posts.data.forEach(e => {
				if(e.cat.id == id){
					newarr.push(e)
				}
			})
			this.blog.posts.data = newarr
		},
		changePage(page) {
			this.page = page
			this.getPageData()
		},
		nextPage(url) {
			if (this.page < this.pagination.total_pages) {
				this.loading = true
				this.page++
				this.api.getCustom(
					url
				).then(
					res => {
						if (res.status == 'success') {
							this.blog = res.data.data
							this.pagination = res.data.data.posts.pagination

							this.loading = false
						} else {
							this.toast(res.data, 'danger')
						}
					}
				)
			}
		},
		prevPage(url) {
			if (this.page > 1) {
				this.loading = true
				this.page--
				this.api.getCustom(
					url
				).then(
					res => {
						if (res.status == 'success') {
							this.blog = res.data.data
							this.pagination = res.data.data.posts.pagination

							this.loading = false
						} else {
							this.toast(res.data, 'danger')
						}
					}
				)
			}
		},
		getPageData() {

			if (this.page > 1 && this.page <= this.pagination.total_pages) {
				this.loading = true
			}
			this.api.get(
				`blog?page=${this.page}`
			).then(
				res => {
					if (res.status == 'success') {
						this.blog = res.data.data
						this.pagination = res.data.data.posts.pagination
						this.loading = false
					} else {
						this.toast(res.data, 'danger')
					}
				}
			)
		},
	},
	created() {
		this.api.get('blog').then(res => {
			if (res.status == 'success') {
				this.blog = res.data.data
				this.pagination = res.data.data.posts.pagination
			} else {
				this.toast(res.data, 'danger')
			}
		})
	},
	data() {
		return {
			loading: false,
			page: 1,
			blog: {},
			pagination: {},
		}
	}
}
</script>