<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta :content="lis" name="keywords"/>
    <meta
        :content="
        'Find the best projects and employers to work with in form Gooreo'
      "
        property="description"
    />
    <meta :content="'Gooreo | All Projects'" property="title"/>
    <meta
        content="Find the best projects and employers to work with in Gooreo"
        property="description"
    />
    <meta :content="'Gooreo | All Projects'" property="og:title"/>
    <meta content="website" property="og:type"/>
    <meta :content="'Gooreo'" property="og:site_name"/>
    <meta
        :content="
        'Find the best projects and employers to work with in form Gooreo'
      "
        property="og:description"
    />
    <meta :content="`https://gooreo.com${$route.path}`" property="og:url"/>
  </teleport>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar bar2" style="background-color: rgb(241, 92, 61);">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol style="overflow-x: scroll; display: block ruby;">
                    <li v-if="!loading"
                        v-for="item in sects.slice(0, 9)"
                        class="breadcrumb-item2"
                    >
                      <router-link
                          :to="{
                          name: 'Projects Category',
                          params: { title: item.title.en, id: item.slug },
                        }"
                      >
                        <h6 class="text-white m-auto">{{ item.title.en }}</h6>
                      </router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->
      <!-- Page Content -->
      <div class="content pt-5">
        <div class="container">
          <div class="col-lg-12 d-flex filters justify-content-between mb-4 py-2 px-2">
            <div class="d-flex">
              <select
                  v-model="fcategory"
                  class="form-control filter-selector select mx-2"
                  @change="addFilter(fcategory, 'category')"
              >
                <option value="">Category</option>
                <option
                    v-for="item in sections"
                    :key="item.id"
                    :value="item.id"
                >{{ item.title.en }}
                </option
                >
              </select>
              <select
                  v-model="fsection"
                  class="form-control filter-selector select mx-2"
                  @change="addFilter(fsection, 'section')"
              >
                <option value="">Sub category</option>
                <option
                    v-for="item in subsections"
                    :key="item.id"
                    :value="item.id"
                >{{ item.title.en }}
                </option
                >
              </select>
              <select
                  v-model="projecttype"
                  class="form-control filter-selector select mx-2"
                  @change="addFilter(projecttype, 'type')"
              >
                <option value="">Project type</option>
                <option value="once">
                  <div>Once</div>
                </option>
                <option value="periodically">
                  <div>Periodically</div>
                </option>
              </select>
              <select
                  v-model="projectlengthtype"
                  class="form-control select filter-selector mx-2"
                  @change="addFilter(projectlengthtype, 'length')"
              >
                <option value="">Project length</option>
                <option value="day">
                  <div>Day</div>
                </option>
                <option value="week">
                  <div>Week</div>
                </option>
                <option value="month">
                  <div>Month</div>
                </option>
              </select>
              <select
                  v-model="projecttimetype"
                  class="form-control filter-selector select mx-2"
                  @change="addFilter(projecttimetype, 'time')"
              >
                <option value="">Work time</option>
                <option value="part">
                  <div>Part time</div>
                </option>
                <option value="full">
                  <div>Full time</div>
                </option>
              </select>
            </div>
            <a
                class="my-2 mx-4 orange-text"
                style="cursor: pointer;"
                @click.prevent="clearFilter()"
            >Clear All</a
            >
          </div>
          <div class="row justify-content-between">
            <!-- Project Content -->
            <div  v-if="projects.length>0"  style="justify-content: center" class="col-md-12   row">
              <div
                  v-for="item in projects"
                  :key="item.id"
                  class="col-md-5 mt-2 mb-2 ms-1 col-lg-5  col-xl-5"
              >
                <div class="freelance-widget shadow widget-author">
                  <div class="freelance-content ">
                    <button class="favourite " @click="addFavorite(item.id)">
                      <i
                          :class="
                          item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                        "
                          :style="item.favorited ? 'color:#dc5454' : ''"
                      ></i>
                    </button>
                    <div class="author-heading">
                      <div class="freelance-location ">
                        <i class="fas fa-clock   " style="width: 20px;"> </i
                        >{{ item.created_at.humen }}
                      </div>
                      <div class="d-flex text-container">
                        <div style="  text-align:center; width: 100px;">
                          <router-link
                              :to="`/profile/${item.author.slug}`"
                              class="profile-img"
                          >
                            <img :src="item.author.avatar" alt="author"/>
                          </router-link>

                          <router-link :to="`/profile/${item.author.slug}`">
                            <div class="profile-name">
                              <div
                                  class="author-location mt-1"
                                  style="color: rgb(241, 92, 61);"
                              >
                                {{ item.author.name }}
                                <i
                                    v-if="item.author.verified_id == true"
                                    class="fas fa-check-circle text-success clearfix verified"
                                ></i>
                              </div>
                            </div>
                          </router-link>
                        </div>
                        <router-link
                            style="width: 80%"
                            :to="`/project/${item.slug}`"
                            tabindex="-1"
                        >
                          <h3 class="mt-3 text-container">
                            {{ item.seo_title }}
                          </h3>
                        </router-link>
                      </div>
                      <div class="freelancers-price">
                        ${{ parseFloat(item.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(item.project_budget_to)?.toFixed(2) }}

                      </div>
                    </div>
                    <div class="counter-stats">
                      <ul>
                        <li>
                          <h5>Project Length</h5>
                          <h3 class="counter-value">
                            {{ item.project_length_from }} -
                            {{ item.project_length_to }}
                            {{ item.project_length_type }}
                          </h3>
                        </li>
                        <li>
                          <h5>Proposals</h5>
                          <h3 class="counter-value">{{ item.applicant_count }}</h3>
                        </li>
                        <li>
                          <h5>Payment Type</h5>
                          <h3 class="counter-value">
                            <span class="jobtype">{{ item.project_type }}</span>
                          </h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="cart-hover">
                    <router-link
                        :to="`/project/${item.slug}`"
                        class="btn-cart"
                        style="background-color: rgb(241, 92, 61);"
                    >Apply now
                    </router-link>
                  </div>
                </div>
              </div>
              <projects-card-skeleton
                  v-for="item in 10"
                  v-if="loading || loading2"
              ></projects-card-skeleton>
              <div class="row pb-4">
                <div style="width: 145px" class="pb-4 m-auto">
                  <button style="background-color: #f15c3d; border-color: #f15c3d"
                          v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                          @click="nextPage(pagination.next_page_url)">
                    Load more
                  </button>
                </div>
              </div>
            </div>
            <div v-else style="height: 650px" class="col-lg-12 col-md-12 row  " >
              <projects-card-skeleton
                  v-for="item in 4"
                  v-if="loading || loading2"
              ></projects-card-skeleton>
              <div v-else class="h-100 w-100 justify-content-center center-text">
                <h4 class="m-auto justify-content-center">Empty</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
    <div id="rating" class="modal fade">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header d-block b-0 pb-0">
            <span class="modal-close float-end"
            ><a
                aria-label="Close"
                data-bs-dismiss="modal"
                href="javascript:void(0);"
            ><i class="far fa-times-circle orange-text"></i></a
            ></span>
          </div>
          <div class="modal-body">
            <form>
              <div class="modal-info">
                <div class="text-center pt-0 mb-5">
                  <h3>Please login to Favourite Project</h3>
                </div>
                <div class="submit-section text-center">
                  <a
                      class="btn btn-primary black-btn click-btn"
                      data-bs-dismiss="modal"
                      href="javascript:void(0);"
                  >Cancel</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["api"],
  methods: {
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.projects).map((value, key) => {
            if (this.projects[value].id == id) {
              this.projects[value].favorited = !this.projects[value].favorited;
            }
          });
        } else {
        }
      });
    },
    clearFilter() {
      this.loading2 = true;
      this.fcategory = "";
      this.fsection = "";
      this.projecttype = "";
      this.projectlengthtype = "";
      this.projecttimetype = "";
      this.getData();
    },
    addFilter(f, type) {
      this.loading = true;
      if (type == "category") {
        this.fcategory2 = `cat[parent][id]=${f}`;
        this.fsection2 = "";
        this.subsections = this.sections.filter((s) => s.id == f)[0].childs;
      }
      if (type == "type") {
        this.projecttype2 = `project_type=${f}`;
      }
      if (type == "length") {
        this.projectlengthtype2 = `project_length_type=${f}`;
      }
      if (type == "time") {
        this.projecttimetype2 = `project_time_type=${f}`;
      }
      if (type == "section") {
        this.fsection2 = `section_id=${f}`;
      }
      let basefil = `${this.projecttype2}&${this.projectlengthtype2}&${this.projecttimetype2}&${this.fcategory2}&${this.fsection2}`;
      let fil = [];
      basefil.split("&").forEach((bf) => {
        if (bf.trim() != "" && bf.trim() != 0) {
          fil.push(bf);
        }
      });
      let fl = "";
      fil.forEach(function (f, idx, array) {
        if (idx !== array.length) {
          fl += `${f}&`;
        } else {
          fl += `${f}`;
        }
      });
      this.filter = fl;
      this.api.get(`projects?${this.filter}`).then((res) => {
        if (res.status == "success") {
          this.projects = res.data.data.projects.data;
          this.sections = res.data.data.sections;
          this.pagination = res.data.data.projects.pagination;
        } else {
          this.toast(res.data, "danger");
        }
        this.loading = false;
      });
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading2 = true;
        this.page++;
        this.api.getCustom(url).then((res) => {
          if (res.status == "success") {
            this.projects = this.projects.concat(res.data.data.projects.data);
            this.sections = res.data.data.sections;
            this.pagination = res.data.data.projects.pagination;
            this.loading2 = false;
          } else {
            this.toast(res.data, "danger");
          }
        });
      }
    },
    async getData() {
      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true;
      }
      this.api.get("projects").then((res) => {
        if (res.status == "success") {
          this.projects = res.data.data.projects.data;
          // console.log( this.projects)
          this.sections = res.data.data.sections;
          let list = [];
          Object.keys(this.projects).forEach((e) => {
            list.push(this.projects[e].seo_title);
          });
          this.lis = list;
          this.pagination = res.data.data.projects.pagination;
          this.loading = false;
          this.loading2 = false;
        } else {
          this.toast(res.data, "danger");
        }
      });
    },
  },
  computed: {
    sects() {
      return this.$store.state.generalsetting.sections;
    },
  },
  data() {
    return {
      context: "",
      lis: [],
      loading: true,
      loading2: true,
      page: 1,
      projects: [],
      sections: [],
      // sects: [],
      subsections: [],
      pagination: {},
      filter: "?",
      fcategory: "",
      fcategory2: "",
      fsection: "",
      fsection2: "",
      projecttype: "",
      projecttype2: "",
      projectlengthtype: "",
      projectlengthtype2: "",
      projecttimetype: "",
      projecttimetype2: "",
    };
  },
  async mounted() {
    await this.getData();
  },
};
</script>
<style scoped>
.paginations li:hover a {
  color: #F15C3DFF;
}
*::-webkit-scrollbar-track {
  background: #F15C3DFF;
}

*::-webkit-scrollbar-thumb {
  background-color: #e7eaec;
  border-radius: 20px;
  border: 3px solid #F15C3DFF;
}
</style>