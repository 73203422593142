<template>
  <div class="col-lg-12 col-md-12">
  <div class="card skeleton search-filter ">
    <div class="card-header d-flex justify-content-between">
      <h4 class="card-title mb-0"></h4>

    </div>
    <div class="card-body">
      <div class="filter-widget">

        <div class="form-group">
          <select disabled="true" class="form-control select">

          </select>
        </div>
      </div>
      <div class="filter-widget">
        <h4></h4>
        <div class="form-group">
          <select  disabled="true" class="form-control select" >
          </select>
        </div>
      </div>
      <div class="filter-widget">
        <h4></h4>
        <p></p>
      </div>
      <div class="filter-widget">
        <h4></h4>
      </div>
      <div class="filter-widget  col-lg-12">
        <select  disabled="true" class="form-control select" >
        </select>
       <p class="mt-3" ></p>
      </div>
      <div class="filter-widget  col-lg-12">
        <h4></h4>
        <div class="form-group">
          <select  disabled="true" class="form-control select" >
          </select>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "filter-skeleton"
}
</script>
