import axios from "axios";
import VueCookies from 'vue-cookies';
import CryptoJS from "crypto-js";

function decrypt(text) {
    try {
        return CryptoJS.AES.decrypt(text, process.env.VUE_APP_CRYPTED_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {

    }
}

export default class API {
    get(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {

                axios
                    .get(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.status == true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again 1.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    getAvatar(url, headers = null) {
        return new Promise(resolve => {
                let response = {}
                axios.get(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                    headers: {
                        Authorization: decrypt(VueCookies.get("token")),
                    },
                } : headers)
                    .then((res) => {
                        response = {data: res};
                        resolve(response);
                    })
            }
        )

    }

    purePost(url, params, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL + url}`, params, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {

                        if (res) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again.'}
                resolve(response);
            }
        });

    }delete(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again.'}
                resolve(response);
            }
        });

    }


    getAddress(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {

                axios
                    .get(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        response = {status: 'success', data: res.data};
                        resolve(response);
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    getSearch(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {

                axios
                    .get(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.success == true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {
                                status: 'error1',
                                data: res.data.msg ?? 'Unknown error, please try again 1.'
                            };
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    post(url, params, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL + url}`, params, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.status == true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again.'}
                resolve(response);
            }
        });
    }

    login(url, params, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL + 'auth/login'}`, params, headers == null ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.access_token != null) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again.'}
                resolve(response);
            }
        });
    }

    getCustom(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .get(url, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.status == true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {
                                status: 'error1',
                                data: res.data.msg ?? 'Unknown error, please try again 1.'
                            };
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    getAddress(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL + url}`, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data != true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {
                                status: 'error1',
                                data: res.data.msg ?? 'Unknown error, please try again 1.'
                            };
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    getRaw(url, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .get(url, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again 2.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again 3.'}
                resolve(response);
            }
        });
    }

    postCustom(url, params, headers = null) {
        return new Promise(resolve => {
            let response = {}
            try {
                axios
                    .post(url, params, headers == null || VueCookies.get('loggedin') == 'true' ? {
                        headers: {
                            Authorization: decrypt(VueCookies.get("token")),
                        },
                    } : headers)
                    .then((res) => {
                        if (res.data.status == true) {
                            response = {status: 'success', data: res.data};
                            resolve(response);
                        } else {
                            response = {status: 'error1', data: res.data.msg ?? 'Unknown error, please try again.'};
                            resolve(response);
                        }
                    }).catch(err => {
                    response = {status: 'error2', data: err.message ?? 'Unknown error, please try again.'}
                    resolve(response);
                });
            } catch (error) {
                response = {status: 'error3', data: error ?? 'Unknown error, please try again.'}
                resolve(response);
            }
        });
    }
}
