<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading"  >
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->

      <layout-header/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="" src="../../../assets/img/home-icon.svg"> Home</router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Projects</li>
                    <li aria-current="page" class="breadcrumb-item">{{ $route.path.split('/')[2] }}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">

          <div class="row">
            <div class="col-lg-12 col-md-12">

              <div class="row blog-grid-row">
                <div v-for="item in subs" :key="item.id" class="col-md-3 col-sm-12">
                  <!-- Blog Post -->
                  <div class="s-grid-card grid-card">
                    <div class="card-image">
                      <router-link :params="`${item.seo_title}`" :to="`/project/${item.slug}`"><img :src="item.photo_url" alt="Post Image"
                                                                                                     class="img-fluid"></router-link>
                    </div>
                    <div class="card-content">
                      <h3 class="m-card-title">
                        <router-link :params="`${item.seo_title}`" :to="`/project/${item.slug}`">{{ item.title.en }}</router-link>
                      </h3>
                    </div>
                  </div>
                  <!-- /Blog Post -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  created() {
    this.getSubCategories()
  },
  inject: ['api'],
  methods: {
  async  getSubCategories() {
    await  this.api.get(`categories/${this.$route.path.split('/')[2]}`).then(res => {
        if(res.status == 'success'){
          this.subs = res.data.data.section.childs
          this.loading = false
        }else{
       location.replace('404')
        }
      })
    }
  },
  data() {
    return {
      loading:true,
      subs: [],
    }
  }
}
</script>