<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <h2 class="breadcrumb-title">Cookies POLICY</h2>
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li aria-current="page" class="breadcrumb-item active"> Cookies POLICY</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="terms-content">
                <div class="terms-text">
                  <p>TravelFront, a company incorporated in the Cayman Islands (hereinafter referred to as “Gooreo”,
                    “we” or “us”) recognises the importance of privacy. In this privacy policy, we describe how we
                    collect, use, and disclose information that we obtain about visitors to our website, www.Gooreo.com,
                    and the services available through our website.
                  </p>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>1. Information we collect.</h3>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <p>We collect personal information directly from you, from third parties, and automatically through
                    your
                    use of our website. We may combine information automatically collected with other information that
                    we
                    have collected about you.
                    Personal information is any information that is about you.
                    We are the Data Controller for data collected through our website.
                    If you are in the EU or the UK, the lawful basis when we process your personal information for the
                    provision of our services is pursuant to a contract.</p>
                  <p>If you are in the EU or the UK, the lawful basis when we process your personal information for
                    marketing is consent.
                    You may browse and use certain portions of our website without directly providing us with any
                    personal
                    information.
                    Certain features, however, may only be used by users that are registered or where you provide us
                    with
                    personal information.</p>
                  <p>Information we collect from you (or someone acting for you):</p>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <ul>
                    <li>contact information, including your name, address, email address, telephone number and other
                      contact details.
                    </li>
                    <li>billing or payment information, such as your credit card number, cardholder name, expiration
                      date, authentication code and billing address.
                    </li>
                    <li>details of the products and services we have provided to you or that you have enquired about,
                      including any additional information necessary to deliver those products and services and respond
                      to your enquiries.
                    </li>
                    <li>information you provide to us through customer surveys or feedback.</li>
                    <li>passport information, including your passport number, passport expiration date, and date of
                      birth.
                    </li>
                    <li>loyalty program details (such as frequent flyer details).</li>
                    <li>information about your health (if disclosed)</li>
                    <li>your device ID, device type, geo-location information, computer and connection information,
                      statistics on page views, traffic to and from the Website, ad data, IP address and standard web
                      log information.
                    </li>
                    <li>
                      any additional information relating to you that you provide to us through your use of our website,
                      in person or through other websites or accounts from which you permit us to collect information.
                    </li>
                    <li>any other personal information that may be required in order to facilitate your dealings with
                      us.
                    </li>
                  </ul>
                  <p>Information about you we collect from third parties. If you apply to work with us, we collect
                    information from you from:</p>
                  <ul>
                    <li>
                      recruitment consultants.
                    </li>
                    <li>your previous employers</li>
                    <li>other organisations that are authorised to give us information about you</li>
                  </ul>
                  <p>to assist to decide whether we can work with you.
                    Information we collect automatically. We automatically collect information through your use of our
                    website using cookies and other technologies. This information includes your</p>
                  <ul>
                    <li>the domain name, operating system, settings and system configurations.</li>
                    <li>IP address</li>
                    <li>the webpages you access within our website</li>
                    <li>the website that led you to our website</li>
                    <li>the website to which you go after leaving our website</li>
                    <li>the dates and times you access our website</li>
                    <li>weblog personal information.</li>
                  </ul>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>2. How do we use your information?</h3>
                  <p>We use your information for the following purposes:</p>
                  <ul>
                    <li>
                      Providing our services. Provide accommodation, flights, and experiences booking services on the
                      website, to manage your account, to communicate with you, including via email, about your use of
                      our services, to respond to your inquiries, and for similar customer service purposes.
                    </li>
                    <li>
                      Marketing. Where you consent, we will provide you with news, special offers, promotions, and
                      information about products we think may interest you; and for other marketing, advertising, and
                      promotional purposes provided that you have not opted out of receiving such communications.
                    </li>
                    <li>Analysing Use of Our Services. To better understand how users access and use our website and
                      services, on an aggregated basis, to respond to user desires and preferences, and for other
                      research and analytical purposes.
                    </li>
                    <li>To Protect Rights and Interests. To protect our rights and interests as well as the rights and
                      interests of our customers, users of our website or services, and any other person.
                    </li>

                  </ul>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>3. Marketing.</h3>
                  <p>Gooreo would like to send you information about products and services of ours that we think you
                    might like.
                  </p>
                  <p>
                    If you have agreed to receive marketing, you may always opt out at a later date.
                  </p>
                  <p>
                    You have the right at any time to stop us from contacting you for marketing purposes.
                  </p>
                  <p>
                    If you no longer wish to be contacted for marketing purposes, please click the ‘Unsubscribe’ button
                    contained in the footer of each of our emails or contact us at <a href=mailto:info@gooreo.com?>info@gooreo.com</a>.
                  </p>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>4. How do we store your personal information?</h3>
                  <p>We undertake a number of physical, administrative, personnel, and technical measures to protect
                    your personal information and prevent it from misuse, interference and loss, as well as unauthorised
                    access, modification or disclosure. Our data security measures include, but are not limited to
                    Secure Sockets Layer (SSL) encryption technology, pseudonymisation, internal data access
                    restrictions, and strict physical access controls to buildings & files.</p>
                  <p>
                    Gooreo will keep your personal information for the duration that your account is active, and for the
                    requisite period in accordance with applicable law following the deactivation of your account. Once
                    this time period has expired, we will delete your personal information.</p>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>5. Security</h3>
                  <p>We take reasonable steps to protect your personal information from misuse, loss, unauthorised
                    access, modification or disclosure. For example, we take steps to destroy or permanently de-identify
                    personal information if we no longer need it for any purpose. Please be aware that despite our
                    efforts, no personal information security measures can guarantee 100% security.
                  </p>
                  <p>
                    If you have an account with us, you should take steps to protect against unauthorised access to your
                    account by, among other things, choosing a robust password that nobody else knows or can easily
                    guess and keeping your log-in and password private. We are not responsible for any lost, stolen, or
                    compromised passwords or for any activity on your account via unauthorised password activity.
                  </p>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>6. How we disclose your information.</h3>
                  <p>We may disclose your information, including personal information, with the following entities:</p>
                  <ul>
                    <li>
                      Our Group of Companies and Employees. We disclose your required information to our employees,
                      subsidiary, related and/or affiliated companies in order to perform the necessary duties to
                      provide you with our services.
                    </li>
                    <li>Service Providers. We disclose your information to our vendors, service providers, or others who
                      perform functions on our behalf. All service providers are required to keep your personal data
                      safe, and process pursuant to a data processing agreement. If the service provider is located
                      outside of the UK or the EU, we put in place measures to ensure that your information has the same
                      level of protection.
                    </li>
                  </ul>
                  <p>We may also disclose your information, including personal information, in the following ways:</p>
                  <ul>
                    <li>Business Transfers. We may disclose your information to another entity if we are acquired by or
                      merged with another company, if we sell or transfer a business unit or assets to another company,
                      as part of a bankruptcy proceeding, or as part of a similar business transfer.
                    </li>
                    <li>Protecting Rights and Interests. We may disclose your information where we believe it is
                      necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud,
                      situations involving potential threats to the safety of any person, violations of our Terms and
                      Conditions or this privacy policy, or as evidence in litigation in which we are involved.
                    </li>
                    <li>Cross Border Disclosure of Information. We may disclose your personal information to
                      international third parties, including countries outside the European Economic Area (EEA)
                      (collectively “Cross-border Disclosure”), generally to arrange travel with a travel service
                      provider on your behalf. Whenever we perform Cross-border Disclosures, we will do so in accordance
                      with applicable law and ensure that a similar degree of protection is afforded to it by
                      implementing appropriate safeguards. Cross-border Disclosures outside the EEA will only be made:
                      <ul>
                        <li> to a country recognised by the European Commission as providing an adequate level of
                          protection.
                        </li>
                        <li>to a country which does not offer adequate protection, but whose transfer has been governed
                          by the standard contractual clauses of the European Commission, or by implementing other
                          appropriate cross-border transfer solutions to provide adequate protection.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <h3>7. Cookies and other tracking mechanisms.</h3>
                  <p>We and our service providers use cookies and other tracking mechanisms to track your use of our
                    website or services. We use these in a range of ways including:</p>
                  <ul>
                    <li>Keeping you signed in.</li>
                    <li>Understanding how you use our website</li>
                    <li>Functionality - Gooreo uses tracking mechanisms so that we recognise you on our website and
                      remember your previously selected preferences. These could include what language you prefer and
                      the location you are in. A mix of first-party and third-party tracking mechanisms are used
                    </li>
                    <li>Advertising - Gooreo uses these tracking mechanisms to collect information about your visit to
                      our website, the content you viewed, the links you followed and information about your browser,
                      device, and your IP address. Gooreo shares some limited aspects of this personal information with
                      third parties for advertising purposes. We also share personal information collected through
                      tracking mechanisms with our advertising partners. This means that when you visit another website,
                      you may be shown advertising based on your browsing patterns on our website.
                    </li>
                  </ul>
                  <p>Types of tracking mechanism we use:</p>
                  <ul>
                    <li>Cookies. We or our service providers use cookies to track visitor activity on our website. A
                      cookie is a text file that a website transfers to your computer's hard drive for record-keeping
                      purposes. We or our service providers may use cookies to track user activities on our website,
                      such as the pages visited and time spent on our website. Most browsers allow users to refuse
                      cookies. The ‘Help’ portion of the toolbar on most browsers will tell you how to prevent your
                      computer from accepting new cookies, how to have the browser notify you when you receive a new
                      cookie, or how to disable cookies altogether. Users who disable cookies may not be able to browse
                      certain areas of the website. Please refer to our Cookie Policy for further information in regards
                      to Cookies used on our website
                    </li>
                    <li>Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique
                      identifier, similar in function to cookies, which are embedded invisibly on web pages. We or our
                      service providers may use clear GIFs (also known as web beacons, web bugs or pixel tags), in
                      connection with our website to track the activities of visitors to our website, help us manage
                      content, and compile statistics about usage of our website. We or our service providers also use
                      clear GIFs in HTML emails to our users, to help us track email response rates, identify when our
                      emails are viewed, and track whether our emails are forwarded.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="terms-content">
                <div class="terms-text">
                  <div class="terms-content">
                    <div class="terms-text">
                      <h3>8. Third-party analytics.</h3>
                      <p>We use service providers, such as Google Analytics demographics and interests reports as well
                        as advertising reporting features, to evaluate the use of our website and our services. We or
                        our service providers use automated devices and applications to evaluate the use of our
                        website and services. We or our service providers use these tools to help us improve our
                        website, services, performance, and user experiences. These entities may use cookies and other
                        tracking technologies, such as web beacons or Flash LSO, to perform their services. To opt-out
                        of Google Analytics, go here. To opt-out of Google Analytics for display advertising or
                        customise Google display network ads, you can visit the Google Ads Settings page.</p>
                      <h3>10. Interest-based advertising.</h3>
                      <p>We use third parties such as network advertisers to serve advertisements on our website and on
                        third-party websites or other media (e.g., social networking platforms). This enables us and
                        these third parties to target advertisements to you for products and services in which you might
                        be interested.</p>
                      <p>Users in the United States may opt-out of many third-party ad networks. For example, you may go
                        to the Digital Advertising Alliance ("DAA”) Consumer Choice Page for information about opting
                        out of interest-based advertising and their choices regarding having information used by DAA
                        companies. You may also go to the Network Advertising Initiative ("NAI”) Consumer Opt-Out Page
                        for information about opting out of interest-based advertising and their choices regarding
                        having information used by NAI members.</p>
                      <p>Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI
                        Consumer Opt-Out Page will opt you out from those companies' delivery of interest-based content
                        or ads to you, but it does not mean you will no longer receive any advertising through our
                        website. You may continue to receive advertisements, for example, based on the particular
                        website that you are viewing (i.e., contextually based ads). Also, if your browsers are
                        configured to reject cookies when you opt out on the DAA or NAI websites, your opt-out may not
                        be effective. </p>
                      <h3>10. User-generated content.</h3>
                      <p>Note that if you post information in a publicly accessible portion of our website or service,
                        it may be viewed by other users and potentially be further disclosed by those users. Please
                        exercise caution when deciding to disclose such information.</p>
                      <h3>11. Your choices.</h3>
                      <p>We take steps to ensure the personal information that Gooreo collects, uses or discloses is
                        accurate, complete and up-to-date.
                        You may modify your personal information by updating your account on our website or by
                        contacting us at <a href=mailto:info@gooreo.com?>info@gooreo.com</a>. On request, we will give
                        you access to the personal
                        information we hold about you. If any personal information we hold about you is out of date or
                        inaccurate, we encourage you to let us know by contacting us using one of the methods listed
                        above and ask us to correct it.</p>
                      <p>If you are a registered user, we may send periodic informational emails to you. You may opt-out
                        of such communications at any time by following the opt-out instructions contained in the email.
                        Please note that it may take up to five business days for us to process opt-out requests.
                        If you opt-out of receiving emails about recommendations or other information we think may
                        interest you, we will still send you emails about your account or any services you have
                        requested or received from us provided that you have not opted-out of receiving such emails.</p>
                      <h3>12. Your Rights.</h3>
                      <p>The right to access – You have the right to request us for copies of your personal information.
                        We may charge you a small fee for this service.
                        The right to rectification – You have the right to request that we correct any information you
                        believe is inaccurate. You also have the right to request us to complete the information you
                        believe is incomplete.
                        The right to erasure – You have the right to request that we erase your personal information,
                        under certain conditions.
                        The right to restrict processing – You have the right to request that we restrict the processing
                        of your personal information, under certain conditions.</p>
                      <p>The right to object to processing – You have the right to object to our processing of your
                        personal information, under certain conditions.
                        The right to personal information portability – You have the right to request that we transfer
                        the personal information that we have collected to another organisation, or directly to you,
                        under certain conditions.
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us at <a href=mailto:info@gooreo.com?>info@gooreo.com</a></p>
                      <h3>13. Children</h3>
                      <p>Our website is not targeted to children under 13 years of age and we do not knowingly collect
                        personal information from children under 13 without parental consent.
                        If we discover that the personal information of a child under 13 of age is in the system without
                        parental consent, we will promptly delete such personal information from our systems.
                        We encourage children of all ages to obtain their parent's or guardian's permission before
                        sharing personal information with any website.</p>
                      <h3>14. External links</h3>
                      <p>This website contains links to other websites. We are not responsible for the information
                        handling practices or content of these external websites. Please read the privacy policies of
                        these third parties before using such websites.</p>
                      <h3>15. Contact us</h3>
                      <p>If you have any questions or concerns about the privacy aspects of our website or services or
                        want to complain about an interference with your privacy by Gooreo, please email us at
                        info@gooreo.com. We will do our best to resolve your complaint as quickly as possible.
                        You can also contact your local data protection authority to lodge a complaint.</p>
                      <p>If you are in the EU you can find your Data Protection Authority here.
                        If you are in the UK you can contact the Information Commissioner’s Office here.</p>
                      <h3>16. Changes to this privacy policy</h3>
                      <p>We may change this privacy policy from time to time, and new versions will be posted on this
                        website, so please check back periodically for updates.</p>
                      <p>If you are registered with us, we will let you know when we update this privacy policy.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<style scoped>
a {
  font-size: 16px;
  color: #009df8
}
</style>
<script>
export default {
  mounted() {

  },
}
</script>