<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header></layout-header>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                      ><img alt="" src="../../../assets/img/home-icon.svg"/>
                        Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item active">
                      PROFILE
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <profilebanner :user="user" :userData="userData" :loading="loading"></profilebanner>
      <div class="content">
        <div class="container">
          <div class="row">

            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <usertab></usertab>
                  <!-- Projects Tab Content -->
                  <div class="pro-post widget-box company-post">
                    <h3 class="pro-title">Projects</h3>
                    <div v-if="userData.projects =='' " class="d-flex ">
                      <p>No projects added yet</p>
                    </div>
                    <div
                        v-if="userData.projects"
                        v-for="item in userData.projects"
                        :key="item.id"
                        class="col-md-6 col-lg-12 col-xl-12"
                    >
                      <div class="freelance-widget widget-author">
                        <div class="freelance-content ">
                          <button class="favourite " @click="addFavorite(item.id)">
                            <i
                                :style="item.favorited ? 'color:yellow' : ''"
                                :class="
                            item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                          "
                            ></i>
                          </button>
                          <div class="author-heading">
                            <div class="d-flex ">
                              <h3
                                  class="my-4"
                                  tabindex="-1"
                              >
                                {{ item.seo_title }}
                              </h3>
                            </div>
                            <div class="freelancers-price">
                              ${{ parseFloat(item.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(item.project_budget_to)?.toFixed(2) }}
                            </div>
                          </div>
                          <div class="counter-stats">
                            <ul>
                              <li>
                                <h5>Project Length</h5>
                                <h3 class="counter-value">
                                  {{ item.project_length_from }} -
                                  {{ item.project_length_to }}
                                  {{ item.project_length_type }}
                                </h3>
                              </li>
                              <li>
                                <h5>Proposals</h5>
                                <h3 class="counter-value">
                                  {{ item.orders_count }}
                                </h3>
                              </li>
                              <li>
                                <h5>Payment Type</h5>
                                <h3 class="counter-value">
                              <span class="jobtype">{{
                                  item.project_type
                                }}</span>
                                </h3>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <router-link
                              :params="`${item.seo_title}`"
                              :to="`/project/${item.slug}`"
                              class="btn-cart"
                              style="background:#f15c3d"
                              tabindex="-1"
                          >
                            Apply now
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Projects Tab Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer></layout-footer>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>
export default {
  inject: ["api"],
  methods: {
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.userData.projects).map((value, key) => {
            if (this.userData.projects[value].id == id) {
              this.userData.projects[value].favorited = !this.userData.projects[
                  value
                  ].favorited;
            }
          });
        } else {
        }
      });
    },
    async getUserInfo() {
      await this.api.get(`profile/${this.$route.path.split("/")[2]}`).then((res) => {
        if (res.status == "success") {
          this.userData = res.data.data;
          this.user = res.data.data.user;
          this.loading = false
        } else {
          location.replace('404')
        }
      });
    },
  },
  created() {
    this.getUserInfo();
  },
  data() {
    return {
      user: {},
      userData: {},
      loading: true,
    };
  },
};
</script>
<style scoped>
.pro-title:after {
  background-color: #f15c3d
}

.pro-title:before {
  background-color: #f15c3d
}

.content {
  padding: 7px 0 30px;
}
</style>