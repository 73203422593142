<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->

      <layout-header />
      <!-- /Header -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">

          <div class="row">
            <div class="col-md-6 offset-md-3">

              <!-- Forgot Password Content -->
              <div class="account-content">
                <div class="align-items-center justify-content-center">
                  <div class="login-right">
                    <div class="login-header text-center">
                      <router-link to="/"><img alt="logo" class="img-fluid" src="../../../assets/img/logo-01.png">
                      </router-link>
                      <h3>Forgot your password?</h3>

                    </div>
                    <form method="post">
                      <div class="form-group form-focus">
                        <input v-model="email" class="form-control floating" type="email" ref="email">
                        <label class="focus-label">Email</label>
                      </div>
                      <router-link to="/login">
                        <vue-recaptcha :sitekey="webkey">

                          <button class="btn btn-primary btn-block btn-lg login-btn" @click.prevent="Forgot()">Send
                            Email</button>
                        </vue-recaptcha>

                      </router-link>
                      <div class="row form-row">
                        <div class="col-6 text-start">
                          <router-link class="forgot-link" to="/login">Remember your password?</router-link>
                        </div>
                        <div class="col-6 text-end dont-have">New to Gooreo?
                          <router-link to="/register">Click here</router-link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- /Forgot Password Content -->

            </div>
          </div>

        </div>
      </div>
      <!-- /Page Content -->
      <!-- Footer -->
      <layout-footer />
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      webkey: process.env.CAPTCHA_webKey,
    }
  },
  inject: ['api'],
  methods: {
    async Forgot() {
      if (this.email != "" && this.email != null) {
        this.api.post('auth/forgot-password', { email: this.email, }).then(res => {

          if (res.status == 'success') {

            this.toast(res.data.msg, 'success');
            this.email = "";

          } else {
            this.toast(res.data.msg, 'danger')
          }
        });
      }
      else {
        this.$refs.email.focus();
        this.toast('Email Empty', 'danger')
      }

    }
  }
}
</script>