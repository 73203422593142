<template>
  <div class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">Create project</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="card" v-if="!isFreelancer">
                <div class="card-body">
                  <div class="warning-card mb-4">
                    <p><i class="fas fa-exclamation-triangle"></i>
                      This page is for employers to add their projects
                    </p>
                  </div>
                  <div class="d-flex space-between">
                    <h3 class="mb-4">Create Project</h3>
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <form class="needs-validation" novalidate>
                        <div class="form-row row">
                          <div class="col-md-12 mb-3">
                            <label for="validationCustom01">Project title</label>
                            <div class="d-flex">
                              <span style="flex-wrap: nowrap;  margin: auto;width: 70px;padding: 10px;">I want</span>
                              <input id="validationCustom01" v-model="title" class="form-control" placeholder="Title"
                                     required type="text">
                            </div>
                            <div class="valid-feedback">
                              Looks good!
                            </div>
                          </div>
                          <p>Include a short title that accurately describes your project.</p>
                          <h3 class="mt-2 ">Category</h3>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Main Category:</label>
                              <select v-model="maincategorey" class="form-select" @change="selectCat()">
                                <option v-for="cat in parents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Sub Category:</label>
                              <select v-model="subcategory" class="form-select">
                                <option v-for="cat in subparents" :value="cat.text">{{ cat.text }}</option>
                              </select>
                            </div>
                          </div>
                          <h3 class="mt-2 mb-2">Describe your project</h3>
                          <p class="mt mb-2">Include a detailed and accurate description of your project.
                            (It must be at least 40 character)
                          </p>
                          <div class="form-group m-2">
                            <QuillEditor ref="editor" v-model:content="description"
                                         :toolbar="editorConfig.modules.toolbar"
                                         theme="snow"/>
                            <!-- <textarea v-model="description" class="form-control" cols="5" placeholder=" Description"
                                      rows="5"></textarea> -->
                          </div>
                          <br>
                          <div class="m-2">
                            <h3 class="mt-2 mb-2">Attach images</h3>
                            <div>
                              <file-pond ref="pond" accepted-file-types="image/*" allow-multiple="true"
                                         allowReorder="true"
                                         class-name="my-pond" credits="false" label-idle="Drop files here..."
                                         maxFiles="10"
                                         name="test" v-on:init="handleFilePondInit"
                                         v-on:updatefiles="handleFilePondInit"/>
                              <!-- <input id="file" accept="image/*" class="hide-input" name="image" type="file"
                                @change="loadFile">
                              <label class="upload" for="file">
                                <i class="fa fa-upload"></i>
                              </label> -->
                            </div>
                          </div>
                          <h6 class="settings-size mt-4"></h6>
                          <div class="upload-images upload-size w-100 d-flex flex-wrap">
                            <div v-for="img in showimages" class="">
                              <img :src="img" alt="Image"
                                   style="height: 125px; width: 150px; object-fit: cover; margin: 0 5px;">
                              <div class="removeImg"
                                   style="color: tomato; cursor: pointer; position: relative; top: -125px; left: 5px;"
                                   @click="removeImage(img)">
                                <i class="far fa-times-circle"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Project type: </label>
                              <select v-model="paymenttype" class="form-select">
                                <option value="once">Once (Projects that pay in full at once)</option>
                                <option value="periodically">Recruitment (Salary Payment Type)</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="paymenttype == 'once'" class="col-md-12">
                            <h3 class="mt-2 ">Project length</h3>
                          </div>
                          <div v-if="paymenttype == 'once'" class="form-group form-placeholder col-md-4 ">
                            <label>Project lenght from: </label>
                            <input v-model="length_from" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div v-if="paymenttype == 'once'" class="form-group form-placeholder col-md-4 ">
                            <label>Project lenght to: </label>
                            <input v-model="length_to" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div v-if="paymenttype == 'once'" class="col-md-4">
                            <div class="form-group">
                              <label>Project lenght type: </label>
                              <select v-model="lengthtype" class="form-select">
                                <option value="day">Day</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="paymenttype == 'once'" class="col-md-12">
                            <h3 class="mt-2 ">Project budget</h3>
                          </div>
                          <div v-if="paymenttype == 'once'" class="form-group form-placeholder col-md-6 ">
                            <label>Project budget from: </label>
                            <input v-model="budget_from" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div v-if="paymenttype == 'once'" class="form-group form-placeholder col-md-6 ">
                            <label>Project budget to: </label>
                            <input v-model="budget_to" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div v-if="paymenttype == 'periodically'" class="col-md-12">
                            <h3 class="mt-2 ">Work Type</h3>
                          </div>
                          <div v-if="paymenttype == 'periodically'" class="col-md-4">
                            <div class="form-group">
                              <select v-model="timetype" class="form-select">
                                <option value="part">Part time</option>
                                <option value="full">Full time</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="paymenttype == 'periodically'" class="col-md-12">
                            <h3 class="mt-2 ">Project budget</h3>
                          </div>
                          <div v-if="paymenttype == 'periodically'" class="form-group form-placeholder col-md-6 ">
                            <label>Number of months: </label>
                            <input v-model="months" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                          <div v-if="paymenttype == 'periodically'" class="form-group form-placeholder col-md-6 ">
                            <label>Budget: </label>
                            <input v-model="budget_from" class="input-tags form-control" data-role="tagsinput"
                                   placeholder="Price" style="min-height: 40px;" type="text"
                                   @keypress="isNumber($event)">
                          </div>
                        </div>
                        <div class="warning-card"><h6><i class="fas fa-exclamation-triangle"></i> Warning: The project
                          cannot be modified once is published.</h6></div>
                        <vue-recaptcha :sitekey="webkey">
                          <button class="btn btn-primary mt-5 " type="submit" @click="publish()"> Publish</button>
                        </vue-recaptcha>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full h-100 p-16 d-flex flex-column align-items-center justify-content-center  m-auto p-auto">
                <please-switch></please-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
    <!-- /Main Wrapper -->
  </div>
  <loader v-if="loading"></loader>
</template>
<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType
  from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
export default {
  components: {
    FilePond
  },
  methods: {
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectCat() {
      this.subparents = this.parents.filter(p => p.text == this.maincategorey)[0].childs
      this.subcategory = this.subparents[0]
    },
    addKeyword(word) {
      this.keywords.push({
        value: word,
        text: word,
      })
      this.keyword = ''
    },
    handleFilePondInit: function () {
      // console.log("FilePond has initialized");
      // example of instance method call on pond reference
      this.images = this.$refs.pond.getFiles().map((file) => file.file);
      // console.log(this.form.photo);


    },
    loadFile(event) {
      this.images.push(event.target.files[0])
      this.showimages.push(URL.createObjectURL(event.target.files[0]))
    },
    removeImage(img) {
      this.showimages.splice(img, 1)
      this.images.splice(img, 1)
    },
    removeKeyword(word) {
      this.keywords.splice(this.keywords.indexOf(word), 1)
    },
    async getData() {
      this.api.get('user/create/projects').then(res => {
        if (res.status == 'success') {
          this.parents = res.data.data.parents
          this.subparents = res.data.data.parents[0].childs
          this.maincategorey = res.data.data.parents[0].text
          this.subcategory = res.data.data.parents[0].childs[0].text
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async publish() {
      this.loading = true
      let form = new FormData();
      for (var i = 0; i < this.images.length; i++) {
        let file = this.images[i];
        form.append('photo[' + i + ']', file);
      }
      form.append('title', 'I want ' + this.title
      )
      form.append('content', this.$refs.editor.getHTML())
      form.append('section_id', this.parents.filter(e => e.text == this.maincategorey)[0].value)
      form.append('user_id', JSON.parse(this.decrypted($cookies.get("user"))).id)
      form.append('project_type', this.paymenttype)
      form.append('project_length_from', this.length_from)
      form.append('project_length_to', this.length_to)
      form.append('project_length_type', this.lengthtype)
      form.append('project_budget_from', this.budget_from)
      form.append('project_budget_to', this.budget_to)
      form.append('project_time_type', this.timetype ?? 'part')
      form.append('project_number_of_month', this.months)
      this.api.post('user/store/project', form, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': this.decrypted($cookies.get("token"))
        }
      }).then(res => {
        if (res.status == 'success') {
          this.toast("Project Published", 'success')
          this.$router.push('/user/projects-manager')
        } else {
          this.loading = false
          if (res.data.title) {
            this.toast(res.data.title[0], 'danger')
          } else if (res.data.content) {
            this.toast(res.data.content[0], 'danger')
          } else if (res.data.project_length_from) {
            this.toast(res.data.project_length_from[0], 'danger')
          } else if (res.data.project_length_to) {
            this.toast(res.data.project_length_to[0], 'danger')
          } else if (res.data.project_budget_from) {
            this.toast(res.data.project_budget_from[0], 'danger')
          } else if (res.data.project_budget_to) {
            this.toast(res.data.title[0], 'danger')
          }
        }
      })
    }
  },
  async created() {
    await this.getData()
    this.time = this.deliveryTime[0]
  },
  inject: ['api'],
  data() {
    return {
      editorConfig: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            // superscript/subscript
            [{indent: "-1"}, {indent: "+1"}], // outdent/indent
            [{direction: "rtl"}], // text direction

            [{size: ["small", false, "large", "huge"]}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],

            [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}],
            [{align: []}],

            ["clean"],
          ],
        },
        theme: "snow",
      },
      webkey: process.env.CAPTCHA_webKey,
      loading: true,
      paymenttype: 'once',
      timetype: 'part',
      lengthtype: 'day',
      budget: 0,
      months: 0,
      length_from: 0,
      length_to: 0,
      budget_from: 0,
      budget_to: 0,
      title: '',
      maincategorey: '',
      subcategory: '',
      maincategoreyId: 0,
      subcategoryId: 0,
      description: '',
      images: [],
      showimages: [],
      parents: [],
      subparents: [],
      time: '',
      deliveryTime: [
        '1 Day',
        '2 Days',
        '3 Days',
        '4 Days',
        '5 Days',
        '6 Days',
        '7 Days',
        '10 Days',
        '15 Days',
        '20 Days',
        '25 Days',
        '30 Days',
        '45 Days',
      ],
      keyword: '',
      price: 0,
      keywords: [],
      instructions: '',
    }
  }
}
</script>
<style scoped>
.warning-card p {
  color: #3f4c53;
  font-size: 15px;
  margin-bottom: 0;
}

.warning-card i {
  color: #cc2626cc;
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
    height: 250px;
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
    height: 250px;
  }
}

.settings-bt {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px 15px;
  position: relative;
  width: 100%;
  height: 46px;
}

.settings-bt .upload {
  position: absolute;
  z-index: 11;
  background: #009df8;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fdfdfd;
}

.settings-bt .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}

.setting-size {
  font-size: 14px;
  color: #131523;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.setting-size span {
  font-weight: 700;
}

.warning-card {
  border-radius: 5px;
}

.warning-card h6 {
  color: #C21010;
}

.warning-card i {
  color: #C21010;
}
</style>