<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader />
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../../../assets/img/home-icon.svg">
                        Home
                      </router-link>
                    </li>
                    <li aria-current="page" class="breadcrumb-item">My Services</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <layout-employeesidebar></layout-employeesidebar>
            <div class="col-xl-9 col-md-8">
              <div class="page-title">
                <h3>Manage Services </h3>
              </div>
              <services-header/>
              <!-- Proposals list -->
              <div v-if="services.length==0" class="m-5">
                <div><h4 style="margin:auto;width:110px">Nothing yet</h4></div>
              </div>
              <div v-else  class="proposals-section">
                <!-- Proposals -->
                <div v-for="item in services" :key="item.id" class="my-projects-list">
                  <div class=" align-items-center freelancer">
                    <div class="projects-card d-flex space-between p-4">
                      <div class="me-3 ">
                        <h4 class="title-info mb-5 "> ID</h4>
                        <h2 class="client-price  " style="font-size:16px">{{ item.id }} </h2>
                      </div>

                      <div class="content-divider-1"></div>
                      <div class="me-2 mx-2">
                        <img :src="item.photo_url" alt="" class="sales-img ">
                      </div>
                      <div class="content-divider-1"></div>

                      <div class="projects-amount ">
                        <h4 class="me-2 mx-2 mb-5 "> Title</h4>
                        <h2 class="client-price pe-1">{{ item.title.en }} </h2>
                      </div>
                      <div class="content-divider-1"></div>
                      <div class="projects-amount2">
                        <h4 class="title-info mb-5 "> Section </h4>
                        <h2 class="client-price ">{{ item.cat_title.en }} </h2>
                      </div>
                      <div class="content-divider-1"></div>
                      <div class="projects-amount1">
                        <h4 class="title-info mb-5 "> Views </h4>
                        <h2 class="client-price ">{{ item.views_count }} <i class="fa fa-eye"></i></h2>
                      </div>
                      <div class="content-divider-1"></div>
                      <div class="projects-amount1">
                        <h4 class="title-info mb-5 "> Orders </h4>
                        <h2 class="client-price ">{{ item.orders_count }} </h2>
                      </div>
                      <div class="content-divider-1"></div>
                      <div class="projects-action text-center">
                        <div style="margin: auto;width: 75%;" class="d-flex text-center">
                          <p class="me-3">Active</p>
                          <input hidden @change="activated( item.status , item.id)" v-model="item.status"
                                 type="checkbox" :id="item.id" class="check">
                          <label :for="item.id" class="checktoggle">{{ item.status }}</label>
                        </div>
                        <router-link :to="`/user/services/${item.id}/edit`" class="projects-btn mb-1">Edit</router-link>
                        <router-link :to="`/marketplace/${item.slug}`" class="projects-btn">View
                        </router-link>
                        <button class="projects-btn mt-1" data-bs-dismiss="modal"
                                style=" background-color:#DA1212; border:none " type="button"
                                @click="deleteService(item.id)"><span>Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Proposals list -->
            <!-- pagination -->
            <!-- /pagination -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <!-- Footer -->
    <layout-footer />
    <!-- /Footer -->
  </div>
</template>

<script>
export default {
  inject: ['api'],
  methods: {
    activated(status1 ,id1) {
      this.api.post(`user/updateStatus/project`, {'_method' : 'post', status: status1,id:id1 },).then(res => {
        if(res.status == 'success'){
          this.toast('Saved', 'success')
        } else{
          this.toast(res.data, 'danger')
        }
      })
    },
    async deleteService(id) {
      this.api.post(`user/delete/service/${id}`, {'_method' : 'delete'}).then(res => {
        if (res.status == 'success') {
          this.toast(' deleted', 'success')
          setTimeout(() => {
            location.reload()
          }, 2000)
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    async getData() {

      this.api.get('user/services?status=pending').then(res => {
        if(res.status == 'success'){
          this.services = res.data.data.services.data
          this.loading = false
        }else{
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  data() {
    return {

      loading: true,
      activeClass: 'active',
      services: [],


    }
  },
  async created() {
    await this.getData()
  },

}

</script>