<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="" src="../../assets/img/home-icon.svg"> Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/marketplace">Contact US</router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content p-1">
        <div class="container ">
          <div class="  row  inner-banner">
            <layout-employeesidebar ></layout-employeesidebar>
            <div class="col-xl-9 ">
              <div class="card">
                <div class="card-body">
                  <div class="card-header  mb-4">
                    <h4 class="card-title">contact us </h4>
                  </div>
                  <form action="#">
                    <h5 class="card-title">Personal Information</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Username</label>
                          <input class="form-control" type="text">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input class="form-control" type="text">
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Message</label>
                        <textarea class="form-control" rows="5"> </textarea>
                      </div>
                    </div>
                    <div class="text-end">
                      <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <layout-footer/>
  </div>
  <loader v-show="loading"></loader>

</template>

<script>
export default {
  name: "contactus"
}
</script>

<style scoped>

</style>