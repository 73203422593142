<template>
  <teleport to="head">
    <meta charset="UTF-8"/>
    <meta :content="'Gooreo |' + ' ' + category.seo_title" property="title"/>
    <meta :content="category.seo_description" name="description"/>
    <meta :content="lis" name="keywords"/>
    <meta
        :content="'Gooreo |' + ' ' + category.seo_title"
        property="og:title"
    />
    <meta content="Gooreo " property="og:site_name"/>
    <meta :content="category.seo_description" property="og:description"/>
    <meta content="website" property="og:type"/>
    <meta :content="$route.path" property="og:url"/>
  </teleport>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <!-- Header -->
      <layout-header/>
      <!-- /Header -->
      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"
                      ><img alt="" src="../../../assets/img/home-icon.svg"/>
                        Home
                      </router-link
                      >
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/marketplace">Projects</router-link>
                    </li>
                    <li v-if="!loading" class="breadcrumb-item">
                      <router-link
                          :params="`${category.seo_title}`"
                          :to="`/category/${category.slug}`"
                      >{{ category.title.en }}
                      </router-link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content pt-5">
        <div class="container">
          <h3 class="mx-2">Filters</h3>
          <div class="col-lg-12 d-flex filters justify-content-between mb-4">
            <div class="d-flex">
              <select
                  v-model="fcategory"
                  class="form-control select mx-2"
                  @change="addFilter(fcategory, 'category')"
              >
                <option value="">Category</option>
                <option
                    v-for="item in sections"
                    :key="item.id"
                    :value="item.id"
                >{{ item.title.en }}
                </option
                >
              </select>
              <select
                  v-model="fsection"
                  class="form-control select mx-2"
                  @change="addFilter(fsection, 'section')"
              >
                <option value="">Sub category</option>
                <option
                    v-for="item in subsections"
                    :key="item.id"
                    :value="item.id"
                >{{ item.title.en }}
                </option
                >
              </select>
              <select
                  v-model="projecttype"
                  class="form-control select mx-2"
                  @change="addFilter(projecttype, 'type')"
              >
                <option value="">Project type</option>
                <option value="once">
                  <div>Once</div>
                </option>
                <option value="periodically">
                  <div>Periodically</div>
                </option>
              </select>
              <select
                  v-model="projectlengthtype"
                  class="form-control select mx-2"
                  @change="addFilter(projectlengthtype, 'length')"
              >
                <option value="">Project length</option>
                <option value="day">
                  <div>Day</div>
                </option>
                <option value="week">
                  <div>Week</div>
                </option>
                <option value="month">
                  <div>Month</div>
                </option>
              </select>
              <select
                  v-model="projecttimetype"
                  class="form-control select mx-2"
                  @change="addFilter(projecttimetype, 'time')"
              >
                <option value="">Work time</option>
                <option value="part">
                  <div>Part time</div>
                </option>
                <option value="full">
                  <div>Full time</div>
                </option>
              </select>
            </div>
            <a
                class="my-2 ms-5 orange-text"
                style="cursor: pointer;"
                @click.prevent="clearFilter()"
            >Clear All</a
            >
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <projectscard-skeleton
                  v-for="i in 10"
                  v-if="loading"
              ></projectscard-skeleton>
              <div
                  v-for="item in projects"
                  :key="item.id"
                  class="col-md-6 col-lg-12 col-xl-12"
              >
                <div class="freelance-widget widget-author">
                  <div class="freelance-content ">
                    <button class="favourite " @click="addFavorite(item.id)">
                      <i
                          :class="
                          item.favorited ? 'fas fa-heart' : 'fas fa-heart'
                        "
                          :style="item.favorited ? 'color:#dc5454' : ''"
                      ></i>
                    </button>
                    <div class="author-heading">
                      <div class="freelance-location ">
                        <i class="fas fa-clock   " style="width: 20px;"> </i
                        >{{ item.created_at.humen }}
                      </div>
                      <div class="d-flex ">
                        <div style="  text-align:center; width: 100px;">
                          <router-link
                              :to="`/profile/${item.author.slug}`"
                              class="profile-img"
                          >
                            <img :src="item.author.avatar" alt="author"/>
                          </router-link>

                          <router-link :to="`/profile/${item.author.slug}`">
                            <div class="profile-name">
                              <div
                                  class="author-location mt-1"
                                  style="color: rgb(241, 92, 61);"
                              >
                                {{ item.author.name }}
                                <i
                                    v-if="item.author.verified_id == true"
                                    class="fas fa-check-circle text-success clearfix verified"
                                ></i>
                              </div>
                            </div>
                          </router-link>
                        </div>
                        <router-link
                            :to="`/project/${item.slug}`"
                            tabindex="-1"
                        >
                          <h3 class="mt-3">{{ item.seo_title }}</h3>
                        </router-link>
                      </div>
                      <div class="freelancers-price">
                        ${{ parseFloat(item.project_budget_from)?.toFixed(2) }} - ${{ parseFloat(item.project_budget_to)?.toFixed(2) }}
                      </div>
                    </div>
                    <div class="counter-stats">
                      <ul>
                        <li>
                          <h5>Project Length</h5>
                          <h3 class="counter-value">
                            {{ item.project_length_from }} -
                            {{ item.project_length_to }}
                            {{ item.project_length_type }}
                          </h3>
                        </li>
                        <li>
                          <h5>Proposals</h5>
                          <h3 class="counter-value">{{ item.orders_count }}</h3>
                        </li>
                        <li>
                          <h5>Payment Type</h5>
                          <h3 class="counter-value">
                            <span class="jobtype">{{ item.project_type }}</span>
                          </h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="cart-hover">
                    <router-link
                        :to="`/project/${item.slug}`"
                        class="btn-cart"
                        style="background-color: rgb(241, 92, 61);"
                        tabindex="-1"
                    >Apply now
                    </router-link>
                  </div>
                </div>
              </div>
              <div style="width: 121px" class="pb-4 m-auto">
                <button v-if="this.page < this.pagination.total_pages" class="btn btn-primary m-auto"
                        @click="nextPage(pagination.next_page_url)">
                  Load more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <layout-footer/>
    </div>
  </div>
</template>
<script>
import FilterSkeleton from "../components/skeleton-loaders/filter-skeleton";
import ProjectscardSkeleton from "../components/skeleton-loaders/projectscard-skeleton";

export default {
  components: {ProjectscardSkeleton, FilterSkeleton},
  async created() {
    await this.getData();
  },
  methods: {
    clearFilter() {
      this.fcategory = "";
      this.fsection = "";
      this.projecttype = "";
      this.projectlengthtype = "";
      this.projecttimetype = "";
      this.getData();
    },
    addFavorite(id) {
      this.api.get(`toggleFavorite/${id}`).then((res) => {
        if (res.status == "success") {
          Object.keys(this.projects).map((value, key) => {
            if (this.projects[value].id == id) {
              this.projects[value].favorited = !this.projects[value].favorited;
            }
          });
        } else {
          this.toast(resp.data, 'danger')
        }
      });
    },
    addFilter(f, type) {
      if (type == "category") {
        this.fcategory2 = `cat[parent][id]=${f}`;
        this.fsection2 = "";
        this.subsections = this.sections.filter((s) => s.id == f)[0].childs;
      }
      if (type == "price") {
        this.pricedir2 = `f_params[orderBy][field]=price&f_params[orderBy][type]=${f}`;
      }
      if (type == "sales") {
        this.salesdir2 = `f_params[orderBy][field]=sales&f_params[orderBy][type]=${f}`;
      }
      if (type == "sortby") {
        this.fby2 = `sortBy=${f}`;
      }
      if (type == "direction") {
        this.fdir2 = `direction=${f}`;
      }
      if (type == "tags") {
        this.ftags2 = `tags['title'][]=${f}`;
      }
      if (type == "online") {
        this.fonline2 = f == true ? `is_online=${f == true ? 1 : 0}` : "";
      }
      if (type == "offline") {
        this.foffline2 = f == true ? `is_online=${f == true ? 0 : 1}` : "";
      }
      if (type == "section") {
        this.fsection2 = `section_id=${f}`;
      }
      if (type == "rating") {
        this.frate2 = `avgRating=${f}`;
      }
      if (type == "days") {
        this.fdays2 = `days_count=${f}`;
      }
      if (type == "daysfrom") {
        this.fdaysfrom2 = `days_count[start]=${f}`;
      }
      if (type == "daysto") {
        this.fdaysto2 = `days_count[end]=${f}`;
      }
      let basefil = `${this.pricedir2}&${this.salesdir2}&${this.ftags2}&${this.fonline2}&${this.foffline2}&${this.fcategory2}&${this.fsection2}&${this.frate2}&${this.fdays2}&${this.fdaysfrom2}&${this.fdaysto2}`;
      let fil = [];
      basefil.split("&").forEach((bf) => {
        if (bf.trim() != "" && bf.trim() != 0) {
          fil.push(bf);
        }
      });
      let fl = "";
      fil.forEach(function (f, idx, array) {
        if (idx !== array.length) {
          fl += `${f}&`;
        } else {
          fl += `${f}`;
        }
      });
      this.filter = fl;
      // console.log(this.pricedir)
      this.loading = true;
      this.api
          .get(`section/${this.$route.path.split("/")[2]}?${this.filter}`)
          .then((res) => {
            if (res.status == "success") {
              this.sections = res.data.data.sections;
              this.projects = res.data.data.postes.data;
              this.pagination = res.data.data.postes.pagination;
              this.loading = false;
            } else {
              this.toast(res.data, "danger");
            }
            this.loading = false;
          });
    },
    nextPage(url) {
      if (this.page < this.pagination.total_pages) {
        this.loading = true;
        this.page++;
        this.api.getCustom(url).then((res) => {
          this.projects = this.project.concat(res.data.data.posts.data);
          this.category = res.data.data.sections;
          this.pagination = res.data.data.posts.pagination;
          this.loading = false;
        });
      }
    },
    async getData() {

      if (this.page > 1 && this.page <= this.pagination.total_pages) {
        this.loading = true;
      }
      await this.api
          .get(
              `section/${this.$route.path.split("/")[2]}/project?page=${this.page}`
          )
          .then((res) => {
            if (res.status == "success") {
              this.projects = res.data.data.posts.data;
              this.category = res.data.data.sections;
              this.pagination = res.data.data.posts.pagination;
              this.api.get("generalSetting").then((resp) => {
                if (resp.status == "success") {
                  this.sections = resp.data.data.sections;
                } else {
                  location.replace('404')
                }
              });
              this.projects = res.data.data.posts.data;
              let list = [];
              Object.keys(this.projects).forEach((e) => {
                list.push(this.projects[e].content.en);
              });
              this.lis = list;
              this.category = res.data.data.sections;
              this.content = res.data.data.sections.content.en;
              this.loading = false;
            } else {
              location.replace('404')
            }
          });
    },
  },

  inject: ["api"],
  data() {
    return {
      content: "",
      loading: true,
      page: 1,
      projects: [],
      category: "",
      pagination: {},
      sections: [],
      subsections: [],
      filter: "?",
      fcategory: "",
      fcategory2: "",
      fsection: "",
      fsection2: "",
      projecttype: "",
      projecttype2: "",
      projectlengthtype: "",
      projectlengthtype2: "",
      projecttimetype: "",
      projecttimetype2: "",
    };
  },
};
</script>