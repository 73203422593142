<template>
  <loader v-if="loading"></loader>
  <div v-if="!loading" class="dashboard-page">
    <div class="main-wrapper">
      <!-- Header -->
      <layout-employeeheader/>
      <!-- /Header -->

      <!-- Breadcrumb -->
      <div class="bread-crumb-bar">
        <div class="container">
          <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
              <div class="breadcrumb-list">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/"><img alt="Post Author" src="../../../../assets/img/home-icon.svg"> Home
                      </router-link>
                    </li>

                    <li aria-current="page" class="breadcrumb-item">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->


      <!-- Page Content -->
      <div class="content content-page">
        <div class="container-fluid">
          <div class="row">
            <!-- sidebar -->
            <layout-employeesidebar/>
            <!-- /sidebar -->
            <div class="col-xl-9 col-md-8">
              <div class="dashboard-sec">
                <div class="row center-text ">
                  <div class="col-md-6 col-lg-4">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-title-widget-info">Total Of Sales</div>
                        <div class="dash-widget-count"> $ {{ parseFloat(dashboard.total_of_sales)?.toFixed(2) }}</div>
                      </div>
                      <div class="dash-widget-more">
                        <router-link class="d-flex" to="/user/balance">View Details <i
                            class="fas fa-arrow-right ms-auto"></i></router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-title-widget-info">Withdrawable Balance</div>
                        <div class="dash-widget-count">$ {{
                            parseFloat(dashboard.balance.balance_available)?.toFixed(2)
                          }}
                        </div>
                      </div>
                      <div class="dash-widget-more">
                        <router-link class="d-flex" to="/user/balance">View Details <i
                            class="fas fa-arrow-right ms-auto"></i></router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="dash-widget">
                      <div class="dash-info">
                        <div class="dash-widget-info">Balance</div>
                        <div class="dash-widget-count">$ {{ parseFloat(dashboard.balance.total)?.toFixed(2) }}</div>
                      </div>
                      <div class="dash-widget-more">
                        <router-link class="d-flex" to="/user/balance">View Details <i
                            class="fas fa-arrow-right ms-auto"></i></router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Chart Content -->
                <div class="row">
                  <div class="col-xl-12 d-flex">
                    <div class="card flex-fill">

                      <div class="card-body">
                        <apexchart :options="chartOptions" :series="series" height="350" type="line"></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
                <dashboardwidget></dashboardwidget>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <!-- Footer -->
      <layout-footer/>
      <!-- /Footer -->
    </div>
  </div>
</template>
<script>

import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  async created() {
    await this.getData()
  },
  methods: {

    async getData() {
      this.api.get('user/dashboard').then(res => {

        if (res.status == 'success') {
          this.dashboard = res.data.data
          this.serviceStatistics = res.data.data.service.view
          this.projectStatistics = res.data.data.project.view
          this.userStatistics = res.data.data.user.view
          Object.keys(this.serviceStatistics).forEach(keys => this.chartOptions.xaxis.categories.push([keys]))
          if (this.serviceStatistics.length !== 0) {
            Object.keys(this.serviceStatistics).forEach(item => this.series[0].data.push(this.serviceStatistics[item]))
          } else {
            this.series[0].data.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,)
          }
          if (this.projectStatistics.length !== 0) {
            Object.keys(this.projectStatistics).forEach(item => this.series[1].data.push(this.projectStatistics[item]))
          } else {
            this.series[1].data.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)
          }
          if (this.userStatistics.length !== 0) {
            Object.keys(this.userStatistics).forEach(item => this.series[2].data.push(this.userStatistics[item]))
          } else {
            this.series[2].data.push(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,)
          }
          this.loading = false
        } else {
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  inject: ['api'],
  data() {
    return {
      loading: true,
      activeClass: 'active',
      dashboard: [],
      chartOptions: {
        chart: {
          redrawOnWindowResize: true,
          redrawOnParentResize: true,
          height: '400px',
          zoom: false,
          horizontalAlign: 'right',
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#FB9300', '#F54748', '#343F56'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        title: {
          text: ' Views',
          align: 'left',
          style: {
            fontSize: '16px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 800,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },

        markers: {
          size: 1
        },

        zoom: {
          enabled: false
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5

        },
        xaxis: {

          categories: [],
          lines: {
            zoom: false,
            show: false,
          }
        },
      },
      series: [{
        name: "Services Views",
        data: []
      },
        {
          name: "Projects Views",
          data: []
        },
        {
          name: "Profile Views",
          data: []
        }],
    }
  }
}
</script>
<style>
.vue-apexcharts {
}
</style>
