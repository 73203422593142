<template>
  <div class="home-page">
    <div class="main-wrapper">
      <layout-header/>
      <section class="section home-banner row-middle">
        <div class="container p-5">
          <div class="card p-5 m-5 w-75 mx-auto">
            <h3>Email Verification</h3>
            <p>Please verify your email address to continue using Gooreo, Enter the code we sent to your email in the
              form below.</p>
            <input v-model="code" class="form-control mt-2 w-100" type="text">
            <div class="row justify-content-center">
              <div class="btn col-2  btn-primary mt-3 mb-3 " @click.prevent="verify()">
                <h4 class="text-white justify-content-center my-1">Verify</h4>
              </div>
            </div>
            <p class="center-text">Didn't received the code? <span
                style="color: #009df8; text-decoration: underline; cursor: pointer;"
                @click.prevent="resend()">Click here</span>
              to resend it!</p>
          </div>
        </div>
      </section>
      <layout-footer/>
    </div>
    <loader v-if="loading"></loader>
  </div>
</template>
<script>

export default {
  data() {
    return {
      loading: false,
      code: ''
    }
  },
  methods: {
    resend() {
      this.api.post('auth/sendCodeEmail', {}).then(res => {
        if (res.status == 'success') {
          this.toast('Code successfully sent, check your inbox!', 'success')
        } else {
          this.toast(res.data, 'danger')
        }
      })
    },
    verify() {
      this.api.post('auth/validateEmail', {code: this.code}).then(res => {
        if (res.status == 'success') {
          $cookies.remove('home')
          $cookies.remove('cart')
          $cookies.remove('cartcount')
          $cookies.set('loggedin', false)
          $cookies.remove('token')
          $cookies.remove('user')
          $cookies.remove('menuHeader')
          location.replace('/login')
        } else {
          this.toast(res.data, 'danger')
        }
      })
    }
  },
  inject: ['api'],
}
</script>